import {
  StyledBoxHeight10,
  StyledBoxHeight20,
  StyledGridGap10,
} from '@/components/styled';
import { ImageFields } from '@/contexts/FeaturesContext/types';
import {
  StyledFunctionMessageBox,
  StyledFunctionMessageEnd,
  StyledFunctionMessageStar,
  StyledFunctionPaddingBottom0,
} from '@/features/components/styled/function';
import { memo } from 'react';
import { PointTypeRadio } from './PointTypeRadio';
import { SamTypeSwitch } from './SamTypeSwitch';

type Props = {
  field: ImageFields;
  isBatch?: boolean;
  isKeepMask?: boolean;
};

/**
 * JSDoc
 * @see セグメンテーションの機能を切り替えるコンポーネント
 */
export const Functions = memo(
  ({ field, isBatch = false, isKeepMask = false }: Props): JSX.Element => {
    return (
      <>
        <StyledFunctionPaddingBottom0>
          <StyledGridGap10>
            <SamTypeSwitch field={field} />
            <PointTypeRadio field={field} isKeepMask={isKeepMask} />
            <StyledBoxHeight20 />
            <StyledFunctionMessageBox>
              <StyledFunctionMessageStar>※</StyledFunctionMessageStar>
              <StyledFunctionMessageEnd>
                選択範囲のプレビューで{!isKeepMask ? '赤く' : '青く'}
                {isBatch
                  ? '塗られた領域の色を参照します。'
                  : !isKeepMask
                  ? '塗られた領域が挿げ替えの対象となります。'
                  : '塗られた領域が固定アイテムの対象となります。'}
              </StyledFunctionMessageEnd>
            </StyledFunctionMessageBox>
            <StyledBoxHeight10 />
          </StyledGridGap10>
        </StyledFunctionPaddingBottom0>
      </>
    );
  },
);
