import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import { useEffect } from 'react';

type Props = {
  files: string[];
};

export const useUpdateDataStatus = ({ files }: Props) => {
  const { featureData, updateFeatureDataSingle } = useFeaturesContext({});

  useEffect(() => {
    if (featureData?.single?.genResult?.image) return;
    if (!files.length) return;
    updateFeatureDataSingle('genResult', {
      image: files[0],
    });
  }, [featureData?.single?.genResult?.image, files, updateFeatureDataSingle]);
};
