import { Box } from '@/components/atoms/Box';
import { StyledBoxHeight5, StyledFontSx } from '@/components/styled';
import { memo } from 'react';

type Props = {
  targetFeatureName: string;
  isNoBackground: boolean;
};

export const BackgroundStatus = memo(
  ({ targetFeatureName, isNoBackground }: Props): JSX.Element => {
    return (
      <>
        {targetFeatureName !== 'background' && (
          <>
            <Box
              style={{
                border: `1px solid ${'var(--color-gray-soft)'}`,
                padding: '8px',
                borderRadius: '4px',
                textAlign: 'center',
              }}
            >
              <StyledFontSx sx={{ color: 'var(--color-gray-deep)' }}>
                {isNoBackground ? '背景透過ON' : '背景透過OFF'}
              </StyledFontSx>
            </Box>
            <StyledBoxHeight5 />
          </>
        )}
      </>
    );
  },
);
