import { Task } from '@/api/schemas';
import {
  StyledFont,
  StyledFontBold,
  StyledGridNowrap,
} from '@/components/styled';
import { TASK_STATUS } from '@/constants';
import { StyledDownloadDot } from '@/features/components/styled/icon';
import { useTasksHelpers } from '@/hooks/tasks/useTasksHelpers';
import { memo } from 'react';
import { StyledCompletedStatusBox } from './styled';

type Props = {
  data: Task;
};

export const Completed = memo(({ data }: Props): JSX.Element => {
  const { isDownloaded } = useTasksHelpers();

  return (
    <>
      <StyledGridNowrap>
        <StyledCompletedStatusBox>
          {isDownloaded(data) ? (
            <StyledFont>
              {TASK_STATUS[data.result.status as keyof typeof TASK_STATUS]}
            </StyledFont>
          ) : (
            <StyledFontBold>
              {TASK_STATUS[data.result.status as keyof typeof TASK_STATUS]}
            </StyledFontBold>
          )}

          {!isDownloaded(data) && <StyledDownloadDot />}
        </StyledCompletedStatusBox>
      </StyledGridNowrap>
    </>
  );
});
