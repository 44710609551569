import { BATCH_LIMIT_SET } from '@/constants';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useBatchConstant } from '@/features/components/steps/hooks/useBatchConstant';
import { useBatchCurrent } from '@/features/components/steps/hooks/useBatchCurrent';
import { useCallback, useEffect, useMemo } from 'react';
import { initialBatchState } from './initial';
import { useBatchUploadHandlers } from './useBatchUploadHandlers';

/**
 * @see バッチアップロードのカスタムフック
 * @see グローバルであるuseFeaturesContextをここで読み込んでいる
 * @see バッチ処理から各所でグローバルを呼ばない方針
 */
export const useBatchUpload = () => {
  const {
    featureData,
    activeFeature,
    updateFeatureData,
    updateFeatureDataSingle,
    updateFeatureDataArray,
    activateTargetFeature,
  } = useFeaturesContext({});
  const { batchConstant } = useBatchConstant();
  const { currentNumber, setCurrentNumber, currentId, setCurrentId } =
    useBatchCurrent();

  const batchMode = useMemo(() => {
    if (!featureData) return false;

    return activeFeature;
  }, [activeFeature, featureData]);

  const isBatchLimitReached = useMemo(() => {
    if (!featureData) return false;

    return featureData.batch.length >= BATCH_LIMIT_SET;
  }, [featureData]);

  const { handleAddNewSet, handleChangeCurrentSet, handleDeleteSet } =
    useBatchUploadHandlers({
      featureData,
      updateFeatureDataArray,
      setCurrentNumber,
    });

  const checkUploadImageCondition = useCallback(
    (id: string) => {
      if (!featureData || !batchConstant) return false;
      const found = featureData?.batch.find((set) => set.id === id);
      if (!found) return false;
      if (batchConstant.hasUploadRef) {
        return found.refSam.combinedBase64;
      }
      if (!batchConstant.hasUploadRef) {
        return found.originalImages.length > 0;
      }

      return false;
    },
    [batchConstant, featureData],
  );

  useEffect(() => {
    if (!featureData) return;
    const foundIndex = featureData.batch.findIndex((set) => set.isCurrent);
    if (foundIndex !== -1) {
      setCurrentNumber(foundIndex);
      setCurrentId(featureData.batch[foundIndex].id);
    }
  }, [featureData, setCurrentId, setCurrentNumber]);

  useEffect(() => {
    if (featureData?.batch[0]) return;
    updateFeatureDataArray('batch', [initialBatchState]);
  }, [featureData, updateFeatureDataArray]);

  return {
    batchConstant,
    featureData,
    updateFeatureData,
    updateFeatureDataSingle,
    updateFeatureDataArray,
    activateTargetFeature,
    currentNumber,
    currentId,
    handleAddNewSet,
    handleChangeCurrentSet,
    checkUploadImageCondition,
    handleDeleteSet,
    batchMode,
    isBatchLimitReached,
  };
};
