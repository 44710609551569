import { useCallback, useState } from 'react';

/**
 * JSDoc
 * @see ダイアログの状態を管理するカスタムフック
 * @returns {Object}
 */
export const useDialog = () => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const handleOpenDialog = useCallback(() => {
    setIsOpenDialog(true);
  }, [setIsOpenDialog]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const handleCloseDialog = useCallback(() => {
    setIsOpenDialog(false);
  }, [setIsOpenDialog]);

  return {
    isOpenDialog,
    setIsOpenDialog,
    handleOpenDialog,
    handleCloseDialog,
  };
};
