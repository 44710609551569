import { ContentHeader } from '@/features/components/organisms/ContentHeader';
import {
  StyledScrollBox,
  StyledSingleContentBox,
} from '@/features/components/templates/Layout/styled';
import { memo } from 'react';

type Props = {
  children: React.ReactNode;
};

export const FeaturesSingleLayout = memo(
  ({ children }: Props): JSX.Element => (
    <>
      <ContentHeader />
      <StyledSingleContentBox>
        <StyledScrollBox>{children}</StyledScrollBox>
      </StyledSingleContentBox>
    </>
  ),
);
