import { Task } from '@/api/schemas';
import { QueueIcon } from '@/components/icons/export';
import { StyledFontBold, StyledGridGap5 } from '@/components/styled';
import { TASK_STATUS } from '@/constants';
import { StyledGenerateStatusBox } from '@/features/components/styled/icon';
import hexToRgba from 'hex-to-rgba';
import { memo } from 'react';

type Props = {
  data: Task;
};

export const InQueue = memo(({ data }: Props): JSX.Element => {
  return (
    <>
      <StyledGenerateStatusBox>
        <QueueIcon />
      </StyledGenerateStatusBox>
      <StyledGridGap5>
        <StyledFontBold color={hexToRgba('#000', 0.6)}>
          {TASK_STATUS[data.result.status as keyof typeof TASK_STATUS]}...
        </StyledFontBold>
      </StyledGridGap5>
    </>
  );
});
