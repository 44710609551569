import type { SVGProps } from 'react';
import { memo } from 'react';
const MultiUploadRef = memo((props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={33}
      height={32}
      fill="none"
      {...props}
    >
      <rect
        width={24}
        height={28}
        x={4.5}
        y={3}
        stroke="#BDBDBD"
        strokeWidth={2}
        rx={1}
      />
      <path fill="#fff" d="M12.5 0h8l6 8h-20z" />
      <path
        fill="#BDBDBD"
        fillRule="evenodd"
        d="m16.5 0-7 8h5v8h4V8h5z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default MultiUploadRef;
