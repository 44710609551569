import { PageInfoSchema } from '@/api/schemas/common';
import { OrganizationSchema } from '@/api/schemas/organization';
import { z } from 'zod';

export const UserSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  deletedAt: z.union([z.string(), z.null()]),
  lastLoginAt: z.union([z.string(), z.null()]),
  organizationId: z.string(),
  organization: OrganizationSchema,
  name: z.string(),
  email: z.string(),
  role: z.string(),
})

export const UserResetPasswordSchema = z.null();

export type User = z.infer<typeof UserSchema>;

export const UsersSchema = z.object({
  data: z.array(UserSchema),
  pageInfo: PageInfoSchema,
});

export type Users = z.infer<typeof UsersSchema>;
