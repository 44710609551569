import {
  StyledFlexEnd,
  StyledFlexGap10,
  StyledFont16Bold,
} from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageFields } from '@/contexts/FeaturesContext/types';
import { StyledFunctionPartition } from '@/features/components/styled/function';
import { Switch } from '@mui/material';
import { memo } from 'react';
import { useRequestBackground } from '../../hooks/useRequestBackground';

type Props = {
  field: ImageFields;
};

/**
 * JSDoc
 * @see SAMのマニュアルとオート切り替えるコンポーネント
 * @see オートを選択するとオートSAMへリクエストを送る
 * @see 背景と白抜き機のみ利用可能
 * @param {Props} { field }
 * @returns {JSX.Element}
 */
export const SamTypeSwitch = memo(({ field }: Props): JSX.Element => {
  const { activeFeatureName, featureData, updateFeatureData } =
    useFeaturesContext({});
  const { handleRequestBackground } = useRequestBackground({
    field,
  });

  const handleClickSwitch = () => {
    updateFeatureData(field, {
      samType: featureData?.[field].samType === 'auto' ? 'manual' : 'auto',
    });
    if (featureData?.[field].samType === 'manual') {
      // biome-ignore lint/complexity/noVoid: あとで修正
      void handleRequestBackground();
    } else {
      updateFeatureData(field, {
        isPreviewLoading: false,
        isPreviewCompleted: false,
        maskBase64: '',
        combinedBase64: '',
      });
    }
  };

  return (
    <>
      {activeFeatureName === 'background' ||
      activeFeatureName === 'white' ||
      activeFeatureName === 'colorTemperature' ||
      activeFeatureName === 'backgroundLora' ? (
        <>
          <StyledFlexGap10 style={{ whiteSpace: 'nowrap' }}>
            <StyledFont16Bold>
              {activeFeatureName === 'colorTemperature'
                ? '自動選択'
                : '背景自動選択'}
            </StyledFont16Bold>
            <StyledFlexEnd>
              <Switch
                checked={featureData?.[field].samType === 'auto'}
                onChange={handleClickSwitch}
              />
            </StyledFlexEnd>
          </StyledFlexGap10>
          <StyledFunctionPartition />
        </>
      ) : (
        <></>
      )}
    </>
  );
});
