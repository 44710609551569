import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

type ContainerProps = {
  isLoading: boolean;
  hasBackground: boolean;
  padding: number;
} & BoxProps;

export const StyledSpinnerContainer = memo(
  ({
    isLoading,
    hasBackground,
    padding,
    children,
    ...props
  }: ContainerProps) => {
    const containerStyle = {
      display: isLoading ? 'block' : 'none',
      backgroundColor: hasBackground ? 'var(--color-gray-black)' : '',
      padding: `${padding}px`,
    };

    return (
      <Box
        className={vanilla.styledSpinnerContainer}
        sx={containerStyle}
        {...props}
      >
        {children}
      </Box>
    );
  },
);

export const StyledSpinnerBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledSpinnerBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledSpinnerCancelBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledSpinnerCancelBox} {...props}>
        {children}
      </Box>
    );
  },
);
