import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import { FeaturePresetImage } from '@/contexts/FeaturesContext/types';
import { useDialog } from '@/hooks/local/useDialog';
import { usePopper } from '@/hooks/local/usePopper';
import { useCallback, useRef } from 'react';

type Props = {
  handleResetAfterPreview: () => void;
};

export const useTargetTypeHandlers = ({ handleResetAfterPreview }: Props) => {
  const {
    featureData,
    updateFeatureData,
    updateFeatureDataSingle,
    initialFeatureDataParamState,
    initialFeatureDataGenStatusState,
    initialFeatureDataImageState,
  } = useFeaturesContext({});
  const { isOpenPopper, handleOpenPopper, handleClosePopper } = usePopper();
  const popperAnchorRef = useRef(null);

  const { isOpenDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const {
    isOpenDialog: isOpenPresetDialog,
    handleOpenDialog: handleOpenPresetDialog,
    handleCloseDialog: handleClosePresetDialog,
  } = useDialog();

  const handleClickFeatureTargetType = useCallback(
    (target: string) => {
      handleResetAfterPreview();
      updateFeatureDataSingle('genStatus', initialFeatureDataGenStatusState);
      updateFeatureData('refImage', initialFeatureDataImageState);
      updateFeatureDataSingle('param', {
        ...initialFeatureDataParamState,
        targetType: target,
      });
    },
    [
      handleResetAfterPreview,
      updateFeatureData,
      initialFeatureDataGenStatusState,
      initialFeatureDataImageState,
      initialFeatureDataParamState,
      updateFeatureDataSingle,
    ],
  );

  const handleChangeNewColor = useCallback(
    (color?: string) => {
      updateFeatureDataSingle('param', {
        pickColor: color,
        newColor: color,
      });
    },
    [updateFeatureDataSingle],
  );

  const handleChangeCurrentColor = useCallback(
    (color?: string) => {
      updateFeatureDataSingle('param', {
        pickColor: '',
        currentColor: color,
      });
      handleResetAfterPreview();
    },
    [handleResetAfterPreview, updateFeatureDataSingle],
  );

  const handleSubmit = useCallback(() => {
    updateFeatureDataSingle('param', {
      newRef: featureData?.refImage.base64,
      currentRef: featureData?.refImage.base64,
      refImageName: featureData?.refImage.file?.name,
    });
    handleResetAfterPreview();
    handleCloseDialog();
  }, [
    featureData?.refImage,
    updateFeatureDataSingle,
    handleResetAfterPreview,
    handleCloseDialog,
  ]);

  const handleSubmitPreset = useCallback(
    (presetValue: FeaturePresetImage) => {
      updateFeatureDataSingle('param', {
        presetId: presetValue.id,
        presetName: presetValue.displayName,
        presetUrl: presetValue.examples[0].url,
      });
      handleResetAfterPreview();
      handleClosePresetDialog();
    },
    [updateFeatureDataSingle, handleResetAfterPreview, handleClosePresetDialog],
  );

  return {
    isOpenPopper,
    handleOpenPopper,
    handleClosePopper,
    popperAnchorRef,
    isOpenDialog,
    handleOpenDialog,
    handleCloseDialog,
    handleChangeNewColor,
    handleChangeCurrentColor,
    handleSubmit,
    handleClickFeatureTargetType,
    isOpenPresetDialog,
    handleOpenPresetDialog,
    handleClosePresetDialog,
    handleSubmitPreset,
  };
};
