import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import { PostTasksV2Param } from '@/types/backendApi';
import { useCallback } from 'react';

export const useColorParam = () => {
  const { featureData } = useFeaturesContext({});

  const getColorSingleColorParam = useCallback(() => {
    const param: PostTasksV2Param = {
      generationMode: 'generate_color_variation_single_rgb_color',
      originalImages: [
        {
          inputImageId: featureData?.mainImage.id,
          maskImageId: featureData?.mainImage.maskId,
        }
      ],
      displayName: featureData?.mainImage.fileName,
      nSample: featureData?.single?.param?.generationCount,
      parameters: {
        color: featureData?.single?.param?.currentColor,
        noBackground: featureData?.single?.param?.isNoBackground,
      },
    };

    return param;
  }, [
    featureData?.mainImage.id,
    featureData?.mainImage.maskId,
    featureData?.mainImage.fileName,
    featureData?.single?.param?.currentColor,
    featureData?.single?.param?.generationCount,
    featureData?.single?.param?.isNoBackground,
  ]);

  const getColorRefImageParam = useCallback(() => {
    const param: PostTasksV2Param = {
      generationMode: 'generate_color_variation_single_color',
      originalImages: [
        {
          inputImageId: featureData?.mainImage.id,
          maskImageId: featureData?.mainImage.maskId,
        }
      ],
      displayName: featureData?.mainImage.fileName,
      nSample: featureData?.single?.param?.generationCount,
      refImage: {
        inputImageId: featureData?.refImage?.id,
        maskImageId: featureData?.refImage?.maskId
      },
      parameters: {
        noBackground: featureData?.single?.param?.isNoBackground,
      },
    };

    return param;
  }, [
    featureData?.mainImage.id,
    featureData?.mainImage.maskId,
    featureData?.mainImage.fileName,
    featureData?.single?.param?.generationCount,
    featureData?.single?.param?.isNoBackground,
    featureData?.refImage?.id,
    featureData?.refImage?.maskId,
  ]);

  return {
    getColorSingleColorParam,
    getColorRefImageParam,
  };
};
