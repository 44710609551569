import { Task } from '@/api/schemas';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { Box } from '@mui/material';
import { memo, useMemo } from 'react';
import { BackgroundLoraNotice } from './BackgroundLoraNotice';
import { FailedDisplay } from './FailedDisplay';

type Props = {
  taskApiResponse: Task | undefined;
  setRetryId: (retryId: string) => void;
  batchId: string;
};

export const Functions = memo(
  ({ taskApiResponse, setRetryId, batchId }: Props): JSX.Element => {
    const { activeFeatureName } = useFeaturesContext({});
    const isError = useMemo(() => {
      return (
        taskApiResponse &&
        (taskApiResponse.result.status === 'FAILED' ||
          taskApiResponse.result.status === 'TIME_OUT')
      );
    }, [taskApiResponse]);

    return (
      <>
        <div
          style={{
            padding: '24px',
            height: '100%',
          }}
        >
            {!isError && activeFeatureName === 'backgroundLora' && (
              // eslint-disable-next-line react/jsx-no-undef
              <BackgroundLoraNotice />
            )}
            {isError && (
              <Box sx={{marginTop: '10px'}}>
                <FailedDisplay setRetryId={setRetryId} batchId={batchId} />
              </Box>
            )}
        </div>
      </>
    );
  },
);
