import { memo } from 'react';

type Props = {
  color?: string;
};
const ItemIcon = memo(({ color = '#fff' }: Props): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
      <path
        fill={color}
        d="M16 6c-3 0-4.563-1.688-5-3-4.802 1.104-8.128 2.873-9.412 3.633a.935.935 0 0 0-.42 1.042l1.59 6.355a1 1 0 0 0 1.212.728l1.787-.447A1 1 0 0 1 7 15.28V28a1 1 0 0 0 1 1h8V6ZM16 6c3.001 0 4.563-1.688 5-3 4.802 1.104 8.128 2.873 9.412 3.633.36.213.52.636.42 1.042l-1.59 6.355a1 1 0 0 1-1.212.727l-1.787-.446a1 1 0 0 0-1.242.97V28a1 1 0 0 1-1 1h-8V6Z"
      />
      <path fill="#78909C" d="M2 21h28v11H2z" />
      <path
        fill="#fff"
        d="M7.884 22.86c.62 0 1.14.06 1.56.18.42.113.733.3.94.56.213.26.32.61.32 1.05 0 .273-.047.52-.14.74-.094.22-.23.4-.41.54-.174.14-.39.233-.65.28v.05c.266.047.503.133.71.26.213.12.38.297.5.53.126.227.19.527.19.9 0 .433-.104.803-.31 1.11-.207.307-.504.54-.89.7-.38.16-.834.24-1.36.24h-2.61v-7.14h2.15Zm.17 2.91c.506 0 .853-.08 1.04-.24.193-.16.29-.397.29-.71 0-.32-.114-.55-.34-.69-.227-.14-.59-.21-1.09-.21h-.94v1.85h1.04Zm-1.04 1.03v2.13h1.16c.52 0 .88-.1 1.08-.3.206-.2.31-.467.31-.8a1.1 1.1 0 0 0-.13-.55c-.087-.153-.237-.27-.45-.35-.214-.087-.504-.13-.87-.13h-1.1Zm7.42-2.32c.487 0 .904.1 1.25.3.354.193.627.473.82.84.194.36.29.8.29 1.32v.64h-3.58c.014.493.15.877.41 1.15.26.267.62.4 1.08.4.354 0 .667-.033.94-.1a5.37 5.37 0 0 0 .86-.31v1.01c-.266.127-.543.22-.83.28-.286.06-.63.09-1.03.09-.526 0-.996-.1-1.41-.3a2.265 2.265 0 0 1-.96-.93c-.226-.413-.34-.927-.34-1.54 0-.62.104-1.14.31-1.56.214-.427.507-.747.88-.96.374-.22.81-.33 1.31-.33Zm0 .93c-.333 0-.606.11-.82.33-.206.213-.33.533-.37.96h2.33c0-.253-.043-.477-.13-.67a.95.95 0 0 0-.37-.45c-.166-.113-.38-.17-.64-.17Zm5.73 3.68c.16 0 .314-.013.46-.04.148-.033.284-.07.41-.11v.94c-.133.06-.306.11-.52.15-.212.047-.436.07-.67.07-.306 0-.586-.05-.84-.15a1.298 1.298 0 0 1-.6-.54c-.146-.253-.22-.607-.22-1.06v-2.82h-.73v-.55l.8-.44.4-1.15h.79v1.19h1.54v.95h-1.54v2.81c0 .253.068.443.2.57.134.12.308.18.52.18Zm4.123-4.61c.7 0 1.23.153 1.59.46.367.3.55.773.55 1.42V30h-.89l-.25-.75h-.04a3.056 3.056 0 0 1-.48.49 1.46 1.46 0 0 1-.56.27c-.206.06-.46.09-.76.09-.32 0-.61-.06-.87-.18a1.365 1.365 0 0 1-.6-.55c-.146-.253-.22-.57-.22-.95 0-.56.204-.98.61-1.26.414-.287 1.037-.443 1.87-.47l.94-.03v-.27c0-.347-.083-.593-.25-.74-.166-.147-.4-.22-.7-.22-.273 0-.536.04-.79.12-.253.08-.5.173-.74.28l-.4-.88c.267-.14.57-.253.91-.34.347-.087.707-.13 1.08-.13Zm.23 2.98c-.553.02-.936.117-1.15.29a.817.817 0 0 0-.32.68c0 .253.077.437.23.55.154.113.35.17.59.17.367 0 .677-.103.93-.31.254-.213.38-.527.38-.94v-.46l-.66.02Z"
      />
    </svg>
  );
});
export default ItemIcon;
