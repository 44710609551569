import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { TabButton } from '@/features/components/atoms/TabButton/TabButton';
import { ResultDisplayName } from '@/features/components/functions/ResultDisplayName';
import { BackgroundPresetDialog } from '@/features/components/modals/BackgroundPresetDialog/BackgroundPresetDialog';
import { UploadRefWithOptionalSam } from '@/features/components/modals/UploadRefWithOptionalSam';
import { useBatchOptionalContext } from '@/features/components/steps/hooks/useBatchOptionalContext';
import { memo, useMemo } from 'react';
import { FunctionsDialogs, FunctionsHandlers } from '../hooks/types';
import { useUpdateRefImage } from '../hooks/useUpdateRefImage';
import { ResultBox } from './ResultBox';
import {
  StyledTabButtonList,
  StyledTabButtonListChild,
  StyledTitle,
} from './styled';

type Props = {
  currentNumber: number;
  handlers: FunctionsHandlers;
  dialogs: FunctionsDialogs;
  isSubmit: boolean;
  setIsSubmit: (isSubmit: boolean) => void;
  targetType: string;
  backgroundUrl: string;
  presetUrl: string;
  presetDisplayName: string;
};
export const TargetTypeForBackgroundLora = memo(
  ({
    currentNumber,
    handlers,
    dialogs,
    isSubmit,
    setIsSubmit,
    targetType,
    backgroundUrl,
    presetUrl,
    presetDisplayName,
  }: Props): JSX.Element => {
    const { featureData } = useFeaturesContext({});

    useUpdateRefImage({
      currentNumber,
      isSubmit,
      setIsSubmit,
    });
    const { isCollectiveSetting, collectiveRefImage } =
      useBatchOptionalContext();

    const refImageDisplayName = useMemo(() => {
      if (isCollectiveSetting) {
        return collectiveRefImage.displayName;
      }

      return featureData?.batch[currentNumber]?.refImage?.displayName;
    }, [
      isCollectiveSetting,
      featureData?.batch,
      currentNumber,
      collectiveRefImage.displayName,
    ]);

    return (
      <>
        <StyledTitle>背景のタイプ</StyledTitle>
        <StyledTabButtonList>
          <StyledTabButtonListChild>
            <TabButton
              onClick={() => {
                handlers.handleClickFeatureTargetType('image');
              }}
              label="画像"
              isActive={targetType === 'image'}
            />
          </StyledTabButtonListChild>
          <StyledTabButtonListChild>
            <TabButton
              onClick={() => {
                handlers.handleClickFeatureTargetType('preset');
              }}
              label="プリセット"
              isActive={targetType === 'preset'}
            />
          </StyledTabButtonListChild>
        </StyledTabButtonList>
        <ResultBox
          currentNumber={currentNumber}
          handleOpenBackgroundDialogWithLoading={
            dialogs.handleOpenBackgroundDialogWithLoading
          }
          handleOpenPresetDialog={dialogs.handleOpenPresetDialog}
          targetType={targetType}
          backgroundUrl={backgroundUrl}
          presetUrl={presetUrl}
        />
        {targetType === 'image' && refImageDisplayName && (
          <ResultDisplayName displayName={refImageDisplayName} />
        )}
        {targetType === 'preset' && presetDisplayName && (
          <ResultDisplayName displayName={presetDisplayName} />
        )}
        <UploadRefWithOptionalSam
          isOpenDialog={dialogs.isOpenBackgroundDialog}
          onClose={dialogs.handleCloseBackgroundDialog}
          dialogTitle="参照する画像をアップロード"
          dialogExplain="背景を参照する画像をアップロードしてください"
          onSubmit={handlers.handleSubmitBackground}
        />
        <BackgroundPresetDialog
          isOpen={dialogs.isOpenPresetDialog}
          onClose={dialogs.handleClosePresetDialog}
          dialogTitle="背景プリセットを選択"
          onSubmit={handlers.handleSubmitPreset}
        />
      </>
    );
  },
);
