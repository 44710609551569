import { memo } from 'react';
import { Dashboard } from './uniques/Dashboard';

/**
 * JSDoc
 * @see ホームコンポーネント
 * @returns {JSX.Element}
 */
export const Home = memo((): JSX.Element => {
  return (
    <>
      <Dashboard />
    </>
  );
});
