import {
  Download,
  Segmentation,
  Setting,
  Upload,
} from '@/features/components/steps';

import {
  BiSolidDownload as downloadIcon,
  BiEditAlt as infoIcon,
  BiSolidCamera as uploadIcon,
} from 'react-icons/bi';

export const initialStepStates = [
  {
    title: '画像のアップロード',
    name: 'upload',
    num: 1,
    explain: '使用する画像をアップロードして下さい。',
    icon: uploadIcon,
    component: Upload,
    isActive: true,
  },
  {
    title: '変更する背景領域の確認',
    name: 'segmentation',
    num: 2,
    explain: '被写体（白抜きしない部分）を選択してください',
    component: Segmentation,
    isActive: false,
  },
  {
    title: 'プレビューの確認',
    name: 'setting',
    num: 3,
    explain: 'プレビューに使用する画像を一つ選択してください',
    icon: infoIcon,
    component: Setting,
    isActive: false,
  },
  {
    title: 'ダウンロード',
    name: 'download',
    num: 4,
    explain:
      'プレビュー画像からダウンロードするものを選択し、出力形式を設定してください',
    icon: downloadIcon,
    component: Download,
    isActive: false,
  },
];

export const initialDataParamState = {
  isNoBackground: false,
  keepMaskImage: '',
  targetType: 'color',
  pickColor: '',
  newColor: '',
  currentColor: '',
  newRef: '',
  currentRef: '',
  withShadow: 'on',
  blurLevel: 'none',
  generationCount: 1,
  format: 'png',
};
