import { Button } from '@/components/atoms/Button';
import {
  StyledBoxHeight10,
  StyledFlexGap10,
  StyledFont16Bold,
  StyledGridGap10,
} from '@/components/styled';
import { FeatureData } from '@/contexts/FeaturesContext/types';
import { FunctionViewType } from '@/features/components/steps/BatchUpload/types';
import { memo } from 'react';

type Props = {
  functionViewType: FunctionViewType;
  featureData: FeatureData | undefined;
  currentNumber: number;
  handleClearDeleteImages: () => void;
  handleDeleteSelectedImages: () => void;
};

export const DeleteStatus = memo(
  ({
    functionViewType,
    featureData,
    currentNumber,
    handleClearDeleteImages,
    handleDeleteSelectedImages,
  }: Props): JSX.Element => {
    return (
      <>
        {functionViewType === 'delete' &&
          featureData?.batch &&
          featureData?.batch.length > 0 &&
          featureData?.batch[currentNumber].deleteImages &&
          featureData?.batch[currentNumber].deleteImages.length >= 1 && (
            <>
              <StyledGridGap10>
                <StyledFont16Bold>ファイルを選択</StyledFont16Bold>
                <StyledFlexGap10>
                  選択中：
                  {featureData?.batch[currentNumber].deleteImages.length}
                </StyledFlexGap10>
              </StyledGridGap10>
              <StyledBoxHeight10 />
              <StyledGridGap10 style={{ gap: 24 }}>
                <>
                  <Button
                    mode="border"
                    style={{ fontSize: '15px' }}
                    onClick={handleClearDeleteImages}
                  >
                    選択をクリア
                  </Button>
                  <Button
                    style={{ fontSize: '15px' }}
                    onClick={handleDeleteSelectedImages}
                  >
                    選択したファイルを削除
                  </Button>
                </>
              </StyledGridGap10>
            </>
          )}
      </>
    );
  },
);
