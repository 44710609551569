import { Task } from '@/api/schemas';

export const useTasksHelpers = () => {
  const isDownloaded = (data: Task) => {
    return data.result.resultImages.some((resultImage) => {
      return Boolean(resultImage.downloadedAt);
    });
  };

  return { isDownloaded };
};
