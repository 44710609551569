import { EditSetNameDialog } from '@/features/components/modals/EditSetNameDialog';
import { useDialog } from '@/hooks/local/useDialog';
import { memo } from 'react';
import { useReEditAtom } from '../hooks/useReEditAtom';
import { Body } from './Body';
import { Head } from './Head';

export const SetTable = memo((): JSX.Element => {
    const {
      isOpenDialog: isOpenSetNameDialog,
      handleOpenDialog: handleOpenSetNameDialog,
      handleCloseDialog: handleCloseSetNameDialog,
    } = useDialog();

    const {tmpDisplayName, setTmpDisplayName} = useReEditAtom()

    return (
      <>
        <Head
        />
        <Body
          handleOpenSetNameDialog={handleOpenSetNameDialog}
        />
        <EditSetNameDialog
          isOpenDialog={isOpenSetNameDialog}
          handleCloseDialog={handleCloseSetNameDialog}
          featureName='reEdit'
          displayNameForReEdit={tmpDisplayName}
          setDisplayNameForReEdit={setTmpDisplayName}
        />
      </>
    );
  },
);
