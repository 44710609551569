// import { Button } from '@mui/material';
import { Button } from '@/components/atoms/Button';
import { Button as MUIButton } from '@mui/material';
import Image from 'next/image';
import { memo } from 'react';
import { MdErrorOutline, MdOutlineCheckCircle } from 'react-icons/md';
import { SamDialog } from '../SamDialog/SamDialog';
import { cardTextWrapperStyle, iconWrapperStyle, imageInformationWrapperStyle, imageWrapperStyle, normalCardStyle, normalCardTextStyle, spanStyle, subtitleStyle, titleStyle, warningCardStyle, warningCardTextStyle, wrapperStyle } from './Functions.css';
import { useSamDialog } from './hooks/useSamDialog';

export const Functions = memo((): JSX.Element => {
    const {
      maskImagesMap,
      selectedIndex,
      selectedImage,
      selectedImageSize,
      selectedImageSizeText,
      isOpenDialog,
      handleOpenDialog,
      handleCloseDialog,
      handleSubmitMaskImages,
    } = useSamDialog()

    if (selectedIndex === undefined || selectedImage === undefined) return <></>

    const hasMaskImage = !!maskImagesMap?.[selectedIndex]

    return (
      <>
        <div className={wrapperStyle}>
          <div className={imageInformationWrapperStyle}>
            <h2 className={titleStyle}>画像データ</h2>
            {selectedImage?.inputImage.originalImageUrl &&
              <div className={imageWrapperStyle}>
                <Image
                  src={selectedImage?.inputImage.originalImageUrl}
                  width={272}
                  height={268}
                  alt=""
                />
              </div>
            }
            <h3 className={subtitleStyle}>画像情報</h3>
            <span className={spanStyle}>{selectedImage?.inputImage.fileName}</span>
            <span className={spanStyle}>{selectedImageSizeText}</span>
          </div>
          {hasMaskImage ? (
            <div className={normalCardStyle}>
              <div className={cardTextWrapperStyle}>
                <div className={iconWrapperStyle}>
                  <MdOutlineCheckCircle
                    color='var(--color-primary-main)'
                    size={22}
                  />
                </div>
                <span className={normalCardTextStyle}>
                  領域を選択済み
                </span>
              </div>
              <Button
                mode="border"
                sx={{
                  width: '240px',
                  height: '42px',
                  color: 'var(--color-primary-main)',
                  '&:hover': {
                    cursor: 'pointer',
                    color: 'var(--color-gray-main)',
                    backgroundColor: 'transparent',
                  },
                  fontSize: '15px',
                }}
                onClick={handleOpenDialog}
              >
                選択範囲を修正
              </Button>
              {/* <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenDialog}
              >
                選択範囲を修正
              </Button> */}
            </div>
            ):(
            <div className={warningCardStyle}>
              <div className={cardTextWrapperStyle}>
                <div className={iconWrapperStyle}>
                  <MdErrorOutline
                    color='var(--error-main)'
                    size={22}
                  />
                </div>
                <span className={warningCardTextStyle}>
                  色温度を選択する領域が選択されていません
                </span>
              </div>
              <MUIButton
                variant="contained"
                color="error"
                onClick={handleOpenDialog}
              >
                領域を選択
              </MUIButton>
            </div>
          )}
        </div>
        <SamDialog
          isOpenDialog={isOpenDialog}
          handleCloseDialog={handleCloseDialog}
          handleSubmitMaskImages={handleSubmitMaskImages}
          dialogTitle="色温度を調整する領域を選択"
          selectedImage={selectedImage}
          selectedImageSize={selectedImageSize}
        />
      </>
    );
  },
);

