import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { memo } from 'react';
import { StyledSmallCircle, StyledSmallCircleContainer } from '../../styled';

type Props = {
  activeStepName: string;
};

export const Circle = memo(({ activeStepName }: Props): JSX.Element => {
  const { featureData, activeFeatureName } = useFeaturesContext({});

  return (
    <StyledSmallCircleContainer>
      {activeFeatureName === 'white' && activeStepName === 'setting' ? (
        <StyledSmallCircle
          sx={{
            backgroundColor: 'white',
          }}
        />
      ) : featureData?.single?.param?.targetType === 'color' &&
        featureData?.single?.param?.currentColor ? (
        <StyledSmallCircle
          sx={{
            backgroundColor: featureData?.single?.param?.currentColor,
          }}
        />
      ) : featureData?.single?.param?.targetType === 'image' &&
        featureData?.single?.param?.currentRef ? (
        <StyledSmallCircle
          sx={{
            background: `url(${featureData?.single?.param?.currentRef})`,
          }}
        />
      ) : (
        <></>
      )}
    </StyledSmallCircleContainer>
  );
});
