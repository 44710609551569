import { BreadcrumbListArrow } from '@/components/icons';
import { StyledBoxHeight24, StyledFont16Nowrap } from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import { memo } from 'react';
/**
 * JSDoc
 * @see パンくずリスト風のタイトル
 * @see 画像を変えてリピート生成時は後ろにタイトルを追加
 * @returns {JSX.Element}
 */
export const BreadcrumbTitle = memo((): JSX.Element => {
  const {
    activeFeatureName,
    findActiveFeature,
    findActiveSub,
    findActiveStep,
  } = useFeaturesContext({});

  return (
    <>
      <StyledFont16Nowrap>{findActiveFeature()?.main.title}</StyledFont16Nowrap>

      {findActiveFeature()?.main.contentTitle && (
        <>
          <StyledBoxHeight24>
            <BreadcrumbListArrow />
          </StyledBoxHeight24>
          <StyledFont16Nowrap>
            {findActiveFeature()?.main.contentTitle}
          </StyledFont16Nowrap>
        </>
      )}

      {findActiveSub() && (
        <>
          <StyledBoxHeight24>
            <BreadcrumbListArrow />
          </StyledBoxHeight24>
          <StyledFont16Nowrap>{findActiveSub()?.title}</StyledFont16Nowrap>
        </>
      )}
      {findActiveStep() && (
        <>
          <StyledBoxHeight24>
            <BreadcrumbListArrow />
          </StyledBoxHeight24>
          <StyledFont16Nowrap>
            {activeFeatureName === 'colorTemperature' ||
            activeFeatureName === 'backgroundLora' ||
            activeFeatureName === 'multiWhite'
              ? '複数枚のパターンを生成'
              : activeFeatureName === 'reEditColorTemperature'
                ? '複数枚のパターンを再生成'
                : '1枚のパターンを生成'
            }
          </StyledFont16Nowrap>
        </>
      )}
    </>
  );
});
