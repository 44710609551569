import { StyledGridGap10 } from '@/components/styled';
import { FeatureBatchOriginalImage } from '@/contexts/FeaturesContext/types';
import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { memo } from 'react';
import { Thumbnail } from './Thumbnail';
import { StyledImageSetContainer } from './styled';

type Props = {
  targetId: string;
  targetNumber: number;
};

/**
 * @see 調整画像をアップロード・表示するコンポーネント
 * @see 色温度と背景Loraで挙動が異なる
 * @see 色温度は領域選択なし
 * @see 背景Loraは領域選択あり
 * @see 背景Loraの場合は領域選択語の合成画像を表示する
 */
export const UploadedImages = memo(
  ({ targetId, targetNumber }: Props): JSX.Element => {
    const { featureData, currentNumber } = useBatchUpload();

    return (
      <>
        {featureData &&
          featureData.batch.length > 0 &&
          featureData?.batch[targetNumber]?.originalImages.length > 0 && (
            <StyledGridGap10>
              <StyledImageSetContainer>
                <>
                  {featureData?.batch[targetNumber]?.originalImages.map(
                    (image: FeatureBatchOriginalImage, index: number) => (
                      <Thumbnail
                        key={`thumbnail-with-check-${index}`.toString()}
                        image={image}
                        index={index}
                        featureData={featureData}
                        targetNumber={targetNumber}
                      />
                    ),
                  )}
                </>
              </StyledImageSetContainer>
            </StyledGridGap10>
          )}
      </>
    );
  },
);
