import { Button } from '@/components/atoms/Button';
import { StyledFlexGap10, StyledFlexGap5 } from '@/components/styled';
import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { memo, useMemo } from 'react';
import { MdAdd } from 'react-icons/md';

type Props = {
  resetWarnings: () => void;
  isBatchLimitReached: boolean;
};

/**
 * @see セットの追加ボタンコンポーネント
 * @see 進行セットが未完、セット上限に達した場合はdisabled
 */
export const AddSetButton = memo(
  ({ resetWarnings, isBatchLimitReached }: Props): JSX.Element => {
    const { handleAddNewSet, featureData, batchConstant } = useBatchUpload();

    const canAddSet = useMemo(() => {
      if (!featureData) return false;
      if (!featureData.batch[0]) return false;
      if (!batchConstant) return false;
      if (batchConstant.hasUploadRef && !featureData.batch[0].refImage.refUrl)
        return false;
      if (featureData.batch[0].originalImages.length <= 0) return false;

      return featureData.batch.every((set) => set.originalImages.length > 0);
    }, [batchConstant, featureData]);

    const handleAddNewSetWithResetWarnings = () => {
      resetWarnings();
      handleAddNewSet();
    };

    return (
      <>
        <StyledFlexGap5>
          <Button
            width={166}
            height={30}
            mode="primary"
            style={{ fontSize: 13 }}
            onClick={handleAddNewSetWithResetWarnings}
            disabled={!canAddSet || isBatchLimitReached}
          >
            <StyledFlexGap10>
              <MdAdd />
              セットをさらに追加
            </StyledFlexGap10>
          </Button>
        </StyledFlexGap5>
      </>
    );
  },
);
