import type { SVGProps } from 'react';
import { memo } from 'react';
const BreadcrumbListArrow = memo(
  (props: SVGProps<SVGSVGElement>): JSX.Element => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={24}
        fill="none"
        {...props}
      >
        <path stroke="#BDBDBD" strokeWidth={2} d="m4 1 8 11-8 11" />
      </svg>
    );
  },
);
export default BreadcrumbListArrow;
