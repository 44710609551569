import { PageInfo } from '@/api/schemas';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

type Props = {
  pageInfo: PageInfo | undefined;
};

/**
 * JSDoc
 * @see ページネーションの状態を管理するカスタムフック
 * @param {Props} props
 * @returns {Object}
 */
export const usePaginator = ({ pageInfo }: Props) => {
  const [count, setCount] = useState(1);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);

  const handleChangePage = useCallback(
    (e: ChangeEvent<unknown>, value: number) => {
      if (pageInfo === undefined) return;
      setPage(value);
      setOffset(Math.ceil(pageInfo.limit * (value - 1)));
    },
    [pageInfo],
  );
  useEffect(() => {
    if (pageInfo?.offset) return;
    setPage(1);
  }, [pageInfo?.offset]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!pageInfo) return;
    setCount(Math.ceil(pageInfo.total / pageInfo.limit));
  }, [page, pageInfo]);

  return { count, offset, page, handleChangePage };
};
