// import { useSnackbar } from '@/hooks/global/useSnackbar';
import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { StyledFlexCenter, StyledGridGap25 } from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useBatchOptionalContext } from '@/features/components/steps/hooks/useBatchOptionalContext';
import { memo, useCallback } from 'react';
import { MdChevronLeft } from 'react-icons/md';
import { useGenerationCondition } from './hooks/useGenerationCondition';
import { styledVerticalAlignBottom } from './styled/vanilla.css';

export const Buttons = memo((): JSX.Element => {
  const { activateTargetStep } = useFeaturesContext({});
  const { setIsBatchGenerating } = useBatchOptionalContext();

  const handleNext = useCallback(() => {
    setIsBatchGenerating(true);
    activateTargetStep('batchDownload');
  }, [activateTargetStep, setIsBatchGenerating]);

  const { isVariationGenerate } = useGenerationCondition();

  return (
    <>
      <StyledFlexCenter>
        <Box className={styledVerticalAlignBottom}>
          <StyledGridGap25 style={{paddingTop: '24px'}}>
            <Button
              mode="border"
              style={{
                fontSize: 15,
                fontWeight: 500,
                width: 272,
                height: 42,
              }}
              onClick={() => {
                activateTargetStep('batchUpload');
              }}
            >
              <MdChevronLeft size={20} />
              アップロード画面へ戻る
            </Button>
            <>
              <Button
                width={272}
                height={42}
                onClick={handleNext}
                disabled={!isVariationGenerate}
              >
                バリエーションの生成
              </Button>
            </>
            <Box />
          </StyledGridGap25>
        </Box>
      </StyledFlexCenter>
    </>
  );
});
