import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledSwiperContainer = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledSwiperContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledBoxWidth660 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxWidth660} {...props}>
      {children}
    </Box>
  );
});
