import { COLOR_TEMPERATURE_BATCH_LIMIT_IMAGES } from '@/constants';
import { BatchDownload, BatchUpload } from '@/features/components/steps';

export const initialStepStates = [
  {
    title: '画像のアップロード',
    name: 'batchUpload',
    num: 1,
    explain:
      '色温度を参照する画像を指定し、調整する画像（上限20枚まで）をアップロードしてください',
    component: BatchUpload,
    isActive: true,
  },
  {
    title: '画像のダウンロード',
    name: 'batchDownload',
    num: 2,
    explain: '生成された画像を確認し、ダウンロードしてください',
    component: BatchDownload,
    isActive: false,
  },
];

export const initialDataParamState = {
  isNoBackground: false,
  keepMaskImage: '',
  targetType: 'image',
  pickColor: '',
  newColor: '',
  currentColor: '',
  newRef: '',
  currentRef: '',
  withShadow: 'on',
  blurLevel: 'none',
  generationCount: 1,
  format: 'png',
  batchLimitImages: COLOR_TEMPERATURE_BATCH_LIMIT_IMAGES,
};
