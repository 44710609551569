import { FeatureData } from '@/contexts/FeaturesContext/types';
import { BatchWarnings } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { useDialog } from '@/hooks/global/useDialog';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { FileResult } from './types';
import { useGetBase64WithResizeAfterCheck } from './useGetBase64WithResizeAfterCheck';
import { usePostImages } from './usePostImages';
import { useUpdateOriginalImages } from './useUpdateOriginalImages';

type Props = {
  featureData: FeatureData | undefined;
  updateFeatureDataArray: (key: keyof FeatureData, value: unknown) => void;
  updateWarningKeyValue: (key: keyof BatchWarnings, value: unknown) => void;
  resetWarnings: () => void;
  acceptedFiles: File[];
  targetNumber: number;
  apiResponse: FileResult[];
  setApiResponse: Dispatch<SetStateAction<FileResult[]>>;
};
/**
 * JSDoc
 * @see 調整画像をアップロードするカスタムフック
 * @see
 */
export const useUploadImage = ({
  featureData,
  updateFeatureDataArray,
  updateWarningKeyValue,
  resetWarnings,
  acceptedFiles,
  targetNumber,
  apiResponse,
  setApiResponse,
}: Props) => {
  const { handleOpenDialog, handleCloseDialog } = useDialog();

  /**
   * @see base64画像をpostして画像URLを取得する
   */
  const { handlePostImages } = usePostImages({
    handleOpenDialog,
    handleCloseDialog,
    setApiResponse,
  });

    /**
   * @see 画像を取得してbase64に変換する
   * @see エラー処理も行う
   * @see 必要であればリサイズしてbase64に変換する
   */
  const { successFiles } = useGetBase64WithResizeAfterCheck({
    featureData,
    updateWarningKeyValue,
    resetWarnings,
    acceptedFiles,
    targetNumber,
    handlePostImages,
  });

  /**
   * @see 調整画像のurlとidをfeaturesContext.batchに更新する
   */
  useUpdateOriginalImages({
    featureData,
    updateFeatureDataArray,
    acceptedFiles,
    targetNumber,
    handleCloseDialog,
    apiResponse,
    setApiResponse,
  });

  const handleRetryPostImages = useCallback(() => {
    handlePostImages(successFiles);
  }, [handlePostImages, successFiles]);

  return {
    handleRetryPostImages,
  }
};
