import {
  BackgroundIcon,
  BackgroundIcon as BackgroundLoraIcon,
  ColorIcon,
  ColorTemperatureIcon,
  GalleryIcon,
  HomeIcon,
  ItemIcon,
  SettingIcon,
  WhiteIcon,
} from '@/components/icons';
import { Admin } from '@/features/Admin';
import { Background } from '@/features/Background';
import { BackgroundLora } from '@/features/BackgroundLora';
import { Color } from '@/features/Color';
import { ColorTemperature } from '@/features/ColorTemperature';
import { Gallery } from '@/features/Gallery';
import { Home } from '@/features/Home';
import { Item } from '@/features/Item';
import { MultiWhite } from '@/features/MultiWhite';
import { ReEditColorTemperature } from '@/features/ReEditColorTemperature';
import { White } from '@/features/White';
import { Feature } from '../types';

export const initialFeatureDataApiState = {
  id: '',
  useId: '',
  email: '',
  organizationId: '',
  generationBatchId: '',
  category: '',
  taskType: '',
  originalImageUrl: '',
  originalImageFileName: '',
  originalImageFormat: '',
  maskImageUrl: '',
  refImageUrl: '',
  refMaskImageUrl: '',
  taskId: '',
  errorMessages: '',
};

export const initialFeatureDataImageState = {
  file: null,
  fileName: '',
  blobUrl: '',
  originalSize: { width: 0, height: 0, bytes: 0 },
  resizedSize: { width: 0, height: 0, bytes: 0 },
  base64: '',
  samType: '',
  pointType: 'positive',
  positivePoints: [],
  negativePoints: [],
  apiPositivePoints: [],
  apiNegativePoints: [],
  isLoupeEnable: true,
  isLoupeDisplay: false,
  isPinDisplay: true,
  isPinLock: false,
  isPreviewLoading: false,
  isPreviewCompleted: false,
  maskBase64: '',
  combinedBase64: '',
  combinedBase64Thumbnail120: '',
  combinedBase64Thumbnail268: '',
  combinedBase64Thumbnail512: '',
  kmPointType: 'positive',
  kmPositivePoints: [],
  kmNegativePoints: [],
  kmApiPositivePoints: [],
  kmApiNegativePoints: [],
  isKmPreviewLoading: false,
  isKmPreviewCompleted: false,
  keepMaskBase64: '',
  keepMaskCombinedBase64: '',
};

export const initialFeatureDataGenStatusState = {
  isEditing: false,
  isGenerating: false,
  isRequesting: false,
  isStop: false,
  isGenerated: false,
  isForceClear: false,
};

export const initialFeatureDataGenResultState = {
  taskId: '',
  generatedImageIds: [],
  generatedImageResults: [],
  image: '',
};

export const initialFeatureDataPresetImageState = {
  id: '',
  displayName: '',
  examples: [],
};

export const initialFeatureBatchRefImageState = {
  refImageId: '',
  refUrl: '',
  maskImageId: '',
  maskUrl: '',
  keepMaskImageId: '',
  keepMaskUrl: '',
  refImageSelectedId: '',
  refImageSelectedUrl: '',
};

export const initialInfoState = {
  featureName: undefined,
  currentStep: undefined,
  createdAt: undefined,
};

export const initialFeatureDataState = {
  info: initialInfoState,
  mainImage: initialFeatureDataImageState,
  refImage: initialFeatureDataImageState,
  single: {
    param: undefined,
    genStatus: initialFeatureDataGenStatusState,
    genResult: initialFeatureDataGenResultState,
  },
  batch: [],
};

/*
  main {
    isVisible: サイドメニューへの表示/非表示
    isDisable: サイドメニュー上でのdisabled表示/通常表示
  }
*/
export const initialFeatureStates: Feature[] = [
  {
    main: {
      title: 'ホーム',
      contentTitle: 'ダッシュボード',
      name: 'home',
      menu: 'HOME',
      icon: HomeIcon,
      component: Home,
      isActive: true,
      isDisable: false,
      isVisible: true,
    },
    sub: undefined,
    step: undefined,
    data: undefined,
    params: undefined,
  },
  {
    main: {
      title: '',
      contentTitle: '',
      name: 'partition',
      menu: '',
      icon: null,
      component: () => null,
      isActive: false,
      isDisable: false,
      isVisible: true,
    },
    sub: undefined,
    step: undefined,
    data: undefined,
    params: undefined,
  },
  {
    main: {
      title: '背景画像を変える',
      contentTitle: '',
      name: 'background',
      menu: '背景',
      icon: BackgroundIcon,
      component: Background,
      isActive: false,
      isDisable: false,
      isVisible: true,
    },
    sub: undefined,
    step: undefined,
    data: initialFeatureDataState,
    params: undefined,
  },
  {
    main: {
      title: '背景を白抜きに変える',
      contentTitle: '',
      name: 'white',
      menu: '白抜き',
      icon: WhiteIcon,
      component: White,
      isActive: false,
      isDisable: false,
      isVisible: true,
    },
    sub: undefined,
    step: undefined,
    data: initialFeatureDataState,
    params: undefined,
  },
  {
    main: {
      title: '背景を白抜きに変える',
      contentTitle: '',
      name: 'multiWhite',
      menu: '複数枚白抜き',
      icon: WhiteIcon,
      component: MultiWhite,
      isActive: false,
      isDisable: false,
      isVisible: false,
    },
    sub: undefined,
    step: undefined,
    data: initialFeatureDataState,
    params: undefined,
  },
  {
    main: {
      title: 'カラーを変える',
      contentTitle: '',
      name: 'color',
      menu: 'カラー',
      icon: ColorIcon,
      component: Color,
      isActive: false,
      isDisable: false,
      isVisible: true,
    },
    sub: undefined,
    step: undefined,
    data: initialFeatureDataState,
    params: undefined,
  },
  {
    main: {
      title: '色温度を調整する',
      contentTitle: '',
      name: 'colorTemperature',
      menu: '色温度',
      icon: ColorTemperatureIcon,
      component: ColorTemperature,
      isActive: false,
      isDisable: false,
      isVisible: true,
    },
    sub: undefined,
    step: undefined,
    data: initialFeatureDataState,
    params: undefined,
  },
  {
    main: {
      title: '色温度を調整する',
      contentTitle: '',
      name: 'reEditColorTemperature',
      menu: '',
      icon: ColorTemperatureIcon,
      component: ReEditColorTemperature,
      isActive: false,
      isDisable: true,
      isVisible: false,
    },
    sub: undefined,
    step: undefined,
    data: initialFeatureDataState,
    params: undefined,
  },
  {
    main: {
      title: '背景を変える',
      contentTitle: '',
      name: 'backgroundLora',
      menu: '複数枚背景',
      icon: BackgroundLoraIcon,
      component: BackgroundLora,
      isActive: false,
      isDisable: false,
      isVisible: false,
    },
    sub: undefined,
    step: undefined,
    data: initialFeatureDataState,
    params: undefined,
  },
  {
    main: {
      title: 'アイテムを変える',
      contentTitle: '',
      name: 'item',
      menu: 'アイテム',
      icon: ItemIcon,
      component: Item,
      isActive: false,
      isDisable: false,
      isVisible: true,
    },
    sub: undefined,
    step: undefined,
    data: initialFeatureDataState,
    params: undefined,
  },
  {
    main: {
      title: '',
      contentTitle: '',
      name: 'partition',
      menu: '',
      icon: null,
      component: () => null,
      isActive: false,
      isDisable: false,
      isVisible: !process.env.NEXT_PUBLIC_PRODUCTION,
    },
    sub: undefined,
    step: undefined,
    data: undefined,
    params: undefined,
  },
  {
    main: {
      title: '生成ギャラリー',
      contentTitle: '', // 非表示にする
      name: 'gallery',
      menu: 'ギャラリー',
      icon: GalleryIcon,
      component: Gallery,
      isActive: false,
      isDisable: false,
      isVisible: true,
    },
    sub: undefined,
    step: undefined,
    data: undefined,
    params: undefined,
  },
  {
    main: {
      title: '',
      contentTitle: '',
      name: 'partition',
      menu: '',
      icon: null,
      component: () => null,
      isActive: false,
      isDisable: false,
      isVisible: !process.env.NEXT_PUBLIC_PRODUCTION,
    },
    sub: undefined,
    step: undefined,
    data: undefined,
    params: undefined,
  },
  {
    main: {
      title: '管理',
      contentTitle: '',
      name: 'admin',
      menu: '管理',
      icon: SettingIcon,
      component: Admin,
      isActive: false,
      isDisable: false,
      isVisible: true,
    },
    sub: [
      {
        parent: 'admin',
        name: 'userList',
        title: 'ユーザー一覧',
        isActive: true,
      },
      {
        parent: 'admin',
        name: 'downloadManagement',
        title: 'ダウンロード管理',
        isActive: false,
      },
    ],
    step: undefined,
    data: undefined,
    params: undefined,
  },
];
