import { StyledGridEnd, StyledGridGap10 } from '@/components/styled';
import {
  StyledPreview,
  StyledPreviewSlide,
} from '@/features/components/styled/preview';
import { FeaturesModalStepLayout } from '@/features/components/templates/Layout/FeaturesModalStepLayout';
import { Dispatch, memo, SetStateAction } from 'react';
import { SelectedImageSize } from '../../Functions/hooks/useSamDialog';
import { Buttons } from './Buttons';
import { Functions } from './Functions';
import { GlobalSegmentation } from './GlobalSegmentation';

type Props = {
  onSubmit: () => void;
  selectedImageSize: SelectedImageSize;
  positivePoints: [number, number][];
  setPositivePoints: Dispatch<SetStateAction<[number, number][]>>;
  negativePoints: [number, number][];
  setNegativePoints: Dispatch<SetStateAction<[number, number][]>>;
  apiPositivePoints: [number, number][];
  setApiPositivePoints: Dispatch<SetStateAction<[number, number][]>>;
  apiNegativePoints: [number, number][];
  setApiNegativePoints: Dispatch<SetStateAction<[number, number][]>>;
  isPreviewCompleted: boolean;
  setIsPreviewCompleted: Dispatch<SetStateAction<boolean>>;
  isPreviewLoading: boolean;
  setIsPreviewLoading: Dispatch<SetStateAction<boolean>>;
  handleRequestPreview: (samType: 'manual' | 'auto') => Promise<void>;
  samType: 'auto' | 'manual';
  setSamType: Dispatch<SetStateAction<'auto' | 'manual'>>
  pointType: 'positive' | 'negative';
  setPointType: Dispatch<SetStateAction<'positive' | 'negative'>>
  handleToggleSwitch: () => void;
  initializeSamDialogState: () => void;
};

// featureContextを使わずに作成したSegmentation用のコンポーネント。うまく使えそうであれば既存コンポーネントと差し替える
export const Segmentation = memo(
  ({
    onSubmit,
    selectedImageSize,
    positivePoints,
    setPositivePoints,
    negativePoints,
    setNegativePoints,
    apiPositivePoints,
    setApiPositivePoints,
    apiNegativePoints,
    setApiNegativePoints,
    isPreviewCompleted,
    setIsPreviewCompleted,
    isPreviewLoading,
    setIsPreviewLoading,
    handleRequestPreview,
    samType,
    setSamType,
    pointType,
    setPointType,
    handleToggleSwitch,
    initializeSamDialogState,
  }: Props): JSX.Element => {
    return (
      <>
        <FeaturesModalStepLayout>
          <StyledGridGap10>
            <StyledPreview swiperIndex={0} maxIndex={0} width={660}>
              <StyledPreviewSlide>
                <GlobalSegmentation
                  selectedImageSize={selectedImageSize}
                  samType={samType}
                  pointType={pointType}
                  positivePoints={positivePoints}
                  negativePoints={negativePoints}
                  setPositivePoints={setPositivePoints}
                  setNegativePoints={setNegativePoints}
                  setApiPositivePoints={setApiPositivePoints}
                  setApiNegativePoints={setApiNegativePoints}
                  isPreviewCompleted={isPreviewCompleted}
                  setIsPreviewCompleted={setIsPreviewCompleted}
                  isPreviewLoading={isPreviewLoading}
                  // field={field}
                  // data={featureData}
                  // updateData={updateFeatureData}
                />
              </StyledPreviewSlide>
            </StyledPreview>
          </StyledGridGap10>
          <div style={{
            verticalAlign: 'top',
            width: '320px',
            minWidth: '320px',
            height: 'calc(100%)',
            position: 'relative',
          }}>
            <Functions
              samType={samType}
              pointType={pointType}
              setPointType={setPointType}
              handleToggleSwitch={handleToggleSwitch}
            />
            {/* 暫定対応・色温度ではファイル情報を表示しない */}
            {/* {!isBatch ? (
              <FileDetail
                field={field}
                onResetImage={handleResetImageFromModal}
                onDialog
              />
            ) : (
              <></>
            )} */}

            <StyledGridEnd
              style={{
                position: 'absolute',
                bottom: 0,
                paddingLeft: '20px',
              }}
            >
              <Buttons
                onSubmit={onSubmit}
                samType={samType}
                isPreviewLoading={isPreviewLoading}
                isPreviewCompleted={isPreviewCompleted}
                positivePoints={positivePoints}
                negativePoints={negativePoints}
                handleRequestPreview={handleRequestPreview}
                initializeSamDialogState={initializeSamDialogState}
              />
            </StyledGridEnd>
          </div>
        </FeaturesModalStepLayout>
      </>
    );
  },
);
