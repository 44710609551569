import { Box } from '@/components/atoms/Box';
import {
  StyledErrorContainer,
  StyledErrorIcon,
  StyledErrorIconBox,
} from '@/features/components/atoms/ErrorCard/styled';
import { memo } from 'react';

type Props = {
  BATCH_LIMIT_SET: number;
};

/**
 * @see 作成セットの上限に達した時の告知コンポーネント
 */
export const NoticeLimitSet = memo(({ BATCH_LIMIT_SET }: Props) => {
  return (
    <>
      <Box
        style={{
          padding: '8px 24px',
        }}
      >
        <StyledErrorContainer
          style={{
            padding: '16px',
            justifyContent: 'center',
            borderRadius: '4px',
          }}
        >
          <StyledErrorIconBox data-testid="test-icon">
            <StyledErrorIcon />
          </StyledErrorIconBox>
          <Box style={{ fontWeight: 'bold' }}>
            作成できるセット上限に達しました（{BATCH_LIMIT_SET}件）
          </Box>
        </StyledErrorContainer>
      </Box>
    </>
  );
});
