import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { Dialog } from '@/components/molecules/Dialog';
import {
  StyledBoxHeight24,
  StyledBoxHeight5,
  StyledFlexEndGap5,
  StyledFont,
  StyledFont16Bold,
  StyledGridCenter,
  StyledGridGap20,
} from '@/components/styled';
import { memo } from 'react';

type Props = {
  isOpenDialog: boolean;
  handleCloseDialog: () => void;
  handleSubmit: () => void;
};

export const ConfirmUnsettledSetDialog = memo(
  ({ isOpenDialog, handleCloseDialog, handleSubmit }: Props): JSX.Element => {
    return (
      <>
        <Dialog
          isOpenDialog={isOpenDialog}
          onClose={handleCloseDialog}
          maxWidth="sm"
          dialogTitle=""
          hasCloseIcon={false}
        >
          <StyledGridCenter style={{ justifyContent: 'center' }}>
            <Box
              style={{
                display: 'grid',
                justifyContent: 'center',
                textAlign: 'center',
                width: '352px',
                height: '212px',
                alignItems: 'center',
              }}
            >
              <Box style={{ display: 'grid', gap: '20px' }}>
                <StyledFont16Bold>
                  ファイルをアップロード中です
                </StyledFont16Bold>
                <Box>
                  <StyledFont>
                    アップロード中のファイルを含むセットは
                    生成することができません。
                  </StyledFont>
                </Box>
                <Box>
                  <StyledFont>
                    すでにアップロードが完了しているセットのみ生成を実行してよろしいですか？
                  </StyledFont>
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                display: 'grid',
                justifyContent: 'center',
                textAlign: 'center',
                height: '60px',
              }}
            >
              <StyledGridGap20>
                <StyledBoxHeight5 />
                <StyledFlexEndGap5>
                  <Button mode="alertBorder" onClick={handleCloseDialog}>
                    キャンセル
                  </Button>
                  <Button mode="alert" onClick={handleSubmit}>
                    生成する
                  </Button>
                </StyledFlexEndGap5>
              </StyledGridGap20>
            </Box>
          </StyledGridCenter>
          <StyledBoxHeight24 />
        </Dialog>
      </>
    );
  },
);
