import { Box } from '@/components/atoms/Box';
import { AnimatePresence, motion } from 'framer-motion';
import { memo } from 'react';

type Props = {
  children: React.ReactNode[];
  isOpenAccordion: boolean;
  backgroundColor?: string;
};

/**
 * @see 汎用のAccordionWrapper
 */
export const AccordionWrapper = memo(
  ({ children, isOpenAccordion, backgroundColor }: Props): JSX.Element => {
    return (
      <Box
        style={{
          backgroundColor,
        }}
      >
        <Box>{children[0]}</Box>
        <AnimatePresence>
          {isOpenAccordion && (
            <motion.div
              transition={{ duration: 0.1 }}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
            >
              {children[1]}
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    );
  },
);
