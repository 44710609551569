import { memo } from 'react';

type Props = {
  color?: string;
};
const SegmentationPositive = memo(
  ({ color = '#00D2A0' }: Props): JSX.Element => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <circle cx={12} cy={12} r={10} fill={color} />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M13 6h-2v5H6v2h5v5h2v-5h5v-2h-5V6Z"
          clipRule="evenodd"
        />
      </svg>
    );
  },
);
export default SegmentationPositive;
