import { useBackendApi } from '@/api/hooks/useBackendApi';
import { Image } from '@/api/schemas';
import { BATCH_MAX_SIZE } from '@/constants';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { initialFeatureDataImageState } from '@/contexts/FeaturesContext/initial';
import {
  FeatureData,
  FeatureSingle,
  ImageFields,
} from '@/contexts/FeaturesContext/types';
import { BatchWarnings } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { useError } from '@/hooks/global/useError';
import { useSnackbar } from '@/hooks/global/useSnackbar';
import { useBase64 } from '@/hooks/utils/useBase64';
import { useResizeBase64 } from '@/hooks/utils/useResizeBase64';
import { useEffect } from 'react';
import { getImageSize } from 'react-image-size';

type Props = {
  field: ImageFields;
  featureData: FeatureData | undefined;
  updateFeatureData: (key: keyof FeatureData, value: unknown) => void;
  updateFeatureDataSingle: (key: keyof FeatureSingle, value: unknown) => void;
  updateWarningKeyValue: (key: keyof BatchWarnings, value: unknown) => void;
  acceptedFiles: File[];
  handleOpenDialog: () => void;
  setRefApiResponse: (res: Image) => void;
};

export const useUploadImage = ({
  field,
  featureData,
  updateFeatureData,
  updateFeatureDataSingle,
  updateWarningKeyValue,
  acceptedFiles,
  handleOpenDialog,
  setRefApiResponse,
}: Props) => {
  const { getResizedBase64 } = useResizeBase64();
  const { handleOpenSnackbar, setSnackbarParam } = useSnackbar();
  const { logger } = useError();
  const { postImages } = useBackendApi({});
  const { getImageBase64 } = useBase64();
  const { activeFeatureName } = useFeaturesContext({});
  const MIN_SIZE = 500;

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      console.log('here');
      updateFeatureData(field, initialFeatureDataImageState);
    }
  }, [acceptedFiles, field, updateFeatureData]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (featureData?.[field].file) return;
    if (acceptedFiles.length <= 0) {
      return;
    }

    const file = acceptedFiles[0];
    const blobUrl = window.URL.createObjectURL(acceptedFiles[0]);
    let base64Copy;
    // biome-ignore lint/complexity/noVoid: あとで修正
    void (async () => {
      const dimension = await getImageSize(blobUrl);
      const originalSize = {
        width: dimension.width,
        height: dimension.height,
        bytes: file.size,
      };
      let resizedSize = {};

      if (dimension.width < MIN_SIZE && dimension.height < MIN_SIZE) {
        updateWarningKeyValue('imageSizeErrorFiles', [file]);

        return;
      }
      if (
        dimension.width >
          BATCH_MAX_SIZE[activeFeatureName as keyof typeof BATCH_MAX_SIZE] ||
        dimension.height >
          BATCH_MAX_SIZE[activeFeatureName as keyof typeof BATCH_MAX_SIZE]
      ) {
        const { resizedWidth, resizedHeight, resizedBytes, base64 } =
          await getResizedBase64({
            targetSize:
              BATCH_MAX_SIZE[activeFeatureName as keyof typeof BATCH_MAX_SIZE],
            width: dimension.width,
            height: dimension.height,
            blobUrl,
          });

        resizedSize = {
          width: resizedWidth,
          height: resizedHeight,
          bytes: resizedBytes || 0,
        };
        base64Copy = base64;
        updateWarningKeyValue('resizedImageFiles', [file]);
      } else {
        base64Copy = await getImageBase64(blobUrl);
      }

      updateFeatureData(field, {
        file,
        fileName: file.name,
        blobUrl,
        originalSize,
        resizedSize,
        base64: base64Copy,
      });
    })();
  }, [
    featureData?.[field].file,
    acceptedFiles,
    field,
    updateFeatureData,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (acceptedFiles.length <= 0) return;
    if (!featureData?.[field].base64) return;
    // post
    // biome-ignore lint/complexity/noVoid: あとで修正
    void (async () => {
      try {
        const res = await postImages({
          image: featureData?.[field].base64,
          fileName: featureData?.[field].fileName,
        });
        if (res) {
          setRefApiResponse(res);
        }

        return res;
      } catch (error) {
        console.error(error);
        logger({ error });

        return null;
      }
    })();

    updateFeatureDataSingle('param', {
      newRef: '',
      currentRef: '',
    });
    setSnackbarParam({
      iconName: 'success',
      text: `画像をアップロードしました`,
      position: { placement: 'rightTop', x: 0, y: 0 },
    });
    handleOpenSnackbar();
    handleOpenDialog();
  }, [
    handleOpenSnackbar,
    setSnackbarParam,
    handleOpenDialog,
    featureData?.[field].base64,
  ]);
};
