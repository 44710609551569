import { useParamsContext } from '@/contexts/ParamsContext';
import { memo, useEffect, useMemo } from 'react';
import { useReEditAtom } from './hooks/useReEditAtom';
import { useTaskData } from './hooks/useTaskData';
import { ReEdit } from './ReEdit';

// MEMO: ロジックの見通しが悪いので、テスト的にContainerコンポーネントを作成した。ここにビジネスロジックを集約することにする（運用しづらければまた考える）
// MEMO: 時間がなかったため下のコンポーネントにビジネスロジックが固まっている。見直して各種containerコンポーネントとして書き直す
export const ReEditContainer = memo((): JSX.Element => {
  const { paramsContext } = useParamsContext()
  const indexes = paramsContext?.selectedImageIndexes as number[] | undefined
  const { taskData } = useTaskData()

  const targetOriginalImages = useMemo(() => {
    if (
      typeof indexes !== 'object' ||
      taskData?.originalImages === undefined
    ) return []

    // 任意のoriginalImagesのみをtaskDataから抽出
    return indexes.map(index => taskData.originalImages?.[index])
  }, [
    indexes,
    taskData?.originalImages,
  ])

  const {
    initializeReEditAtoms,
    setTargetOriginalImages,
    tmpDisplayName,
    setTmpDisplayName,
  } = useReEditAtom()

  // ページコンポーネントでしか更新しないatomの初期化
  useEffect(() => {
    setTargetOriginalImages(targetOriginalImages)
  }, [targetOriginalImages, setTargetOriginalImages])

  // tmpDisplayNameの初期化（末尾に `(再生成)` をつける）
  useEffect(() => {
    if (
      tmpDisplayName !== undefined
      || !taskData?.displayName
    ) return

    const addChar = '(再生成)'

    if (taskData.displayName.length + addChar.length <= 255) {
      const name = taskData.displayName
        ? `${taskData.displayName}${addChar}`
        : '再生成'
      setTmpDisplayName(name)
    } else {
      const name = taskData.displayName
        ? `${taskData.displayName}`
        : '再生成'
      setTmpDisplayName(name)
    }
  }, [taskData, tmpDisplayName, setTmpDisplayName])

  // アンマウント時にatomを初期化
  useEffect(() => {
    return () => {
      initializeReEditAtoms();
    }
  }, [initializeReEditAtoms])

  return <ReEdit />
});
