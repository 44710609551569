import { Box, BoxProps } from '@/components/atoms/Box';
import { forwardRef, memo } from 'react';
import * as vanilla from './vanilla.css';

type ButtonContentProps = BoxProps & {
  isDragActive: boolean;
};

export const StyledButtonContent = forwardRef<
  HTMLDivElement,
  ButtonContentProps
>(({ children, sx, isDragActive, ...props }, ref) => {
  const style = {
    opacity: isDragActive ? 0.8 : 1,
    outline: isDragActive
      ? `1px solid ${'var(--color-primary-main)'}`
      : '1px solid #ccc',
  };

  return (
    <Box
      ref={ref}
      sx={sx}
      style={style}
      className={vanilla.styledButtonContent}
      {...props}
    >
      {children}
    </Box>
  );
});

export const StyledAddButtonBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledAddButtonBox} {...props}>
      {children}
    </Box>
  );
});
