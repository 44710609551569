import { TasksParam } from '@/api/params';
import { usePopper } from '@/hooks/local/usePopper';
import { useConvertDate } from '@/hooks/utils/useConvertDate';
import moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';

type Props = {
  updateTasksParamObject: (object: Partial<TasksParam>) => void;
};

export const useGalleryCalendar = ({ updateTasksParamObject }: Props) => {
  const { convertToUnixTime } = useConvertDate();
  const [startDateString, setStartDateString] = useState<string>('');
  const [endDateString, setEndDateString] = useState<string>('');
  const [isStartDateError, setIsStartDateError] = useState<boolean>(false);
  const [isEndDateError, setIsEndDateError] = useState<boolean>(false);
  const [targetDate, setTargetDate] = useState<string>('');
  const [isCalendarEnable, setIsCalendarEnable] = useState<boolean>(false);
  const { handleOpenPopper, handleClosePopper, anchorElement, isOpenPopper } =
    usePopper();

  const handleClear = useCallback(() => {
    setIsCalendarEnable(false);
    setStartDateString('');
    setEndDateString('');
    updateTasksParamObject({
      offset: 0,
      createdAtAfterUnix: 0,
      createdAtBeforeUnix: 99999999999,
    });
    handleClosePopper();
  }, [handleClosePopper, updateTasksParamObject]);

  const handleSubmit = useCallback(() => {
    const createdAtAfterUnix = startDateString
      ? convertToUnixTime(startDateString)
      : 0;
    let createdAtBeforeUnix = 99999999999; // デフォルトの値

    if (endDateString) {
      // endDateStringがある場合、その日の23:59:59をUNIXタイムスタンプに変換
      const endOfDay = moment(endDateString).tz('Asia/Tokyo').endOf('day');
      createdAtBeforeUnix = endOfDay.unix();
    }

    updateTasksParamObject({
      offset: 0,
      createdAtAfterUnix,
      createdAtBeforeUnix,
    });

    handleClosePopper();
  }, [
    convertToUnixTime,
    endDateString,
    handleClosePopper,
    startDateString,
    updateTasksParamObject,
  ]);

  useEffect(() => {
    if (!isCalendarEnable) return;
    const today = moment().tz('Asia/Tokyo').format('YYYY-MM-DD');
    const lastMonth = moment()
      .tz('Asia/Tokyo')
      .subtract(1, 'months')
      .format('YYYY-MM-DD');

    setEndDateString((current) => current || today);
    setStartDateString((current) => current || lastMonth);
  }, [isCalendarEnable]);

  useEffect(() => {
    if (startDateString && endDateString) {
      const start = moment(startDateString);
      const end = moment(endDateString);

      if (end.isBefore(start)) {
        setIsEndDateError(true);
        setEndDateString(startDateString);
      } else {
        setIsEndDateError(false);
      }
    }
  }, [startDateString, endDateString]);

  const handleOpenCalendar = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, name: string) => {
      setIsCalendarEnable(true);
      handleOpenPopper(e);
      setTargetDate(name);
    },
    [handleOpenPopper],
  );

  return {
    startDateString,
    setStartDateString,
    endDateString,
    setEndDateString,
    isStartDateError,
    setIsStartDateError,
    isEndDateError,
    setIsEndDateError,
    targetDate,
    setTargetDate,
    handleOpenCalendar,
    handleClear,
    handleSubmit,
    anchorElement,
    isOpenPopper,
    handleClosePopper,
    isCalendarEnable,
    setIsCalendarEnable,
  };
};
