import {
  Download,
  Segmentation,
  Setting,
  Upload,
} from '@/features/components/steps';

export const initialStepStates = [
  {
    title: '画像のアップロード',
    name: 'upload',
    num: 1,
    explain: '使用する画像をアップロードして下さい。',
    component: Upload,
    isActive: true,
  },
  {
    title: '変更する背景領域の確認',
    name: 'segmentation',
    num: 2,
    explain: '被写体（背景以外）を選択してください',
    component: Segmentation,
    isActive: false,
  },
  {
    title: '背景情報の設定',
    name: 'setting',
    num: 3,
    explain: '挿げ替える背景の情報を設定してください',
    component: Setting,
    isActive: false,
  },
  {
    title: 'ダウンロード',
    name: 'download',
    num: 4,
    explain:
      'プレビュー画像からダウンロードするものを選択し、出力形式を設定してください',
    component: Download,
    isActive: false,
  },
];

export const initialDataParamState = {
  isNoBackground: false,
  keepMaskImage: '',
  targetType: 'image',
  pickColor: '',
  newColor: '',
  currentColor: '',
  colorName: '',
  refImageName: '',
  presetId: '',
  presetName: '',
  presetUrl: '',
  newRef: '',
  currentRef: '',
  withShadow: 'on',
  blurLevel: 'none',
  generationCount: 1,
  format: 'png',
};
