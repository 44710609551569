import { useCallback } from 'react';
import Resizer from 'react-image-file-resizer';
import { useBase64 } from './useBase64';

export const useResizeBase64 = () => {
  const { base64ToBlob } = useBase64();
  const getResizedImageByBlob = useCallback(
    async (blob: Blob, width: number, height: number) => {
      const resizedImage = await new Promise((resolve) => {
        Resizer.imageFileResizer(
          blob,
          width,
          height,
          'WEBP',
          90,
          0,
          (uri) => {
            resolve(uri as string);
          },
          'base64',
        );
      });
      if (!resizedImage) return;
      const base64 = resizedImage as string;
      const resizedBytes = blob.size;

      // eslint-disable-next-line consistent-return
      return {
        resizedBytes,
        base64,
      };
    },
    [],
  );

  type GetResizedBase64Props = {
    targetSize: number;
    width: number;
    height: number;
    blobUrl?: string;
    originalBase64?: string;
  };

  const getResizedBase64 = useCallback(
    async ({
      targetSize,
      width,
      height,
      blobUrl,
      originalBase64,
    }: GetResizedBase64Props) => {
      let resizedWidth = 0;
      let resizedHeight = 0;
      const blob = blobUrl
        ? await (await fetch(blobUrl)).blob()
        : originalBase64
        ? // eslint-disable-next-line @typescript-eslint/await-thenable
          await base64ToBlob(originalBase64)
        : null;

      if (!blob) return {};

      if (width > height) {
        const n = width / targetSize;
        resizedWidth = targetSize;
        resizedHeight = Math.round(height / n);
      } else if (width < height) {
        const n = height / targetSize;
        resizedWidth = Math.round(width / n);
        resizedHeight = targetSize;
      } else if (width === height) {
        resizedWidth = targetSize;
        resizedHeight = targetSize;
      }

      const { resizedBytes, base64 } =
        (await getResizedImageByBlob(blob, resizedWidth, resizedHeight)) || {};

      return { resizedWidth, resizedHeight, resizedBytes, base64 };
    },
    [base64ToBlob, getResizedImageByBlob],
  );

  return {
    getResizedBase64,
  };
};
