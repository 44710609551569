import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import { MdInfoOutline } from 'react-icons/md';
import * as vanilla from './vanilla.css';

export const StyledErrorContainer = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledErrorContainer} {...props}>
      {children}
    </Box>
  );
});

export const StyledErrorIconBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledErrorIconBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledErrorIcon = memo(() => {
  return <MdInfoOutline className={vanilla.styledErrorIcon} />;
});

export const StyledErrorBox = memo(
  ({ width, children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledErrorBox} sx={{ width }} {...props}>
        {children}
      </Box>
    );
  },
);
