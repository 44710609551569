import type { SVGProps } from 'react';
import { memo } from 'react';
const DeletedStatus = memo((props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <g clipPath="url(#deletedStatus_svg__a)">
        <path
          fill="#D32F2F"
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm5 11H7v-2h10v2Z"
        />
      </g>
      <defs>
        <clipPath id="deletedStatus_svg__a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});
export default DeletedStatus;
