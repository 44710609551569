import { Box } from '@/components/atoms/Box';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageResult } from '@/contexts/FeaturesContext/types';
import { useCheckArray } from '@/hooks/global/useCheckArray';
import { UseQueryResult } from '@tanstack/react-query';
import { memo, useEffect, useMemo, useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import type { DownloadDialogMode } from '../../modals/DownloadDialog';
import { DownloadDialogViewer } from '../DownloadDialogViewer/DownloadDialogViewer';
import { accordionToggleButtonStyle, normalTextStyle, tealTextStyle } from './DownloadDialogDownloader.css';
import { Label } from './Label/Label';

type Props = {
  isGenerated: boolean;
  imageResults: ImageResult[];
  setFiles: React.Dispatch<React.SetStateAction<string[]>>;
  repeatBackgroundImage?: string;
  hasCheckIcon?: boolean;
  taskType?: string;
  downloadDialogMode: DownloadDialogMode;
  handleClickSupportStartButton: () => void;
  handleClickSupportCancelButton: () => void;
  taskId: string | undefined;
  targetModeEn: string | undefined;
  handleCloseDownloadDialog: () => void;
  refetchTasks: UseQueryResult['refetch'];
};

// MEMO: 元々GlobbalDownloaderだったものを分離してサポート機能を追加にしたもの。
// TODO: DownloadDialogのディレクトリに移動する
export const DownloadDialogDownloader = memo(
  ({
    isGenerated,
    imageResults,
    setFiles,
    repeatBackgroundImage,
    hasCheckIcon = true,
    taskType,
    downloadDialogMode,
    handleClickSupportStartButton,
    handleClickSupportCancelButton,
    taskId,
    targetModeEn,
    handleCloseDownloadDialog,
    refetchTasks,
  }: Props) => {
    const {
      checkArray,
      setCheckArray,
      checkedCount,
    } = useCheckArray();
    const filteredImages = useMemo(() => {
      switch (downloadDialogMode) {
        case 'selectDownloadImage':
          return imageResults
        case 'selectSupportImage':
          return imageResults
        case 'supportForm':
          return imageResults.filter((_, index) => checkArray[index])
        default:
          throw new Error("Invalid downloadDialogMode");
      }
    }, [
      imageResults,
      checkArray,
      downloadDialogMode,
    ]);

    const isDisplayAcoordion = useMemo(() => {
      return downloadDialogMode === 'supportForm' && checkedCount >= 5
    }, [
      downloadDialogMode,
      checkedCount,
    ])

    const [isOpenSupportAccordion, setIsOpenSupportAccordion] = useState(false)
    const { activeFeatureName } = useFeaturesContext({})

    const label = useMemo(() => {
      return (
        <>
          <Label
            isDisplaySupportButton={activeFeatureName === 'gallery'}
            downloadDialogMode={downloadDialogMode}
            handleClickSupportStartButton={handleClickSupportStartButton}
            handleClickSupportCancelButton={handleClickSupportCancelButton}
          />
          {downloadDialogMode === 'supportForm' && (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '17px',
            }}>
              <span className={normalTextStyle}>
                選択した画像
                {checkedCount ? `（${checkedCount}）` : ''}
              </span>
              { isDisplayAcoordion &&
                <button
                  type="button"
                  className={accordionToggleButtonStyle}
                  onClick={() => setIsOpenSupportAccordion(!isOpenSupportAccordion)}
                >
                  { isOpenSupportAccordion ?
                  <>
                    <span className={tealTextStyle}>
                      表示を減らす
                    </span>
                    <MdExpandLess
                      size={16}
                      color="var(--color-teal-400)"
                    />
                  </>
                  :
                  <>
                    <span className={tealTextStyle}>
                      すべての画像を表示
                    </span>
                    <MdExpandMore
                      size={16}
                      color="var(--color-teal-400)"
                    />
                  </>
                  }
                </button>
              }
            </div>
          )}
        </>
      )
    }, [
      downloadDialogMode,
      handleClickSupportCancelButton,
      handleClickSupportStartButton,
      checkedCount,
      isDisplayAcoordion,
      isOpenSupportAccordion,
      activeFeatureName,
    ])

    useEffect(() => {
      setCheckArray(imageResults.map(() => true));
    }, [imageResults, setCheckArray]);

    useEffect(() => {
      if (!imageResults.length) return;
      const urls = imageResults.map(
        (imageResult: ImageResult) => imageResult.url,
      );
      setFiles(urls);
    }, [imageResults, setFiles]);

    // TODO: そもそもDownloadDIalog(selectModeのstateが初期化されているコンポーネント)を非表示にしたときにunmountするように書き直す
    // biome-ignore lint/correctness/useExhaustiveDependencies: handleClickSupportCancelButtonを依存にするとなぜか無限ループが発生する。そもそもビジネスロジック全体を整備する必要がある
    useEffect(() => {
      return () => {
        handleClickSupportCancelButton();
      }
    }, [])

    return (
      <Box
        sx={{
          position: 'relative',
          verticalAlign: 'top',
        }}
      >
        {!isGenerated ? (
          <></>
        ) : (
          <DownloadDialogViewer
            imageResults={filteredImages}
            hasAnimation={false}
            hasCheckIcon={hasCheckIcon}
            repeatBackgroundImage={repeatBackgroundImage}
            /*  2024/06/08 追加 バッチダウンロードの場合はラベルは表示しない、
            使用側で設定しても良いがひとまずここで設定 */
            label={label}
            taskType={taskType}
            isDownloadDialog
            downloadDialogMode={downloadDialogMode}
            taskId={taskId}
            isOpenSupportAccordion={isOpenSupportAccordion}
            targetModeEn={targetModeEn}
            handleCloseDownloadDialog={handleCloseDownloadDialog}
            refetchTasks={refetchTasks}
          />
        )}
      </Box>
    );
  },
);
