import { memo } from 'react';

type Props = {
  color?: string;
};
const HomeIcon = memo(({ color = '#fff' }: Props): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
      <path
        fill={color}
        fillRule="evenodd"
        d="M26.77 10.615H30c0-3.86-3.14-7-7-7v3.231c2.078 0 3.77 1.69 3.77 3.77ZM5.23 21.385H2c0 3.86 3.14 7 7 7v-3.231a3.773 3.773 0 0 1-3.77-3.77Zm3.77-7v3.23c-3.86 0-7-3.14-7-7s3.14-7 7-7v3.231a3.773 3.773 0 0 0-3.77 3.77c0 2.078 1.692 3.769 3.77 3.769Zm7 0a3.773 3.773 0 0 1-3.77-3.77c0-2.078 1.691-3.769 3.77-3.769 2.078 0 3.77 1.69 3.77 3.77H23c0-3.86-3.14-7-7-7s-7 3.14-7 7 3.14 7 7 7c2.078 0 3.77 1.69 3.77 3.769 0 2.078-1.692 3.769-3.77 3.769a3.773 3.773 0 0 1-3.77-3.77H9c0 3.86 3.14 7 7 7s7-3.14 7-7-3.14-7-7-7Zm7 3.23v-3.23c3.86 0 7 3.14 7 7s-3.14 7-7 7v-3.231c2.078 0 3.77-1.69 3.77-3.77 0-2.078-1.692-3.769-3.77-3.769Z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default HomeIcon;
