import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { memo } from 'react';

type Props = {
  handleClickApplySupportWithSelectImageButton: (e: React.MouseEvent<HTMLElement>) => void;
  checkedCount: number;
  bottom?: number;
};

// TODO: absoluteを使わない形に修正する（レイアウトから修正する必要があるのでまぁまぁ時間かかりそう）
// MEMO: ボタンの位置をabsoluteで指定してしまっているので、複数ボタンを配置した際レイアウト崩れが起きてしまう。そのためワークアラウンドとしてbottomをpropsで渡してボタン位置をコントロールできるようにした
export const SupportApplyButton = memo(
  ({
    handleClickApplySupportWithSelectImageButton,
    checkedCount,
    bottom = -10,
  }: Props): JSX.Element => {
    return (
      <Box style={{ position: 'absolute', bottom, left: -40 }}>
        <Button
          style={{ width: 360, fontSize: 15 }}
          onClick={handleClickApplySupportWithSelectImageButton}
          disabled={checkedCount === 0}
          mode='teal'
        >
          選択した画像でサポートを申請
          {checkedCount ? `（${checkedCount}）` : ''}
        </Button>
      </Box>
    );
  },
);
