import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

type DialogBoxProps = {
  dialogTitleFontSize?: number;
  paddingType?: 'normal' | 'wide' | 'fit';
} & BoxProps;

export const StyledTitleBox = memo(
  ({
    dialogTitleFontSize,
    paddingType,
    children,
    ...props
  }: DialogBoxProps) => {
    const titleBoxStyle = {
      fontSize: !dialogTitleFontSize ? '18px' : dialogTitleFontSize,
      fontWeight: 'bold',
      padding:
        paddingType === 'normal'
          ? '24px 24px 0px 24px'
          : paddingType === 'wide'
          ? '32px 20px 0px 32px'
          : '15px 24px 0px 24px',
    };

    return (
      <Box className={vanilla.styledTitleBox} style={titleBoxStyle} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledCloseIconBox = memo(
  ({ paddingType, children, ...props }: DialogBoxProps) => {
    const closeIconBoxStyle = {
      padding:
        paddingType === 'normal'
          ? '0px 0px 0px 0px'
          : paddingType === 'wide'
          ? '32px 20px 0px 32px'
          : '0px 0px 0px 0px',
    };

    return (
      <Box
        className={vanilla.styledClosIconBox}
        style={closeIconBoxStyle}
        {...props}
      >
        {children}
      </Box>
    );
  },
);

export const StyledChildrenBox = memo(
  ({ paddingType, children, ...props }: DialogBoxProps) => {
    const childrenBoxStyle = {
      padding:
        paddingType === 'normal'
          ? '10px 24px 0px 24px'
          : paddingType === 'wide'
          ? '32px 20px 25px 32px'
          : '0px 0px 0px 0px',
    };

    return (
      <Box style={childrenBoxStyle} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledButtonBox = memo(
  ({ paddingType, children, ...props }: DialogBoxProps) => {
    const buttonBoxStyle = {
      padding:
        paddingType === 'normal'
          ? '10px 24px 0px 24px'
          : paddingType === 'wide'
          ? '32px 20px 25px 32px'
          : '0px 25px 0px 25px',
    };

    return (
      <Box
        className={vanilla.styledButtonBox}
        style={buttonBoxStyle}
        {...props}
      >
        {children}
      </Box>
    );
  },
);

export const StyledBottomHeight25pxBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledBottomHeight25pxBox} {...props}>
        {children}
      </Box>
    );
  },
);
