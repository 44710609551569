import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledStepContentBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledStepContentBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledSingleContentWithScrollBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledSingleContentWithScrollBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledSingleContentBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledSingleContentBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledStepLeftBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledStepLeftBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledStepLeftCentering = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledStepLeftCentering} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledSplitLeftBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledSplitLeftBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledSplitRightBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledSplitRightBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledScrollBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledScrollBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledDialogContentBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledDialogContentBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledModalStepContentBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledModalStepContentBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledModalStepLeftBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledModalStepLeftBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledModalStepRightBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledModalStepRightBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledModalDownloadContentBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledModalDownloadContentBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledModalDownloadRightBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledModalDownloadRightBox} {...props}>
        {children}
      </Box>
    );
  },
);
