import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { StyledFlexCenter, StyledGridGap25 } from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { memo, useCallback } from 'react';
import { initialBatchState } from '../../../BatchUpload/hooks/initial';
import {
  styledVerticalAlignBottom,
  styledVerticalAlignNone,
} from '../styled/vanilla.css';

type Props = {
  isDialog?: boolean;
  isAllTasksDone: boolean;
};

export const ColorTemperatureButton = memo(
  ({ isDialog, isAllTasksDone }: Props): JSX.Element => {
    const {
      activateTargetFeature,
      updateFeatureDataArray,
      activateTargetStep,
    } = useFeaturesContext({});

    const handleGoToTop = useCallback(() => {
      updateFeatureDataArray('batch', [initialBatchState]);
      activateTargetFeature('colorTemperature'); // 色温度再生成の場合に必要
      activateTargetStep('batchUpload');
    }, [
      activateTargetStep,
      activateTargetFeature,
      updateFeatureDataArray,
    ]);

    const handleGoToGallery = useCallback(() => {
      updateFeatureDataArray('batch', [initialBatchState]);
      activateTargetFeature('gallery');
    }, [activateTargetFeature, updateFeatureDataArray]);

    return (
      <>
        <StyledFlexCenter>
          <Box
            className={
              !isDialog ? styledVerticalAlignBottom : styledVerticalAlignNone
            }
          >
            <StyledGridGap25>
              {!isDialog && (
                <>
                  <Button
                    mode="border"
                    style={{ width: 280, fontSize: 15 }}
                    onClick={handleGoToGallery}
                    disabled={isAllTasksDone}
                  >
                    バックグラウンドで実行
                  </Button>
                  <Button
                    style={{ width: 280, fontSize: 15 }}
                    onClick={handleGoToTop}
                    disabled={!isAllTasksDone}
                  >
                    色温度を変えるトップへ
                  </Button>
                </>
              )}
              <Box />
            </StyledGridGap25>
          </Box>
        </StyledFlexCenter>
      </>
    );
  },
);
