import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledTitle = memo(({ children }: { children: React.ReactNode}) => {
  return <span className={vanilla.styledTitle}>{children}</span>;
});

export const StyledTabButtonList = memo(({ children }: { children: React.ReactNode}) => {
  return <ul className={vanilla.styledTabButtonList}>{children}</ul>;
});

export const StyledTabButtonListChild = memo(({ children }: { children: React.ReactNode}) => {
  return <li className={vanilla.styledTabButtonListChild}>{children}</li>;
});
