import {
  FeatureBatchRef,
  FeatureParam,
  FeaturePresetImage,
} from '@/contexts/FeaturesContext/types';
import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import {
  initialCollectivePresetImage,
  initialCollectiveRefImage,
  initialCollectiveSettingParam,
} from './initial';

const CollectiveSettingParamAtom = atom<FeatureParam>(
  initialCollectiveSettingParam,
);
const IsCollectiveSettingAtom = atom<boolean>(false);

const CollectiveRefImageAtom = atom<FeatureBatchRef>(initialCollectiveRefImage);

const CollectivePresetImageAtom = atom<FeaturePresetImage>(
  initialCollectivePresetImage,
);

const IsStopBatchGeneratingAtom = atom<boolean>(false);
const IsBatchGeneratingAtom = atom<boolean>(false);

/**
 * @see バッチオプショナルコンテキストを使用するためのフック
 * @see FeaturesContextに入れたくないがバッチ処理にて必要なグローバルステートを管理するためのカスタムフック
 */
export const useBatchOptionalContext = () => {
  const [collectiveSettingParam, setCollectiveSettingParam] = useAtom(
    CollectiveSettingParamAtom,
  );
  const [isCollectiveSetting, setIsCollectiveSetting] = useAtom(
    IsCollectiveSettingAtom,
  );

  const [collectiveRefImage, setCollectiveRefImage] = useAtom(
    CollectiveRefImageAtom,
  );
  const [collectivePresetImage, setCollectivePresetImage] = useAtom(
    CollectivePresetImageAtom,
  );
  const [isStopBatchGenerating, setIsStopBatchGenerating] = useAtom(
    IsStopBatchGeneratingAtom,
  );
  const [isBatchGenerating, setIsBatchGenerating] = useAtom(
    IsBatchGeneratingAtom,
  );

  const initializeBatchOptionalContext = useCallback(() => {
    setCollectiveSettingParam(initialCollectiveSettingParam);
    setIsCollectiveSetting(false);
    setCollectiveRefImage(initialCollectiveRefImage);
    setCollectivePresetImage(initialCollectivePresetImage);
    setIsStopBatchGenerating(false);
    setIsBatchGenerating(false);
  }, [
    setCollectiveSettingParam,
    setIsCollectiveSetting,
    setCollectiveRefImage,
    setCollectivePresetImage,
    setIsStopBatchGenerating,
    setIsBatchGenerating,
  ]);

  return {
    collectiveSettingParam,
    setCollectiveSettingParam,
    isCollectiveSetting,
    setIsCollectiveSetting,
    collectiveRefImage,
    setCollectiveRefImage,
    collectivePresetImage,
    setCollectivePresetImage,
    initializeBatchOptionalContext,
    isStopBatchGenerating,
    setIsStopBatchGenerating,
    isBatchGenerating,
    setIsBatchGenerating,
  };
};
