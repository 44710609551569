import { Task } from '@/api/schemas';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useState } from 'react';

export const useBatchDownload = () => {
  const { featureData, activateTargetFeature, updateFeatureDataArray } =
    useFeaturesContext({});
  const [taskApiResponse, setTaskApiResponse] = useState<Task>();
  const [retryId, setRetryId] = useState('');
  const [batchId, setBatchId] = useState('');
  const [isAllTasksDone, setIsAllTasksDone] = useState(false);
  const [deletedIds, setDeletedIds] = useState<string[]>([]);

  return {
    featureData,
    activateTargetFeature,
    updateFeatureDataArray,
    taskApiResponse,
    setTaskApiResponse,
    retryId,
    setRetryId,
    batchId,
    setBatchId,
    isAllTasksDone,
    setIsAllTasksDone,
    deletedIds,
    setDeletedIds,
  };
};
