import { memo } from 'react';

type Props = {
  color?: string;
};
const ModelIcon = memo(({ color = '#fff' }: Props): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
      <path
        fill={color}
        fillRule="evenodd"
        d="M16 16a7 7 0 1 0 0-14 7 7 0 0 0 0 14Zm11 14a1 1 0 0 0 .975-.778C27.575 22.957 22.366 18 16 18S4.425 22.957 4.025 29.222A1 1 0 0 0 5 30h22Z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default ModelIcon;
