import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';

const isOpenDialogAtom = atom<boolean>(false);

/**
 * JSDoc
 * @see ダイアログの状態を管理するカスタムフック
 * @returns {Object}
 */
export const useDialog = () => {
  const [isOpenDialog, setIsOpenDialog] = useAtom(isOpenDialogAtom);

  const handleOpenDialog = useCallback(() => {
    setIsOpenDialog(true);
  }, [setIsOpenDialog]);

  const handleCloseDialog = useCallback(() => {
    setIsOpenDialog(false);
  }, [setIsOpenDialog]);

  return {
    isOpenDialog,
    setIsOpenDialog,
    handleOpenDialog,
    handleCloseDialog,
  };
};
