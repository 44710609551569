import type { SVGProps } from 'react';
import { memo } from 'react';
const CheckedRef = memo((props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      {...props}
    >
      <path
        fill="#2E7D32"
        d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0M8 15l-5-5 1.41-1.41L8 12.17l7.59-7.59L17 6z"
      />
    </svg>
  );
});
export default CheckedRef;
