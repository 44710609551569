import { TasksOrderBy, TasksParam, initialTasksOrderBy } from '@/api/params';
import { useAuthContext } from '@/contexts/AuthContext';
import { useCallback, useEffect, useState } from 'react';

const initialTaskParam: TasksParam = {
  limit: 50,
  offset: 0,
  orderBy: 'created_at_desc',
  createdAtAfterUnix: 0,
  createdAtBeforeUnix: 99999999999,
  timer: Date.now(),
};

type Props = {
  createdAtAfterUnix?: number;
  createdAtBeforeUnix?: number;
};

/**
 * JSDoc
 * @see TasksParamを扱うカスタムフック
 * @see useTasksが冗長になるのでこちらに分けている
 * @see TaskList(gallery)とTaskTrayの両方で使用
 */
export const useTasksParam = ({
  createdAtAfterUnix = 0,
  createdAtBeforeUnix = 99999999999,
}: Props) => {
  const [tasksParam, setTasksParam] = useState<TasksParam>(initialTaskParam);
  const [tasksOrderBy, setTasksOrderBy] = useState(initialTasksOrderBy);
  const [isOrderBy, setIsOrderBy] = useState(false);
  const { user } = useAuthContext();

  const updateTasksParam = useCallback(
    (fieldName: keyof TasksParam, value: unknown) => {
      setTasksParam((prev) => ({
        ...prev,
        [fieldName]: value as keyof typeof tasksParam,
      }));
    },
    [],
  );

  const updateTasksParamObject = useCallback((object: Partial<TasksParam>) => {
    setTasksParam((prev) => ({
      ...prev,
      ...object,
    }));
  }, []);

  useEffect(() => {
    if (!createdAtAfterUnix && !createdAtBeforeUnix) return;
    setTasksParam((currentParams) => ({
      ...currentParams,
      createdAtAfterUnix,
      createdAtBeforeUnix,
    }));
  }, [createdAtAfterUnix, createdAtBeforeUnix]);

  const addUserId = useCallback(() => {
    console.log('addUserId');
    setTasksParam((currentParams) => ({
      ...currentParams,
      userId: user?.id,
      timer: Date.now(),
      offset: 0,
    }));
  }, [user?.id]);

  const removeUserId = useCallback(() => {
    setTasksParam((currentParams) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { userId, ...rest } = currentParams;

      return { ...rest, offset: 0 };
    });
  }, []);

  const handleClickOrderBy = (name: keyof TasksOrderBy) => {
    setTasksOrderBy((current) => {
      const newOrderBy = { ...current };
      newOrderBy[name] = current[name] === 'asc' ? 'desc' : 'asc';

      return newOrderBy;
    });
    setIsOrderBy(true);
  };

  useEffect(() => {
    if (!isOrderBy) return;
    const toSnakeCase = (str: string) =>
      str.replace(/([A-Z])/g, '_$1').toLowerCase();

    const orderByString = Object.entries(tasksOrderBy)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, value]) => value)
      .map(([key, value]) => {
        return `${toSnakeCase(key)}_${toSnakeCase(value)}`;
      })
      .join(',');

    console.log(orderByString);

    setTasksParam((currentParams) => ({
      ...currentParams,
      orderBy: orderByString,
    }));

    setIsOrderBy(false);
  }, [isOrderBy, tasksOrderBy]);

  return {
    tasksParam,
    updateTasksParam,
    updateTasksParamObject,
    addUserId,
    removeUserId,
    tasksOrderBy,
    handleClickOrderBy,
  };
};
