import { Report } from '@/api/schemas';
import { useCallback, useEffect, useState } from 'react';

type Props = {
  apiResponse: Report;
  startYear: number;
  startMonth: number;
  targetYear: number;
  targetMonth: number;
  setTargetYear: (year: number) => void;
  setTargetMonth: (month: number) => void;
};

export const useMonthlyPicker = ({
  apiResponse,
  startYear,
  startMonth,
  targetYear,
  targetMonth,
  setTargetYear,
  setTargetMonth,
}: Props) => {
  const [availableMonths, setAvailableMonths] = useState<number[]>([]);
  const [thisYear, setThisYear] = useState<number>(0);
  const [thisMonth, setThisMonth] = useState<number>(0);
  const [thisStartDate, setThisStartDate] = useState<string>('');
  const [thisEndDate, setThisEndDate] = useState<string>('');

  const handleSetTargetDate = useCallback(
    (year: number, month: number) => {
      setTargetYear(year);
      setTargetMonth(month);
    },
    [setTargetMonth, setTargetYear],
  );

  useEffect(() => {
    if (!apiResponse || !apiResponse.startDate || !apiResponse.endDate) return;
    const startDate = new Date(apiResponse.startDate as unknown as string);
    const endDate = new Date(apiResponse.endDate as unknown as string);

    setThisYear(startDate.getFullYear());
    setThisMonth(startDate.getMonth() + 1);
    setThisStartDate(
      `${startDate.getFullYear()}年${
        startDate.getMonth() + 1
      }月${startDate.getDate()}日`,
    );
    setThisEndDate(
      `${endDate.getFullYear()}年${
        endDate.getMonth() + 1
      }月${endDate.getDate()}日`,
    );
  }, [apiResponse]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    if (startYear === targetYear) {
      const months = Array.from(
        { length: 12 - startMonth + 1 },
        (_, i) => i + startMonth,
      );
      setAvailableMonths(months);
    } else if (targetYear === currentYear) {
      const months = Array.from({ length: currentMonth }, (_, i) => i + 1);
      setAvailableMonths(months);
    } else {
      const months = Array.from({ length: 12 }, (_, i) => i + 1);
      setAvailableMonths(months);
    }
  }, [startMonth, startYear, targetMonth, targetYear]);

  return {
    availableMonths,
    thisYear,
    thisMonth,
    thisStartDate,
    thisEndDate,
    handleSetTargetDate,
  };
};
