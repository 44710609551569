import { MotionProps, motion } from 'framer-motion';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledResponsiveContainer = memo(
  ({ children, ...props }: MotionProps) => {
    return (
      <motion.div className={vanilla.styledResponsiveContainer} {...props}>
        {children}
      </motion.div>
    );
  },
);
