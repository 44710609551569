import { WHITE_BATCH_LIMIT_IMAGES } from '@/constants';
import {
  BatchUpload
} from '@/features/components/steps';

export const initialStepStates = [
  {
    title: 'xxx', // TODO: 不要であれば型変更して渡さなくても良いようにする（contextが複雑なので無理にしなくても良い）
    name: 'batchUpload',
    num: 1,
    explain: '背景を白抜きする画像（上限100枚まで）をアップロードしてください',
    component: BatchUpload,
    isActive: true,
  },
];

// TODO: いったんBackgroundLoraのものをコピペしてきたものなので不要なものがあれば削除する。
export const initialDataParamState = {
  isNoBackground: false,
  keepMaskImage: '',
  targetType: 'image',
  pickColor: '',
  newColor: '',
  currentColor: '',
  newRef: '',
  currentRef: '',
  withShadow: 'on',
  blurLevel: 'none',
  generationCount: 1,
  format: 'png',
  batchLimitImages: WHITE_BATCH_LIMIT_IMAGES,
};
