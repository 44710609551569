import { ButtonModeProps } from '@/components/atoms/Button/types';
import { useCallback } from 'react';

/**
 * JSDoc
 * @see ボタンスタイリングを行うカスタムフック
 * @returns {function} getBackgroundColor, getFontColor, getBorder, getBoxShadow, getHoverBackgroundColor
 */
export const useButtonStyle = () => {
  const getBackgroundColor = useCallback(
    (mode: ButtonModeProps, disabled?: boolean) => {
      if (disabled) {
        switch (mode) {
          case 'border':
            return 'var(--color-gray-white)';
          default:
            return 'var(--color-gray-light)';
        }
      }

      switch (mode) {
        case 'primary':
          return 'var(--color-primary-deep)';
        case 'danger':
          return 'var(--color-danger-main)';
        case 'alert':
          return 'var(--color-alert-main)';
        case 'teal':
          return 'var(--color-teal-400)';
        case 'border':
        case 'alertBorder':
          return 'var(--color-gray-white)';
        case 'dangerBorder':
          return 'var(--color-gray-white)';
        case 'gray':
          return 'var(--color-gray-pale)';
        case 'text':
          return 'transparent';
        default:
          return 'var(--color-gray-white)';
      }
    },
    [],
  );

  const getFontColor = useCallback(
    (mode: ButtonModeProps, disabled?: boolean) => {
      if (disabled) {
        switch (mode) {
          case 'border':
            return 'var(--color-gray-light)';
          default:
            return 'var(--color-gray-white)';
        }
      }
      switch (mode) {
        case 'primary':
        case 'danger':
        case 'alert':
        case 'teal':
          return 'var(--color-gray-white)';
        case 'border':
          return 'var(--color-primary-deep)';
        case 'alertBorder':
          return 'var(--color-alert-main)';
        case 'dangerBorder':
          return 'var(--color-danger-main)';
        case 'gray':
          return 'var(--color-gray-main)';
        default:
          return 'var(--color-gray-black)';
      }
    },
    [],
  );

  const getBorder = useCallback((mode: ButtonModeProps, disabled?: boolean) => {
    if (disabled) {
      switch (mode) {
        case 'border':
          return `1px solid ${'var(--color-gray-light)'}`;
        default:
          return '0px solid transparent';
      }
    }
    switch (mode) {
      case 'border':
        return `1px solid ${'var(--color-primary-main)'}`;
      case 'alertBorder':
        return `1px solid ${'var(--color-alert-main)'}`;
      case 'dangerBorder':
        return `1px solid ${'var(--color-danger-main)'}`;
      default:
        return '0px solid transparent';
    }
  }, []);

  const getBoxShadow = useCallback(
    (mode: ButtonModeProps, disabled?: boolean, hasShadow?: boolean) => {
      if (disabled || !hasShadow) return '';
      switch (mode) {
        case 'primary':
        case 'danger':
        case 'alert':
        case 'teal':
          return '0px 3px 1px -2px rgba(0,0,0,0.4)';
        default:
          return '';
      }
    },
    [],
  );

  const getHoverBackgroundColor = useCallback((mode: ButtonModeProps) => {
    switch (mode) {
      case 'primary':
        return 'var(--color-primary-dark)';
      case 'alert':
        return 'var(--color-alert-dark)';
      case 'teal':
        return 'var(--color-teal-dark)';
      case 'gray':
        return 'var(--color-gray-soft)';
      default:
        return 'var(--color-gray-soft)';
    }
  }, []);

  const getActiveBackgroundColor = useCallback((mode: ButtonModeProps) => {
    switch (mode) {
      case 'primary':
        return 'var(--color-primary-main)';
      case 'alert':
        return 'var(--color-alert-main)';
      case 'teal':
        return 'var(--color-teal-400)';
      case 'gray':
        return 'var(--color-gray-light)';
      default:
        return 'var(--color-gray-light)';
    }
  }, []);

  return {
    getBackgroundColor,
    getFontColor,
    getBorder,
    getBoxShadow,
    getHoverBackgroundColor,
    getActiveBackgroundColor,
  };
};
