import { atom, useAtom } from 'jotai';

const uploadingProgressAtom = atom<number>(0);

export const useUploadingProgress = () => {
  const [uploadingProgress, setUploadingProgress] = useAtom(
    uploadingProgressAtom,
  );

  return { uploadingProgress, setUploadingProgress };
};
