import { useBackendApi } from '@/api';
import { Task } from '@/api/schemas';
import { FeatureBatch } from '@/contexts/FeaturesContext/types';
import { useBatchOptionalContext } from '@/features/components/steps/hooks/useBatchOptionalContext';
import { useError } from '@/hooks/global/useError';
import { useEffect, useState } from 'react';
import { useCoordinateParam } from './useCoordinateParam';

type GenStatus = {
  isGenerating: boolean;
  isGenerated: boolean;
  isFailed: boolean;
};

type Props = {
  setTaskApiResponse: (taskApiResponse: Task | undefined) => void;
  set: FeatureBatch;
  retryId: string;
  setRetryId: (retryId: string) => void;
  batchId: string;
};
/**
 * @see セットごとに生成のリクエストを行うカスタムフック
 * @see DataWithRequestからpropsで渡されたセットごとに生成状態をローカルステートで持つ
 * @see postTaskV2で生成リクエストを行う
 * @see 戻り値のidを元にgenTaskで生成状態を監視する
 */
export const useRequest = ({
  set,
  retryId,
  setRetryId,
  batchId,
  setTaskApiResponse,
}: Props) => {
  const [apiPostResponse, setApiPostResponse] = useState<Task | undefined>();
  const [apiGetResponse, setApiGetResponse] = useState<Task | undefined>();
  const { postTasksV2, getTask } = useBackendApi({});
  const { loggerFromGeneratingStatus, logger } = useError();
  const [genStatus, setGenStatus] = useState<GenStatus>({
    isGenerating: false,
    isGenerated: false,
    isFailed: false,
  });
  const {
    isStopBatchGenerating,
    isBatchGenerating,
    setIsBatchGenerating,
    setIsStopBatchGenerating,
  } = useBatchOptionalContext();
  const { makeParam } = useCoordinateParam({ set });

  /**
   * @see リトライはbatchのidを元に発火
   * @see 実行された時に各ステートを初期化
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!apiPostResponse) return;
    if (!retryId) return;
    if (retryId !== set.id) return;
    setTaskApiResponse(undefined);
    setApiPostResponse(undefined);
    setApiGetResponse(undefined);
    setGenStatus({
      isGenerating: false,
      isGenerated: false,
      isFailed: false,
    });
    setRetryId('');
    setIsBatchGenerating(true);
    setIsStopBatchGenerating(false);
  }, [
    apiPostResponse,
    batchId,
    retryId,
    set.id,
    setIsBatchGenerating,
    setIsStopBatchGenerating,
    setRetryId,
    setTaskApiResponse,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (isStopBatchGenerating) return;
    if (!isBatchGenerating) return;
    if (apiPostResponse) return;

    // biome-ignore lint/complexity/noVoid: あとで修正
    void (async () => {
      try {
        const param = makeParam();
        if(!param) throw new Error('makeparam() returned undefined');

        const res = await postTasksV2(param);
        setApiPostResponse(res);
      } catch (error) {
        console.error(error);
        logger({ error });
      }
    })();
  }, [
    postTasksV2,
    set.originalImages,
    set.refImage.maskImageId,
    set.refImage.refImageId,
    set.setName,
    setApiPostResponse,
    apiPostResponse,
    logger,
    isStopBatchGenerating,
    makeParam,
    set.id,
    isBatchGenerating,
  ]);

  useEffect(() => {
    if (isStopBatchGenerating) return;
    if (apiGetResponse) return;
    // biome-ignore lint/complexity/noVoid: あとで修正
    void (async () => {
      if (!apiPostResponse) return;
      try {
        const res = await getTask(apiPostResponse.id, {});
        setApiGetResponse(res);
        setGenStatus({
          isGenerating: true,
          isGenerated: false,
          isFailed: false,
        });
      } catch (error) {
        console.error(error);
        logger({ error });
      }
    })();
  }, [apiGetResponse, getTask, apiPostResponse, logger, isStopBatchGenerating]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    // biome-ignore lint/suspicious/noEmptyBlockStatements: 必要性が不明。あとで確認
    if (isStopBatchGenerating) return () => {};
    // biome-ignore lint/suspicious/noEmptyBlockStatements: 必要性が不明。あとで確認
    if (genStatus.isGenerated || genStatus.isFailed) return () => {};
    if (apiGetResponse && apiGetResponse.result.status === 'COMPLETED') {
      setGenStatus({
        isGenerating: false,
        isGenerated: false,
        isFailed: true,
      });

      // biome-ignore lint/suspicious/noEmptyBlockStatements: 必要性が不明。あとで確認
      return () => {};
    }
    if (
      apiGetResponse &&
      (apiGetResponse.result.status === 'FAILED' ||
        apiGetResponse.result.status === 'CANCELED' ||
        apiGetResponse.result.status === 'TIME_OUT')
    ) {
      loggerFromGeneratingStatus(apiGetResponse.result.status);
      setGenStatus({
        isGenerating: false,
        isGenerated: false,
        isFailed: true,
      });

      // biome-ignore lint/suspicious/noEmptyBlockStatements: 必要性が不明。あとで確認
      return () => {};
    }

    // biome-ignore lint/suspicious/noEmptyBlockStatements: 必要性が不明。あとで確認
    if (!genStatus.isGenerating) return () => {};
    // biome-ignore lint/suspicious/noEmptyBlockStatements: 必要性が不明。あとで確認
    if (!apiPostResponse) return () => {};

    const timeoutId = setTimeout(() => {
      // biome-ignore lint/complexity/noVoid: あとで修正
      void (async () => {
        if (!apiPostResponse) return;
        try {
          const res = await getTask(apiPostResponse.id, {});
          setApiGetResponse(res);
        } catch (error) {
          console.error(error);
          logger({ error });
        }
      })();
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    apiGetResponse,
    apiPostResponse,
    genStatus.isFailed,
    genStatus.isGenerated,
    genStatus.isGenerating,
    getTask,
    isStopBatchGenerating,
    logger,
    loggerFromGeneratingStatus,
    set,
  ]);

  return {
    apiPostResponse,
    apiGetResponse,
    genStatus,
  };
};
