import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { useDialog } from '@/hooks/local/useDialog';
import { usePopper } from '@/hooks/local/usePopper';
import { useCallback, useState } from 'react';

export const useBodyHandlers = () => {
  const { featureData } = useBatchUpload();
  const [deleteSetName, setDeleteSetName] = useState('');
  const [deleteId, setDeleteId] = useState('');

  const { handleOpenPopper, isOpenPopper, handleClosePopper, anchorElement } =
    usePopper();

  const {
    handleOpenDialog: handleOpenConfirmDialog,
    isOpenDialog: isOpenConfirmDialog,
    handleCloseDialog: handleCloseConfirmDialog,
  } = useDialog();

  const { handleDeleteSet } = useBatchUpload();

  const handleOpenDeleteMenu = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, id: string, setName: string) => {
      handleOpenPopper(e);
      setDeleteId(id);
      setDeleteSetName(setName);
    },
    [handleOpenPopper],
  );

  const handleOpenDeleteDialog = useCallback(() => {
    handleClosePopper();
    handleOpenConfirmDialog();
  }, [handleClosePopper, handleOpenConfirmDialog]);

  const handleSubmit = useCallback(() => {
    if (!deleteId) return;
    handleDeleteSet(deleteId);
    handleCloseConfirmDialog();
    setDeleteId('');
    setDeleteSetName('');
  }, [deleteId, handleDeleteSet, handleCloseConfirmDialog]);

  return {
    featureData,
    handleOpenDeleteMenu,
    handleOpenDeleteDialog,
    handleSubmit,
    deleteSetName,
    deleteId,
    isOpenPopper,
    anchorElement,
    isOpenConfirmDialog,
    handleCloseConfirmDialog,
    handleClosePopper,
  };
};
