import { Box } from '@/components/atoms/Box';
import { theme } from '@/config/theme';
import { formatHex, hexToHsv, hexToRgb, rgbToHex, rgbToHsv } from '@/utils/reactColorPalette';
import { Stack, TextField, styled } from '@mui/material';
import { toInteger } from 'lodash';
import { memo, useCallback, useState } from 'react';

import { Hue, IColor, Saturation, useColor } from "react-color-palette";
import "react-color-palette/css";

// TODO: MUI廃止時に書き換える
const CenterdTextField = styled(TextField)({
  '& > div': {
    height: '40px',
  },
  '& input': {
    fontSize: theme.fontSize.mdl,
    textAlign: 'center',
    height: '40px',
    paddingTop: '0',
    paddingBottom: '0',
  },
});
// TODO: MUI廃止時に書き換える
const SaturationWrapper = styled('div')({
  // react-color-paletteが出力するコンポーネントのカスタマイズ
  '.rcp-saturation': {
    borderRadius: '10px!important',
  },
});


type Props = {
  currentColor: string | undefined;
  onChangeNewColor: (color: string) => void;
};

/**
 * JSDoc
 * @see カラーピッカーコンポーネント
 * @see カラーを選択する際に使用する
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const ColorPicker = memo(
  ({ onChangeNewColor: handleChangeNewColor, currentColor}: Props) => {

    const [iColor, setIColor] = useColor(currentColor || '#FFFFFF');

    const [colorR, setColorR] = useState<string | undefined>(iColor.rgb.r.toString())
    const [colorG, setColorG] = useState<string | undefined>(iColor.rgb.g.toString())
    const [colorB, setColorB] = useState<string | undefined>(iColor.rgb.b.toString())
    const [colorHex, setColorHex] = useState<string | undefined>(iColor.hex.toString())

    const handleChangeColorHex = useCallback((hex: string) => {
      setColorHex(hex)

      const rgb = hexToRgb(hex)
      setColorR(rgb.r.toString())
      setColorG(rgb.g.toString())
      setColorB(rgb.b.toString())

      const hsv = hexToHsv(hex)
      setIColor(prev => {
        return {
          hex,
          rgb: {
            ...rgb,
            a: prev.rgb.a,
          },
          hsv: {
            ...hsv,
            a: prev.hsv.a,
          },
        }
      })
      // hexを直接操作した場合は値をフォーマットして渡す
      handleChangeNewColor(formatHex(hex))
    }, [setIColor, handleChangeNewColor])

    const handleChangeColorR = useCallback((r: string) => {
      setColorR(r)
      const hex = rgbToHex(r, colorG, colorB)
      setColorHex(hex)
      const hsv = rgbToHsv(Number(r), Number(colorG), Number(colorB))
      setIColor(prev => {
        return {
          hex,
          rgb: {
            ...prev.rgb,
            r: Number(r),
          },
          hsv: {
            ...hsv,
            a: prev.hsv.a,
          },
        }
      })
    }, [colorG, colorB, setIColor])

    const handleChangeColorG = useCallback((g: string) => {
      setColorG(g)
      const hex = rgbToHex(colorR, g, colorB)
      setColorHex(hex)
      const hsv = rgbToHsv(Number(colorR), Number(g), Number(colorB))
      setIColor(prev => {
        return {
          hex,
          rgb: {
            ...prev.rgb,
            g: Number(g),
          },
          hsv: {
            ...hsv,
            a: prev.hsv.a,
          },
        }
      })
      // hexの変換時にフォーマットできているためそのまま渡す
      handleChangeNewColor(hex)
    }, [colorR, colorB, setIColor, handleChangeNewColor])

    const handleChangeColorB = useCallback((b: string) => {
      setColorB(b)
      const hex = rgbToHex(colorR, colorG, b)
      setColorHex(hex)
      const hsv = rgbToHsv(Number(colorR), Number(colorG), Number(b))
      setIColor(prev => {
        return {
          hex,
          rgb: {
            ...prev.rgb,
            b: Number(b),
          },
          hsv: {
            ...hsv,
            a: prev.hsv.a,
          },
        }
      })
      // hexの変換時にフォーマットできているためそのまま渡す
      handleChangeNewColor(formatHex(hex))
    }, [colorR, colorG, setIColor, handleChangeNewColor])

    const handleChangeIColor = useCallback((eventValue: IColor) => {
      setIColor(eventValue)
      const r = toInteger(eventValue.rgb.r).toString()
      const g = toInteger(eventValue.rgb.g).toString()
      const b = toInteger(eventValue.rgb.b).toString()
      setColorR(r)
      setColorG(g)
      setColorB(b)
      const hex = rgbToHex(r,g,b)
      setColorHex(hex)
      // hexの変換時にフォーマットできているためそのまま渡す
      handleChangeNewColor(formatHex(hex))
    }, [setIColor, handleChangeNewColor])

    // TODO: MUI廃止時に書き換える

    return (
        <Stack gap="20px" useFlexGap>
          <SaturationWrapper>
            <Saturation height={300} color={iColor} onChange={handleChangeIColor} />
          </SaturationWrapper>
          <Hue color={iColor} onChange={handleChangeIColor} />
          <Stack direction="row" gap="8px" useFlexGap>
            <Stack direction="column" gap="4px" useFlexGap>
              <Box component="span" sx={{ fontSize: theme.fontSize.mdl, height: 24, textAlign: 'center' }}>R</Box>
              <CenterdTextField value={colorR} variant="outlined" onChange={(event) => handleChangeColorR(event.currentTarget.value)} />
            </Stack>
            <Stack direction="column" gap="4px" useFlexGap>
              <Box component="span" sx={{ fontSize: theme.fontSize.mdl, height: 24, textAlign: 'center'  }}>G</Box>
              <CenterdTextField value={colorG} variant="outlined" onChange={(event) => handleChangeColorG(event.currentTarget.value)}/>
            </Stack>
            <Stack direction="column" gap="4px" useFlexGap>
              <Box component="span" sx={{ fontSize: theme.fontSize.mdl, height: 24, textAlign: 'center'  }}>B</Box>
              <CenterdTextField
                value={colorB} variant="outlined" onChange={(event) => handleChangeColorB(event.currentTarget.value)}/>
            </Stack>
          </Stack>
          <CenterdTextField
            value={colorHex}
            variant="outlined"
            onChange={(event) => handleChangeColorHex(event.currentTarget.value)}
          />
        </Stack>
    );
  },
);
