import { Report, Task, TaskResultImage } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import { Tooltip } from '@/components/atoms/Tooltip';
import { StyledFlexCenterExpanded, StyledGridGap5 } from '@/components/styled';
import { StyledAdminDetailBorderButton } from '@/components/styled/button';
import { Skelton } from '@/features/components/atoms/Skelton';
import { GlobalNavigator } from '@/features/components/organisms/GlobalNavigator';
import { StyledScrollContainer } from '@/features/components/styled/navigator';
import { StyledTable } from '@/features/components/styled/table';
import { useTasks, useTasksHandlers, useTasksParam } from '@/hooks/tasks';
import { useConvertDate } from '@/hooks/utils/useConvertDate';
import { useIcons } from '@/hooks/utils/useIcons';
import {
  Divider,
  Table as MuiTable,
  TableBody,
  TableCell as Td,
} from '@mui/material';
import { memo, useCallback, useState } from 'react';
import { Head } from './Head';
import {
  StyledBoxNowrapWidth50,
  StyledDataContainer,
  StyledFileNameBox,
  StyledFlexEndGap10Height30,
  StyledFlexGap10Height30,
  StyledTr,
} from './styled';

type Props = {
  reportApiResponse: Report;
  setDetailData: (data: Task) => void;
};

export const Table = memo(
  ({ reportApiResponse, setDetailData }: Props): JSX.Element => {
    const { convertToUnixTime } = useConvertDate();

    const { tasksParam, updateTasksParamObject, addUserId, removeUserId } =
      useTasksParam({
        createdAtAfterUnix: convertToUnixTime(reportApiResponse.startDate),
        createdAtBeforeUnix: convertToUnixTime(reportApiResponse.endDate),
      });

    const { handelChangeLimit } = useTasksHandlers({
      updateTasksParamObject,
      addUserId,
      removeUserId,
    });

    const {
      apiResponse,
      isLoading,
      count,
      page,
      scrollBoxRef,
      handleChangePage,
    } = useTasks({
      tasksParam,
      updateTasksParamObject,
      hasAutoReload: false,
    });

    const { returnIcon } = useIcons();
    const { convertToDateTime } = useConvertDate();
    const findDownloaded = useCallback((array: TaskResultImage[]) => {
      const downloadedArray = array.filter((d) => d.downloadedAt !== null);
      if (downloadedArray.length > 0) {
        return downloadedArray.length;
      }

      return 0;
    }, []);
    const [selectedId, setSelectedId] = useState<string | null>(null);

    const handleClickDetailButton = useCallback(
      (data: Task) => {
        setSelectedId(data.id);
        setDetailData(data);
      },
      [setDetailData],
    );

    return (
      <>
        <StyledScrollContainer ref={scrollBoxRef}>
          <StyledDataContainer>
            {isLoading && <Skelton mode="downloadManagementList" />}
            {!isLoading && apiResponse && apiResponse.data.length === 0 && (
              <StyledFlexCenterExpanded>
                データがありません
              </StyledFlexCenterExpanded>
            )}
            {!isLoading && apiResponse && apiResponse.data.length > 0 && (
              <StyledTable>
                <MuiTable>
                  <Head />
                  <TableBody>
                    {apiResponse.data.map((d, i: number) => (
                      <StyledTr
                        key={`key-${i}`.toString()}
                        isSelected={selectedId === d.id}
                      >
                        <Td>
                          <StyledFlexGap10Height30>
                            <StyledBoxNowrapWidth50>
                              {returnIcon(d.category)}
                            </StyledBoxNowrapWidth50>
                            <Tooltip title={d.originalImages?.[0].inputImage.fileName || ''}>
                              <StyledFileNameBox>
                                {d.originalImages?.[0].inputImage.fileName}
                              </StyledFileNameBox>
                            </Tooltip>
                          </StyledFlexGap10Height30>
                        </Td>
                        <Td>
                          <StyledGridGap5>
                            <Box>{convertToDateTime(d.createdAt)}</Box>
                            <Box>{d.user?.email}</Box>
                          </StyledGridGap5>
                        </Td>
                        <Td>{d.nSample}</Td>
                        <Td>{findDownloaded(d.result.resultImages)}</Td>
                        <Td>
                          <StyledFlexGap10Height30>
                            <Divider orientation="vertical" />
                          </StyledFlexGap10Height30>
                        </Td>
                        <Td>
                          <StyledFlexEndGap10Height30>
                            <StyledAdminDetailBorderButton
                              onClick={() => {
                                handleClickDetailButton(d);
                              }}
                              disabled={!findDownloaded(d.result.resultImages)}
                            >
                              詳細
                            </StyledAdminDetailBorderButton>
                          </StyledFlexEndGap10Height30>
                        </Td>
                      </StyledTr>
                    ))}
                  </TableBody>
                </MuiTable>
              </StyledTable>
            )}
          </StyledDataContainer>
        </StyledScrollContainer>

        <GlobalNavigator
          isLoading={isLoading}
          limit={tasksParam.limit}
          count={count}
          page={page}
          handelChangeLimit={handelChangeLimit}
          handleChangePage={handleChangePage}
        />
      </>
    );
  },
);
