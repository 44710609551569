/* eslint-disable no-unsafe-optional-chaining */
import { FeatureData, ImageFields } from '@/contexts/FeaturesContext/types';
import { useCallback, useEffect, useState } from 'react';
import { useSegmentationKeys } from './useSegmentationKeys';

type Props = {
  field: ImageFields;
  isKeepMask?: boolean;
  data: FeatureData;
  updateData: (field: keyof FeatureData, value: unknown) => void;
};

/**
 * JSDoc
 * @see セグメンテーションのポイントを追加・保存するカスタムフック
 * @param {Props} { field }
 * @returns { previewSize, handleClick }
 */
export const useSegmentationAnything = ({
  field,
  isKeepMask,
  data,
  updateData,
}: Props) => {
  const {
    pointType,
    positiveKey,
    negativeKey,
    apiPositiveKey,
    apiNegativeKey,
    isPreviewCompletedKey,
  } = useSegmentationKeys(isKeepMask);

  const [previewSize, setPreviewSize] = useState<{
    width: number;
    height: number;
    reductionRatio: number;
  }>({ width: 0, height: 0, reductionRatio: 1 });

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const handleAddPoint = useCallback(
    (x: number, y: number) => {
      if (x <= 0 || y <= 0) return;
      if (data?.[field][pointType] === 'positive') {
        console.log(apiPositiveKey);
        console.log(x);
        console.log(y);
        updateData(field, {
          [isPreviewCompletedKey]: false,
          [positiveKey]: [...(data?.[field][positiveKey] ?? []), [x, y]],
          [apiPositiveKey]: [
            ...(data?.[field][apiPositiveKey] ?? []),
            [
              Math.round(x / previewSize.reductionRatio),
              Math.round(y / previewSize.reductionRatio),
            ],
          ],
        });
      } else {
        updateData(field, {
          [isPreviewCompletedKey]: false,
          [negativeKey]: [...(data?.[field][negativeKey] ?? []), [x, y]],
          [apiNegativeKey]: [
            ...(data?.[field][apiNegativeKey] ?? []),
            [
              Math.round(x / previewSize.reductionRatio),
              Math.round(y / previewSize.reductionRatio),
            ],
          ],
        });
      }
    },
    [
      data?.[field][positiveKey],
      data?.[field][negativeKey],
      data?.[field][apiPositiveKey],
      data?.[field][apiNegativeKey],
      data?.[field][pointType],
      field,
    ],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    updateData(field, {
      [apiPositiveKey]: data?.[field][positiveKey].map(
        (point: [number, number]) => [
          Math.round(point[0] / previewSize.reductionRatio),
          Math.round(point[1] / previewSize.reductionRatio),
        ],
      ),
    });
    updateData(field, {
      [apiNegativeKey]: data?.[field][negativeKey].map(
        (point: [number, number]) => [
          Math.round(point[0] / previewSize.reductionRatio),
          Math.round(point[1] / previewSize.reductionRatio),
        ],
      ),
    });
  }, [
    data?.[field][positiveKey],
    data?.[field][negativeKey],
    field,
    updateData,
    previewSize.reductionRatio,
  ]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (data?.[field].samType === 'auto') return;
      const { offsetX } = event.nativeEvent;
      const { offsetY } = event.nativeEvent;
      const x = Math.round(offsetX);
      const y = Math.round(offsetY);
      handleAddPoint(x, y);
    },
    [handleAddPoint, data?.[field].samType, field],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!data?.[field]) return;

    const maxWidth = 660;
    const maxHeight = 600;

    let height;
    let width;
    let reductionRatio;

    const originalHeight = data?.[field].originalSize.height;
    const originalWidth = data?.[field].originalSize.width;
    const resizedHeight = data?.[field].resizedSize.height || originalHeight;
    const resizedWidth = data?.[field].resizedSize.width || originalWidth;

    if (resizedWidth / resizedHeight > maxWidth / maxHeight) {
      // 横が縦より大きい場合、横幅を基準にサイズ調整
      reductionRatio = maxWidth / resizedWidth;
      width = maxWidth;
      height = Math.ceil(resizedHeight * reductionRatio);
    } else {
      // 縦が横より大きいまたは等しい場合、縦幅を基準にサイズ調整
      reductionRatio = maxHeight / resizedHeight;
      height = maxHeight;
      width = Math.ceil(resizedWidth * reductionRatio);
    }

    setPreviewSize({ width, height, reductionRatio });
  }, [
    data?.[field]?.originalSize.height,
    data?.[field]?.originalSize.width,
    field,
  ]);

  return {
    previewSize,
    handleClick,
  };
};
