import { Box } from '@/components/atoms/Box';
import { Dialog } from '@/components/molecules/Dialog';
import {
  StyledBoxHeight10,
  StyledBoxHeight30,
  StyledFlexCenter,
  StyledFlexGap10,
  StyledFlexGap5,
  StyledGrid,
  StyledGridGap15,
} from '@/components/styled';
import { StyledDialogProgressBackgroundButton } from '@/components/styled/button';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useTaskTray } from '@/hooks/global/useTaskTray';
import { LinearProgress } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { StyledConfirmBody, StyledConfirmDialogContainer } from './styled';

type Props = {
  title?: string;
  isOpen: boolean;
  loadingProgressPercent: number | null;
  handleCloseProgress: () => void;
  handleStopGenerating: () => void;
};

/**
 * JSDoc
 * @see 生成タスク進行中ダイアログ
 * @see バックラウンドで実行するかキャンセルするかの選択ダイアログ
 * @see 生成が進行した場合はキャンセルボタンを表示しない
 * @see 白抜きとリピート生成時はキャンセルボタンを表示しない
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const ProgressDialog = memo(
  ({
    title = '生成を待っています',
    isOpen,
    loadingProgressPercent = 0,
    handleCloseProgress,
    handleStopGenerating,
  }: Props): JSX.Element => {
    const [progress, setProgress] = useState(0);
    const [isConfirmCancel, setIsConfirmCancel] = useState(false);
    const { activeFeatureName, featureData } = useFeaturesContext({});
    /*
    2月スプリントではキャンセルは使わない
    サーバーサイドでキャンセルapiが実装されてから導入
    しかしながら、この関数にupdateFeatureDataを使うべきではないので
    実装時には呼び出し元で設定する
    const handleCancel = () => {
      updateFeatureDataSingle('genStatus', {
        isStop: true,
        isGenerated: false,
      });
      handleCloseProgress();
    };
    */
    const { setIsTaskTrayAnimation } = useTaskTray();
    const handleBackground = () => {
      handleStopGenerating();
      handleCloseProgress();
      setIsTaskTrayAnimation(true);
    };

    useEffect(() => {
      if (!isOpen) {
        setIsConfirmCancel(false);
      }
    }, [isOpen]);

    useEffect(() => {
      if (!loadingProgressPercent) return;
      setProgress(Math.round(loadingProgressPercent * 100));
    }, [loadingProgressPercent]);

    return (
      <Dialog
        isOpenDialog={isOpen}
        // biome-ignore lint/suspicious/noEmptyBlockStatements:
        onClose={() => {}} // クローズさせない
        maxWidth="sm"
        dialogTitle=""
        hasCloseIcon={false}
      >
        {!isConfirmCancel && (
          <StyledConfirmDialogContainer>
            <StyledConfirmBody>
              <StyledGrid>
                <StyledFlexCenter>
                  {loadingProgressPercent ? (
                    <LinearProgress
                      sx={{ width: 200, height: 4 }}
                      variant="determinate"
                      value={progress}
                    />
                  ) : (
                    <LinearProgress sx={{ width: 200, height: 4 }} />
                  )}
                </StyledFlexCenter>
                <StyledBoxHeight30 />
                <Box sx={{ fontSize: '16px', textAlign: 'center' }}>
                  {loadingProgressPercent ? <>生成中です</> : <>{title}</>}
                </Box>
                <StyledBoxHeight10 />
                <StyledFlexCenter
                  sx={{ fontSize: 'var(--font-size-xs)', textAlign: 'center' }}
                >
                  {loadingProgressPercent ? (
                    <>({progress}%)</>
                  ) : (
                    <StyledFlexGap5>
                      <Box
                        sx={{
                          borderRadius: '50%',
                          backgroundColor: '#EF5350',
                          width: 12,
                          height: 12,
                        }}
                      />
                      待機タスク:10(00分)
                    </StyledFlexGap5>
                  )}
                </StyledFlexCenter>
                <StyledBoxHeight30 />
                <StyledFlexCenter>
                  <StyledFlexGap10>
                    <StyledGridGap15>
                      <StyledDialogProgressBackgroundButton
                        onClick={handleBackground}
                      >
                        バックグラウンドで実行
                      </StyledDialogProgressBackgroundButton>
                      {activeFeatureName === 'white' ? (
                        <></>
                      ) : (
                        <>
                          {/*! loadingProgressPercent && (
                            <TextButton
                              onClick={handleCancel}
                              label="キャンセル"
                            />
                          ) */}
                        </>
                      )}
                    </StyledGridGap15>
                  </StyledFlexGap10>
                </StyledFlexCenter>
              </StyledGrid>
            </StyledConfirmBody>
          </StyledConfirmDialogContainer>
        )}
      </Dialog>
    );
  },
);
