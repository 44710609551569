import { MotionProps, motion } from 'framer-motion';
import React, { memo } from 'react';
import * as vanilla from './vanilla.css';

type StyledSnackbarMotionDivProps = {
  children?: React.ReactNode;
} & MotionProps;

export const StyledLoadingIconBox = memo(
  ({ children, ...props }: StyledSnackbarMotionDivProps) => {
    return (
      <motion.div className={vanilla.styledLoadingIconBox} {...props}>
        {children}
      </motion.div>
    );
  },
);
