import { Box, BoxProps } from '@/components/atoms/Box';
import { MotionProps, motion } from 'framer-motion';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledGlobalViewerContainer = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledGlobalViewerContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledSwiperMotionDiv = memo(
  ({ children, ...props }: MotionProps) => {
    return (
      <motion.div className={vanilla.styledSwiperMotionDiv} {...props}>
        {children}
      </motion.div>
    );
  },
);

export const StyledSwiperThumbsMotionDiv = memo(
  ({ children, ...props }: MotionProps) => {
    return (
      <motion.div className={vanilla.styledSwiperThumbsMotionDiv} {...props}>
        {children}
      </motion.div>
    );
  },
);
