import { Box } from '@/components/atoms/Box';
import { useAppContext } from '@/contexts/AppContext';
import { memo } from 'react';
import { StyledResponsiveContainer } from './styled';

type Props = {
  children: React.ReactNode;
};

export const ResponsiveWrapper = memo(({ children }: Props): JSX.Element => {
  const { contentScale } = useAppContext();

  return (
    <>
      <StyledResponsiveContainer
        animate={{ scale: contentScale }}
        transition={{ duration: 0, delay: 0 }}
      >
        <Box>{children}</Box>
      </StyledResponsiveContainer>
    </>
  );
});
