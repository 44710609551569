import { Box } from '@/components/atoms/Box';
import { StyledFont } from '@/components/styled';
import { BatchConstant } from '@/features/components/steps/hooks/useBatchConstant';
import {
  StyledTableRowContainer,
  StyledTableRowName,
} from '@/features/components/styled/batch';
import { memo } from 'react';

type Props = {
  isDisabledAccordionAll: boolean;
  batchConstant: BatchConstant | undefined;
  accordionAllButtonObject: {
    display: string;
    icon: React.ReactNode;
  };
  handleClickAllAccordion: () => void;
};

export const Head = memo(
  ({
    isDisabledAccordionAll,
    batchConstant,
    accordionAllButtonObject,
    handleClickAllAccordion,
  }: Props): JSX.Element => {
    return (
      <StyledTableRowContainer
        style={{
          borderTop: 0,
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        }}
      >
        <StyledTableRowName>
          <StyledFont>画像セット</StyledFont>
        </StyledTableRowName>
        <Box style={{ width: 100, maxWidth: 100 }} />
        <Box style={{ width: 50, maxWidth: 50 }} />
        <Box style={{ width: 200, whiteSpace: 'nowrap' }}>
          <StyledFont>アップロード日時</StyledFont>
        </Box>
        <Box style={{ width: 150, whiteSpace: 'nowrap', textAlign: 'center' }}>
          <StyledFont>ファイル数</StyledFont>
        </Box>
        {batchConstant?.hasUploadRef ? (
          <Box
            style={{ width: 150, textAlign: 'center', whiteSpace: 'nowrap' }}
          >
            <StyledFont>参照画像指定</StyledFont>
          </Box>
        ) : (
          <></>
        )}
        <Box
          style={{
            width: 100,
            maxWidth: 100,
            textAlign: 'center',
            whiteSpace: 'nowrap',
          }}
        >
          {batchConstant?.addableSet &&
            <Box
              onClick={() => !isDisabledAccordionAll && handleClickAllAccordion()}
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: isDisabledAccordionAll
                  ? 'var(--color-gray-main)'
                  : 'var(--color-primary-main)',
                fontSize: '14px',
                '&:hover': {
                  color: isDisabledAccordionAll
                    ? 'var(--color-gray-main)'
                    : 'var(--color-primary-dark)',
                  cursor: 'pointer',
                },
              }}
            >
              {accordionAllButtonObject.display}
              {accordionAllButtonObject.icon}
            </Box>
          }
        </Box>
      </StyledTableRowContainer>
    );
  },
);
