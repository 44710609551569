import { StyledGridGap8 } from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import { GenerationCount } from '@/features/components/functions/GenerationCount';
import {
  StyledFunctionPartition
} from '@/features/components/styled/function';
import { useEffect, useState } from 'react';
import { TargetTypeForBackground } from './TargetTypeForBackground';
import { TargetTypeForColor } from './TargetTypeForColor';
import { TargetTypeForItem } from './TargetTypeForItem';
import { useTargetTypeHandlers } from './hooks/useTargetTypeHandlers';

type Props = {
  handleResetAfterPreview: () => void;
};
export const Functions = ({ handleResetAfterPreview }: Props): JSX.Element => {
  const { activeFeatureName, featureData, updateFeatureDataSingle } =
    useFeaturesContext({});

  const { handleClickFeatureTargetType } = useTargetTypeHandlers({
    handleResetAfterPreview,
  });

  const [value, setValue] = useState<unknown>({});

  useEffect(() => {
    if (!value) return;
    updateFeatureDataSingle('param', value);
    handleResetAfterPreview();
  }, [handleResetAfterPreview, updateFeatureDataSingle, value]);

  return (
    <>
      <div
        style={{
          padding: '24px',
          height: '100%',
        }}
      >
        <StyledGridGap8>
          {activeFeatureName === 'background' && (
            <TargetTypeForBackground
              handleClickFeatureTargetType={handleClickFeatureTargetType}
              handleResetAfterPreview={handleResetAfterPreview}
            />
          )}
          {activeFeatureName === 'color' && (
            <TargetTypeForColor
              handleClickFeatureTargetType={handleClickFeatureTargetType}
              handleResetAfterPreview={handleResetAfterPreview}
            />
          )}
          {activeFeatureName === 'item' && (
            <TargetTypeForItem
              handleResetAfterPreview={handleResetAfterPreview}
            />
          )}
          <StyledFunctionPartition />
          <GenerationCount
            data={featureData?.single?.param?.generationCount}
            setValue={setValue}
            max={3}
          />
        </StyledGridGap8>
      </div>
    </>
  );
};
