import type { SVGProps } from 'react';
import { memo } from 'react';

const SegmentationPositivePin = memo(
  (props: SVGProps<SVGSVGElement>): JSX.Element => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={36}
        height={42}
        fill="none"
        {...props}
      >
        <g
          fillRule="evenodd"
          clipRule="evenodd"
          filter="url(#segmentationAddPin_svg__a)"
        >
          <path
            fill="#00D2A0"
            d="M22.974 25.677A9.996 9.996 0 0 0 28 17c0-5.523-4.477-10-10-10S8 11.477 8 17a9.996 9.996 0 0 0 5.027 8.677c1.87 1.504 4.195 4.89 4.973 7.33.778-2.44 3.103-5.826 4.974-7.33Z"
          />
          <path fill="#fff" d="M19 11h-2v5h-5v2h5v5h2v-5h5v-2h-5v-5Z" />
        </g>
        <defs>
          <filter
            id="segmentationAddPin_svg__a"
            width={40}
            height={46}
            x={-2}
            y={-2}
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feMorphology
              in="SourceAlpha"
              radius={2}
              result="effect1_dropShadow_1070_53207"
            />
            <feOffset dy={3} />
            <feGaussianBlur stdDeviation={1.5} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1070_53207"
            />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy={3} />
            <feGaussianBlur stdDeviation={2} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
            <feBlend
              in2="effect1_dropShadow_1070_53207"
              result="effect2_dropShadow_1070_53207"
            />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy={1} />
            <feGaussianBlur stdDeviation={4} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
            <feBlend
              in2="effect2_dropShadow_1070_53207"
              result="effect3_dropShadow_1070_53207"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect3_dropShadow_1070_53207"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  },
);
export default SegmentationPositivePin;
