import { useBackendApi } from '@/api';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageFields } from '@/contexts/FeaturesContext/types';
import { useSnackbar } from '@/hooks/global/useSnackbar';
import { useGetFiles } from '@/hooks/utils/useGetFiles';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { getImageSize } from 'react-image-size';
import { useUploadImageHelpers } from './useUploadImageHelpers';

type Props = {
  field: ImageFields;
  errorFiles: File[];
  setErrorFiles: (errorFiles: File[]) => void;
  setExceededFiles: (exceededFiles: File[]) => void;
};
/**
 * JSDoc
 * @see 画像を取得してfeaturesContextに保存するmodalのアップローダーと共通カスタムフック
 * @see useDropzoneにて画像を取得する
 * @see useUploadImageHelperを用いて必要であればリサイズしてbase64とサイズ情報を保存する
 * @see アップロード成功時はSnackbarを表示する
 * @param {Props} props - プロパティ
 * @param {ImageFields} props.field - フィールド "mainImage| refImage"
 * @returns {object} getRootProps - ドラッグアンドドロップエリアのプロパティ
 * @returns {object} getInputProps - ドラッグアンドドロップエリアのプロパティ
 * @returns {boolean} isDragActive - ドラッグアンドドロップエリアがアクティブかどうか
 * @returns {string[]} errorFiles - エラーファイルの配列
 * @returns {string[]} exceededFiles - エラーファイルの配列
 */
export const useUploadImage = ({
  field,
  errorFiles,
  setErrorFiles,
  setExceededFiles,
}: Props) => {
  const { getFiles } = useGetFiles({
    setErrorFiles,
    setExceededFiles,
  });
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      getFilesFromEvent: (event) => getFiles(event),
    });

  const { featureData, updateFeatureData } = useFeaturesContext({});
  const { getImageInfo } = useUploadImageHelpers();
  const { handleOpenSnackbar, setSnackbarParam } = useSnackbar();
  const { postImages } = useBackendApi({});

  const MIN_SIZE = 500;

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (featureData?.[field].file) return;
    if (acceptedFiles.length <= 0) {
      // updateFeatureData(field, initialFeatureDataImageState);

      return;
    }

    const file = acceptedFiles[0];
    const blobUrl = window.URL.createObjectURL(acceptedFiles[0]);

    // biome-ignore lint/complexity/noVoid: あとで修正
    void (async () => {
      const dimension = await getImageSize(blobUrl);
      if (dimension.width < MIN_SIZE && dimension.height < MIN_SIZE) {
        setErrorFiles([...errorFiles, file]);

        return;
      }
      const { originalSize, resizedSize, base64 } = await getImageInfo(
        file,
        dimension,
        blobUrl,
      );
      const postImageResult = await postImages({
        image: base64,
        fileName: file.name,
      });

      // TODO: featureDataを自由な値にできるため危険。型制御やロジックの整備を行う。
      updateFeatureData(field, {
        file,
        fileName: file.name,
        blobUrl,
        originalSize,
        resizedSize,
        base64,
        // 必要ない？refに使う場合は必要？
        id: postImageResult?.id // MEMO: v1/gen/tasks からv2に置き換えた際に追加した値。他のstepでは含まれていない場合がある
      });
    })();

  }, [
    featureData?.[field].file,
    acceptedFiles,
    field,
    updateFeatureData,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: あとで修正
  useEffect(() => {
    if (!featureData?.[field].base64) return;
    setSnackbarParam({
      iconName: 'success',
      text: `画像をアップロードしました`,
      position: { placement: 'rightTop', x: -320, y: 0 },
    });
    handleOpenSnackbar();
  }, [
    handleOpenSnackbar,
    setSnackbarParam,
    featureData?.[field].base64,
  ]);

  return {
    getRootProps,
    getInputProps,
    isDragActive,
  };
};
