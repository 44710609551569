export const useSegmentationKeys = (isKeepMask?: boolean) => {
  const pointType = !isKeepMask ? 'pointType' : 'kmPointType';
  const positiveKey = !isKeepMask ? 'positivePoints' : 'kmPositivePoints';
  const negativeKey = !isKeepMask ? 'negativePoints' : 'kmNegativePoints';
  const apiPositiveKey = !isKeepMask
    ? 'apiPositivePoints'
    : 'kmApiPositivePoints';
  const apiNegativeKey = !isKeepMask
    ? 'apiNegativePoints'
    : 'kmApiNegativePoints';
  const isPreviewCompletedKey = !isKeepMask
    ? 'isPreviewCompleted'
    : 'isKmPreviewCompleted';
  const isPreviewLoadingKey = !isKeepMask
    ? 'isPreviewLoading'
    : 'isKmPreviewLoading';
  const combinedKey = !isKeepMask ? 'combinedBase64' : 'keepMaskCombinedBase64';

  return {
    pointType,
    positiveKey,
    negativeKey,
    apiPositiveKey,
    apiNegativeKey,
    isPreviewCompletedKey,
    isPreviewLoadingKey,
    combinedKey,
  };
};
