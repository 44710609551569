import { memo } from 'react';
import { useBatchSetting } from '../../hooks/useBatchSetting';
import { AccordionRow } from './AccordionRow';

export const Body = memo((): JSX.Element => {
  const { featureData } = useBatchSetting();

  return (
    <>
      {featureData?.batch.map((set, i) => (
        <AccordionRow
          key={`accordion-${i.toString()}`}
          index={i}
          set={set}
          targetNumber={i}
        />
      ))}
    </>
  );
});
