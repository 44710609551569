import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { Dialog } from '@/components/molecules/Dialog';
import {
  StyledBoxHeight24,
  StyledBoxHeight5,
  StyledFlexEndGap5,
  StyledFont16Bold,
  StyledGridCenter,
  StyledGridGap20,
} from '@/components/styled';
import { memo } from 'react';

type Props = {
  isOpenDialog: boolean;
  handleCloseDialog: () => void;
  handleSubmit: () => void;
  setName: string;
  id: string;
};

export const ConfirmDeleteSetDialog = memo(
  ({
    isOpenDialog,
    handleCloseDialog,
    handleSubmit,
    setName,
    id,
  }: Props): JSX.Element => {
    return (
      <>
        <Dialog
          isOpenDialog={isOpenDialog}
          onClose={handleCloseDialog}
          maxWidth="sm"
          dialogTitle=""
          hasCloseIcon={false}
        >
          <StyledGridCenter style={{ justifyContent: 'center' }}>
            <Box
              style={{
                display: 'grid',
                justifyContent: 'center',
                textAlign: 'center',
                width: '352px',
                height: '212px',
                alignItems: 'center',
              }}
            >
              <Box style={{ display: 'grid', gap: '20px' }}>
                <StyledFont16Bold>
                  セットを削除して宜しいですか？
                </StyledFont16Bold>
                <Box>
                  <Box>一度削除したセットは元に戻せません。</Box>
                  <Box
                    sx={{
                      maxWidth: 300,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    削除セット名：{setName}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                display: 'grid',
                justifyContent: 'center',
                textAlign: 'center',
                height: '60px',
              }}
            >
              <StyledGridGap20>
                <StyledBoxHeight5 />
                <StyledFlexEndGap5>
                  <Button mode="alertBorder" onClick={handleCloseDialog}>
                    キャンセル
                  </Button>
                  <Button mode="alert" onClick={handleSubmit}>
                    削除する
                  </Button>
                </StyledFlexEndGap5>
              </StyledGridGap20>
            </Box>
          </StyledGridCenter>
          <StyledBoxHeight24 />
        </Dialog>
      </>
    );
  },
);
