import { useCallback, useState } from 'react';
import { DropEvent } from 'react-dropzone';
import { useValidateUploadImage } from './useValidateUploadImage';

type MyEvent = DropEvent | InputEvent;

type Props = {
  updateErrorAtGettingFiles: (
    formatErrorFiles: File[],
    dataSizeErrorFiles: File[],
  ) => void;
};

/**
 * @see バッチ処理のファイルをブラウザにアップロードするためのhooks
 * @see ファイルのフォーマット、画像サイズを超えたファイルをupdateErrorAtGettingFilesに更新
 * @see 取得ファイルからエラーファイルを削除して返す
 */
export const useBatchGetFiles = ({ updateErrorAtGettingFiles }: Props) => {
  const [files, setFiles] = useState<File[]>([]);

  const { validateImageFiles } = useValidateUploadImage();

  const getFiles = useCallback(
    async (event: MyEvent) => {
      const newFiles: File[] = [];
      const newFormatErrorFiles: File[] = [];
      const newDataSizeErrorFiles: File[] = [];

      const getFileFromHandle = async (fileHandle: FileSystemFileHandle) => {
        const file = await fileHandle.getFile();

        return file;
      };

      // biome-ignore lint/suspicious/noExplicitAny: あとで修正
      const getItemFile = (item: any): Promise<File> => {
        // eslint-disable-next-line no-promise-executor-return, @typescript-eslint/no-unsafe-return
        return new Promise((resolve) => item.file(resolve));
      };

      if (Array.isArray(event) && event[0] instanceof FileSystemFileHandle) {
        const handles = await Promise.all(event.map(getFileFromHandle));
        handles.forEach((file) => {
          // biome-ignore lint/complexity/noVoid: あとで修正
          void validateImageFiles(
            file,
            newFiles,
            newDataSizeErrorFiles,
            newFormatErrorFiles,
          );
        });
      } else if (
        event instanceof InputEvent &&
        event.target instanceof HTMLInputElement
      ) {
        const fileList = event.target.files;
        if (fileList) {
          Array.from(fileList).forEach((file) => {
            // biome-ignore lint/complexity/noVoid: あとで修正
            void validateImageFiles(
              file,
              newFiles,
              newDataSizeErrorFiles,
              newFormatErrorFiles,
            );
          });
        }
      } else if (event.type === 'drop') {
        const dataTransferItemsList = (event as DragEvent).dataTransfer?.items;
        if (!dataTransferItemsList) {
          return newFiles;
        }
        const itemEntries = Array.from(dataTransferItemsList).map((item) =>
          item.webkitGetAsEntry ? item.webkitGetAsEntry() : item,
        );

        // biome-ignore lint/suspicious/noExplicitAny: あとで修正
        const traverseFileTree = async (item: any) => {
          if (item.isFile) {
            const file = await getItemFile(item);
            // biome-ignore lint/complexity/noVoid: あとで修正
            void validateImageFiles(
              file,
              newFiles,
              newDataSizeErrorFiles,
              newFormatErrorFiles,
            );
          } else if (item.isDirectory) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const dirReader = item.createReader();
            // biome-ignore lint/suspicious/noExplicitAny: あとで修正
            const entries = await new Promise<any[]>((resolve) =>
              // eslint-disable-next-line no-promise-executor-return, @typescript-eslint/no-unsafe-return
              dirReader.readEntries(resolve),
            );
            await Promise.all(entries.map((entry) => traverseFileTree(entry)));
          }
        };

        await Promise.all(itemEntries.map((entry) => traverseFileTree(entry)));
      }
      setFiles(newFiles); // 処理が完了したら、newFilesでfiles状態を更新
      if (newFormatErrorFiles.length > 0 || newDataSizeErrorFiles.length > 0) {
        console.log('images size');
        updateErrorAtGettingFiles(newFormatErrorFiles, newDataSizeErrorFiles);
      }
      console.log(newFormatErrorFiles);
      console.log(newDataSizeErrorFiles);

      return newFiles;
    },
    [updateErrorAtGettingFiles, validateImageFiles],
  );

  return {
    files,
    getFiles,
  };
};
