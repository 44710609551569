import {
  StyledFlexGap10,
  StyledFontBoldBlack06,
  StyledGrid,
} from '@/components/styled';
import { memo } from 'react';

export const InQueue = memo((): JSX.Element => {
  return (
    <>
      <StyledGrid>
        <StyledFlexGap10>
          <StyledFontBoldBlack06>
            <>生成待ち</>
          </StyledFontBoldBlack06>
        </StyledFlexGap10>
      </StyledGrid>
    </>
  );
});
