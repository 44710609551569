import { StyledFlexEnd } from '@/components/styled';
import { memo } from 'react';
import { PiCaretDownBold, PiCaretUpBold } from 'react-icons/pi';
import {
  StyledHistoryMotionTab,
  StyledHistoryMotionTabContainer,
  StyledHistoryTab,
  StyledHistoryTabText,
} from '../styled';

type Props = {
  isActive: boolean;
  onClick: () => void;
};

export const HistoryMotionToggle = memo(
  ({ isActive, onClick }: Props): JSX.Element => {
    return (
      <StyledHistoryMotionTabContainer>
        <StyledHistoryMotionTab
          key="tab"
          initial={{ bottom: 0 }}
          animate={{ bottom: isActive ? 0 : -88 }}
          transition={{ duration: 0.4, ease: [0.04, 0.1, 0.2, 0.1] }}
        >
          <StyledHistoryTab onClick={onClick}>
            <StyledHistoryTabText>History</StyledHistoryTabText>
            <StyledFlexEnd>
              {isActive && <PiCaretDownBold size={16} />}
              {!isActive && <PiCaretUpBold size={16} />}
            </StyledFlexEnd>
          </StyledHistoryTab>
        </StyledHistoryMotionTab>
      </StyledHistoryMotionTabContainer>
    );
  },
);
