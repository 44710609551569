import { Box, BoxProps } from '@/components/atoms/Box';
import { memo, ReactNode } from 'react';
import * as vanilla from './vanilla.css';

export const StyledImageBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styleImageBox} {...props}>
      {children}
    </Box>
  );
});

interface StyledFileNameProps {
  children: ReactNode;
}

export const StyledFileName = memo(({ children }: StyledFileNameProps) => {
  return (
    <span className={vanilla.stylefileName}>
      {children}
    </span>
  );
});
