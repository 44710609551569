import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

type Props = {
  date: string;
  setDate: Dispatch<SetStateAction<string>>;
  isError?: boolean;
  isCalendarEnable: boolean;
};

const daysOfWeek = ['日', '月', '火', '水', '木', '金', '土'];

const parseDate = (dateString: string) => {
  const [year, month, day] = dateString.split('-').map(Number);
  const date = new Date(Date.UTC(year, month - 1, day));

  return new Date(date.getTime() + 9 * 60 * 60 * 1000); // 9時間をミリ秒で加算
};

export const formatDate = (targetDate: Date) => {
  const d = new Date(targetDate);
  const year = d.getFullYear();
  const month = `0${d.getMonth() + 1}`.slice(-2);
  const day = `0${d.getDate()}`.slice(-2);

  return `${year}-${month}-${day}`;
};

export const useCalendar = ({
  date,
  setDate,
  isError,
  isCalendarEnable,
}: Props) => {
  const [parsedDate, setParsedDate] = useState<Date>(parseDate(date));
  const today = useMemo(() => {
    const tempToday = new Date();
    tempToday.setHours(0, 0, 0, 0);

    return tempToday;
  }, []);

  today.setHours(0, 0, 0, 0);
  const currentYear = parsedDate.getFullYear();
  const currentMonth = parsedDate.getMonth();
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
  const daysInMonth = lastDayOfMonth.getDate();
  const firstDayOfWeek = firstDayOfMonth.getDay();
  const [selectedDate, setSelectedDate] = useState<Date>(parseDate(date));

  const fillDays = useCallback(
    (start: number, end: number, month: number) => {
      return Array.from(
        { length: end - start + 1 },
        (_, i) => new Date(currentYear, month, start + i),
      );
    },
    [currentYear],
  );

  const calendarDays = useMemo(() => {
    const prevMonthLastDay = new Date(currentYear, currentMonth, 0).getDate();
    const previousMonthDays =
      firstDayOfWeek > 0
        ? fillDays(
            prevMonthLastDay - firstDayOfWeek + 1,
            prevMonthLastDay,
            currentMonth - 1,
          )
        : [];
    const currentMonthDays = fillDays(1, daysInMonth, currentMonth);
    const totalDays = previousMonthDays.length + currentMonthDays.length;
    const nextMonthDaysCount = totalDays % 7 === 0 ? 0 : 7 - (totalDays % 7);
    const nextMonthDays = fillDays(1, nextMonthDaysCount, currentMonth + 1);

    return [...previousMonthDays, ...currentMonthDays, ...nextMonthDays];
  }, [currentYear, currentMonth, firstDayOfWeek, fillDays, daysInMonth]);

  const updateDateWithinBounds = (newDate: Date) => {
    if (newDate > today) {
      setDate(formatDate(today));
    } else {
      setDate(formatDate(newDate));
    }
  };

  const handleGoToPreviousMonth = () => {
    if (!isCalendarEnable) return;
    const newDate = new Date(currentYear, currentMonth - 1, 1);
    updateDateWithinBounds(newDate);
  };

  const handleGoToNextMonth = () => {
    if (!isCalendarEnable) return;
    const newDate = new Date(currentYear, currentMonth + 1, 1);
    updateDateWithinBounds(newDate);
  };

  const handleGoToPreviousYear = () => {
    if (!isCalendarEnable) return;
    setDate(
      new Date(currentYear - 1, currentMonth, 2).toISOString().split('T')[0],
    );
  };

  const handleGoToNextYear = () => {
    if (!isCalendarEnable) return;
    const nextYear = new Date(currentYear + 1, currentMonth, 2);
    if (nextYear > today) return;
    setDate(nextYear.toISOString().split('T')[0]);
  };
  const isFutureDate = useCallback(
    (thisDate: Date) => {
      return thisDate > today;
    },
    [today],
  );

  const isToday = useCallback(
    (thisDate: Date) => {
      return thisDate !== null && thisDate.getTime() === today.getTime();
    },
    [today],
  );

  const isCurrentMonthDate = useCallback(
    (thisDate: Date) => {
      const year = thisDate.getFullYear();
      const month = thisDate.getMonth();
      const displayYear = parsedDate.getFullYear();
      const displayMonth = parsedDate.getMonth();

      return year === displayYear && month === displayMonth;
    },
    [parsedDate],
  );

  const handleDateClick = (clickedDate: Date) => {
    if (!isCalendarEnable) return;
    if (!isFutureDate(clickedDate)) {
      const dateInJapanTimezone = new Date(
        clickedDate.getTime() + 9 * 60 * 60 * 1000,
      );
      const isoDateString = dateInJapanTimezone.toISOString().split('T')[0];
      setDate(isoDateString);
      setSelectedDate(clickedDate);
    }
  };

  const isDateSelected = useCallback(
    (dateToCheck: Date) => {
      if (selectedDate) {
        const selectedDateString = formatDate(selectedDate);
        const dateToCheckString = formatDate(dateToCheck);

        return dateToCheckString === selectedDateString;
      }
      const initialSelectedDate = formatDate(parseDate(date));
      const dateToCheckString = formatDate(dateToCheck);

      return dateToCheckString === initialSelectedDate;
    },
    [selectedDate, date],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (isError) return;

    setParsedDate(parseDate(date));
  }, [date, isError, selectedDate]);

  useEffect(() => {
    if (isError) return;

    const checkParsedDate = parseDate(date);
    if (
      selectedDate &&
      checkParsedDate &&
      selectedDate.getFullYear() === checkParsedDate.getFullYear() &&
      selectedDate.getMonth() === checkParsedDate.getMonth() &&
      selectedDate.getDate() === checkParsedDate.getDate()
    ) {
      return;
    }

    setSelectedDate(checkParsedDate);
  }, [date, isError, selectedDate]);

  useEffect(() => {
    if (isError || !isCalendarEnable) return;

    const newParsedDate = parseDate(date);
    if (newParsedDate > today) {
      const todayStr = formatDate(today);
      setDate(todayStr);
    }
  }, [date, isError, isCalendarEnable, today, setDate]);

  return {
    today,
    daysOfWeek,
    calendarDays,
    currentYear,
    currentMonth,
    firstDayOfMonth,
    isToday,
    isDateSelected,
    isFutureDate,
    isCurrentMonthDate,
    handleDateClick,
    handleGoToPreviousMonth,
    handleGoToNextMonth,
    handleGoToPreviousYear,
    handleGoToNextYear,
  };
};
