import { Box, BoxProps } from '@/components/atoms/Box';
import { FormControl, FormControlProps } from '@mui/material';
import { forwardRef, memo } from 'react';
import * as vanilla from './navigator.css';

export const StyledScrollContainer = forwardRef<HTMLDivElement, BoxProps>(
  ({ children, ...props }, ref) => {
    return (
      <Box ref={ref} className={vanilla.styledScrollContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledNavigatorContainer = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledNavigatorContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledNavigatorColumns = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledNavigatorColumns} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledBoxMinWidth120 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxMinWidth120} {...props}>
      {children}
    </Box>
  );
});

export const StyledDisplayBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledDisplayBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledFormControl = memo(
  ({ children, ...props }: FormControlProps) => {
    return (
      <FormControl className={vanilla.styledFormControl} {...props}>
        {children}
      </FormControl>
    );
  },
);
