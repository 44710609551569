import { useBackendApi } from '@/api';
import { User } from '@/api/schemas';
import { captureException } from '@sentry/nextjs';
import { atom, useAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

const userAtom = atom<User | undefined>(undefined);

/**
 * JSDoc
 * @see 認証コンテキスト
 * @returns {Object}
 */
export const useAuthContext = () => {
  const [user, setUser] = useAtom(userAtom);
  const { getMe } = useBackendApi({});
  const router = useRouter();

  const updateUser = useCallback(async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const me = await getMe();
      setUser(me);
    } catch (error) {
      console.error(error);
      captureException(error);
      setUser(undefined);
      // biome-ignore lint/complexity/noVoid: あとで修正
      void router.push(`/api/auth/logout`)
    }
  }, [getMe, setUser, router]);

  // Auth0のセッションが有効になった場合、APIにuserを取得しに行く。
  // セッションが無効になったら削除する。
  useEffect(() => {
    if (user) return;
    // biome-ignore lint/complexity/noVoid: あとで修正
    void updateUser();
  }, [updateUser, user]);

  return {
    user,
    setUser,
  };
};
