import { Box, BoxProps } from '@/components/atoms/Box';
import Image, { ImageProps } from 'next/image';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledProgressContainer = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledProgressContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledCircleImage = memo(({ children, ...props }: ImageProps) => {
  return <Image className={vanilla.styledCircle} {...props} />;
});
