import { Box } from '@/components/atoms/Box';
import { StyledFlexGap10 } from '@/components/styled';
import { StyledTdWidth300 } from '@/features/Admin/uniques/UserList/Head/styled';
import { StyledTable } from '@/features/components/styled/table';
import { range } from '@/utils/range';
import {
  Divider,
  Table,
  TableBody,
  TableCell as Td,
  TableRow as Tr,
} from '@mui/material';
import { motion } from 'framer-motion';
import { memo } from 'react';
import {
  StyledCircle36x36,
  StyledSquare140x16,
  StyledSquare218x16,
  StyledSquare40x16,
  StyledSquare70x16,
  StyledUserListHeadBox,
} from '../styled';

export const UserList = memo(() => {
  return (
    <Box>
      <Divider />
      <Box>
        <StyledTable>
          <Table>
            <TableBody>
              <Tr>
                <Td>
                  <StyledUserListHeadBox>
                    <StyledFlexGap10>
                      <StyledSquare40x16 />
                      <StyledSquare140x16 />
                    </StyledFlexGap10>
                  </StyledUserListHeadBox>
                </Td>
              </Tr>
              <Tr>
                <StyledTdWidth300>
                  <StyledFlexGap10>
                    <StyledSquare140x16 />
                  </StyledFlexGap10>
                </StyledTdWidth300>
              </Tr>
            </TableBody>
          </Table>
        </StyledTable>
      </Box>
      {range(1, 10).map((i) => (
        <Box key={`skelton-${i}`.toString()}>
          <motion.div
            initial={{ opacity: 0.4 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 1,
              repeat: Infinity,
              repeatType: 'reverse',
              delay: 1,
            }}
          >
            <Box>
              <StyledTable>
                <Table>
                  <TableBody>
                    <Tr>
                      <StyledTdWidth300>
                        <StyledFlexGap10>
                          <StyledCircle36x36 />
                          <StyledSquare218x16 />
                        </StyledFlexGap10>
                      </StyledTdWidth300>
                      <Td>
                        <StyledFlexGap10>
                          <StyledSquare70x16 />
                        </StyledFlexGap10>
                      </Td>
                      <Td />
                      <Td />
                    </Tr>
                  </TableBody>
                </Table>
              </StyledTable>
            </Box>
          </motion.div>
        </Box>
      ))}
    </Box>
  );
});
