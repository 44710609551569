import { CircularProgress } from "@mui/material";
import { grey } from "@mui/material/colors";
import Image from "next/image";
import { useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { loaderWrapperStyle, wrapperStyle } from "./ImageWithLoader.css";

type Props = {
  loaderWidth: number;
  loaderHeight: number;
  loaderMode: 'beater' | 'spinner';
} & React.ComponentProps<typeof Image>;

export const ImageWithLoader = ({
  loaderWidth,
  loaderHeight,
  loaderMode,
  ...imageProps
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const Loader = () => {
    switch (loaderMode) {
      case 'spinner':
        return <CircularProgress sx={{ width: loaderWidth, height: loaderHeight }} />
      case 'beater':
        return <BeatLoader
          size={loaderWidth || loaderHeight}
          color={grey[400]}
          speedMultiplier={0.5}
        />
      default:
        throw new Error("ImageWithLoader's loaderMode is undefined");
    }
  }

  return (
    <div className={wrapperStyle}>
      { isLoading &&
      <div className={loaderWrapperStyle}>
        <Loader />
      </div>
      }
      <Image
        {...imageProps}
        onLoadingComplete={() => setIsLoading(false)}
      />
    </div>
  )
}
