import { Typography } from '@/components/atoms/Typography';
import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';

export type Props = {
  title: string;
  placement?: TooltipProps['placement'];
  children: React.ReactNode;
  delay?: number;
};

/**
 * JSDoc
 * @see ツールチップを表示するコンポーネント
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const Tooltip = ({
  title,
  placement = 'right',
  children,
  delay = 1000,
}: Props) => (
  <>
    <MuiTooltip
      title={<Typography fontSize={14}>{title}</Typography>}
      placement={placement}
      enterDelay={delay}
    >
      <span className="styledFont" style={{ fontWeight: 400 }}>
        {children}
      </span>
    </MuiTooltip>
  </>
);
