import { Button as MuiButton, styled } from '@mui/material';

export const StyledButton = styled(MuiButton, {
  shouldForwardProp: (prop) =>
    prop !== 'fontColor' &&
    prop !== 'fontSize' &&
    prop !== 'fontWeight' &&
    prop !== 'backgroundColor' &&
    prop !== 'boxShadow' &&
    prop !== 'hoverBackgroundColor' &&
    prop !== 'activeBackgroundColor' &&
    prop !== 'borderRadius',
})<{
  mode: string;
  width?: number | string;
  height?: number | string;
  fontSize?: number | string;
  fontWeight?: number;
  disabled?: boolean;
  fontColor: string;
  border: string;
  boxShadow: string;
  backgroundColor: string;
  hoverBackgroundColor: string;
  activeBackgroundColor: string;
  borderRadius?: number;
}>(
  ({
    theme,
    width,
    height = 40,
    fontSize,
    fontWeight = 500,
    fontColor,
    border,
    boxShadow,
    backgroundColor,
    hoverBackgroundColor,
    activeBackgroundColor,
    borderRadius = 5,
    disabled,
  }) => ({
    width,
    height,
    fontSize: fontSize || 'var(--font-size-lg)',
    fontWeight,
    color: fontColor,
    border,
    boxShadow,
    backgroundColor,
    borderRadius,
    textTransform: 'none',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: hoverBackgroundColor,
    },
    '&:active': {
      backgroundColor: activeBackgroundColor,
    },
    disableRipple: true,

    minWidth: 0,
    minHeight: 0,
    textWrap: 'nowrap',
    paddingLeft: '15px',
    paddingRight: '15px',
  }),
);
