import { FeatureBatch } from '@/contexts/FeaturesContext/types';
import { AccordionWrapper } from '@/features/components/molecules/AccordionWrapper';
import { useAccordionContext } from '@/features/components/molecules/AccordionWrapper/hooks/useAccordionContext';
import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { useBatchOptionalContext } from '@/features/components/steps/hooks/useBatchOptionalContext';
import hexToRgba from 'hex-to-rgba';
import { memo, useMemo } from 'react';
import { Bar } from './Bar';
import { Content } from './Content';

type Props = {
  index: number;
  set: FeatureBatch;
  targetNumber: number;
};

export const AccordionRow = memo(
  ({index, set, targetNumber }: Props): JSX.Element => {
    const { handleChangeCurrentSet, checkUploadImageCondition, currentNumber } =
      useBatchUpload();
    const { isCollectiveSetting } = useBatchOptionalContext();

    const { accordionStates, handleChangeAccordionState } = useAccordionContext(
      {
        currentNumber,
      },
    );

    const isOpenAccordion = useMemo(() => {
      if (!accordionStates[targetNumber]) return true;

      return accordionStates[targetNumber].isOpen;
    }, [accordionStates, targetNumber]);

    const backgroundColor = useMemo(() => {
      if (isCollectiveSetting) {
        return hexToRgba('#e3f2fd', 0.6);
      }

      return currentNumber === targetNumber
        ? hexToRgba('#e3f2fd', 0.6)
        : '#fff';
    }, [currentNumber, isCollectiveSetting, targetNumber]);

    return (
      <AccordionWrapper
        isOpenAccordion={isOpenAccordion}
        backgroundColor={backgroundColor}
      >
        <Bar
          index={index}
          set={set}
          handleChangeCurrentSet={handleChangeCurrentSet}
          checkUploadImageCondition={checkUploadImageCondition}
          isOpenAccordion={isOpenAccordion}
          handleChangeAccordionState={handleChangeAccordionState}
          currentNumber={currentNumber}
        />
        <Content targetId={set.id} targetNumber={targetNumber} />
      </AccordionWrapper>
    );
  },
);
