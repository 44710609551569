import { memo } from 'react';

type Props = {
  color?: string;
};
const GalleryIcon = memo(({ color = '#fff' }: Props): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
      <path
        fill={color}
        fillRule="evenodd"
        d="M5 4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5Zm14 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-8Zm-1 15a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1v-8ZM5 18a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1H5Z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default GalleryIcon;
