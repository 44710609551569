import {
  StyledBoxHeight10,
  StyledBoxHeight20,
  StyledGridGap10
} from '@/components/styled';
import {
  StyledFunctionMessageBox,
  StyledFunctionMessageEnd,
  StyledFunctionMessageStar,
  StyledFunctionPaddingBottom0,
} from '@/features/components/styled/function';
import { Dispatch, memo, SetStateAction } from 'react';
import { PointTypeRadio } from './PointTypeRadio';
// import { SamTypeSwitch } from './SamTypeSwitch';

type Props = {
  samType: 'manual' | 'auto';
  pointType: 'positive' | 'negative';
  setPointType: Dispatch<SetStateAction<'positive' | 'negative'>>
  handleToggleSwitch: () => void;
};
// セグメンテーションの機能を切り替えるコンポーネント
export const Functions = memo(
  ({
    samType,
    pointType,
    setPointType,
    handleToggleSwitch,
  }: Props): JSX.Element => {
    return (
      <>
        <StyledFunctionPaddingBottom0>
          <StyledGridGap10>
            {/* <SamTypeSwitch
              samType={samType}
              handleToggleSwitch={handleToggleSwitch}
            /> */}
            <PointTypeRadio
              samType={samType}
              pointType={pointType}
              setPointType={setPointType}
            />
            <StyledBoxHeight20 />
            <StyledFunctionMessageBox>
              <StyledFunctionMessageStar>※</StyledFunctionMessageStar>
              <StyledFunctionMessageEnd>
                選択範囲のプレビューで赤く塗られた領域の色を参照します。
              </StyledFunctionMessageEnd>
            </StyledFunctionMessageBox>
            <StyledBoxHeight10 />
          </StyledGridGap10>
        </StyledFunctionPaddingBottom0>
      </>
    );
  },
);
