import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './batch.css';

export const StyledTableRowContainer = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styleTableRowContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledTableRowName = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styleTableRowName} {...props}>
      {children}
    </Box>
  );
});

export const StyledTableRowNameFlex = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styleTableRowNameFlex} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledTableRowNameEllipsis = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styleTableRowNameEllipsis} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledDividerBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledDividerBox} {...props}>
      {children}
    </Box>
  );
});
