import { UPLOAD_IMAGE_LIMIT_BYTES } from '@/constants';

export const useValidateUploadImage = () => {
  const validateImageFiles = (
    file: File,
    newArray: File[],
    exceedArray: File[],
    errorArray: File[],
  ) => {
    if (file.name.match(/\.(jpg|jpeg|png|webp)$/i)) {
      if (file.size <= UPLOAD_IMAGE_LIMIT_BYTES) {
        newArray.push(file);
      } else {
        exceedArray.push(file);
      }
    } else {
      errorArray.push(file);
    }
  };

  return {
    validateImageFiles,
  };
};
