import { Dialog } from '@/components/molecules/Dialog';
import { StyledBoxPaddingTop10 } from '@/components/styled';
import { memo } from 'react';
import { Segmentation } from './Segmentation';
import { useStepDetector } from './hooks/useStepDetector';
import { StyledDialogContainer } from './styled';

type Props = {
  isOpenDialog: boolean;
  onClose: () => void;
  onSubmit: () => void;
  dialogTitle: string;
  dialogExplain: string;
};

/**
 * JSDoc
 * @see 参照画像アップロードダイアログ
 * @see オプションでセグメンテーションを行う
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const KeepMaskDialog = memo(
  ({
    isOpenDialog,
    onClose,
    onSubmit,
    dialogTitle,
    dialogExplain,
  }: Props): JSX.Element => {
    const field = 'mainImage';
    const { modalStep } = useStepDetector();

    return (
      <Dialog
        maxWidth="xl"
        isOpenDialog={isOpenDialog}
        onClose={onClose}
        onSubmit={onSubmit}
        dialogTitle={dialogTitle}
        hasCloseIcon
      >
        <StyledBoxPaddingTop10>{dialogExplain}</StyledBoxPaddingTop10>

        <StyledDialogContainer>
          {modalStep === 'segmentation' && (
            <>
              <Segmentation field={field} onSubmit={onSubmit} />
            </>
          )}
        </StyledDialogContainer>
      </Dialog>
    );
  },
);
