import { useBackendApi } from '@/api';
import { Task } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import { StyledFlexNowrapGap10 } from '@/components/styled';
import { IconButton } from '@mui/material';
import { Dispatch, memo, SetStateAction } from 'react';
import { MdCreate, MdDownload } from 'react-icons/md';

type Props = {
  data: Task;
  handleSubmitTargetDownloadStep: (id: string) => void;
  handleOpenDownloadDialog: (id: string, taskType: string) => void;
  modeEn: string;
  columnIndex: number;
  setSelectedColumnIndex: Dispatch<SetStateAction<number | undefined>>;
};

export const DownloadButton = memo(
  ({
    data,
    handleSubmitTargetDownloadStep,
    handleOpenDownloadDialog,
    modeEn,
    columnIndex,
    setSelectedColumnIndex,
  }: Props): JSX.Element => {
    const { getTask } = useBackendApi({});

    return (
      <>
        {data.result.status === 'COMPLETED' && (
          <StyledFlexNowrapGap10>
            {modeEn === 'colorTemperature' ||
            modeEn === 'backgroundLora' ||
            modeEn === 'multiWhite' ? (
              <>
                <Box style={{ width: 38 }} />
                <IconButton
                  style={{ color: 'var(--color-primary-main)' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSelectedColumnIndex(columnIndex);
                    handleOpenDownloadDialog(data.id, data.taskType);
                  }}
                >
                  <MdDownload />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  sx={{ color: 'var(--color-primary-main)' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleSubmitTargetDownloadStep(data.id);
                  }}
                >
                  <MdCreate size={22} />
                </IconButton>
                <IconButton
                  sx={{ color: 'var(--color-primary-main)' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSelectedColumnIndex(columnIndex);
                    handleOpenDownloadDialog(data.id, data.taskType);
                  }}
                >
                  <MdDownload />
                </IconButton>
              </>
            )}
          </StyledFlexNowrapGap10>
        )}
      </>
    );
  },
);
