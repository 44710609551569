import * as Sentry from '@sentry/nextjs';
import { AxiosError } from 'axios';
import { atom, useAtom } from 'jotai';

const axiosErrorAtom = atom<AxiosError | null>(null);
const generatingStatusAtom = atom<string | null>(null);
const isErrorAtom = atom<boolean>(false);

type Props = {
  error: unknown;
  status?: string | null;
  setIsLoading?: (isLoading: boolean) => void;
  setIsGenerated?: (isGenerated: boolean) => void;
};

/**
 * JSDoc
 * @see エラーを管理するカスタムフック
 * @see Sentryにエラーを送信する
 * @returns {Object}
 */
export const useError = () => {
  const [axiosError, setAxiosError] = useAtom(axiosErrorAtom);
  const [generatingStatus, setGeneratingStatus] = useAtom(generatingStatusAtom);

  const [isError, setIsError] = useAtom(isErrorAtom);

  const logger = ({ error, setIsLoading, setIsGenerated }: Props) => {
    console.log(error);

    const thisError = error as AxiosError;
    setAxiosError(thisError);
    Sentry.captureException(error);

    if (setIsLoading) {
      setIsLoading(false);
    }
    if (setIsGenerated) {
      setIsGenerated(false);
    }
    setIsError(true);
  };

  const loggerFromGeneratingStatus = (status: string) => {
    console.log(status);
    if (status) setGeneratingStatus(status);
    Sentry.captureException(status);
    setIsError(true);
  };

  return {
    axiosError,
    logger,
    isError,
    setIsError,
    generatingStatus,
    loggerFromGeneratingStatus,
  };
};
