import { useZodGet } from "@/api";
import { PresetsSchema } from "@/api/schemas";
import { useMemo } from "react";

export const usePresetIdSrcMap = () => {
  const {
    data: presetData,
  } = useZodGet('gen/presets', PresetsSchema, {});

  const presetIdSrcMap = useMemo(() => {
    if (!presetData) return

    const mapObject: Record<string, string> = {}
    for (let i = 0; i < presetData?.data.length; i++) {
      for (let j = 0; j < presetData?.data[i].examples.length; j++) {
        mapObject[presetData?.data[i].examples[j].presetId] = presetData?.data[i].examples[j].url;
      }
    }

    return mapObject
  }, [
    presetData,
  ])

  return {
    presetIdSrcMap,
  }
}
