import houndstooth from '@/assets/any/houndstooth.png';
import { StyledGridEnd } from '@/components/styled';
import { FEATURE_EN_NAMES_FROM_BACKEND } from '@/constants';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageResult } from '@/contexts/FeaturesContext/types';
import { GlobalDownloader } from '@/features/components/organisms/GlobalDownloader';
import { StyledFunctionBox } from '@/features/components/styled/function';
import { FeaturesStepLayout } from '@/features/components/templates/Layout/FeaturesStepLayout';
import { useCheckArray } from '@/hooks/global/useCheckArray';
import { memo, useEffect } from 'react';
import { useDownload } from '../../../../hooks/global/useDownload';
import { Buttons } from './Buttons';
import { Functions } from './Functions';
import { useUpdateDataApiFromTask } from './hooks/useUpdateDataApiFromTask';
import { useUpdateDataStatus } from './hooks/useUpdateDataStatus';

type Props = {
  taskId?: string;
};

export const Download = memo(({ taskId }: Props): JSX.Element => {
  const { featureData, activeFeatureName } = useFeaturesContext({});

  const { taskApiResponse } = useUpdateDataApiFromTask({
    taskId:
      taskId ||
      (featureData?.single?.genResult.taskId
        ? featureData?.single?.genResult.taskId
        : ''),
    isDialog: false,
  });

  const { downloadingName, handleDownloadWithApi, files, setFiles } =
    useDownload({ taskApiResponse });

  useUpdateDataStatus({ files });

  const repeatBackgroundImage = () => {
    if (activeFeatureName === 'background') return '';
    if (!featureData?.single?.param?.isNoBackground) return '';

    return houndstooth.src;
  };

  const targetFeatureName = () => {
    return FEATURE_EN_NAMES_FROM_BACKEND[
      activeFeatureName as keyof typeof FEATURE_EN_NAMES_FROM_BACKEND
    ];
  };

  const { setDownloadedArray } = useCheckArray();
  useEffect(() => {
    if (!taskApiResponse?.result.resultImages) return;
    setDownloadedArray(
      taskApiResponse?.result.resultImages?.map(
        (imageResult: ImageResult) => !!imageResult.downloadedAt,
      ) ?? [],
    );
  }, [taskApiResponse?.result.resultImages, setDownloadedArray]);

  console.log('download');

  return (
    <>
      <FeaturesStepLayout>
        <GlobalDownloader
          isGenerated={featureData?.single?.genStatus?.isGenerated || false}
          imageResults={
            featureData?.single?.genResult?.generatedImageResults || []
          }
          setFiles={setFiles}
          repeatBackgroundImage={repeatBackgroundImage()}
        />
        <StyledFunctionBox>
          <Functions
            targetFeatureName={targetFeatureName()}
            downloadingName={downloadingName}
            handleDownloadWithApi={handleDownloadWithApi}
            isNoBackground={featureData?.single?.param?.isNoBackground || false}
          />
          <StyledGridEnd>
            <Buttons />
          </StyledGridEnd>
        </StyledFunctionBox>
      </FeaturesStepLayout>
    </>
  );
});
