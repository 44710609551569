import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { memo } from 'react';

import { StyledExplainNumber, StyledExplainText } from './styled';

/**
 * @see ステップの説明を表示するコンポーネント
 */
export const StepExplain = memo((): JSX.Element => {
  const { findActiveStep } = useFeaturesContext({});

  return (
    <>
      <StyledExplainNumber>{findActiveStep()?.num}</StyledExplainNumber>
      <StyledExplainText>{findActiveStep()?.explain}</StyledExplainText>
    </>
  );
});
