import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

type skeltonProps = {
  width: number;
  height: number;
} & BoxProps;

export const StyledSquare = memo(
  ({ width, height, children, ...props }: skeltonProps) => {
    const skeltonStyle = {
      width,
      height,
    };

    return (
      <Box className={vanilla.styledSquare} sx={skeltonStyle} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledCircle = memo(
  ({ width, height, children, ...props }: skeltonProps) => {
    const skeltonStyle = {
      width,
      height,
    };

    return (
      <Box className={vanilla.styledCircle} sx={skeltonStyle} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledTaskListSpacer = memo(({ children }: BoxProps) => {
  return <Box className={vanilla.styledTaskListSpacer}>{children}</Box>;
});

export const StyledUserListHeadBox = memo(({ children }: BoxProps) => {
  return <Box className={vanilla.styledUserListHeadBox}>{children}</Box>;
});

export const StyledDividerBox = memo(({ children }: BoxProps) => {
  return <Box className={vanilla.styledDividerBox}>{children}</Box>;
});

export const StyledGridGap15Pl20 = memo(({ children }: BoxProps) => {
  return <Box className={vanilla.styledGridGap15Pl20}>{children}</Box>;
});

export const StyledFlexWidth176Gap8 = memo(({ children }: BoxProps) => {
  return <Box className={vanilla.styledFlexWidth176Gap8}>{children}</Box>;
});

export const StyledSquare16x16 = memo(() => {
  return <Box className={vanilla.styledSquare16x16} />;
});

export const StyledSquare24x16 = memo(() => {
  return <Box className={vanilla.styledSquare24x16} />;
});

export const StyledSquare24x24 = memo(() => {
  return <Box className={vanilla.styledSquare24x24} />;
});

export const StyledSquare34x34 = memo(() => {
  return <Box className={vanilla.styledSquare34x34} />;
});

export const StyledSquare40x16 = memo(() => {
  return <Box className={vanilla.styledSquare40x16} />;
});

export const StyledSquare56x56 = memo(() => {
  return <Box className={vanilla.styledSquare56x56} />;
});

export const StyledSquare56x16 = memo(() => {
  return <Box className={vanilla.styledSquare56x16} />;
});

export const StyledSquare60x20 = memo(() => {
  return <Box className={vanilla.styledSquare60x20} />;
});

export const StyledSquare70x16 = memo(() => {
  return <Box className={vanilla.styledSquare70x16} />;
});

export const StyledSquare76x16 = memo(() => {
  return <Box className={vanilla.styledSquare56x16} />;
});

export const StyledSquare100x16 = memo(() => {
  return <Box className={vanilla.styledSquare100x16} />;
});

export const StyledSquare120x14 = memo(() => {
  return <Box className={vanilla.styledSquare120x14} />;
});

export const StyledSquare126x16 = memo(() => {
  return <Box className={vanilla.styledSquare126x16} />;
});

export const StyledSquare140x16 = memo(() => {
  return <Box className={vanilla.styledSquare140x16} />;
});

export const StyledSquare160x14 = memo(() => {
  return <Box className={vanilla.styledSquare160x14} />;
});

export const StyledSquare160x20 = memo(() => {
  return <Box className={vanilla.styledSquare160x20} />;
});

export const StyledSquare180x16 = memo(() => {
  return <Box className={vanilla.styledSquare180x16} />;
});

export const StyledSquare218x16 = memo(() => {
  return <Box className={vanilla.styledSquare218x16} />;
});

export const StyledSquare80x16 = memo(() => {
  return <Box className={vanilla.styledSquare80x16} />;
});

export const StyledCircle36x36 = memo(() => {
  return <Box className={vanilla.styledCircle36x36} />;
});

export const StyledCircle56x56 = memo(() => {
  return <Box className={vanilla.styledCircle56x56} />;
});
