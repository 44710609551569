import { Box, BoxProps } from '@/components/atoms/Box';
import { TableCell, TableCellProps } from '@mui/material';
import React, { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledTableContainer = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledTableContainer} {...props}>
      {children}
    </Box>
  );
});

export const StyledTable = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledTable} {...props}>
      {children}
    </Box>
  );
});

export const StyledDateTitleBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledDateTitleBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledDateTitle = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledDateTitle} {...props}>
      {children}
    </Box>
  );
});

export const StyledPreviousMonth = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledPreviousMonth} {...props}>
      {children}
    </Box>
  );
});

type NextMonthProps = {
  isFuture: boolean;
  children?: React.ReactNode;
} & BoxProps;
export const StyledNextMonth = memo(
  ({ isFuture, children, ...props }: NextMonthProps) => {
    return (
      <Box
        className={
          isFuture
            ? vanilla.styledNextMonthIsFuture
            : vanilla.styledNextMonthIsNotFuture
        }
        {...props}
      >
        {children}
      </Box>
    );
  },
);

export const StyledDayCell = memo(({ children, ...props }: TableCellProps) => {
  return (
    <TableCell className={vanilla.styledDayCell} {...props}>
      {children}
    </TableCell>
  );
});

type DateCellProps = {
  date: Date;
  isToday: (date: Date) => boolean;
  isDateSelected: (date: Date) => boolean | null;
  isFutureDate: (date: Date) => boolean;
  isCurrentMonthDate: (date: Date) => boolean;
  children?: React.ReactNode;
} & TableCellProps;

export const StyledDateCell = ({
  date,
  isToday,
  isDateSelected,
  isFutureDate,
  isCurrentMonthDate,
  children,
  ...props
}: DateCellProps) => {
  const cellStyle = {
    backgroundColor:
      !isDateSelected(date) && isToday(date)
        ? 'var(--color-gray-light)'
        : isDateSelected(date)
        ? 'var(--color-primary-main)'
        : '',
    color: isDateSelected(date)
      ? 'var(--color-gray-white)'
      : isCurrentMonthDate(date) || isToday(date)
      ? 'var(--color-gray-black)'
      : 'var(--color-gray-soft)',
    opacity: isFutureDate(date) ? 0.5 : 1,
    cursor: isFutureDate(date) ? '' : 'pointer',
  };

  return (
    <TableCell className={vanilla.styledDateCell} sx={cellStyle} {...props}>
      {children}
    </TableCell>
  );
};
