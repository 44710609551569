import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fmolecules%2FCalendar%2Fstyled%2Fvanilla.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA52T0U7CMBSG73mKc2MiiSUbiMr2Bl4YEx%2FAHNZua%2Bjapu0caHh318KAOUHixbKd9j%2F9v3NON3mPi4Wb6WkEXyMAjZRyWSQQR3qdjrajSbcfg8OlYEG1VIYyQzIlBGrLEui%2BUr%2BJ2aowqpbUC5RJ4APNLdkFpDC4IU3JHRunh5MSkEqG5FxJR3KsuNgk8KKcgjeUFp5ff7LQOzgNy8DVcOrKBGZTzw5QMl6U7hg7tnYEBS9kS8ykYyYdlgwguGSky41PKaMexfTUM46iGy%2Bl3GqBLXxhOPUL%2Fk0cq9pVx3wT6kraVp%2Bb7vGqQEXarlT2yHbiNQtevxcQemb5J%2Bs6fVggFQ1dPjcHimY1PhzR7EteKhHIw5SI1ZgxP6DGoO4x3Q%2BYBMvdVX5ZbaxXaMUHpc4HxxrP1dM8BM05G6ty17Pp5T5ezL0K8el%2F07DVxWlUyOW457O44LP%2FBw1SXrd3Zr67fn%2B5b78Bh5vtKPADAAA%3D%22%7D"
export var styledDateCell = '_1g9t3p29';
export var styledDateTitle = '_1g9t3p23';
export var styledDateTitleBox = '_1g9t3p22';
export var styledDayCell = '_1g9t3p28';
export var styledNextMonth = '_1g9t3p25';
export var styledNextMonthIsFuture = '_1g9t3p26 _1g9t3p25';
export var styledNextMonthIsNotFuture = '_1g9t3p27 _1g9t3p25';
export var styledPreviousMonth = '_1g9t3p24';
export var styledTable = '_1g9t3p21';
export var styledTableContainer = '_1g9t3p20';