import { useCheckArray } from '@/hooks/global/useCheckArray';
import { usePopper } from '@/hooks/local/usePopper';
import { useEffect } from 'react';

// type Props = {
//   setTaskApiResponse: (taskApiResponse: Task | undefined) => void;
//   handleCloseDownloadDialog: () => void;
// };

export const useHandlers = () => {
  const { checkedCount } = useCheckArray();

  const { isOpenPopper, handleOpenPopper, handleClosePopper, anchorElement } =
    usePopper();

  /* チェックがなくなったらフォーマットのポップアップを閉じる */
  useEffect(() => {
    if (!checkedCount) {
      handleClosePopper();
    }
  }, [checkedCount, handleClosePopper]);

  // TODO: これを使っていたことで意図しない箇所（BatchDownload）でtaskApiResponseがundefinedになってしまうバグが発生していたためコメントアウト。別のタイミングで整理する
  // const handleCloseDialogWithPopper = useCallback(() => {
  //   setTaskApiResponse(undefined);
  //   handleCloseDownloadDialog();
  //   handleClosePopper();
  // }, [handleCloseDownloadDialog, handleClosePopper, setTaskApiResponse]);

  return {
    // handleCloseDialogWithPopper,
    handleOpenPopper,
    handleClosePopper,
    isOpenPopper,
    anchorElement,
    checkedCount,
  };
};
