import { Tasks } from '@/api/schemas';
import { TASK_RELOAD_INTERVAL } from '@/constants';
import { useEffect, useState } from 'react';

type Props = {
  apiResponse?: Tasks;
};

export const useTasksReloadController = ({ apiResponse }: Props) => {
  const [reloadInterval, setReloadInterval] = useState(30000);

  const handleSetReloadInterval = (interval: number) => {
    setReloadInterval(interval);
  };

  useEffect(() => {
    if (!apiResponse) return;

    const hasInProgressTask = apiResponse.data.some(
      (task) =>
        task.result.status === 'IN_QUEUE' ||
        task.result.status === 'IN_PROGRESS',
    );
    setReloadInterval(
      hasInProgressTask
        ? TASK_RELOAD_INTERVAL.inQueueAndInProgress
        : TASK_RELOAD_INTERVAL.default,
    );
  }, [apiResponse]);

  return {
    reloadInterval,
    handleSetReloadInterval,
  };
};
