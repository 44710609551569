import { Box } from '@/components/atoms/Box';
import { ImageWithLoader } from '@/components/molecules/ImageWithLoader/ImageWithLoader';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { memo } from 'react';
import { StyledSmallCircleContainer } from '../../styled';

export const KeepMaskCircle = memo((): JSX.Element => {
  const { featureData, activeFeatureName } = useFeaturesContext({});

  return (
    <>
      {featureData?.single?.param?.keepMaskImage && (
        <StyledSmallCircleContainer>
          <Box
            sx={{
              width: '33px',
              height: '33px',
              borderRadius: '50%',
              outline: '2px solid #fff',
            }}
          >
            <ImageWithLoader
              src={featureData?.single?.param?.keepMaskImage}
              fill
              alt=""
              quality={90}
              style={{ objectFit: 'cover', borderRadius: '50%' }}
              loaderHeight={8}
              loaderWidth={8}
              loaderMode="beater"
            />
          </Box>
        </StyledSmallCircleContainer>
      )}
    </>
  );
});
