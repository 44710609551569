import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

type radioBoxProps = {
  width?: string | number;
  isEnable?: boolean;
  isActive?: boolean;
} & BoxProps;

export const StyledRadioBox = memo(
  ({
    width = '100%',
    isEnable = true,
    isActive,
    children,
    ...props
  }: radioBoxProps) => {
    const radioStyle = {
      width,
      backgroundColor: isActive
        ? 'var(--color-primary-pale)'
        : 'var(--color-gray-pale)',
      border: isActive
        ? `1px solid ${'var(--color-primary-main)'}`
        : `1px solid ${'var(--color-gray-white)'}`,
      opacity: isEnable ? 1 : 0.3,
      '&:hover': {
        cursor: isEnable ? 'pointer' : 'default',
        backgroundColor: isEnable ? 'var(--color-gray-light)' : '',
      },
    };

    return (
      <Box className={vanilla.styledRadioBox} sx={radioStyle} {...props}>
        {children}
      </Box>
    );
  },
);

type radioBoxFlexEndProps = {
  isActive: boolean;
} & BoxProps;

export const StyledRadioFlexEnd = memo(
  ({ isActive, children, ...props }: radioBoxFlexEndProps) => {
    const radioStyle = {
      color: isActive ? 'var(--color-primary-deep)' : 'var(--color-gray-main)',
    };

    return (
      <Box className={vanilla.styledRadioFlexEnd} sx={radioStyle} {...props}>
        {children}
      </Box>
    );
  },
);
