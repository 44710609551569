import { TaskOriginalImage } from "@/api/schemas";
import { atom, useAtom } from "jotai";
import { useCallback } from "react";

type MaskImage = {
  id: string;
  maskId: string;
  maskImageBase64: string;
  maskOverlayImageBase64: string;
} | undefined

type MaskImagesMap = Record<number, MaskImage> | undefined

const selectedIndexAtom = atom<number | undefined>(undefined)
const tmpMaskImageAtom = atom<MaskImage>(undefined)
const maskImagesMapAtom = atom<MaskImagesMap>(undefined)
const displayNameAtom = atom<string | undefined>(undefined)
const originalImagesAtom = atom<TaskOriginalImage[]>([])

export const useReEditAtom = () => {
  const [selectedIndex, setSelectedIndex] = useAtom(selectedIndexAtom)
  const [tmpMaskImage, setTmpMaskImage] = useAtom(tmpMaskImageAtom)
  const [maskImagesMap, setMaskImagesMap] = useAtom(maskImagesMapAtom)
  const [tmpDisplayName, setTmpDisplayName] = useAtom(displayNameAtom)

  // ページコンポーネントマウント時のみ変更するatom
  const [targetOriginalImages, setTargetOriginalImages] = useAtom(originalImagesAtom)

  // TODO: このような書き方だとtaskDataがなぜかundefinedになってsetしたあとに再setしてくれない。setTargetOriginalImagesを外に出さないようにする書き方を調査する
  // const {targetOriginalImages: originalImages} = useTargetOriginalImages();
  // useEffect(() => {
  //   setTargetOriginalImages(hoge)
  // }, [hoge, setTargetOriginalImages])

  const initializeReEditAtoms = useCallback(() => {
    setSelectedIndex(undefined)
    setTmpMaskImage(undefined)
    setMaskImagesMap(undefined)
    setTmpDisplayName(undefined)
    setTargetOriginalImages([])
  }, [
    setSelectedIndex,
    setTmpMaskImage,
    setMaskImagesMap,
    setTmpDisplayName,
    setTargetOriginalImages,
  ])

  return {
    initializeReEditAtoms,
    selectedIndex,
    setSelectedIndex,
    tmpMaskImage,
    setTmpMaskImage,
    maskImagesMap,
    setMaskImagesMap,
    tmpDisplayName,
    setTmpDisplayName,
    targetOriginalImages,
    setTargetOriginalImages, // TODO: 外部で制御しない値なので外に出さない
  }
}

