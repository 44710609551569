import { TasksParam } from '@/api/params';
import { SelectChangeEvent } from '@mui/material';
import { useCallback, useState } from 'react';

type Props = {
  updateTasksParamObject: (object: Partial<TasksParam>) => void;
  addUserId: () => void;
  removeUserId: () => void;
};

/**
 * JSDoc
 * @see Tasksのハンドラー関数をまとめたカスタムフック
 * @param {Props} props - updateTasksParamObject, addUserId, removeUserId
 * @returns {Object} isToggledUserId, handelChangeLimit, handleToggleUserId
 */
export const useTasksHandlers = ({
  updateTasksParamObject,
  addUserId,
  removeUserId,
}: Props) => {
  const [isToggledUserId, setIsToggledUserId] = useState(false);

  const handleToggleUserId = useCallback(() => {
    if (!isToggledUserId) {
      addUserId();
      setIsToggledUserId(true);
    } else {
      removeUserId();
      setIsToggledUserId(false);
    }
  }, [addUserId, isToggledUserId, removeUserId]);

  const handelChangeLimit = useCallback(
    (event: SelectChangeEvent<number>) => {
      updateTasksParamObject({
        offset: 0,
        limit: Number(event.target.value),
      });
    },
    [updateTasksParamObject],
  );

  return {
    isToggledUserId,
    handelChangeLimit,
    handleToggleUserId,
  };
};
