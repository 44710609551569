import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import {
  StyledBoxHeight24,
  StyledBoxHeight5,
  StyledFlexEndGap5,
  StyledFont16Bold,
  StyledGridCenter,
  StyledGridGap20,
} from '@/components/styled';
import { memo } from 'react';

type Props = {
  handleCloseDialog: () => void;
  handleSubmit: () => void;
};

export const EraseData = memo(
  ({ handleCloseDialog, handleSubmit }: Props): JSX.Element => {
    return (
      <>
        <StyledGridCenter style={{ justifyContent: 'center' }}>
          <Box
            style={{
              display: 'grid',
              justifyContent: 'center',
              textAlign: 'center',
              width: '352px',
              height: '212px',
              alignItems: 'center',
            }}
          >
            <Box style={{ display: 'grid', gap: '20px' }}>
              <StyledFont16Bold>
                編集中のデータを消去しても宜しいですか？
              </StyledFont16Bold>
              <Box>
                <Box>
                  作業中に他のページに移動すると
                  <br />
                  アップロードデータが削除されます。
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              display: 'grid',
              justifyContent: 'center',
              textAlign: 'center',
              height: '60px',
            }}
          >
            <StyledGridGap20>
              <StyledBoxHeight5 />
              <StyledFlexEndGap5>
                <Button mode="alertBorder" onClick={handleCloseDialog}>
                  キャンセル
                </Button>
                <Button
                  mode="alert"
                  onClick={handleSubmit}
                  style={{ width: '69px' }}
                >
                  OK
                </Button>
              </StyledFlexEndGap5>
            </StyledGridGap20>
          </Box>
        </StyledGridCenter>
        <StyledBoxHeight24 />
      </>
    );
  },
);
