import {
  SegmentationNegativePin,
  SegmentationPositivePin,
} from '@/components/icons';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

type PinProps = {
  left: number;
  top: number;
  onClick: () => void;
};

export const StyledPositivePointPin = memo(
  ({ left, top, ...props }: PinProps) => {
    const style = {
      left: left - 16,
      top: top - 32,
    };

    return (
      <SegmentationPositivePin
        className={vanilla.styledPin}
        style={style}
        {...props}
      />
    );
  },
);

export const StyledNegativePointPin = memo(
  ({ left, top, ...props }: PinProps) => {
    const style = {
      left: left - 16,
      top: top - 32,
    };

    return (
      <SegmentationNegativePin
        className={vanilla.styledPin}
        style={style}
        {...props}
      />
    );
  },
);
