import { useDialog } from '@/hooks/global/useDialog';
import { useEffect } from 'react';

type Props = {
  ref: React.RefObject<HTMLElement>;
  onClickAway: () => void;
};

export const useClickAway = ({ ref, onClickAway }: Props) => {
  // TODO: 現状LocalのuseDialogを使っている箇所の状態を監視できないため、管理方法をNoyamaさんに相談
  const { isOpenDialog } = useDialog();
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpenDialog) return;
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickAway();
      }
    };

    // マウスダウンイベントを監視
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // コンポーネントのアンマウント時にイベントリスナーを削除
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickAway, isOpenDialog]);
};
