import { useFeaturesContext } from '@/contexts/FeaturesContext';

export const useHandlers = () => {
  const {
    updateFeatureData,
    updateFeatureDataSingle,
    initialFeatureDataImageState,
    initialFeatureDataGenStatusState,
    initialFeatureDataGenResultState,
  } = useFeaturesContext({});

  const handleResetImageFromModal = () => {
    updateFeatureData('refImage', initialFeatureDataImageState);
    updateFeatureDataSingle('genStatus', initialFeatureDataGenStatusState);
    updateFeatureDataSingle('genResult', initialFeatureDataGenResultState);
    updateFeatureDataSingle('param', {
      pickRef: '',
      newRef: '',
      currentRef: '',
    });
  };

  return { handleResetImageFromModal };
};
