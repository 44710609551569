import { Box, BoxProps } from '@/components/atoms/Box';
import hexToRgba from 'hex-to-rgba';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

type FileTextProps = {
  status: string;
} & BoxProps;

export const StyledFileTextEllipsis = memo(
  ({ status, children, ...props }: FileTextProps) => {
    const fileTextStyle = {
      color:
        status === 'IN_QUEUE' || status === 'FAILED'
          ? hexToRgba('#000', 0.6)
          : 'var(--color-gray-dark)',
    };

    return (
      <Box
        className={vanilla.styledFileTextEllipsis}
        sx={fileTextStyle}
        {...props}
      >
        {children}
      </Box>
    );
  },
);

type FileNameContainerProps = {
  isTray?: boolean;
} & BoxProps;

export const StyledFileNameContainer = memo(
  ({ isTray, children, ...props }: FileNameContainerProps) => {
    const style = {
      height: isTray ? '28px' : '44px',
    };

    return (
      <Box className={vanilla.styledFileNameContainer} sx={style} {...props}>
        {children}
      </Box>
    );
  },
);
