import { useZodGet } from "@/api";
import { TaskSchema } from "@/api/schemas";
import { useParamsContext } from "@/contexts/ParamsContext";

export const useTaskData = () => {
  const { paramsContext } = useParamsContext()
  const {
    data: taskData,
    isLoading,
  } = useZodGet(`gen/tasks/${paramsContext?.id}`, TaskSchema, {});

  return { taskData, isLoading }
}
