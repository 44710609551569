import { Box } from '@/components/atoms/Box';
import { useButtonStyle } from '@/components/atoms/Button/hooks/useButtonStyle';
import { StyledButton } from '@/components/atoms/Button/styled';
import { ButtonModeProps } from '@/components/atoms/Button/types';
import { StyledFlexGap10 } from '@/components/styled';
import { ButtonProps, CircularProgress, SxProps } from '@mui/material';
import React, { memo } from 'react';

declare module '@mui/material/Button' {
  interface ButtonPropsOverrides {
    mode: true;
  }
}

export type Props = {
  mode?: ButtonModeProps;
  hasShadow?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  width?: number;
  height?: number;
  fontSize?: number;
  fontWeight?: number;
  type?: ButtonProps['type'];
  disabled?: boolean;
  onClick?: ButtonProps['onClick'];
  sx?: SxProps;
  ref?: React.Ref<HTMLButtonElement>;
} & ButtonProps;

/**
 * JSDoc
 * @see Sugekaeのボタンコンポーネント
 * @see アプリで使用するボタンスタイルを全て定義している
 * @see 冗長になるスタイリングにはstyledとカスタムフックを使用している
 * @see https://mui.com/components/buttons/
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const Button = memo(
  ({
    children,
    mode = 'primary',
    hasShadow = true,
    isLoading = false,
    width,
    height,
    fontSize,
    fontWeight,
    disabled,
    onClick,
    sx = {},
    ref,
    ...props
  }: Props) => {
    const {
      getBackgroundColor,
      getFontColor,
      getBorder,
      getBoxShadow,
      getHoverBackgroundColor,
      getActiveBackgroundColor,
    } = useButtonStyle();
    const backgroundColor = getBackgroundColor(mode, disabled);
    const fontColor = getFontColor(mode, disabled);
    const border = getBorder(mode, disabled);
    const boxShadow = getBoxShadow(mode, disabled, hasShadow);
    const hoverBackgroundColor = getHoverBackgroundColor(mode);
    const activeBackgroundColor = getActiveBackgroundColor(mode);

    return (
      <StyledButton
        data-testid="test-button"
        mode={mode}
        width={width}
        height={height}
        fontSize={fontSize}
        fontWeight={fontWeight}
        disabled={disabled}
        onClick={onClick}
        fontColor={fontColor}
        border={border}
        boxShadow={boxShadow}
        backgroundColor={backgroundColor}
        hoverBackgroundColor={hoverBackgroundColor}
        activeBackgroundColor={activeBackgroundColor}
        sx={sx}
        ref={ref}
        {...props}
      >
        <StyledFlexGap10>
          {isLoading && (
            <Box sx={{ width: 24, height: 20 }}>
              <CircularProgress data-testid="loading-indicator" size={20} />
            </Box>
          )}
          {children}
          {/* loading spinnerのダミー */}
          {isLoading && <Box sx={{ width: 24, height: 20 }} />}
        </StyledFlexGap10>
      </StyledButton>
    );
  },
);
