import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { memo } from 'react';

type Props = {
  isCollectiveSetting: boolean;
  onClick: () => void;
};
export const ModeButton = memo(
  ({ isCollectiveSetting, onClick }: Props): JSX.Element => {
    return (
      <>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {!isCollectiveSetting ? (
            <Button
              style={{ width: 114, height: 30, fontSize: 13 }}
              onClick={onClick}
            >
              一括設定モード
            </Button>
          ) : (
            <Button
              mode="border"
              style={{ width: 155, height: 30, fontSize: 13 }}
              onClick={onClick}
            >
              一括設定モードを解除
            </Button>
          )}
        </Box>
      </>
    );
  },
);
