import { useCallback } from 'react';

export const useImageSize = () => {
  const getImageSize = useCallback(
    async (
      url: string | null | undefined,
    ): Promise<{ width: number; height: number; bytes: number }> => {
      if (!url) return { width: 0, height: 0, bytes: 0 };

      // 画像の寸法を取得するための新しいPromise
      const dimensionPromise = new Promise<{ width: number; height: number }>(
        (resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve({ width: img.width, height: img.height });
          img.onerror = reject;
          img.src = url;
        },
      );

      // バイト数を取得
      const response = await fetch(url);
      const bytes = parseInt(response.headers.get('content-length') ?? '0', 10);

      // 画像の寸法を待つ
      const dimension = await dimensionPromise;

      return {
        ...dimension,
        bytes,
      };
    },
    [],
  );

  const getImageBytes = useCallback((bytes: number) => {
    if (bytes >= 1024 * 1024) {
      const megabytes = (bytes / (1024 * 1024)).toFixed(1);

      return `${megabytes}MB`;
    }
    const kilobytes = (bytes / 1024).toFixed(1);

    return `${kilobytes}KB`;
  }, []);

  return {
    getImageSize,
    getImageBytes,
  };
};
