import { Dialog } from '@/components/molecules/Dialog';
import { memo } from 'react';
import { AwayGeneration } from './AwayGeneration';
import { EraseData } from './EraseData';

type Props = {
  isOpenDialog: boolean;
  handleCloseDialog: () => void;
  handleSubmit: () => void;
  displayType?: string;
};
/**
 * JSDoc
 * @see バッチ処理のデータがある場合に警告ダイアログを表示する
 * @see 配下のグローバルhooksを使用している
 */
export const ConfirmEraseDataDialog = memo(
  ({
    isOpenDialog,
    handleCloseDialog,
    handleSubmit,
    displayType,
  }: Props): JSX.Element => {
    return (
      <>
        <Dialog
          isOpenDialog={isOpenDialog}
          onClose={handleCloseDialog}
          maxWidth="sm"
          dialogTitle=""
          hasCloseIcon={false}
        >
          {displayType === 'batchDownload' ? (
            <AwayGeneration
              handleCloseDialog={handleCloseDialog}
              handleSubmit={handleSubmit}
            />
          ) : (
            <EraseData
              handleCloseDialog={handleCloseDialog}
              handleSubmit={handleSubmit}
            />
          )}
        </Dialog>
      </>
    );
  },
);
