import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './table.css';

export const StyledTable = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledTable} {...props}>
      {children}
    </Box>
  );
});
