import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './icon.css';

export const StyledCircle32 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledCircle32} {...props}>
      {children}
    </Box>
  );
});

export const StyledLargeIconBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledLargeIconBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledLargeIconScale = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledLargeIconScale} {...props}>
      {children}
    </Box>
  );
});

export const StyledMediumIconBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledMediumIconBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledSmallIconScale = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledSmallIconScale} {...props}>
      {children}
    </Box>
  );
});

export const StyledDownloadDot = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledDownloadDot} {...props}>
      {children}
    </Box>
  );
});

export const StyledGenerateStatusBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledGenerateStatusBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledGrayIconBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledGrayIconBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledDangerIconBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledDangerIconBox} {...props}>
      {children}
    </Box>
  );
});
