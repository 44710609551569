import { Box, BoxProps } from '@/components/atoms/Box';
import Image, { ImageProps } from 'next/image';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledCircledShadow = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledCircledShadow} {...props}>
      {children}
    </Box>
  );
});

type CircleProps = {
  backgroundColor?: string;
} & BoxProps;
export const StyledCircleAbove = memo(
  ({ backgroundColor, children, ...props }: CircleProps) => {
    const style = {
      backgroundColor,
    };

    return (
      <Box className={vanilla.styledCircleAbove} style={style} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledCircleBelow = memo(
  ({ backgroundColor, children, ...props }: CircleProps) => {
    const style = {
      backgroundColor,
    };

    return (
      <Box className={vanilla.styledCircleBelow} style={style} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledImageAbove = memo(({ ...props }: ImageProps) => {
  return <Image className={vanilla.styledImageAbove} {...props} />;
});

export const StyledImageBelow = memo(({ ...props }: ImageProps) => {
  return <Image className={vanilla.styledImageBelow} {...props} />;
});

export const StyledCircleBoth = memo(
  ({ backgroundColor, children, ...props }: CircleProps) => {
    const style = {
      backgroundColor,
    };

    return (
      <Box className={vanilla.styledCircleBoth} style={style} {...props}>
        {children}
      </Box>
    );
  },
);
