import { Box } from '@/components/atoms/Box';
import { ImageFields } from '@/contexts/FeaturesContext/types';
import { UploaderInterface } from '@/features/components/molecules/UploaderInterface';
import { TextField } from '@mui/material';
import { memo, useState } from 'react';
import { usePasteImage } from './hooks/usePasteImage';
import { useUploadImage } from './hooks/useUploadImage';

type Props = {
  field: ImageFields;
};
/**
 * JSDoc
 * @see グローバルなアップローダー
 * @returns {JSX.Element}
 */
export const GlobalUploader = memo(({ field }: Props): JSX.Element => {
  const [errorFiles, setErrorFiles] = useState<File[]>([]);
  const [exceededFiles, setExceededFiles] = useState<File[]>([]);

  const { getRootProps, getInputProps, isDragActive } = useUploadImage({
    field: field as ImageFields,
    errorFiles,
    setErrorFiles,
    setExceededFiles,
  });

  const { handlePasteImage, inputRef } = usePasteImage({
    field: field as ImageFields,
    errorFiles,
    setErrorFiles,
    setExceededFiles,
  });

  console.log('GlobalUploader');

  return (
    <>
      <Box style={{ position: 'absolute', opacity: 0 }}>
        <TextField
          inputRef={inputRef} // inputRefプロパティを使用する
          multiline
          rows={8}
          onPaste={handlePasteImage}
        />
      </Box>
      <UploaderInterface
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        errorFiles={errorFiles}
        exceededFiles={exceededFiles}
        isDragActive={isDragActive}
      />
    </>
  );
});
