import "../../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fstyled%2Fflex.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xb2piMnJuMCB7CiAgZGlzcGxheTogZmxleDsKICBhbGlnbi1pdGVtczogY2VudGVyOwp9Ci5fMW9qYjJybjEgewogIHdoaXRlLXNwYWNlOiBub3dyYXA7Cn0KLl8xb2piMnJuMiB7CiAgd2lkdGg6IDEwMCU7Cn0KLl8xb2piMnJuMyB7CiAganVzdGlmeS1jb250ZW50OiBmbGV4LXN0YXJ0Owp9Ci5fMW9qYjJybjQgewogIHdpZHRoOiAxMDAlOwogIGp1c3RpZnktY29udGVudDogZmxleC1lbmQ7Cn0KLl8xb2piMnJuNSB7CiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7Cn0KLl8xb2piMnJuNiB7CiAgaGVpZ2h0OiAxMDAlOwp9Ci5fMW9qYjJybjcgewogIGFsaWduLWl0ZW1zOiBmbGV4LXN0YXJ0Owp9Ci5fMW9qYjJybjggewogIGFsaWduLWl0ZW1zOiBmbGV4LWVuZDsKfQouXzFvamIycm45IHsKICBnYXA6IDhweDsKfQouXzFvamIycm5hIHsKICB3aWR0aDogMTAwJTsKfQouXzFvamIycm5iIHsKICB3aWR0aDogMTAwJTsKICBoZWlnaHQ6IDEwMCU7Cn0KLl8xb2piMnJuYyB7CiAgZ2FwOiA1cHg7Cn0KLl8xb2piMnJuZCB7CiAgZ2FwOiA4cHg7Cn0KLl8xb2piMnJuZSB7CiAgZ2FwOiA4cHg7Cn0KLl8xb2piMnJuZiB7CiAgZ2FwOiAxMHB4Owp9Ci5fMW9qYjJybmcgewogIGdhcDogMTBweDsKfQouXzFvamIycm5oIHsKICBnYXA6IDE1cHg7Cn0KLl8xb2piMnJuaSB7CiAgZ2FwOiAyMHB4Owp9Ci5fMW9qYjJybmogewogIGdhcDogNXB4Owp9Ci5fMW9qYjJybmsgewogIGdhcDogMTBweDsKfQouXzFvamIycm5sIHsKICBnYXA6IDEwcHg7Cn0%3D%22%7D"
import "../../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ffeatures%2Fcomponents%2Ftemplates%2FLayout%2Fstyled%2Fvanilla.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA51U0ZKCIBR97yt4aaYeaEjLyr6GBIVdAgdJbXf69wUth7Jlqhdxzrmec%2B696sLgKsYCgd8JAKWquOFKpkBTgQ2v6d6ihFelwOcU5IK2Dmg4MSwFGRbZbIlQ3QAItqhs545klBfMpMARzAGqpjoXqkkB44RQuZ9cJovedRlwfclkYJllk1XZ2mOXdMc6udYG%2FKPO%2F32n%2BMY%2Bj%2F4DuSS0tTPwvOJ%2FvVYoaBah5GU3v1t8MsrzX30668D81p%2F29MkAE98sjqyCKz1yCUcYbkdYOMZBaUI1FDR3b659fyolOAE11jMIMyWUhoXGZyicSP8Ezr4LrU6S9Hw6Lm4YN3R%2B10%2Fk9bPx%2B7GJpg9fzzS4zm2%2FTkwIl4Xr04buLuh649XuutqbdLL2xvQUHDL1wxt0sJ94g8ZP3GEP0oPK4fkeAzsLJR5ks3f%2BYDeRzYMI6URG5V%2BnyvD8bJcrDZWmJyCVZP9SJ5c%2Fv6wz%2BmUFAAA%3D%22%7D"
export var styledDialogContentBox = 'tas3al8 _1ojb2rn0';
export var styledModalDownloadContentBox = 'tas3alc';
export var styledModalDownloadRightBox = 'tas3ald';
export var styledModalStepContentBox = 'tas3al9 _1ojb2rn0';
export var styledModalStepLeftBox = 'tas3ala';
export var styledModalStepRightBox = 'tas3alb';
export var styledScrollBox = 'tas3al7';
export var styledSingleContentBox = 'tas3al2 _1ojb2rn5 _1ojb2rn0';
export var styledSingleContentWithScrollBox = 'tas3al1';
export var styledSplitLeftBox = 'tas3al5 _1ojb2rn5 _1ojb2rn0';
export var styledSplitRightBox = 'tas3al6';
export var styledStepContentBox = 'tas3al0';
export var styledStepLeftBox = 'tas3al3 _1ojb2rn5 _1ojb2rn0';
export var styledStepLeftCentering = 'tas3al4';