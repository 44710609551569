import { Box, BoxProps } from '@/components/atoms/Box';
import { TableRowProps, TableRow as Tr } from '@mui/material';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledDataContainer = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledDataContainer} {...props}>
      {children}
    </Box>
  );
});

type TrProps = {
  isSelected: boolean;
} & TableRowProps;

export const StyledTr = memo(({ isSelected, children, ...props }: TrProps) => {
  const trStyle = {
    backgroundColor: isSelected
      ? 'var(--color-gray-pale)'
      : 'var(--color-gray-white)',
  };

  return (
    <Tr sx={trStyle} {...props}>
      {children}
    </Tr>
  );
});

export const StyledFileNameBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledFileNameBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxNowrapWidth50 = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledBoxNowrapWidth50} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledFlexGap10Height30 = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledFlexGap10Height30} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledFlexEndGap10Height30 = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledFlexEndGap10Height30} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledPaginatorContainer = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledPaginatorContainer} {...props}>
        {children}
      </Box>
    );
  },
);
