import { Dialog } from '@/components/molecules/Dialog';
import { StyledBoxPaddingTop10 } from '@/components/styled';
import { memo } from 'react';
import { Segmentation } from './Segmentation';
import { Upload } from './Upload';
import { UploadEnd } from './UploadEnd';
import { useStepDetector } from './hooks/useStepDetector';
import { StyledDialogContainer } from './styled';

type Props = {
  isOpenDialog: boolean;
  onClose: () => void;
  onSubmit: () => void;
  dialogTitle: string;
  dialogExplain: string;
  isBatch?: boolean;
  field?: 'refImage' | 'mainImage';
};

/**
 * JSDoc
 * @see 参照画像アップロードダイアログ
 * @see オプションでセグメンテーションを行う
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const UploadRefWithOptionalSam = memo(
  ({
    isOpenDialog,
    onClose,
    onSubmit,
    dialogTitle,
    dialogExplain,
    isBatch = false,
    field = 'refImage',
  }: Props): JSX.Element => {
    const { modalStep } = useStepDetector({ field });

    return (
      <Dialog
        maxWidth="xl"
        isOpenDialog={isOpenDialog}
        onClose={onClose}
        onSubmit={onSubmit}
        dialogTitle={dialogTitle}
        hasCloseIcon
      >
        {dialogExplain && (
          <StyledBoxPaddingTop10>{dialogExplain}</StyledBoxPaddingTop10>
        )}

        <StyledDialogContainer
          style={{ marginTop: dialogExplain ? 0 : '-30px' }}
        >
          {modalStep === 'upload' && (
            <>
              <Upload field={field} />
            </>
          )}
          {modalStep === 'uploadEnd' && (
            <>
              <UploadEnd field={field} onSubmit={onSubmit} />
            </>
          )}
          {modalStep === 'segmentation' && (
            <>
              <Segmentation
                field={field}
                onSubmit={onSubmit}
                isBatch={isBatch}
              />
            </>
          )}
        </StyledDialogContainer>
      </Dialog>
    );
  },
);
