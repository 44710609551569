import { ImageFields } from '@/contexts/FeaturesContext/types';
import { GlobalUploader } from '@/features/components/organisms/GlobalUploader';
import { FeaturesModalStepLayout } from '@/features/components/templates/Layout/FeaturesModalStepLayout';
import { memo } from 'react';

type Props = {
  field: ImageFields;
};

/**
 * JSDoc
 * @see アップロードステップ
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const Upload = memo(({ field }: Props): JSX.Element => {
  console.log('upload');

  return (
    <>
      <FeaturesModalStepLayout>
        <GlobalUploader field={field} />
        <></>
      </FeaturesModalStepLayout>
    </>
  );
});
