import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { Dialog } from '@/components/molecules/Dialog';
import {
  StyledBoxHeight24,
  StyledFlexGap10,
  StyledGridGap10,
} from '@/components/styled';
import { FeaturePresetImage } from '@/contexts/FeaturesContext/types';
import { memo, useEffect, useState } from 'react';
import { RowData } from './RowData';
import { useRequestPresets } from './hooks/useRequestPresets';
import { StyledPresetContainer, StyledRowContainer } from './styled';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  dialogTitle: string;
  onSubmit: (presetValue: FeaturePresetImage) => void;
};

export const BackgroundPresetDialog = memo(
  ({ isOpen, onClose, dialogTitle, onSubmit }: Props): JSX.Element => {
    const [targetValue, setTargetValue] = useState<string>('');
    const [presetValue, setPresetValue] = useState<
      FeaturePresetImage | undefined
    >({
      id: '',
      displayName: '',
      examples: [],
    });
    const [tabValue, setTabValue] = useState<string>('default');
    const { apiResponse } = useRequestPresets();

    useEffect(() => {
      if (targetValue) {
        setPresetValue(
          apiResponse?.data.find((preset) => preset.id === targetValue),
        );
      }
    }, [targetValue, apiResponse?.data]);

    return (
      <Dialog
        isOpenDialog={isOpen}
        onClose={onClose}
        maxWidth="xl"
        dialogTitle={dialogTitle}
        hasCloseIcon={false}
      >
        <StyledPresetContainer>
          <StyledRowContainer>
            <StyledGridGap10>
              {apiResponse &&
                apiResponse.data.map((preset) => (
                  // biome-ignore lint/correctness/useJsxKeyInIterable: あとで修正
                  <RowData
                    preset={preset}
                    targetValue={targetValue}
                    setTargetValue={setTargetValue}
                  />
                ))}
            </StyledGridGap10>
          </StyledRowContainer>
          <StyledBoxHeight24 />
          <Box style={{ height: 60 }}>
            <StyledFlexGap10>
              <Button
                mode="border"
                style={{ height: 32, fontSize: 14, width: '50%' }}
                onClick={() => {
                  onClose();
                }}
              >
                キャンセル
              </Button>
              <Button
                style={{ height: 32, fontSize: 14, width: '50%' }}
                onClick={() => {
                  if (presetValue) {
                    onSubmit(presetValue);
                  }
                }}
                disabled={!presetValue}
              >
                決定
              </Button>
            </StyledFlexGap10>
          </Box>
        </StyledPresetContainer>
      </Dialog>
    );
  },
);
