import { Box } from '@/components/atoms/Box';
import { memo } from 'react';
import {
  StyledErrorBox,
  StyledErrorContainer,
  StyledErrorIcon,
  StyledErrorIconBox,
} from './styled';

type Props = {
  errorMessage?: string[];
  errorElement?: React.ReactNode;
  width?: number | string;
  hasIcon?: boolean;
  padding?: string;
  color?: string;
};

/**
 * JSDoc
 * @see Sugekaeのアップロード画像類のエラー表示を行うカード
 * @see 適合しない画像をアップロードされた際に表示される
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const ErrorCard = memo(
  ({
    errorMessage,
    errorElement,
    width,
    hasIcon = true,
    padding,
    color,
  }: Props): JSX.Element => {
    return (
      <StyledErrorContainer sx={{ padding, color }}>
        {hasIcon && (
          <StyledErrorIconBox data-testid="test-icon">
            <StyledErrorIcon />
          </StyledErrorIconBox>
        )}
        <StyledErrorBox width={width}>
          {errorMessage &&
            errorMessage.length > 0 &&
            errorMessage.map((error: string) => (
              <Box key={error}>
                <Box>{error}</Box>
              </Box>
            ))}
          {errorElement && errorElement}
        </StyledErrorBox>
      </StyledErrorContainer>
    );
  },
);
