import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { memo } from 'react';
import { DownloadManagement } from './uniques/DownloadManagement';
import { UserList } from './uniques/UserList';

/**
 * JSDoc
 * @see 管理者画面コンポーネント
 * @returns {JSX.Element}
 */
export const Admin = memo((): JSX.Element => {
  const { findActiveSub } = useFeaturesContext({});

  return (
    <>
      {findActiveSub()?.name === 'userList' ? (
        <UserList />
      ) : (
        <DownloadManagement />
      )}
    </>
  );
});
