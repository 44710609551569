import { TaskOriginalImage } from "@/api/schemas";
import { useDialog } from "@/hooks/local/useDialog";
import { useImageSize } from "@/hooks/utils/useImageSize";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useReEditAtom } from "../../hooks/useReEditAtom";
import { useRequestPreview } from "../../hooks/useRequestPreview";

export type SelectedImageSize = {
  width: number;
  height: number;
  bytes: number;
} | undefined

export const useSamDialog = () => {
  const {
    tmpMaskImage,
    setTmpMaskImage,
    maskImagesMap,
    setMaskImagesMap,
    selectedIndex,
    targetOriginalImages,
  } = useReEditAtom();

  const selectedImage: TaskOriginalImage | undefined = useMemo(() => {
    if (!targetOriginalImages || selectedIndex === undefined) return undefined

    return targetOriginalImages[selectedIndex]
  }, [
    selectedIndex,
    targetOriginalImages,
  ])

  const { getImageSize, getImageBytes } = useImageSize();
  const [selectedImageSize, setSelectedImageSize] = useState<SelectedImageSize>()

  // selectedImageSizeを計算するuseEffect
  useEffect(() => {
    if (!selectedImage?.inputImage?.originalImageUrl) return

    getImageSize(selectedImage.inputImage.originalImageUrl).then(imageSize => {
      setSelectedImageSize({
        width: imageSize.width,
        height: imageSize.height,
        bytes: imageSize.bytes,
      })
    }).catch((error) => {
      throw new Error(error)
    })
  }, [
    selectedImage?.inputImage?.originalImageUrl,
    getImageSize,
  ])

  const selectedImageSizeText = useMemo(() => {
    if (!selectedImageSize) return undefined

    const width = selectedImageSize.width.toString()
    const height = selectedImageSize.height.toString()
    const bytes = getImageBytes(selectedImageSize.bytes)

    return `解像度: ${width}x${height}（${bytes}）`
  }, [
    selectedImageSize,
    getImageBytes,
  ])

  const {
    isOpenDialog,
    handleOpenDialog,
    handleCloseDialog: _handleCloseDialog,
  } = useDialog();

  const handleCloseDialog = useCallback(() => {
    _handleCloseDialog()
    setTmpMaskImage(undefined)
  }, [
    _handleCloseDialog,
    setTmpMaskImage,
  ])

  const handleSubmitMaskImages = useCallback(() => {
    if (selectedIndex === undefined) throw new Error("selectedIndex is undefined");
    if (tmpMaskImage === undefined) throw new Error("tmpMaskImage is undefined");

    setMaskImagesMap(prev => ({
      ...prev,
      [selectedIndex]: tmpMaskImage,
    }))
  }, [
    selectedIndex,
    tmpMaskImage,
    setMaskImagesMap,
  ])

  const [positivePoints, setPositivePoints] = useState<[number, number][]>([]);
  const [negativePoints, setNegativePoints] = useState<[number, number][]>([]);
  const [apiPositivePoints, setApiPositivePoints] = useState<[number, number][]>([]);
  const [apiNegativePoints, setApiNegativePoints] = useState<[number, number][]>([]);

  const [samType, setSamType] = useState<'manual' | 'auto'>('manual');
  const [pointType, setPointType] = useState<'positive' | 'negative'>('positive');

  // TODO: useRequestPreviewの呼び出し場所をプレビューボタン付近まで持っていけないか？（previewの状態はこのカスタムフックで定義する？）
  const {
    isPreviewCompleted,
    isPreviewLoading,
    setIsPreviewCompleted,
    setIsPreviewLoading,
    handleRequestPreview,
  } = useRequestPreview({
    selectedImage,
    apiPositivePoints,
    apiNegativePoints,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const handleToggleSwitch = useCallback(() => {
    const newSamType = samType === 'auto' ? 'manual' : 'auto';
    if (newSamType === 'auto') {
      setTmpMaskImage(undefined);
      setIsPreviewCompleted(false);
      // biome-ignore lint/complexity/noVoid: あとで修正
      void (async () => {
        await handleRequestPreview(newSamType);
        setIsPreviewCompleted(true);
      })()

    } else if (newSamType === 'manual') {
      setIsPreviewCompleted(false);
      setTmpMaskImage(undefined)
    } else {
      throw new Error('Invalid samType');
    }
    setSamType(newSamType);
  }, [samType, setSamType, handleRequestPreview, setTmpMaskImage, setIsPreviewCompleted]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const initializeSamDialogState = useCallback(() => {
    setPositivePoints([]);
    setNegativePoints([]);
    setApiPositivePoints([]);
    setApiNegativePoints([]);
    setTmpMaskImage(undefined);
    setIsPreviewCompleted(false);
  }, [
    setPositivePoints,
    setNegativePoints,
    setApiPositivePoints,
    setApiNegativePoints,
    setTmpMaskImage,
    setIsPreviewCompleted,
  ]);

  return {
    maskImagesMap,
    selectedIndex,
    selectedImage,
    selectedImageSize,
    selectedImageSizeText,
    isOpenDialog,
    handleOpenDialog,
    handleCloseDialog,
    handleSubmitMaskImages,

    // MEMO: リファクタリングしたい。整備対象として隔離
    positivePoints,
    setPositivePoints,
    negativePoints,
    setNegativePoints,
    apiPositivePoints,
    setApiPositivePoints,
    apiNegativePoints,
    setApiNegativePoints,
    isPreviewCompleted,
    isPreviewLoading,
    setIsPreviewCompleted,
    setIsPreviewLoading,
    handleRequestPreview,
    samType,
    setSamType,
    pointType,
    setPointType,
    handleToggleSwitch,
    initializeSamDialogState,
  }
}
