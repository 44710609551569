import { Box } from '@/components/atoms/Box';
import { TextButton } from '@/components/atoms/TextButton/';
import { Popper } from '@/components/molecules/Popper';
import {
  StyledFlex,
  StyledFlexEnd,
  StyledFontBoldNowrap,
  StyledGridGap10,
} from '@/components/styled';
import { Z_INDEX } from '@/constants';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageFields } from '@/contexts/FeaturesContext/types';
import { StyledDangerIconBox } from '@/features/components/styled/icon';
import { usePopper } from '@/hooks/local/usePopper';
import { useImageSize } from '@/hooks/utils/useImageSize';
import { memo } from 'react';
import { MdInfo } from 'react-icons/md';
import {
  StyledFileDetailBox,
  StyledFileDetailContainer,
  StyledFileName,
  StyledFileSizeBox,
  StyledMessageBox,
} from './styled';

type Props = {
  field: ImageFields;
  onResetImage: () => void;
  isKeepMask?: boolean;
  onDialog?: boolean;
};

/**
 * JSDoc
 * @see FeaturesData画像フィールドの情報の表示
 * @see 画像変更テキストボタン押下時は画像をリセット
 * @see 呼び出し元によってリセットする値が異なる
 * @see 呼び出し元（通常セグメンテーション、リピート時のセグメンテーション、参照画像モーダル）
 * @param {Props} { field }
 * @param {Props} { onResetImage }
 * @returns {JSX.Element}
 */
export const FileDetail = memo(
  ({
    field,
    onResetImage,
    isKeepMask,
    onDialog = false,
  }: Props): JSX.Element => {
    const { featureData } = useFeaturesContext({});

    const { getImageBytes } = useImageSize();
    const { isOpenPopper, handleOpenPopper, handleClosePopper, anchorElement } =
      usePopper();

    return (
      <>
        <StyledFileDetailContainer>
          <StyledGridGap10>
            <StyledFlex>
              <StyledFontBoldNowrap>画像情報</StyledFontBoldNowrap>
              <StyledFlexEnd>
                {!isKeepMask && (
                  <TextButton label="画像を差し替える" onClick={onResetImage} />
                )}
              </StyledFlexEnd>
            </StyledFlex>
            <StyledFileDetailBox>
              <StyledFileName>{featureData?.[field]?.fileName}</StyledFileName>
              {featureData?.[field]?.originalSize &&
                featureData?.[field]?.originalSize.width && (
                  <StyledFileSizeBox
                    bytes={featureData?.[field].resizedSize.bytes || 0}
                  >
                    <>
                      {featureData?.[field].resizedSize?.bytes ? (
                        <Box>
                          解像度：{featureData?.[field].resizedSize?.width} x{' '}
                          {featureData?.[field].resizedSize?.height}
                        </Box>
                      ) : (
                        <Box>
                          解像度：{featureData?.[field].originalSize?.width} x{' '}
                          {featureData?.[field].originalSize?.height}
                        </Box>
                      )}
                      (
                      {getImageBytes(
                        featureData?.[field].originalSize?.bytes || 0,
                      )}
                      ）
                      <StyledFlexEnd>
                        {featureData?.[field].resizedSize?.bytes ? (
                          <StyledDangerIconBox
                            onMouseOver={handleOpenPopper}
                            onMouseOut={handleClosePopper}
                          >
                            <MdInfo size={24} />
                          </StyledDangerIconBox>
                        ) : (
                          ''
                        )}
                      </StyledFlexEnd>
                    </>
                  </StyledFileSizeBox>
                )}
            </StyledFileDetailBox>
          </StyledGridGap10>
        </StyledFileDetailContainer>
        <Popper
          title=""
          isOpenPopper={isOpenPopper}
          onClose={handleClosePopper}
          anchorElement={anchorElement}
          placement="bottom"
          placementY={0}
          hasClose={false}
          boxShadow="0px 4px 5px -2px rgba(0, 0, 0, 0.20), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)"
          paddingRight="16px"
          borderRadius="4px"
          backgroundColor="#fdeded"
          zIndex={onDialog ? Z_INDEX.popperOnDialog : Z_INDEX.popperDefault}
        >
          <StyledMessageBox>
            <>
              指定サイズを超える画像がアップロードされたため、下記の解像度に変更されます。
            </>
            ※ 元画像サイズ：{featureData?.[field].originalSize?.width}x
            {featureData?.[field].originalSize?.height}
          </StyledMessageBox>
        </Popper>
      </>
    );
  },
);
