import { Box, BoxProps } from '@/components/atoms/Box';
import Image, { ImageProps } from 'next/image';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledImageThumbnail = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledImageThumbnail} {...props}>
      {children}
    </Box>
  );
});

export const StyledImage = memo(({ children, ...props }: ImageProps) => {
  return <Image className={vanilla.styledImage} {...props} />;
});

export const StyledCircle = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledCircle} {...props}>
      {children}
    </Box>
  );
});

export const StyledCircleImage = memo(({ children, ...props }: ImageProps) => {
  return <Image className={vanilla.styledCircle} {...props} />;
});
