import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';

const isOpenTaskTrayPopperAtom = atom<boolean>(false);
const anchorElementAtom = atom<null | HTMLElement>(null);

export const useTaskTrayPopper = () => {
  const [isOpenTaskTrayPopper, setIsOpenTaskTrayPopper] = useAtom(
    isOpenTaskTrayPopperAtom,
  );
  const [anchorElement, setAnchorElement] = useAtom(anchorElementAtom);

  const handleOpenTaskTrayPopper = useCallback(
    (e: React.MouseEvent<HTMLElement>): void => {
      // biome-ignore lint/suspicious/noExplicitAny: あとで修正
      setAnchorElement(e.currentTarget as any);
      setIsOpenTaskTrayPopper(true);
    },
    [setAnchorElement, setIsOpenTaskTrayPopper],
  );

  const handleCloseTaskTrayPopper = (): void => {
    setIsOpenTaskTrayPopper(false);
  };

  return {
    isOpenTaskTrayPopper,
    setIsOpenTaskTrayPopper,
    handleOpenTaskTrayPopper,
    handleCloseTaskTrayPopper,
    anchorElement,
    setAnchorElement,
  };
};
