import { useBackendApi } from "@/api";
import { TaskOriginalImage } from "@/api/schemas";
import { useError } from "@/hooks/global/useError";
import { useBase64 } from "@/hooks/utils/useBase64";
import { useCallback, useState } from "react";
import { useReEditAtom } from "./useReEditAtom";


type UsePreviewArgs = {
  selectedImage: TaskOriginalImage | undefined;
  apiPositivePoints: [number, number][];
  apiNegativePoints: [number, number][];
}

export const useRequestPreview = ({
  selectedImage,
  apiPositivePoints,
  apiNegativePoints,
}: UsePreviewArgs) => {
  const { logger } = useError();
  const { getImageBase64 } = useBase64();
  const {
    postImages,
    postSegmentationAnything,
  } = useBackendApi({isInverseResult: false})
  const {
    postSegmentationBackground: inverseResutPostSegmentationBackground,
  } = useBackendApi({isInverseResult: true})
  const { setTmpMaskImage } = useReEditAtom();

  const {
    id: mainImageId,
    fileName: mainImageFileName,
    originalImageUrl: mainImageUrl
  } = selectedImage?.inputImage ||
    {
      id: undefined,
      fileName: undefined,
      originalImageUrl: undefined
    };

  const postAndProcessImageForManual = useCallback(async () => {
    if (
      !mainImageId
      || !mainImageFileName
      || !mainImageUrl
    ) return undefined

    const base64Result = await getImageBase64(mainImageUrl);

    const segmentationResult = await postSegmentationAnything(
      base64Result,
      apiPositivePoints,
      apiNegativePoints,
    )

    const maskImagesResult = await postImages({
      image: segmentationResult?.maskImageBase64,
      fileName: `${mainImageFileName}-mask`,
    });

    if (
      !maskImagesResult?.id
      || !segmentationResult?.maskImageBase64
      || !segmentationResult?.maskOverlayImageBase64
    ) {
      throw new Error("Response value is invalid");
    }

    const response = {
      ...segmentationResult,
      id: mainImageId,
      maskId: maskImagesResult.id,
    }

    return response
  }, [
    mainImageId,
    mainImageFileName,
    mainImageUrl,
    apiPositivePoints,
    apiNegativePoints,
    getImageBase64,
    postImages,
    postSegmentationAnything,
  ]);

  // MEMO: ...ForManualとほぼ同じだが、呼ぶawait関数が異なるので冗長だが別に分けている。
  const postAndProcessImageForAuto = useCallback(async () => {
    if (
      !mainImageId
      || !mainImageFileName
      || !mainImageUrl
    ) return undefined

    const base64Result = await getImageBase64(mainImageUrl);

    const segmentationResult = await inverseResutPostSegmentationBackground(base64Result)

    const maskImagesResult = await postImages({
      image: segmentationResult?.maskImageBase64,
      fileName: `${mainImageFileName}-mask`,
    });

    if (
      !maskImagesResult?.id
      || !segmentationResult?.maskImageBase64
      || !segmentationResult?.maskOverlayImageBase64
    ) {
      throw new Error("Response value is invalid");
    }

    const response = {
      ...segmentationResult,
      id: mainImageId,
      maskId: maskImagesResult.id,
    }

    return response
  }, [
    mainImageId,
    mainImageFileName,
    mainImageUrl,
    getImageBase64,
    postImages,
    inverseResutPostSegmentationBackground,
  ]);

  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [isPreviewCompleted, setIsPreviewCompleted] = useState(false);

  // MEMO: stateとしてのsamTypeを参照すると、samType更新と同時に呼び出したとき値の同期保証ができないため、引数として明示的に_samTypeを渡す（useEffectで制御した方が良いかもしれないが、値がどこで制御されているか見通しが悪くなるので避けている）
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const handleRequestPreview = useCallback(async (_samType: 'auto' | 'manual'): Promise<void> => {
    try {
      setIsPreviewCompleted(false);
      setIsPreviewLoading(true);

      switch (_samType) {
        case 'manual': {
          const tmpMaskImage = await postAndProcessImageForManual();
          setIsPreviewCompleted(true);
          setIsPreviewLoading(false);
          setTmpMaskImage(tmpMaskImage);
          break;
        }
        case 'auto': {
          const tmpMaskImage = await postAndProcessImageForAuto();
          setIsPreviewCompleted(true);
          setIsPreviewLoading(false);
          setTmpMaskImage(tmpMaskImage);
          break;
        }
        default:
          setIsPreviewCompleted(true);
          setIsPreviewLoading(false);
          throw new Error('Invalid samType');
      }
    } catch (error) {
      logger({ error });
    }
  }, [
    postAndProcessImageForManual,
    postAndProcessImageForAuto,
    setTmpMaskImage,
    setIsPreviewCompleted,
    setIsPreviewLoading,
    logger,
  ]);

  return {
    isPreviewLoading,
    isPreviewCompleted,
    setIsPreviewLoading,
    setIsPreviewCompleted,
    handleRequestPreview
  }
}
