import { Typography, TypographyProps } from '@mui/material';
import { memo } from 'react';
import * as vanilla from './font.css';

export const StyledFont = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFont}`}>{children}</span>;
});

export const StyledFontBold = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFontBold}`}>{children}</span>;
});

export const StyledFontNowrap = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFontNowrap}`}>{children}</span>;
});

export const StyledFontBoldBlack06 = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFontBoldBlack06}`}>{children}</span>;
});

export const StyledFontBoldNowrap = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFontBoldNowrap}`}>{children}</span>;
});

export const StyledFontDeep = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFontDeep}`}>{children}</span>;
});

export const StyledFontDeepNowrap = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFontDeepNowrap}`}>{children}</span>;
});

export const StyledFontError = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFontError}`}>{children}</span>;
});

export const StyledFont12 = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFont12}`}>{children}</span>;
});

export const StyledFont12Bold = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFont12Bold}`}>{children}</span>;
});

export const StyledFont12Deep = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFont12Deep}`}>{children}</span>;
});

export const StyledFont12Danger = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFont12Danger}`}>{children}</span>;
});

export const StyledFont12DangerCenter = memo(
  ({ children }: TypographyProps) => {
    return (
      <span className={`${vanilla.styledFont12DangerCenter}`}>{children}</span>
    );
  },
);

export const StyledFont12BoldNowrapWhite = memo(
  ({ children }: TypographyProps) => {
    return (
      <span className={`${vanilla.styledFont12BoldNowrapWhite}`}>
        {children}
      </span>
    );
  },
);

export const StyledFont14 = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFont14}`}>{children}</span>;
});

export const StyledFont16 = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFont16}`}>{children}</span>;
});

export const StyledFont16Bold = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFont16Bold}`}>{children}</span>;
});

export const StyledFont16Nowrap = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFont16Nowrap}`}>{children}</span>;
});

export const StyledFont16White = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFont16White}`}>{children}</span>;
});

export const StyledFont16Main = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFont16Main}`}>{children}</span>;
});

export const StyledFont16Error = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFont16Error}`}>{children}</span>;
});

export const StyledFont18Bold = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFont18Bold}`}>{children}</span>;
});

export const StyledFont20BoldDanger = memo(({ children }: TypographyProps) => {
  return (
    <span className={`${vanilla.styledFont20BoldDanger}`}>{children}</span>
  );
});

export const StyledFont24Dark = memo(({ children }: TypographyProps) => {
  return <span className={`${vanilla.styledFont24Dark}`}>{children}</span>;
});

type FontProps = {
  size?: number | string;
  color?: string;
  fontWeight?: number | string;
} & TypographyProps;

export const StyledFontSx = memo(
  ({ children, size, color, fontWeight = 400, ...props }: FontProps) => {
    return (
      <Typography
        className={`${vanilla.styledFontSx}`}
        style={{ fontSize: size, color, fontWeight }}
        {...props}
      >
        {children}
      </Typography>
    );
  },
);

export const StyledFontBoldSx = memo(
  ({ children, size, color, fontWeight = 700, ...props }: FontProps) => {
    return (
      <Typography
        className={`${vanilla.styledFontBoldSx}`}
        style={{ fontSize: size, color, fontWeight }}
        {...props}
      >
        {children}
      </Typography>
    );
  },
);

export const StyledFontNowrapSx = memo(
  ({ children, size, color, fontWeight = 400, ...props }: FontProps) => {
    return (
      <Typography
        className={`${vanilla.styledFontNowrapSx}`}
        style={{ fontSize: size, color, fontWeight }}
        {...props}
      >
        {children}
      </Typography>
    );
  },
);

export const StyledFontBoldNowrapSx = memo(
  ({ children, size, color, fontWeight = 400, ...props }: FontProps) => {
    return (
      <Typography
        className={`${vanilla.styledFontBoldNowrapSx}`}
        style={{ fontSize: size, color, fontWeight }}
        {...props}
      >
        {children}
      </Typography>
    );
  },
);
