import { Task } from '@/api/schemas';
import {
  StyledFlexGap15,
  StyledFontBold,
  StyledGridGap5,
} from '@/components/styled';
import { TASK_STATUS } from '@/constants';
import { StyledGenerateStatusBox } from '@/features/components/styled/icon';
import { CircularProgress } from '@mui/material';
import { memo } from 'react';

type Props = {
  data: Task;
};

export const InProgress = memo(({ data }: Props): JSX.Element => {
  return (
    <StyledFlexGap15>
      <StyledGenerateStatusBox>
        <CircularProgress style={{ padding: '10px' }} />
      </StyledGenerateStatusBox>
      <StyledGridGap5>
        <StyledFontBold>
          {TASK_STATUS[data.result.status as keyof typeof TASK_STATUS]}...
        </StyledFontBold>
      </StyledGridGap5>
    </StyledFlexGap15>
  );
});
