import { ImageWithLoader } from '@/components/molecules/ImageWithLoader/ImageWithLoader';
import {
  FeatureBatchOriginalImage,
  FeatureData,
} from '@/contexts/FeaturesContext/types';
import { memo, useMemo } from 'react';
import {
  StyledImageSetThumbnailBox,
  StyledImageSetThumbnailCenter,
} from '../styled';

type Props = {
  image: FeatureBatchOriginalImage;
  index: number;
  featureData: FeatureData;
  targetNumber: number;
};

export const Thumbnail = memo(
  ({ image, index, featureData, targetNumber }: Props): JSX.Element => {
    const combinedBase64Thumbnail120 = useMemo(
      () => image.mainImage.combinedBase64Thumbnail120,
      [image.mainImage.combinedBase64Thumbnail120],
    );

    return (
      <>
        <StyledImageSetThumbnailBox
          key={`upload-set-image-${index}`.toString()}
          style={{
            outline: image.isSelected
              ? `1px solid ${'var(--color-primary-main)'}`
              : '1px solid #ccc',
            cursor: 'pointer',
            backgroundColor: '#fff',
          }}
        >
          <StyledImageSetThumbnailCenter>
            <ImageWithLoader
              src={`${image.url}&d=118x118`}
              width={100}
              height={120}
              alt=""
              loaderHeight={8}
              loaderWidth={8}
              loaderMode="beater"
            />
          </StyledImageSetThumbnailCenter>
        </StyledImageSetThumbnailBox>
      </>
    );
  },
);
