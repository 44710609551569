import { Box } from '@/components/atoms/Box';
import { StyledFont } from '@/components/styled';
import {
  StyledTableRowContainer,
  StyledTableRowName,
} from '@/features/components/styled/batch';
import { memo } from 'react';

export const Head = memo((): JSX.Element => {
    return (
      <StyledTableRowContainer
        style={{
          borderTop: 0,
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        }}
      >
        <StyledTableRowName>
          <StyledFont>画像セット</StyledFont>
        </StyledTableRowName>
        <Box style={{ width: 100, maxWidth: 100 }} />
        <Box style={{ width: 50, maxWidth: 50 }} />
        <Box style={{ width: 200, whiteSpace: 'nowrap' }}>
          <StyledFont>アップロード日時</StyledFont>
        </Box>
        <Box style={{ width: 150, whiteSpace: 'nowrap', textAlign: 'center' }}>
          <StyledFont>ファイル数</StyledFont>
        </Box>
        <Box
          style={{ width: 150, textAlign: 'center', whiteSpace: 'nowrap' }}
        >
          <StyledFont>参照画像指定</StyledFont>
        </Box>
        <Box
          style={{ width: 150, textAlign: 'center', whiteSpace: 'nowrap' }}
        >
          <StyledFont>領域選択</StyledFont>
        </Box>
      </StyledTableRowContainer>
    );
  },
);
