import { Task } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import { StyledGrid } from '@/components/styled';
import { useDownloadManagement } from '@/features/Admin/hooks/useDownloadManagement';
import { FeaturesSplitLayout } from '@/features/components/templates/Layout/FeaturesSplitLayout';
import { memo, useState } from 'react';
import { Detail } from './Detail';
import { Search } from './Search';
import { Status } from './Status';
import { Table } from './Table';

export const DownloadManagement = memo((): JSX.Element => {
  const {
    reportApiResponse,
    startYear,
    startMonth,
    targetYear,
    targetMonth,
    setTargetYear,
    setTargetMonth,
  } = useDownloadManagement();

  const [detailData, setDetailData] = useState<Task | undefined>(undefined);

  return (
    <>
      {reportApiResponse && reportApiResponse.organizationId && (
        <FeaturesSplitLayout>
          <StyledGrid>
            <Search
              apiResponse={reportApiResponse}
              startYear={startYear}
              startMonth={startMonth}
              targetYear={targetYear}
              targetMonth={targetMonth}
              setTargetYear={setTargetYear}
              setTargetMonth={setTargetMonth}
            />
            <Status reportApiResponse={reportApiResponse} />
            <Table
              reportApiResponse={reportApiResponse}
              setDetailData={setDetailData}
            />
          </StyledGrid>
          <Box>
            <Detail detailData={detailData} />
          </Box>
        </FeaturesSplitLayout>
      )}
    </>
  );
});
