import { Task } from '@/api/schemas';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useError } from '@/hooks/global/useError';
import { useEffect } from 'react';

type Props = {
  handleStopGenerating: () => void;
  handleGetPreviewApi: () => Promise<void>;
  apiPostResponse: Task | undefined;
  apiGetResponse: Task | undefined;
  handleCloseProgress: () => void;
};

export const useRequest = ({
  handleStopGenerating,
  handleGetPreviewApi,
  apiPostResponse,
  apiGetResponse,
  handleCloseProgress,
}: Props) => {
  const { featureData, updateFeatureData, updateFeatureDataSingle } =
    useFeaturesContext({});
  const { isError, loggerFromGeneratingStatus } = useError();

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    // biome-ignore lint/suspicious/noEmptyBlockStatements: 必要性が不明。あとで確認
    if (featureData?.single?.genStatus.isForceClear) return () => {};
    // biome-ignore lint/suspicious/noEmptyBlockStatements: 必要性が不明。あとで確認
    if (featureData?.single?.genStatus.isGenerated) return () => {};
    if (apiGetResponse && apiGetResponse.result.status === 'COMPLETED') {
      handleCloseProgress();
      updateFeatureDataSingle('genStatus', {
        isGenerating: false,
        isRequesting: false,
        isStop: false,
        isGenerated: true,
        isForceClear: false,
      });

      // biome-ignore lint/suspicious/noEmptyBlockStatements: 必要性が不明。あとで確認
      return () => {};
    }
    if (
      apiGetResponse &&
      (apiGetResponse.result.status === 'FAILED' ||
        apiGetResponse.result.status === 'CANCELED' ||
        apiGetResponse.result.status === 'TIME_OUT')
    ) {
      loggerFromGeneratingStatus(apiGetResponse.result.status);
      handleStopGenerating();

      // biome-ignore lint/suspicious/noEmptyBlockStatements: 必要性が不明。あとで確認
      return () => {};
    }

    // biome-ignore lint/suspicious/noEmptyBlockStatements: 必要性が不明。あとで確認
    if (!featureData?.single?.genStatus.isGenerating) return () => {};
    // biome-ignore lint/suspicious/noEmptyBlockStatements: 必要性が不明。あとで確認
    if (!apiPostResponse) return () => {};
    // biome-ignore lint/suspicious/noEmptyBlockStatements: 必要性が不明。あとで確認
    if (!featureData?.mainImage.base64 || !featureData?.mainImage.maskBase64) return () => {};
    let timeoutId: NodeJS.Timeout;
    if (!featureData?.single?.genStatus.isRequesting) {
      timeoutId = setTimeout(() => {
        // biome-ignore lint/complexity/noVoid: あとで修正
        void handleGetPreviewApi();
        updateFeatureDataSingle('genStatus', {
          isRequesting: false,
        });
      }, 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    apiGetResponse,
    apiPostResponse,
    featureData?.single?.genStatus.isGenerated,
    featureData?.single?.genStatus.isGenerating,
    featureData?.single?.genStatus.isRequesting,
    featureData?.mainImage.base64,
    featureData?.mainImage.maskBase64,
    handleCloseProgress,
    handleGetPreviewApi,
    handleStopGenerating,
    updateFeatureData,
    loggerFromGeneratingStatus,
    featureData?.single?.genStatus.isForceClear,
    updateFeatureDataSingle,
  ]);

  useEffect(() => {
    if (!isError) return;
    handleStopGenerating();
  }, [handleStopGenerating, isError]);

  return { isError };
};
