import { z } from 'zod';

export const PageInfoSchema = z.object({
  total: z.number(),
  offset: z.number(),
  limit: z.number(),
  count: z.number(),
});

export type PageInfo = z.infer<typeof PageInfoSchema>;
