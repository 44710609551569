import { Box } from '@/components/atoms/Box';
import { StyledBoxHeight100vh } from '@/components/styled/box';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { FeatureStep } from '@/contexts/FeaturesContext/types';
import { LoadingSpinner } from '@/features/components/atoms/LoadingSpinner';
import { useLoadingSpinner } from '@/hooks/global/useLoadingSpinner';
import { Fragment, createElement, memo } from 'react';
import { initialDataParamState, initialStepStates } from './initial';

console.log('white');
/**
 * JSDoc
 * @see 生成機能・白抜きコンポーネント
 * @returns {JSX.Element}
 */
export const White = memo((): JSX.Element => {
  const { activeFeature } = useFeaturesContext({
    initialFeatureName: 'white',
    initialStepStates,
    initialDataParamState,
  });
  const { isOpenLoadingSpinner } = useLoadingSpinner();

  return (
    <>
      {isOpenLoadingSpinner && (
        <StyledBoxHeight100vh>
          <LoadingSpinner />
        </StyledBoxHeight100vh>
      )}

      {!isOpenLoadingSpinner && activeFeature && (
        <Box>
          {activeFeature.step &&
            activeFeature.step.map((d: FeatureStep, i: number) => (
              <Fragment key={String(`${d.name}-${i}`)}>
                {d.isActive && d.component && <>{createElement(d.component)}</>}
              </Fragment>
            ))}
        </Box>
      )}
    </>
  );
});
