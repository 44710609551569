import { TextButton } from '@/components/atoms/TextButton';
import { Dialog } from '@/components/molecules/Dialog';
import {
  StyledBoxTextAlignCenter,
  StyledFlexCenter,
  StyledFont,
  StyledFont16Bold,
  StyledGridCenter,
  StyledGridCenterGap15,
} from '@/components/styled';
import { StyledDialogConfirmAbortionButton } from '@/components/styled/button';
import { memo } from 'react';
import { StyledDialogContainer } from './styled';

type Props = {
  isOpenDialog: boolean;
  onSubmit: () => void;
  onClose: () => void;
};
/**
 * JSDoc
 * @see 中断確認ダイアログ
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const ConfirmAbortionDialog = memo(
  ({ isOpenDialog, onSubmit, onClose }: Props): JSX.Element => {
    return (
      <Dialog
        maxWidth="sm"
        isOpenDialog={isOpenDialog}
        onClose={onClose}
        hasTitle={false}
        hasCloseIcon={false}
      >
        <StyledDialogContainer>
          <StyledGridCenter>
            <StyledFont16Bold>
              今の作業を中断して生成を確認しますか？
            </StyledFont16Bold>
            <StyledBoxTextAlignCenter>
              <StyledFont>
                中断すると
                <br />
                作業中のタスクデータは失われます。
              </StyledFont>
            </StyledBoxTextAlignCenter>
            <StyledGridCenterGap15>
              <StyledDialogConfirmAbortionButton onClick={onSubmit}>
                中断する
              </StyledDialogConfirmAbortionButton>
              <StyledFlexCenter>
                <TextButton label="キャンセル" onClick={onClose} />
              </StyledFlexCenter>
            </StyledGridCenterGap15>
          </StyledGridCenter>
        </StyledDialogContainer>
      </Dialog>
    );
  },
);
