import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledSearchContainer = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledSearchContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledAdjustWrapper = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledAdjustWrapper} {...props}>
      {children}
    </Box>
  );
});

export const StyledCalendarTitleBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledCalendarTitleBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledCalendarBetweenBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledCalendarBetweenBox} {...props}>
        {children}
      </Box>
    );
  },
);
