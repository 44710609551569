import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fstyled%2Fgrid.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xNTBsdHNqMCB7CiAgZGlzcGxheTogZ3JpZDsKfQouXzE1MGx0c2oxIHsKICB3aGl0ZS1zcGFjZTogbm93cmFwOwp9Ci5fMTUwbHRzajIgewogIGFsaWduLWl0ZW1zOiBmbGV4LXN0YXJ0Owp9Ci5fMTUwbHRzajMgewogIGdhcDogNXB4Owp9Ci5fMTUwbHRzajQgewogIGdhcDogOHB4Owp9Ci5fMTUwbHRzajUgewogIGdhcDogMTBweDsKfQouXzE1MGx0c2o2IHsKICBnYXA6IDEwcHg7Cn0KLl8xNTBsdHNqNyB7CiAgZ2FwOiAyMHB4Owp9Ci5fMTUwbHRzajggewogIGdhcDogMjVweDsKfQouXzE1MGx0c2o5IHsKICBhbGlnbi1pdGVtczogZmxleC1lbmQ7CiAgdmVydGljYWwtYWxpZ246IGJvdHRvbTsKfQouXzE1MGx0c2phIHsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIGp1c3RpZnktY29udGVudDogY2VudGVyOwp9Ci5fMTUwbHRzamIgewogIGFsaWduLWl0ZW1zOiBjZW50ZXI7CiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7Cn0KLl8xNTBsdHNqYyB7CiAgYWxpZ24taXRlbXM6IGNlbnRlcjsKICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjsKfQ%3D%3D%22%7D"
export var styledGrid = '_150ltsj0';
export var styledGridAlignStart = '_150ltsj2 _150ltsj0';
export var styledGridCenter = '_150ltsja _150ltsj0';
export var styledGridCenterGap10 = '_150ltsjb _150ltsj5 _150ltsj0';
export var styledGridCenterGap15 = '_150ltsjc _150ltsj6 _150ltsj0';
export var styledGridEnd = '_150ltsj9 _150ltsj0';
export var styledGridGap10 = '_150ltsj5 _150ltsj0';
export var styledGridGap15 = '_150ltsj6 _150ltsj0';
export var styledGridGap20 = '_150ltsj7 _150ltsj0';
export var styledGridGap25 = '_150ltsj8 _150ltsj0';
export var styledGridGap5 = '_150ltsj3 _150ltsj0';
export var styledGridGap8 = '_150ltsj4 _150ltsj0';
export var styledGridNowrap = '_150ltsj1 _150ltsj0';