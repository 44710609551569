import { Popper } from '@/components/molecules/Popper';
import { ConfirmDeleteSetDialog } from '@/features/components/modals/ConfirmDeleteSetDialog';
import { WarningFunction } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { FunctionViewType } from '@/features/components/steps/BatchUpload/types';
import { memo } from 'react';
import { AccordionRow } from './AccordionRow';
import { useBodyHandlers } from './hooks/useBodyHandlers';
import { StyledMenuBox } from './styled';

type Props = {
  handleOpenSetNameDialog: () => void;
  setFunctionViewType: (type: FunctionViewType) => void;
  warningFunction: WarningFunction;
};

export const Body = memo(
  ({
    handleOpenSetNameDialog,
    setFunctionViewType,
    warningFunction,
  }: Props): JSX.Element => {
    const {
      featureData,
      handleOpenDeleteMenu,
      handleOpenDeleteDialog,
      handleSubmit,
      deleteSetName,
      deleteId,
      isOpenPopper,
      anchorElement,
      isOpenConfirmDialog,
      handleCloseConfirmDialog,
      handleClosePopper,
    } = useBodyHandlers();

    return (
      <>
        {featureData?.batch.map((set, i) => (
          <AccordionRow
            key={`accordion-${i.toString()}`}
            set={set}
            targetNumber={i}
            handleOpenSetNameDialog={handleOpenSetNameDialog}
            handleOpenDeleteMenu={handleOpenDeleteMenu}
            setFunctionViewType={setFunctionViewType}
            warningFunction={warningFunction}
          />
        ))}

        <ConfirmDeleteSetDialog
          isOpenDialog={isOpenConfirmDialog}
          handleCloseDialog={handleCloseConfirmDialog}
          handleSubmit={handleSubmit}
          setName={deleteSetName}
          id={deleteId}
        />
        <Popper
          isOpenPopper={isOpenPopper}
          onClose={handleClosePopper}
          anchorElement={anchorElement}
          placement="right"
          placementY={25}
          hasClose={false}
        >
          <StyledMenuBox onMouseDown={handleOpenDeleteDialog}>
            このセットを削除
          </StyledMenuBox>
        </Popper>
      </>
    );
  },
);
