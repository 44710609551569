import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './box.css';

export const StyledBoxNowrap = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxNowrap} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxPaddingTop10 = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledBoxPaddingTop10} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledBoxPaddingTop100 = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledBoxPaddingTop100} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledBoxMarginTopMinus30 = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledBoxMarginTopMinus30} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledBoxWidth3 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxWidth3} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxWidth8 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxWidth8} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxWidth10 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxWidth10} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxWidth12 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxWidth12} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxWidth20 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxWidth20} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxWidth30 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxWidth30} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxWidth50 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxWidth50} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxExpanded = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxExpanded} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxRelativeWidthExpanded = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledBoxRelativeWidthExpanded} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledBoxHeight5 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxHeight5} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxHeight10 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxHeight10} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxHeight15 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxHeight15} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxHeight16 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxHeight16} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxHeight20 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxHeight20} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxHeight24 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxHeight24} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxHeight25 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxHeight25} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxHeight30 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxHeight30} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxHeight32 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxHeight32} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxHeight64 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxHeight64} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxHeight100vh = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBoxHeight100vh} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxTextAlignCenter = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledBoxTextAlignCenter} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledBoxTextAlignLeft = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledBoxTextAlignLeft} {...props}>
        {children}
      </Box>
    );
  },
);
export const StyledBoxPaddingLeft20 = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledBoxPaddingLeft20} {...props}>
        {children}
      </Box>
    );
  },
);
