import { Box, BoxProps } from '@/components/atoms/Box';
import { forwardRef } from 'react';
import * as vanilla from './vanilla.css';

export const StyledUploadInterface = forwardRef<HTMLDivElement, BoxProps>(
  ({ children, ...props }, ref) => {
    return (
      <Box ref={ref} className={vanilla.styledUploadInterface} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledUploadAfterInterface = forwardRef<HTMLDivElement, BoxProps>(
  ({ children, ...props }, ref) => {
    return (
      <Box ref={ref} className={vanilla.styledUploadAfterInterface} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledAfterButton = forwardRef<HTMLDivElement, BoxProps>(
  ({ children, ...props }, ref) => {
    return (
      <Box ref={ref} className={vanilla.styledUploadAfterInterface} {...props}>
        {children}
      </Box>
    );
  },
);
