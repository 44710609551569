import { Box } from '@/components/atoms/Box';
import { StyledGridGap10 } from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { GenerationCount } from '@/features/components/functions/GenerationCount';
import { useBatchSetting } from '@/features/components/steps/BatchSetting/hooks/useBatchSetting';
import { StyledFunctionWrapper } from '@/features/components/styled/function';
import Divider from '@mui/material/Divider';
import { memo } from 'react';
import { ModeButton } from './ModeButton';
import { SetName } from './SetName';
import { TargetTypeForBackgroundLora } from './TargetTypeForBackground';
import { useFunctions } from './hooks/useFunctions';

export const Functions = memo((): JSX.Element => {
  const { currentNumber, currentState } = useBatchSetting();
  const { featureData } = useFeaturesContext({});

  const {
    handlers,
    dialogs,
    setValue,
    isCollectiveSetting,
    targetType,
    generationCount,
    backgroundUrl,
    presetUrl,
    presetDisplayName,
    isSubmit,
    setIsSubmit,
  } = useFunctions({ featureData, currentNumber });
  console.log(featureData);

  return (
    <>
      <StyledFunctionWrapper>
        <StyledGridGap10>
          <SetName
            setName={currentState?.setName}
            isCollectiveSetting={isCollectiveSetting}
          />
          <ModeButton
            isCollectiveSetting={isCollectiveSetting}
            onClick={handlers.handleClickCollectiveSettingParam}
          />
          <Divider sx={{ margin: '16px 0' }} />
          <Box>
            <TargetTypeForBackgroundLora
              currentNumber={currentNumber}
              handlers={handlers}
              dialogs={dialogs}
              isSubmit={isSubmit}
              setIsSubmit={setIsSubmit}
              targetType={targetType}
              backgroundUrl={backgroundUrl}
              presetUrl={presetUrl}
              presetDisplayName={presetDisplayName}
            />
          </Box>
          <Divider sx={{ margin: '16px 0' }} />
          <Box>
            <GenerationCount
              data={generationCount}
              setValue={setValue}
              label="バリエーション数"
              max={3}
            />
          </Box>
          <Divider sx={{ margin: '16px 0' }} />
        </StyledGridGap10>
      </StyledFunctionWrapper>
    </>
  );
});
