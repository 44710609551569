import { Box, BoxProps } from '@/components/atoms/Box';
import { styled } from '@mui/material';
import Image, { ImageProps } from 'next/image';
import { forwardRef, memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledThumbnailContainer = forwardRef<HTMLDivElement, BoxProps>(
  ({ children, ...props }, ref) => {
    return (
      <Box ref={ref} className={vanilla.styledThumbnailContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledThumbnailBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledThumbnailBox} {...props}>
      {children}
    </Box>
  );
});

const color = {
  primary: 'var(--color-primary-main)',
  teal: 'var(--color-teal-400)',
}

export const StyledThumbnail = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'swiperIndex' &&
    prop !== 'isChecked' &&
    prop !== 'hasCheckIcon' &&
    prop !== 'isChecked' &&
    prop !== 'isDownloaded',
})<{
  index: number;
  swiperIndex: number;
  hasCheckIcon?: boolean;
  isChecked?: boolean;
  variant: 'primary' | 'teal'
}>(({ index, swiperIndex, variant }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '72px',
  height: '72px',
  '& img': {
    display: 'block',
    objectFit: 'contain',
  },
  borderRadius: '4px',
  outline:
    swiperIndex === index
      ? `2px solid ${color[variant]}`
      : `1px solid ${'var(--color-gray-soft)'}`,
  '&:hover': {
    cursor: 'pointer',
  },
  backgroundColor: 'var(--color-gray-white)',
}));

export const StyledImage = memo(({ children, ...props }: ImageProps) => {
  return <Image className={vanilla.styledImage} {...props} />;
});
