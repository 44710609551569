import { StyledBoxHeight100vh } from '@/components/styled';
import { ContentHeader } from '@/features/components/organisms/ContentHeader';
import {
  StyledScrollBox,
  StyledSplitRightBox,
  StyledStepContentBox,
  StyledStepLeftCentering,
} from '@/features/components/templates/Layout/styled';
import { memo } from 'react';

type Props = {
  children: React.ReactNode[];
};

export const FeaturesBatchDownloadLayout = memo(
  ({ children }: Props): JSX.Element => {
    const [leftNode, rightNode] = children;

    return (
      <>
        <ContentHeader />
        <StyledBoxHeight100vh style={{ height: 'calc(100vh - 100px)' }}>
          <StyledStepContentBox>
            <StyledStepLeftCentering>
              <StyledScrollBox style={{ height: 'calc(100% - 64px)' }}>
                {leftNode}
              </StyledScrollBox>
            </StyledStepLeftCentering>
            <StyledSplitRightBox>
              <StyledScrollBox style={{ height: 'calc(100% - 64px)' }}>
                {rightNode}
              </StyledScrollBox>
            </StyledSplitRightBox>
          </StyledStepContentBox>
        </StyledBoxHeight100vh>
      </>
    );
  },
);
