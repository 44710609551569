export const initialCollectiveSettingParam = {
  isNoBackground: false,
  keepMaskImage: '',
  targetType: 'image',
  pickColor: '',
  newColor: '',
  currentColor: '',
  newRef: '',
  currentRef: '',
  withShadow: 'on',
  blurLevel: 'none',
  generationCount: 1,
  format: 'png',
};

export const initialCollectiveRefImage = {
  displayName: '',
  refImageId: '',
  refUrl: '',
  maskImageId: '',
  maskUrl: '',
  keepMaskImageId: '',
  keepMaskUrl: '',
  refImageSelectedId: '',
  refImageSelectedUrl: '',
};

export const initialCollectivePresetImage = {
  id: '',
  displayName: '',
  examples: [],
};
