import { ImageWithLoader } from '@/components/molecules/ImageWithLoader/ImageWithLoader';
import { FeatureBatch, ImageResult } from '@/contexts/FeaturesContext/types';
import { memo, useEffect, useState } from 'react';
import { useImageSilhouette } from './hooks/useImageSilhouette';

type Props = {
  set: FeatureBatch;
  image: ImageResult;
  index: number;
};
export const CombinedSilhouetteImage = memo(
  ({ set, image, index }: Props): JSX.Element => {
    const { getImageSilhouette } = useImageSilhouette();
    const [imageSilhouette, setImageSilhouette] = useState<string | null>(null);

    useEffect(() => {
      // biome-ignore lint/complexity/noVoid: あとで修正
      void (async () => {
        const res = await getImageSilhouette({
          mainImageSource: image.url,
          maskImageSource: set.originalImages[0].mainImage.maskBase64,
          color: '#000000',
        });
        setImageSilhouette(res);
      })();
    }, [getImageSilhouette, image.url, set]);

    return (
      <>
        {imageSilhouette && (
          <ImageWithLoader
            src={imageSilhouette}
            fill
            alt=""
            style={{
              objectFit: 'contain',
              objectPosition: 'center',
            }}
            loaderHeight={8}
            loaderWidth={8}
            loaderMode="beater"
          />
        )}
      </>
    );
  },
);
