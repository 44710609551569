// import { useSnackbar } from '@/hooks/global/useSnackbar';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { memo } from 'react';
import { BackgroundLoraButton } from './BackgroundLoraButton';
import { ColorTemperatureButton } from './ColorTemperatureButton';

type Props = {
  isDialog?: boolean;
  isAllTasksDone: boolean;
  deletedIds: string[];
};

export const Buttons = memo(
  ({ isDialog, isAllTasksDone, deletedIds }: Props): JSX.Element => {
    const { activeFeatureName } = useFeaturesContext({});

    return (
      <>
        {activeFeatureName === 'backgroundLora' ? (
          <BackgroundLoraButton
            isDialog={isDialog}
            isAllTasksDone={isAllTasksDone}
            deletedIds={deletedIds}
          />
        ) : (
          <ColorTemperatureButton
            isDialog={isDialog}
            isAllTasksDone={isAllTasksDone}
          />
        )}
      </>
    );
  },
);
