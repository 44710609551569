import { BACKGROUND_BATCH_LIMIT_IMAGES, BATCH_LIMIT_SET, COLOR_TEMPERATURE_BATCH_LIMIT_IMAGES, WHITE_BATCH_LIMIT_IMAGES } from "@/constants";

// TODO: 本来は定数にマッピングした方がわかりやすいが、型定義がゆるいため（activeFeatureNameがstring型のため）エラーになってしまう。activeFeatureNameの型定義を行ない、定数として書き直す。
export const getBatchLimitImagesByActiveFeature = (activeFeatureName: string) => {
  switch (activeFeatureName) {
    case 'colorTemperature':
      return COLOR_TEMPERATURE_BATCH_LIMIT_IMAGES
    case 'multiWhite':
      return WHITE_BATCH_LIMIT_IMAGES
    case 'backgroundLora':
      return BACKGROUND_BATCH_LIMIT_IMAGES
    default:
      return BATCH_LIMIT_SET
  }
}
