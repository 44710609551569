import { ImageSize } from '@/contexts/FeaturesContext/types';
import { useCallback, useState } from 'react';

export type ResizedImageFile = {
  name: string;
  originalSize: ImageSize;
  resizedSize: ImageSize;
};

export type BatchWarnings = {
  formatErrorFiles: File[];
  dataSizeErrorFiles: File[];
  imageSizeErrorFiles: File[];
  duplicatedErrorFiles: File[];
  exceededImageLimitErrorFiles: File[];
  resizedImageFiles: File[];
};

export type WarningFunction = {
  updateWarningKeyValue: (field: keyof BatchWarnings, value: unknown) => void;
  updateErrorAtGettingFiles: (
    formatErrorFiles: File[],
    dataSizeErrorFiles: File[],
  ) => void;
  resetWarnings: () => void;
};

/**
 * @see バッチアップロードのエラーに関するカスタムフック
 * @see グローバルで行うと想定外のバグが起こるのでローカルステートを用いる
 * エラー：
 * フォーマットエラー（規定外）
 * データサイズエラー（5MB以上）
 * 画像サイズエラー（小さすぎる）
 * 同一ファイルエラー（同一セットで二重アップロードさせない）
 * リサイズ（2560以上）→この場合はリサイズされる
 * 画像最大数エラー（20枚を超えるアクションはエラー）
 * 次のアップロードを行うとエラーはリセットされる。
 * 一度エラー詳細情報を開き、後に他のアクションを行った場合にもエラー情報を消す仕様。
 * 次のアクションとは
 * 選択中のセットにアップロード
 * 他のセットを選択する（戻るとエラーは消えている）
 *
 */
export const useBatchUploadWarnings = () => {
  const [warningsData, setWarningsData] = useState<BatchWarnings>({
    formatErrorFiles: [],
    dataSizeErrorFiles: [],
    imageSizeErrorFiles: [],
    duplicatedErrorFiles: [],
    exceededImageLimitErrorFiles: [],
    resizedImageFiles: [],
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const updateWarningKeyValue = useCallback(
    (field: keyof BatchWarnings, value: unknown) => {
      setWarningsData((prev) => ({
        ...prev,
        [field]: value,
      }));
    },
    [setWarningsData],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const updateErrorAtGettingFiles = useCallback(
    (formatErrorFiles: File[], dataSizeErrorFiles: File[]) => {
      const updateWarningsData = {
        ...warningsData,
        formatErrorFiles,
        dataSizeErrorFiles,
      };
      setWarningsData(updateWarningsData);
    },
    [setWarningsData, warningsData],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const resetWarnings = useCallback(() => {
    setWarningsData({
      formatErrorFiles: [],
      dataSizeErrorFiles: [],
      imageSizeErrorFiles: [],
      duplicatedErrorFiles: [],
      exceededImageLimitErrorFiles: [],
      resizedImageFiles: [],
    });
  }, [setWarningsData]);

  return {
    warningsData,
    warningFunction: {
      updateWarningKeyValue,
      updateErrorAtGettingFiles,
      resetWarnings,
    },
  };
};
