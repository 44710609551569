import { BATCH_MAX_SIZE } from '@/constants';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { FeatureData } from '@/contexts/FeaturesContext/types';
import { BatchWarnings } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { useBase64 } from '@/hooks/utils/useBase64';
import { useResizeBase64 } from '@/hooks/utils/useResizeBase64';
import { useEffect, useState } from 'react';
import { getImageSize } from 'react-image-size';
import { PostFile } from './types';

type Props = {
  featureData: FeatureData | undefined;
  updateWarningKeyValue: (key: keyof BatchWarnings, value: unknown) => void;
  resetWarnings: () => void;
  acceptedFiles: File[];
  targetNumber: number;
  handlePostImages: (postFiles: PostFile[]) => void;
};

/**
 * JSDoc
 * @see 調整画像のチェックを行った後に必要であればリサイズしてbase64に変換するカスタムフック
 */
export const useGetBase64WithResizeAfterCheck = ({
  featureData,
  updateWarningKeyValue,
  resetWarnings,
  acceptedFiles,
  targetNumber,
  handlePostImages,
}: Props) => {
  const [successFiles, setSuccessFiles] = useState<PostFile[]>([]);
  const { getImageBase64 } = useBase64();
  const { getResizedBase64 } = useResizeBase64();
  const { activeFeatureName, initialFeatureDataParamState } = useFeaturesContext({});

  // biome-ignore lint/correctness/useExhaustiveDependencies: 無限ループ回避
  useEffect(() => {
    if (!featureData || !featureData.batch || acceptedFiles.length <= 0) return;
    resetWarnings();
    const processFiles = async () => {
      const duplicatedErrorFiles = [];
      const imageSizeErrorFiles = [];
      const exceededImageLimitErrorFiles = [];
      const resizedImageFiles = [];
      const tmpSuccessFiles: PostFile[] = [];

      const originalImageLength =
        featureData.batch[targetNumber].originalImages.length;
      const acceptedFilesLength = acceptedFiles.length;
      if (initialFeatureDataParamState?.batchLimitImages === undefined) throw new Error("initialFeatureDataParamState?.batchLimitImages is undefined");

      if (originalImageLength + acceptedFilesLength > initialFeatureDataParamState?.batchLimitImages) {
        exceededImageLimitErrorFiles.push(...acceptedFiles);
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const file of acceptedFiles) {
        const findFile = featureData.batch[targetNumber].originalImages.find(
          (image) => image.fileName === file.name,
        );
        if (findFile) {
          duplicatedErrorFiles.push(file);
        } else {
          const blobUrl = window.URL.createObjectURL(file);
          // eslint-disable-next-line no-await-in-loop
          const dimension = await getImageSize(blobUrl);
          if (dimension && dimension.width < 500 && dimension.height < 500) {
            imageSizeErrorFiles.push(file);
          } else if (
            dimension.width >
              BATCH_MAX_SIZE[
                activeFeatureName as keyof typeof BATCH_MAX_SIZE
              ] ||
            dimension.height >
              BATCH_MAX_SIZE[activeFeatureName as keyof typeof BATCH_MAX_SIZE]
          ) {
            const { base64 } =
              // eslint-disable-next-line no-await-in-loop
              await getResizedBase64({
                targetSize:
                  BATCH_MAX_SIZE[
                    activeFeatureName as keyof typeof BATCH_MAX_SIZE
                  ],
                width: dimension.width,
                height: dimension.height,
                blobUrl,
              });
            resizedImageFiles.push(file);
            if (base64) {
              tmpSuccessFiles.push({
                fileName: file.name,
                url: blobUrl,
                base64,
                width: dimension.width,
                height: dimension.height,
                size: file.size,
              });
            }
          } else {
            // eslint-disable-next-line no-await-in-loop
            const base64 = await getImageBase64(blobUrl);
            tmpSuccessFiles.push({
              fileName: file.name,
              url: blobUrl,
              base64,
              width: dimension.width,
              height: dimension.height,
              size: file.size,
            });
          }
        }
      }

      if (duplicatedErrorFiles.length) {
        updateWarningKeyValue('duplicatedErrorFiles', duplicatedErrorFiles);
      }
      if (imageSizeErrorFiles.length) {
        updateWarningKeyValue('imageSizeErrorFiles', imageSizeErrorFiles);
      }
      if (resizedImageFiles.length) {
        updateWarningKeyValue('resizedImageFiles', resizedImageFiles);
      }
      if (exceededImageLimitErrorFiles.length) {
        updateWarningKeyValue(
          'exceededImageLimitErrorFiles',
          exceededImageLimitErrorFiles,
        );
      } else if (tmpSuccessFiles.length) {
        // MEMO: リトライ用にstateとして持っておく
        setSuccessFiles(tmpSuccessFiles);
        handlePostImages(tmpSuccessFiles);
      }
    };

    // biome-ignore lint/complexity/noVoid: あとで修正
    void processFiles();
  }, [
    acceptedFiles,
    updateWarningKeyValue,
  ]);

  return {
    successFiles,
  }
};
