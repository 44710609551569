import { Box as MuiBox, BoxProps as MuiBoxProps } from '@mui/material';
import React, { forwardRef, memo } from 'react';
import * as vanilla from './styled/vanilla.css';

export type BoxProps = MuiBoxProps & {
  ref?: React.Ref<HTMLDivElement>;
};

const BoxComponent: React.ForwardRefRenderFunction<HTMLDivElement, BoxProps> = (
  { children, ...props },
  ref,
) => {
  return (
    <MuiBox ref={ref} className={vanilla.styledBox} {...props}>
      {children}
    </MuiBox>
  );
};

export const Box = memo(forwardRef(BoxComponent));
