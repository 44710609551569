import { z } from 'zod';

export const OrganizationSchema = z
  .object({
    id: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
    name: z.string(),
    displayName: z.string(),
    subscriptionStartDate: z.union([z.string(), z.null()]),
    subscriptionPlan: z.union([z.string(), z.null()]),
  })
  .nullable();

export type Organization = z.infer<typeof OrganizationSchema>;
