import { BACKGROUND_BATCH_LIMIT_IMAGES } from '@/constants';
import {
  BatchDownload,
  BatchSetting,
  BatchUpload,
} from '@/features/components/steps';

export const initialStepStates = [
  {
    title: '画像のアップロード',
    name: 'batchUpload',
    num: 1,
    explain: '背景を生成する画像（上限20枚まで）をアップロードしてください',
    component: BatchUpload,
    isActive: true,
  },
  {
    title: '背景の設定',
    name: 'batchSetting',
    num: 2,
    explain: '生成する背景を設定してください',
    component: BatchSetting,
    isActive: false,
  },
  {
    title: 'バリエーションの確認',
    name: 'batchDownload',
    num: 3,
    explain:
      'バリエーション画像を確認し、全ての画像に適用する背景を選択してください',
    component: BatchDownload,
    isActive: false,
  },
];

export const initialDataParamState = {
  isNoBackground: false,
  keepMaskImage: '',
  targetType: 'image',
  pickColor: '',
  newColor: '',
  currentColor: '',
  newRef: '',
  currentRef: '',
  withShadow: 'on',
  blurLevel: 'none',
  generationCount: 1,
  format: 'png',
  batchLimitImages: BACKGROUND_BATCH_LIMIT_IMAGES,
};
