import { Box } from '@/components/atoms/Box';
import { Dialog } from '@/components/molecules/Dialog';
import {
  StyledBoxHeight24,
  StyledBoxHeight5,
  StyledFlexEndGap5,
  StyledGridGap20,
} from '@/components/styled';
import {
  StyledDialogAddUserButton,
  StyledDialogAddUserCancelButton,
} from '@/components/styled/button';
import { ErrorCard } from '@/features/components/atoms/ErrorCard';
import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { useSnackbar } from '@/hooks/global/useSnackbar';
import { TextField } from '@mui/material';
import { memo, useCallback, useEffect, useState } from 'react';

type Props = {
  isOpenDialog: boolean;
  handleCloseDialog: () => void;
  featureName: 'batchUpload' | 'reEdit';
  displayNameForReEdit?: string | undefined;
  setDisplayNameForReEdit?: (name: string) => void;
};

// TODO: featureNameを使っている関係上込み入った実装になっている。BatchUploadでfeatureDataを使わないようにしたときに整備する。
export const EditSetNameDialog = memo(
  ({
    isOpenDialog,
    handleCloseDialog,
    featureName,
    displayNameForReEdit,
    setDisplayNameForReEdit,
  }: Props): JSX.Element => {
    const { handleOpenSnackbar, setSnackbarParam } = useSnackbar();

    const [setName, setSetName] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string[]>([]);
    const { currentNumber, featureData, updateFeatureDataArray } =
      useBatchUpload();

    useEffect(() => {
      if (featureData && featureData.batch[currentNumber]) {
        setSetName(featureData.batch[currentNumber].setName);
      }
    }, [currentNumber, featureData]);

    const handleChangeSetName = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value) {
        setErrorMessage(['セット名を入力してください']);
      } else if (e.target.value.length > 255) {
        setErrorMessage(['セット名は255文字以内で入力してください']);
      } else {
        setErrorMessage([]);
      }
      setSetName(e.target.value);
    };

    const handleSubmitForBatchUpload = useCallback(() => {
      if (!featureData) return;

      const updateSet = featureData.batch.map((set, index) => {
        if (index === currentNumber) {
          return {
            ...set,
            setName,
          };
        }

        return set;
      });
      updateFeatureDataArray('batch', updateSet);
      handleCloseDialog();
      setSnackbarParam({
        iconName: 'success',
        text: `セット名を変更しました`,
        position: { placement: 'rightTop', x: 0, y: 0 },
      });
      handleOpenSnackbar();
    }, [
      currentNumber,
      featureData,
      handleCloseDialog,
      handleOpenSnackbar,
      setName,
      setSnackbarParam,
      updateFeatureDataArray,
    ]);

    const handleSubmitForReEdit = useCallback(() => {
      if (!setDisplayNameForReEdit) throw new Error("setDisplayNameForReEdit is not defined");

      setDisplayNameForReEdit(setName);
      handleCloseDialog();
      setSnackbarParam({
        iconName: 'success',
        text: `セット名を変更しました`,
        position: { placement: 'rightTop', x: 0, y: 0 },
      });
      handleOpenSnackbar();
    }, [
      setName,
      handleCloseDialog,
      handleOpenSnackbar,
      setSnackbarParam,
      setDisplayNameForReEdit,
    ]);

    const handleSubmit = useCallback(() => {
      switch (featureName) {
        case 'batchUpload':
          handleSubmitForBatchUpload();
          break;
        case 'reEdit':
          handleSubmitForReEdit();
          break;
        default:
          throw new Error('Invalid featureName');
      }
    }, [
      featureName,
      handleSubmitForBatchUpload,
      handleSubmitForReEdit,
    ]);

    useEffect(() => {
      if (featureName === 'reEdit' && displayNameForReEdit) {
        setSetName(displayNameForReEdit);
      }
    }, [featureName, displayNameForReEdit]);

    return (
      <>
        <Dialog
          isOpenDialog={isOpenDialog}
          onClose={handleCloseDialog}
          maxWidth="sm"
          dialogTitle="セット名の変更"
          hasCloseIcon={false}
        >
          <Box>
            <StyledGridGap20>
              <Box>
                <TextField
                  label="セット名"
                  variant="standard"
                  sx={{ width: 320 }}
                  value={setName}
                  onChange={handleChangeSetName}
                />
              </Box>
              {errorMessage.length > 0 && (
                <ErrorCard errorMessage={errorMessage} width={260} />
              )}
              <StyledBoxHeight5 />
              <StyledFlexEndGap5>
                <StyledDialogAddUserCancelButton onClick={handleCloseDialog}>
                  キャンセル
                </StyledDialogAddUserCancelButton>
                <StyledDialogAddUserButton
                  disabled={
                    errorMessage.length > 0 ||
                    (featureData?.batch[currentNumber] &&
                      featureData?.batch[currentNumber].setName === setName)
                  }
                  onClick={handleSubmit}
                >
                  このセット名で決定
                </StyledDialogAddUserButton>
              </StyledFlexEndGap5>
            </StyledGridGap20>
          </Box>
          <StyledBoxHeight24 />
        </Dialog>
      </>
    );
  },
);
