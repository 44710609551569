import { Task, TaskResultImage } from '@/api/schemas';
import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import { useBase64 } from '@/hooks/utils/useBase64';
import { useCallback, useEffect, useState } from 'react';

type Props = {
  apiPostResponse: Task | undefined;
  apiGetResponse: Task | undefined;
};

export const useProgress = ({ apiPostResponse, apiGetResponse }: Props) => {
  const [isOpenProgress, setIsOpenProgress] = useState(false);
  const [loadingProgressTitle, setProgressTitle] = useState('');
  const [loadingProgressPercent, setProgressPercent] = useState<number | null>(
    0,
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const handleOpenProgress = useCallback(() => {
    setIsOpenProgress(true);
  }, [setIsOpenProgress]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const handleCloseProgress = useCallback(() => {
    setIsOpenProgress(false);
  }, [setIsOpenProgress]);

  const {
    activeFeatureName,
    featureData,
    updateFeatureDataSingle,
    activateTargetStep,
  } = useFeaturesContext({});

  const { getImageBase64 } = useBase64();

  useEffect(() => {
    if (featureData?.single?.genStatus.isGenerating) return;
    if (!featureData?.single?.genStatus.isStop) return;
    handleCloseProgress();
    // handleStopGenerating();
  }, [
    handleCloseProgress,
    featureData?.single?.genStatus.isGenerating,
    featureData?.single?.genStatus.isStop,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!featureData?.single?.genStatus.isGenerating) return;
    if (apiPostResponse) return;
    if (apiGetResponse) return;
    setProgressTitle('生成を待っています ...');

    setProgressPercent(0);
    handleOpenProgress();
  }, [
    apiGetResponse,
    apiPostResponse,
    featureData?.single?.genStatus.isGenerating,
    handleOpenProgress,
    setProgressPercent,
    setProgressTitle,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!apiGetResponse) return;
    if (apiGetResponse.result.status !== 'IN_PROGRESS') return;
    setProgressTitle('生成中です');
    setProgressPercent(apiGetResponse.result.progress);
  }, [apiGetResponse, setProgressPercent, setProgressTitle]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!apiGetResponse) return;
    if (apiGetResponse.result.status !== 'COMPLETED') return;
    if (featureData?.single?.genResult?.generatedImageResults.length) return;
    console.log('apiGetResponse', apiGetResponse);
    const ids = apiGetResponse.result.resultImages.map(
      (imageObj: TaskResultImage) => imageObj.id,
    );
    updateFeatureDataSingle('genResult', {
      taskId: apiGetResponse.id,
      generatedImageIds: ids,
      generatedImageResults: apiGetResponse.result.resultImages,
    });
    updateFeatureDataSingle('genStatus', {
      isGenerating: false,
      isStop: false,
      isGenerated: true,
    });
    if (activeFeatureName === 'white') {
      activateTargetStep('download');
    }
  }, [
    activeFeatureName,
    apiGetResponse,
    featureData?.single?.genResult?.generatedImageResults.length,
    getImageBase64,
    activateTargetStep,
    updateFeatureDataSingle,
  ]);

  return {
    isOpenProgress,
    loadingProgressTitle,
    loadingProgressPercent,
    handleCloseProgress,
  };
};
