import { atom, useAtom } from 'jotai';

const isOpenTaskTrayAtom = atom<boolean>(false);
const isTaskTrayAnimationAtom = atom<boolean>(false);

/**
 * JSDoc
 * @see タスクアニメーションを管理するグローバルカスタムフック（仮設置）
 * @see 近い将来、useLoadingSpinnerなどと共にAppContextにまとめる
 */
export const useTaskTray = () => {
  const [isOpenTaskTray, setIsOpenTaskTray] = useAtom(isOpenTaskTrayAtom);
  const [isTaskTrayAnimation, setIsTaskTrayAnimation] = useAtom(
    isTaskTrayAnimationAtom,
  );

  return {
    isOpenTaskTray,
    setIsOpenTaskTray,
    isTaskTrayAnimation,
    setIsTaskTrayAnimation,
  };
};
