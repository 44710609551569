import { Box } from '@/components/atoms/Box';
import { StyledFlexNowrap } from '@/components/styled';
import { Z_INDEX } from '@/constants';
import { DialogProps, IconButton, Dialog as MuiDialog } from '@mui/material';
import { memo } from 'react';
import { MdClose } from 'react-icons/md';
import {
  StyledChildrenBox,
  StyledCloseIconBox,
  StyledTitleBox,
} from './styled';

export type Props = {
  width?: number;
  maxWidth?: DialogProps['maxWidth'];
  isOpenDialog: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  children: React.ReactNode;
  hasTitle?: boolean;
  hasCloseIcon?: boolean;
  dialogTitle?: string;
  dialogTitleFontSize?: number;
  paddingType?: 'normal' | 'wide' | 'fit';
  zIndex?: number;
};

/**
 * JSDoc
 * @see Sugekaeのダイアログコンポーネント
 * @see ダイアログを表示する際に使用する
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const Dialog = memo(
  ({
    width,
    maxWidth = 'sm',
    isOpenDialog,
    onClose,
    onSubmit,
    children,
    hasTitle = true,
    hasCloseIcon = true,
    dialogTitle,
    dialogTitleFontSize,
    paddingType = 'normal',
    zIndex = Z_INDEX.dialogDefault,
  }: Props) => (
    // TODO: PopperのuseClickAwayで作成しているイベントハンドラが発火しないようにワークアラウンド対応。useClickAway自体の制御ロジックを修正する
    // biome-ignore lint/a11y/useFocusableInteractive: あとで修正
    <div role="button" style={{ width }} onMouseDown={(e) => {e.stopPropagation();}}>
      <MuiDialog
        maxWidth={maxWidth}
        onClose={onClose}
        open={isOpenDialog}
        style={{ zIndex }}
      >
        <Box style={{ width }}>
          <StyledFlexNowrap>
            {hasTitle && (
              <StyledTitleBox
                dialogTitleFontSize={dialogTitleFontSize}
                paddingType={paddingType}
              >
                {dialogTitle}
              </StyledTitleBox>
            )}
            {hasCloseIcon && (
              <StyledCloseIconBox paddingType={paddingType}>
                <IconButton onClick={onClose}>
                  <MdClose data-testid="close-icon" />
                </IconButton>
              </StyledCloseIconBox>
            )}
          </StyledFlexNowrap>
          <StyledChildrenBox paddingType={paddingType}>
            {children}
          </StyledChildrenBox>
        </Box>
      </MuiDialog>
    </div>
  ),
);
