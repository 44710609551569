import { atom, useAtom } from "jotai";
import { useCallback } from "react";

const isOpenBatchUploadRetryDialogAtom = atom<boolean>(false);

// TODO: 他のダイアログhooksに合わせてatomを使っている。完全なグローバルとなっていてどのコンポーネント（もしくはhooks）がこの値を制御しているのか見通しづらい状態なので、contextのスコープを切るかjotaiを使わない方向に書き換える
export const useBatchUploadRetryDialog = () => {
  const [isOpenBatchUploadRetryDialog, setIsOpenBatchUploadRetryDialog] =
  useAtom(isOpenBatchUploadRetryDialogAtom);

  const handleCloseBatchUploadRetryDialog = useCallback(() => {
    setIsOpenBatchUploadRetryDialog(false);
  }, [setIsOpenBatchUploadRetryDialog])

  const handleOpenBatchUploadRetryDialog = useCallback(() => {
    setIsOpenBatchUploadRetryDialog(true);
  }, [setIsOpenBatchUploadRetryDialog])

  return {
    isOpenBatchUploadRetryDialog,
    handleOpenBatchUploadRetryDialog,
    handleCloseBatchUploadRetryDialog,
  }
}
