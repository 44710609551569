import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './flex.css';

export const StyledFlex = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledFlex} {...props}>
      {children}
    </Box>
  );
});

export const StyledFlexNowrap = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledFlexNowrap} {...props}>
      {children}
    </Box>
  );
});

export const StyledFlexWidthExpanded = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledFlexWidthExpanded} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledFlexStart = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledFlexStart} {...props}>
      {children}
    </Box>
  );
});

export const StyledFlexEnd = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledFlexEnd} {...props}>
      {children}
    </Box>
  );
});

export const StyledFlexCenter = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledFlexCenter} {...props}>
      {children}
    </Box>
  );
});

export const StyledFlexCenterHeightExpanded = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledFlexCenterHeightExpanded} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledFlexAlignStart = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledFlexAlignStart} {...props}>
      {children}
    </Box>
  );
});

export const StyledFlexAlignEndGap8 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledFlexAlignEndGap8} {...props}>
      {children}
    </Box>
  );
});

export const StyledFlexCenterWidthExpanded = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledFlexCenterWidthExpanded} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledFlexCenterExpanded = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledFlexCenterExpanded} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledFlexGap5 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledFlexGap5} {...props}>
      {children}
    </Box>
  );
});

export const StyledFlexGap8 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledFlexGap8} {...props}>
      {children}
    </Box>
  );
});

export const StyledFlexCenterGap8 = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledFlexCenterGap8} {...props}>
        {children}
      </Box>
    );
  },
);


export const StyledFlexGap10 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledFlexGap10} {...props}>
      {children}
    </Box>
  );
});

export const StyledFlexNowrapGap10 = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledFlexNowrapGap10} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledFlexGap15 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledFlexGap15} {...props}>
      {children}
    </Box>
  );
});

export const StyledFlexGap20 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledFlexGap20} {...props}>
      {children}
    </Box>
  );
});

export const StyledFlexEndGap5 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledFlexEndGap5} {...props}>
      {children}
    </Box>
  );
});

export const StyledFlexEndGap10 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledFlexEndGap10} {...props}>
      {children}
    </Box>
  );
});

export const StyledFlexCenterGap10 = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledFlexCenterGap10} {...props}>
        {children}
      </Box>
    );
  },
);
