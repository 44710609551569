import { useBatchSetting } from '@/features/components/steps/BatchSetting/hooks/useBatchSetting';
import { useBatchOptionalContext } from '@/features/components/steps/hooks/useBatchOptionalContext';
import { useEffect, useMemo, useState } from 'react';

export const useGenerationCondition = () => {
  const {
    isCollectiveSetting,
    collectiveRefImage,
    collectivePresetImage,
  } = useBatchOptionalContext();

  const { featureData } = useBatchSetting();
  const isEnableVariationGenerate = useMemo(() => {
    if (isCollectiveSetting && collectiveRefImage.refUrl) {
      return true;
    }
    if (isCollectiveSetting && collectivePresetImage?.examples?.[0]?.url) {
      return true;
    }

    return featureData?.batch.every((batch) => {
      return !!batch.refImage?.refUrl || !!batch?.presetImage?.examples?.[0]?.url;
    });
  }, [
    isCollectiveSetting,
    collectiveRefImage,
    collectivePresetImage,
    featureData?.batch,
  ]);

  const [isVariationGenerate, setIsVariationGenerate] = useState(false);

  useEffect(() => {
    setIsVariationGenerate(!!isEnableVariationGenerate);
  }, [isEnableVariationGenerate]);

  return { isVariationGenerate };
};
