/**
 * JSDoc
 * @see 日付を変換するカスタムフック
 * @returns {Object}
 */
export const useConvertDate = () => {
  const convertToUnixTime = (dateString: string) => {
    const date = new Date(dateString);

    return Math.floor(date.getTime() / 1000);
  };

  const convertToDateTime = (dateString: string) => {
    /*
    "2023-11-29T16:09:20.23798+09:00"
    2023-11-29 16:09に変換
    この表記にする
    */
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const hour = date.getHours();
    const minutes = date.getMinutes();

    const monthString = month < 10 ? `0${month}` : `${month}`;
    const dayString = day < 10 ? `0${day}` : `${day}`;
    const hourString = hour < 10 ? `0${hour}` : `${hour}`;
    const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;

    return `${year}-${monthString}-${dayString} ${hourString}:${minutesString}`;
  };

  return { convertToUnixTime, convertToDateTime };
};
