import { Paginator } from '@/components/molecules/Paginator';
import {
  StyledBoxMinWidth120,
  StyledDisplayBox,
  StyledFormControl,
  StyledNavigatorColumns,
  StyledNavigatorContainer,
} from '@/features/components/styled/navigator';
import { StyledPaginatorBox } from '@/features/components/styled/paginator';
import { InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { memo } from 'react';

type Props = {
  isLoading: boolean;
  limit: number;
  count: number;
  page: number;
  handelChangeLimit: (event: SelectChangeEvent<number>) => void;
  handleChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
};
export const GlobalNavigator = memo(
  ({
    isLoading,
    limit,
    count,
    page,
    handelChangeLimit,
    handleChangePage,
  }: Props): JSX.Element => {
    return (
      <StyledNavigatorContainer>
        <StyledNavigatorColumns>
          <StyledDisplayBox>
            <StyledFormControl size="small">
              <InputLabel id="demo-select-small-label">表示件数</InputLabel>
              <Select
                value={limit}
                label="表示件数"
                onChange={handelChangeLimit}
              >
                <MenuItem value={50}>50件</MenuItem>
                <MenuItem value={100}>100件</MenuItem>
              </Select>
            </StyledFormControl>
          </StyledDisplayBox>
          <StyledPaginatorBox>
            <Paginator
              size="large"
              count={count}
              page={page}
              onChange={handleChangePage}
              disabled={isLoading}
            />
          </StyledPaginatorBox>
          <StyledBoxMinWidth120 />
        </StyledNavigatorColumns>
      </StyledNavigatorContainer>
    );
  },
);
