import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledPresetContainer = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledPresetContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledRowContainer = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledRowContainer} {...props}>
      {children}
    </Box>
  );
});

export const StyledRowBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledRowBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledRowTitleBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledRowTitleBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledRowDisplayNameBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledRowDisplayNameBox} {...props}>
        {children}
      </Box>
    );
  },
);
