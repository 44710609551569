import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import {
  StyledBoxHeight24,
  StyledFlexEndGap5,
  StyledFont16Bold,
  StyledGridCenter,
  StyledGridGap20,
} from '@/components/styled';
import { memo } from 'react';

type Props = {
  handleCloseDialog: () => void;
  handleSubmit: () => void;
};

export const AwayGeneration = memo(
  ({ handleCloseDialog, handleSubmit }: Props): JSX.Element => {
    return (
      <>
        <StyledGridCenter style={{ justifyContent: 'center' }}>
          <Box
            style={{
              display: 'grid',
              justifyContent: 'center',
              textAlign: 'center',
              width: '360px',
              height: '128px',
              alignItems: 'center',
            }}
          >
            <Box style={{ display: 'grid', gap: '24px' }}>
              <StyledFont16Bold>
                この画面を離れても宜しいですか？
              </StyledFont16Bold>
              <Box style={{ fontSize: '14px' }}>
                生成していないセットのデータは
                <br />
                すべてクリアされます
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              display: 'grid',
              justifyContent: 'center',
              textAlign: 'center',
              height: '60px',
            }}
          >
            <StyledGridGap20>
              <StyledFlexEndGap5>
                <Button
                  mode="alertBorder"
                  onClick={handleCloseDialog}
                  style={{ width: '118px', height: '36px', fontSize: '14px' }}
                >
                  キャンセル
                </Button>
                <Button
                  mode="alert"
                  onClick={handleSubmit}
                  style={{ width: '118px', height: '36px', fontSize: '14px' }}
                >
                  画面を離れる
                </Button>
              </StyledFlexEndGap5>
            </StyledGridGap20>
          </Box>
        </StyledGridCenter>
        <StyledBoxHeight24 />
      </>
    );
  },
);
