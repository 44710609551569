import { motion } from 'framer-motion';
import { memo } from 'react';

export type Props = {
  duration?: number;
  children: React.ReactNode;
};

/**
 * JSDoc
 * @see フェードインを行うコンポーネント
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const FadeIn = memo(({ duration = 0.5, children }: Props) => (
  <motion.div
    initial={{ opacity: 0, scale: 1 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 1 }}
    transition={{ duration }}
  >
    {children}
  </motion.div>
));
