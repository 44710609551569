import { createTheme } from '@mui/material';
import { blue, orange, red, teal } from '@mui/material/colors';

/**
 * JSDoc
 * @see Muiテーマを定義している
 */
export const theme = createTheme({
  palette: {
    primary: {
      pale: blue[50],
      light: blue[200],
      soft: blue[300],
      main: blue[400],
      deep: blue[500],
      dark: blue[600],
    },
    secondary: {
      pale: '#eeeeee',
      light: '#dddddd',
      soft: '#cccccc',
      main: '#999999',
      deep: '#666666',
      dark: '#333333',
    },
    gray: {
      white: '#ffffff',
      pale: '#eeeeee',
      light: '#dddddd',
      soft: '#cccccc',
      main: '#999999',
      deep: '#666666',
      dark: '#333333',
      black: '#000000',
    },
    danger: {
      pale: red[100],
      light: red[300],
      soft: red[500],
      main: red[700],
      deep: red[800],
      dark: red[900],
    },
    alert: {
      pale: orange[100],
      light: orange[300],
      soft: orange[500],
      main: orange[700],
      deep: orange[800],
      dark: orange[900],
    },
    teal: {
      pale: teal[100],
      light: teal[200],
      soft: teal[300],
      main: teal[500],
      deep: teal[600],
      dark: teal[700],
    },
  },
  breakpoints: {
    values: {
      xs: 430,
      sm: 650,
      md: 1280,
      lg: 1400,
      xl: 1600,
    },
  },
  space: ['0px', '8px', '16px', '32px', '64px'],
  fontSize: {
    xxxs: '10px',
    xxs: '11px',
    xs: '12px',
    sm: '13px',
    md: '14px',
    mdl: '16px',
    lg: '18px',
    xl: '20px',
    xxl: '22px',
    xxxl: '24px',
  },
  letterSpacing: ['0.06px', '0.07px', '0.08px', '0.09px', '0.1px', '0.1px'],
  lineHeight: ['17px', '19px', '22px', '26px', '28px', '37px', '43px'],
  typography: {
    body1: {
      fontSize: 14,
      fontFamily: 'Noto Sans JP',
      fontWeight: 400,
    },
    fontSize: 14,
    fontFamily: 'Noto Sans JP',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Noto Sans JP';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
        }
      `,
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});
