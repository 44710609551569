import { motion } from 'framer-motion';
import { memo } from 'react';

export type Props = {
  isFadeOut?: boolean;
  duration?: number;
  children: React.ReactNode;
  onAnimationComplete?: () => void;
};

/**
 * JSDoc
 * @see フェードアウトを行うコンポーネント
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const FadeOut = memo(
  ({
    isFadeOut = false,
    duration = 0.15,
    children,
    onAnimationComplete,
  }: Props) => (
    <>
      {isFadeOut ? (
        <motion.div
          animate={{ opacity: 0 }}
          transition={{ duration, type: 'tween' }}
          onAnimationComplete={onAnimationComplete}
        >
          {children}
        </motion.div>
      ) : (
        <>{children}</>
      )}
    </>
  ),
);
