import { Box } from '@/components/atoms/Box';
import { ColorPicker } from '@/components/molecules/ColorPicker';
import {
  StylePopperColorCancelButton,
  StylePopperColorOkButton,
} from '@/components/styled/button';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { memo, useCallback } from 'react';
import {
  StyledButtonBox,
  StyledColorBox,
  StyledPickerContainer,
} from './styled';

type Props = {
  newColor: string | undefined;
  currentColor: string | undefined;
  isOpenColorPicker: boolean;
  onChangeNewColor: (color: string | undefined) => void;
  onChangeCurrentColor: (color: string | undefined) => void;
  onClose: () => void;
};
export const PopupColor = memo(
  ({
    newColor,
    currentColor,
    isOpenColorPicker,
    onChangeNewColor: handleChangeNewColor,
    onChangeCurrentColor: handleChangeCurrentColor,
    onClose: handleClose,
  }: Props) => {
    const { featureData } = useFeaturesContext({});

    const handleCancel = useCallback(() => {
      if (currentColor) {
        handleChangeNewColor(currentColor);
      }
      if (!currentColor) {
        handleChangeNewColor('');
        handleChangeCurrentColor('');
      }
      handleClose();
    }, [
      currentColor,
      handleChangeCurrentColor,
      handleChangeNewColor,
      handleClose,
    ]);

    const handleSubmit = useCallback(() => {
      if (newColor) {
        handleChangeCurrentColor(newColor);
      }
      handleClose();
    }, [handleChangeCurrentColor, handleClose, newColor]);

    return (
      <Box sx={{padding: '20px 24px 20px', width: 348}}>
        {isOpenColorPicker && (
          <StyledPickerContainer>
            <StyledColorBox>
              <ColorPicker
                currentColor={currentColor}
                onChangeNewColor={handleChangeNewColor}
              />
            </StyledColorBox>
            <StyledButtonBox sx={{marginTop: '20px'}}>
              <StylePopperColorCancelButton onClick={handleCancel}>
                キャンセル
              </StylePopperColorCancelButton>
              <StylePopperColorOkButton
                onClick={handleSubmit}
                disabled={!featureData?.single?.param?.newColor}
              >
                OK
              </StylePopperColorOkButton>
            </StyledButtonBox>
          </StyledPickerContainer>
        )}
      </Box>
    );
  },
);
