import { Box } from '@/components/atoms/Box';
import { StyledFont16, StyledFont16Bold } from '@/components/styled';
import { memo } from 'react';

type Props = {
  setName?: string;
  isCollectiveSetting: boolean;
};
export const SetName = memo(
  ({ setName, isCollectiveSetting }: Props): JSX.Element => {
    return (
      <>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            paddingBottom: '5px',
          }}
        >
          {!isCollectiveSetting ? (
            <Box
              sx={{
                maxWidth: 260,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              <StyledFont16>選択中：</StyledFont16>
              <StyledFont16Bold>{setName}</StyledFont16Bold>
            </Box>
          ) : (
            <>
              <StyledFont16>選択中：</StyledFont16>
              <StyledFont16Bold>全てのセット（一括設定）</StyledFont16Bold>
            </>
          )}
        </Box>
      </>
    );
  },
);
