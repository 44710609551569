import { useCallback, useEffect } from 'react';

type Props = {
  // biome-ignore lint/suspicious/noExplicitAny: あとで修正
  swiperRef: any;
  // biome-ignore lint/suspicious/noExplicitAny: あとで修正
  setSwiperRef: (swiper: any) => void;
  swiperIndex: number;
  setSwiperIndex: (index: number) => void;
};
export const useSwiperState = ({
  swiperRef,
  setSwiperRef,
  swiperIndex,
  setSwiperIndex,
}: Props) => {
  const handleGetActiveSwiperIndex = useCallback(
    // biome-ignore lint/suspicious/noExplicitAny: あとで修正
    (swiper: any) => {
      setSwiperIndex(swiper.realIndex);
    },
    [setSwiperIndex],
  );

  const handleChangeSlide = useCallback(
    (i: number) => {
      if (!swiperRef) return;
      swiperRef.slideToLoop(i);
      setSwiperIndex(i);
    },
    [setSwiperIndex, swiperRef],
  );

  useEffect(() => {
    if (!swiperRef) return;
    if (swiperIndex) return;
    swiperRef.slideToLoop(0);
    setSwiperIndex(0);
  }, [setSwiperIndex, swiperIndex, swiperRef]);

  return {
    swiperRef,
    setSwiperRef,
    handleGetActiveSwiperIndex,
    handleChangeSlide,
  };
};
