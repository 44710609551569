import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledTaskTrayContainer = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledTaskTrayContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledTaskTrayContentBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledTaskTrayContentBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledTaskTrayFooterBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledTaskTrayFooterBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledTaskTrayFooterFixed = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledTaskTrayFooterFixed} {...props}>
        {children}
      </Box>
    );
  },
);
