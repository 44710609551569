import Image from "next/image";
import { useMemo } from "react";
import { thumbnailStyle } from "./SelectableThumbnail.css";

type SelectableThumbnailProps = {
  originalImageSrc: string | undefined;
  maskImageSrc: string | undefined;
  displayImageType: 'original' | 'mask';
  isSelected: boolean;
  onClick: () => void;
};

// MEMO: デザイン修正があったため急遽チェックマークを削除した。コンポーネントとして最適化できていないため、必要があれば整備する。
// TODO: Swiperと一緒に使われているサムネイルとは別物。共通化できそうなので可能であれば対応する。
export const SelectableThumbnail = ({
  originalImageSrc,
  maskImageSrc,
  displayImageType,
  isSelected,
  onClick,
}: SelectableThumbnailProps) => {
  const displaySrc = useMemo(() => {
    if (displayImageType === 'original' && originalImageSrc)
      return originalImageSrc;

    if (displayImageType === 'mask' && maskImageSrc)
      return maskImageSrc;

    return originalImageSrc;
  }, [originalImageSrc, maskImageSrc, displayImageType]);

  return (
    <div
      className={isSelected ? thumbnailStyle.isSelected : thumbnailStyle.default}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onClick}
    >
      { displaySrc && (
        <Image
          src={displaySrc}
          width={120}
          height={120}
          alt=""
        />
      )}
    </div>
  )
}
