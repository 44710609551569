import { Box } from '@/components/atoms/Box';
import {
  StyledBoxTextAlignLeft,
  StyledFont16Error,
  StyledFontError,
  StyledGridGap10,
} from '@/components/styled';
import { ErrorCard } from '@/features/components/atoms/ErrorCard';
import { memo } from 'react';

type Props = { errorFiles: File[]; exceededFiles: File[] };

/**
 * JSDoc
 * @see アップロードエラーカード
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const UploadedErrorCard = memo(
  ({ errorFiles, exceededFiles }: Props): JSX.Element => {
    return (
      <>
        {(errorFiles.length > 0 || exceededFiles.length > 0) && (
          <ErrorCard
            errorElement={
              <StyledGridGap10>
                <StyledBoxTextAlignLeft>
                  {errorFiles.length > 0 && (
                    <>
                      <Box>
                        {errorFiles.map((f) => (
                          <Box key={f.name}>
                            <StyledFont16Error>{f.name}</StyledFont16Error>
                          </Box>
                        ))}
                      </Box>
                      <StyledFontError>
                        このファイルは使用出来ません
                      </StyledFontError>
                    </>
                  )}
                  {exceededFiles.length > 0 && (
                    <>
                      <Box>
                        {exceededFiles.map((f) => (
                          <Box key={f.name}>
                            <StyledFont16Error>{f.name}</StyledFont16Error>
                          </Box>
                        ))}
                      </Box>
                      <StyledFontError>
                        ファイル容量が5MBを越えています
                      </StyledFontError>
                    </>
                  )}
                </StyledBoxTextAlignLeft>
              </StyledGridGap10>
            }
            hasIcon
            padding="20px"
            color={'var(--color-danger-main)'}
          />
        )}
      </>
    );
  },
);
