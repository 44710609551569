import { memo } from 'react';
import { BatchDownloadRow } from './BatchDownloadRow';
import { DownloadManagementList } from './DownloadManagementList';
import { TaskList } from './TaskList';
import { TaskTray } from './TaskTray';
import { UserList } from './UserList';

type Props = {
  mode: string;
};
export const Skelton = memo(({ mode }: Props) => {
  return (
    <>
      {mode === 'taskList' && <TaskList />}
      {mode === 'taskTray' && <TaskTray />}
      {mode === 'downloadManagementList' && <DownloadManagementList />}
      {mode === 'userList' && <UserList />}
      {mode === 'batchDownloadRow' && <BatchDownloadRow />}
    </>
  );
});
