import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './grid.css';

export const StyledGrid = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledGrid} {...props}>
      {children}
    </Box>
  );
});

export const StyledGridAlignStart = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledGridAlignStart} {...props}>
      {children}
    </Box>
  );
});

export const StyledGridNowrap = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledGridNowrap} {...props}>
      {children}
    </Box>
  );
});

export const StyledGridGap5 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledGridGap5} {...props}>
      {children}
    </Box>
  );
});

export const StyledGridGap8 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledGridGap8} {...props}>
      {children}
    </Box>
  );
});

export const StyledGridGap10 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledGridGap10} {...props}>
      {children}
    </Box>
  );
});

export const StyledGridGap15 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledGridGap15} {...props}>
      {children}
    </Box>
  );
});

export const StyledGridGap20 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledGridGap20} {...props}>
      {children}
    </Box>
  );
});

export const StyledGridGap25 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledGridGap25} {...props}>
      {children}
    </Box>
  );
});

export const StyledGridEnd = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledGridEnd} {...props}>
      {children}
    </Box>
  );
});

export const StyledGridCenter = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledGridCenter} {...props}>
      {children}
    </Box>
  );
});

export const StyledGridCenterGap10 = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledGridCenterGap10} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledGridCenterGap15 = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledGridCenterGap15} {...props}>
        {children}
      </Box>
    );
  },
);
