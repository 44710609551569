import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledImageSetContainer = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledImageSetContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledImageSetThumbnailBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledImageSetThumbnailBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledImageSetThumbnailCenter = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledImageSetThumbnailCenter} {...props}>
        {children}
      </Box>
    );
  },
);
