import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

export const openState = {
  display: 'すべて閉じる',
  icon: <MdKeyboardArrowUp size={20} />,
};

export const closeState = {
  display: 'すべて開く',
  icon: <MdKeyboardArrowDown size={20} />,
};
