import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledPreviewContainer = memo(({ children }: BoxProps) => {
  return <Box className={vanilla.styledPreviewContainer}>{children}</Box>;
});

export const StyledGlobalViewerPreviewBox = memo(({ children }: BoxProps) => {
  return <Box className={vanilla.styledGlobalViewerPreviewBox}>{children}</Box>;
});
