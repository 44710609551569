import { TaskOriginalImage } from "@/api/schemas";
import { Dialog } from "@/components/molecules/Dialog";
import { useCallback } from "react";
import { useSamDialog } from "../Functions/hooks/useSamDialog";
import { dialogContainerStyle } from "./SamDialog.css";
import { Segmentation } from "./Segmentation";

type Props = {
  isOpenDialog: boolean;
  handleCloseDialog: () => void;
  handleSubmitMaskImages: () => void;
  dialogTitle: string;
  selectedImage: TaskOriginalImage | undefined;
  selectedImageSize: { width: number; height: number; bytes: number; } | undefined
};

// Segmentation自体のロジックが大きいのでここにビジネスロジックを集約する
export const SamDialog = ({
  isOpenDialog,
  handleCloseDialog,
  handleSubmitMaskImages,
  dialogTitle,
  selectedImage,
  selectedImageSize,
}: Props) => {

  const {
    positivePoints,
    setPositivePoints,
    negativePoints,
    setNegativePoints,
    apiPositivePoints,
    setApiPositivePoints,
    apiNegativePoints,
    setApiNegativePoints,
    isPreviewCompleted,
    isPreviewLoading,
    setIsPreviewCompleted,
    setIsPreviewLoading,
    handleRequestPreview,
    samType,
    setSamType,
    pointType,
    setPointType,
    handleToggleSwitch,
    initializeSamDialogState,
  } = useSamDialog();

  const onClose = useCallback(() => {
    initializeSamDialogState()
    handleCloseDialog();
  }, [
    initializeSamDialogState,
    handleCloseDialog,
  ])

  const onSubmit = useCallback(() => {
    handleSubmitMaskImages()
    initializeSamDialogState()
    handleCloseDialog();
  }, [
      handleSubmitMaskImages,
      initializeSamDialogState,
      handleCloseDialog,
  ])

  return (
    <Dialog
      maxWidth="xl"
      isOpenDialog={isOpenDialog}
      onClose={onClose}
      onSubmit={onSubmit}
      dialogTitle={dialogTitle}
      hasCloseIcon
    >
      <div className={dialogContainerStyle}
        style={{ marginTop: '-30px' }}
      >
        <Segmentation
          onSubmit={onSubmit}
          selectedImageSize={selectedImageSize}
          positivePoints={positivePoints}
          negativePoints={negativePoints}
          apiPositivePoints={apiPositivePoints}
          apiNegativePoints={apiNegativePoints}
          samType={samType}
          pointType={pointType}
          isPreviewLoading={isPreviewLoading}
          isPreviewCompleted={isPreviewCompleted}
          setSamType={setSamType}
          setPointType={setPointType}
          setPositivePoints={setPositivePoints}
          setNegativePoints={setNegativePoints}
          setApiPositivePoints={setApiPositivePoints}
          setApiNegativePoints={setApiNegativePoints}
          setIsPreviewLoading={setIsPreviewLoading}
          setIsPreviewCompleted={setIsPreviewCompleted}
          handleRequestPreview={handleRequestPreview}
          handleToggleSwitch={handleToggleSwitch}
          initializeSamDialogState={initializeSamDialogState}
        />
      </div>
    </Dialog>
  )
}
