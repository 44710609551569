import { memo } from 'react';

type Props = {
  color?: string;
};
const BackgroundIcon = memo(({ color = '#fff' }: Props): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
      <path
        fill={color}
        fillRule="evenodd"
        d="M15.998 21v-2a5.997 5.997 0 0 1-6-6 5.997 5.997 0 0 1 7.64-5.769l6.228-6.229h-4.254l-3.614 3.615V1.002H3a2 2 0 0 0-2 2v25.996a2 2 0 0 0 2 2h2.044c.505-5.605 5.215-9.999 10.954-9.999Zm14.997-10.14V6.608L16.577 21.024c1.23.065 2.41.32 3.5.755l10.918-10.918Zm-4.66 17.403c.316.865.53 1.78.616 2.734h.904L31 27.853v-4.254l-4.664 4.664Zm4.575-25.81a2.004 2.004 0 0 0-.672-1.01.536.536 0 0 0-.058-.046 3.149 3.149 0 0 0-.165-.11c-.05-.03-.1-.06-.155-.085-.05-.025-.1-.045-.15-.065-.07-.025-.14-.05-.215-.065a1.642 1.642 0 0 1-.062-.017l-.063-.017a1.82 1.82 0 0 0-.37-.036h-.884l-7.814 7.814c.73.75 1.26 1.685 1.52 2.735l9.088-9.099Zm.085 16.902-6.134 6.133c-.6-.814-1.3-1.54-2.095-2.16l8.229-8.228v4.255Z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default BackgroundIcon;
