/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@/components/atoms/Box';
import { BigChecked, BigUnChecked } from '@/components/icons/others';
import { StyledFlexCenterExpanded } from '@/components/styled';
import { ImageResult } from '@/contexts/FeaturesContext/types';
import { useSwiperState } from '@/features/components/organisms/GlobalViewer/hooks/useSwiperState';
import {
  StyledPreview,
  StyledPreviewBox,
  StyledPreviewSlide
} from '@/features/components/styled/preview';
import { useCheckArray } from '@/hooks/global/useCheckArray';
import { useGetResizedSize } from '@/hooks/utils/useGetResizedSize';
import { CircularProgress } from '@mui/material';
import hexToRgba from 'hex-to-rgba';
import { memo, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Thumbs } from 'swiper/modules';
import { StyledSwiperContainer } from './styled';

type Props = {
  imageResults: ImageResult[];
  // biome-ignore lint/suspicious/noExplicitAny:
  swiperRef: any;
  // biome-ignore lint/suspicious/noExplicitAny:
  setSwiperRef: (swiper: any) => void;
  swiperIndex: number;
  setSwiperIndex: (index: number) => void;
  repeatBackgroundImage?: string;
  taskType?: string;
  isDownloadDialog?: boolean;
};

/**
 * JSDoc
 * @see 写真スワイパーの汎用コンポーネント
 * @see プレビュー等で使用
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const Swiper = memo(
  ({
    imageResults,
    swiperRef,
    setSwiperRef,
    swiperIndex,
    setSwiperIndex,
    repeatBackgroundImage,
    taskType,
    isDownloadDialog,
  }: Props) => {
    console.log(taskType);
    const { handleGetActiveSwiperIndex } = useSwiperState({
      swiperRef,
      setSwiperRef,
      swiperIndex,
      setSwiperIndex,
    });
    /**
     * @see 2024-06-08に追加
     * 現状色温度の結果には参照画像があるため、スワイパーの幅は512pxにする
     * 以降のバッチに参照画像がないモードもあるので、その場合の幅は660pxにする
     * 将来的にもう少し簡潔な書き方をする
     */
    const { imageWidth, imageHeight } = useGetResizedSize({
      imageUrl: imageResults[0].url,
      maxWidth: 512,
      maxHeight: 600,
    });

    const { findDownloaded, checkIndex, checkArray } = useCheckArray();
    const [isImageLoading, setIsImageLoading] = useState(true);

    return (
      <StyledSwiperContainer>
        <Box style={{ width: 512 }}>
          <StyledPreviewBox id="slider">
            <StyledPreview
              onSwiper={(swiper) => {
                setSwiperRef(swiper);
              }}
              onSlideChangeTransitionEnd={handleGetActiveSwiperIndex}
              grabCursor
              centeredSlides
              slidesPerView="auto"
              spaceBetween={30}
              navigation
              modules={[Navigation, Thumbs]}
              swiperIndex={swiperIndex}
              maxIndex={imageResults.length - 1}
              className="mySwiper"
              width={512}
              style={{
                width: 512,
              }}
            >
              <>
                {imageResults.map((imageResult: ImageResult, i: number) => (
                  <StyledPreviewSlide
                    key={`image-${imageResult.url}-${i}`.toString()}
                    style={{
                      width: 512,
                    }}
                  >
                    {isDownloadDialog && (
                      <Box
                        style={{
                          position: 'absolute',
                          right: 5,
                          bottom: 5,
                          zIndex: 20,
                        }}
                        onClick={() => {
                          checkIndex(i);
                        }}
                      >
                        {checkArray[i] && (
                          <Box style={{ cursor: 'pointer' }}>
                            <BigChecked />
                          </Box>
                        )}
                        {!checkArray[i] && (
                          <Box
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              checkIndex(i);
                            }}
                          >
                            <BigUnChecked />
                          </Box>
                        )}
                      </Box>
                    )}
                    {findDownloaded(i) && (
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '4px',
                          position: 'absolute',
                          zIndex: 10,
                          left: 0,
                          top: 0,
                          backgroundColor: hexToRgba('#fff', '0.5'),
                          color: '#000',
                          fontSize: 12,
                          fontWeight: 512,
                          padding: '4px',
                        }}
                      >
                        <Box
                          style={{
                            width: 10,
                            height: 10,
                            backgroundColor: '#545454',
                            border: '1px solid #fff',
                            borderRadius: '50%',
                          }}
                        />
                        ダウンロード済み
                      </Box>
                    )}
                    <StyledFlexCenterExpanded>
                      { isImageLoading &&
                        <CircularProgress data-testid="spinner" sx={{ width: 32, height: 32 }} />
                      }
                      <img
                        src={imageResult.url}
                        width={imageWidth}
                        height={imageHeight}
                        style={{
                          objectFit: 'contain',
                          backgroundImage:
                            repeatBackgroundImage && imageWidth && imageHeight
                              ? `url(${repeatBackgroundImage})`
                              : '',
                        }}
                        alt=""
                        onLoad={() => {setIsImageLoading(false)}}
                      />
                    </StyledFlexCenterExpanded>
                  </StyledPreviewSlide>
                ))}
              </>
            </StyledPreview>
          </StyledPreviewBox>
        </Box>
      </StyledSwiperContainer>
    );
  },
);
