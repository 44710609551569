import { memo } from 'react';

type Props = {
  color?: string;
};
const ColorIcon = memo(({ color = '#fff' }: Props): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
      <path
        fill={color}
        d="M9.956 19.621a7.04 7.04 0 0 1-.792-1.91H1a15.023 15.023 0 0 0 3.183 7.684l5.773-5.773ZM14.288 9.165V1a15.023 15.023 0 0 0-7.684 3.183l5.773 5.773a7.041 7.041 0 0 1 1.91-.792l.001.001ZM25.395 4.183A15.023 15.023 0 0 0 17.711 1v8.165c.681.17 1.323.44 1.91.792l5.773-5.773h.001ZM9.165 14.288c.17-.681.44-1.323.792-1.91L4.183 6.604A15.023 15.023 0 0 0 1 14.288h8.165ZM22.043 12.377c.352.587.62 1.229.792 1.91H31a15.023 15.023 0 0 0-3.183-7.684l-5.773 5.773h-.001ZM22.834 17.71a6.98 6.98 0 0 1-.792 1.911l5.773 5.773a15.028 15.028 0 0 0 3.183-7.684h-8.165ZM19.621 22.043a7.042 7.042 0 0 1-1.91.792V31a15.023 15.023 0 0 0 7.684-3.183l-5.773-5.773v-.001ZM14.288 22.834a6.98 6.98 0 0 1-1.91-.792l-5.773 5.773a15.028 15.028 0 0 0 7.684 3.183v-8.165h-.001Z"
      />
    </svg>
  );
});
export default ColorIcon;
