import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useBatchConstant } from '@/features/components/steps/hooks/useBatchConstant';
import { useBatchCurrent } from '@/features/components/steps/hooks/useBatchCurrent';
import { useCallback, useMemo } from 'react';

export const useBatchSetting = () => {
  const {
    featureData,
    updateFeatureData,
    updateFeatureDataSingle,
    updateFeatureDataArray,
    activateTargetFeature,
    initialFeatureDataParamState,
  } = useFeaturesContext({});
  const { batchConstant } = useBatchConstant();
  const { currentNumber, currentId, currentState } = useBatchCurrent();

  const isBatchLimitReached = useMemo(() => {
    if (!featureData) return false;
    if (initialFeatureDataParamState?.batchLimitImages === undefined) {
      throw new Error("initialFeatureDataParamState?.batchLimitImages is not defined");
    }

    return featureData.batch.length >= initialFeatureDataParamState?.batchLimitImages;
  }, [featureData, initialFeatureDataParamState?.batchLimitImages]);

  const checkUploadImageCondition = useCallback(
    (id: string) => {
      if (!featureData || !batchConstant) return false;
      const found = featureData?.batch.find((set) => set.id === id);
      if (!found) return false;
      if (batchConstant.hasUploadRef) {
        return found.refSam.combinedBase64;
      }
      if (!batchConstant.hasUploadRef) {
        return found.originalImages.length > 0;
      }

      return false;
    },
    [batchConstant, featureData],
  );

  return {
    batchConstant,
    featureData,
    updateFeatureData,
    updateFeatureDataSingle,
    updateFeatureDataArray,
    activateTargetFeature,
    currentNumber,
    currentId,
    currentState,
    checkUploadImageCondition,
    isBatchLimitReached,
  };
};
