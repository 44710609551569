import { z } from 'zod';

const DownloadItemSchema = z.object({
  category: z.string(),
  count: z.number(),
});

const GenerationItemSchema = z.object({
  category: z.string(),
  count: z.number(),
});

const DownloadSchema = z.object({
  total: z.number(),
  items: z.array(DownloadItemSchema).optional().nullable(),
  limit: z.union([z.number(), z.null()]),
});

const GenerationSchema = z.object({
  total: z.number(),
  items: z.array(GenerationItemSchema).optional().nullable(),
  limit: z.union([z.number(), z.null()]),
});

export const ReportSchema = z.object({
  organizationId: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  totalUsers: z.number(),
  invoiceAmount: z.number(),
  totalUnits: z.number(),
  freeUnits: z.number(),
  download: DownloadSchema,
  generation: GenerationSchema,
});

export type DownloadItem = z.infer<typeof DownloadItemSchema>;
export type GenerationItem = z.infer<typeof GenerationItemSchema>;
export type Download = z.infer<typeof DownloadSchema>;
export type Generation = z.infer<typeof GenerationSchema>;
export type Report = z.infer<typeof ReportSchema>;
