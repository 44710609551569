import { Box } from '@/components/atoms/Box';
import { StyledFlexCenter, StyledFont } from '@/components/styled';
import {
  StyledTableRowContainer,
  StyledTableRowName,
} from '@/features/components/styled/batch';
import { memo } from 'react';

export const ColorTemperatureHead = memo((): JSX.Element => {
  return (
    <>
      <StyledTableRowContainer
        style={{ borderTop: 0, gridTemplateColumns: '1fr 1fr 1fr 1fr' }}
      >
        <StyledTableRowName>
          <StyledFont>画像セット</StyledFont>
        </StyledTableRowName>
        <Box style={{ minWidth: 150, whiteSpace: 'nowrap' }}>
          <StyledFont>アップロード日時</StyledFont>
        </Box>
        <StyledFlexCenter style={{ minWidth: 150, whiteSpace: 'nowrap' }}>
          <StyledFont>ファイル数</StyledFont>
        </StyledFlexCenter>
        <Box style={{ minWidth: 'calc(100% - 700px)', whiteSpace: 'nowrap' }}>
          <StyledFont>ステータス</StyledFont>
        </Box>
      </StyledTableRowContainer>
    </>
  );
});
