import { useBackendApi } from '@/api';
import { Segmentation } from '@/api/schemas/segmentation';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageFields } from '@/contexts/FeaturesContext/types';
import { useError } from '@/hooks/global/useError';
import { useImageMasking } from '@/hooks/utils/useImageMasking';
import { useCallback, useEffect, useState } from 'react';

type Props = {
  field: ImageFields;
  isKeepMask?: boolean;
};

type PostAndProcessImageResponse = Segmentation & {
  id: string,
  maskId: string,
}

/**
 * @see セグメンテーションのプレビューをリクエストするカスタムフック
 */
export const useRequestPreview = ({ field, isKeepMask }: Props) => {
  const { getImageMasking } = useImageMasking();

  const positiveKey = !isKeepMask ? 'positivePoints' : 'kmPositivePoints';
  const negativeKey = !isKeepMask ? 'negativePoints' : 'kmNegativePoints';
  const apiPositiveKey = !isKeepMask
    ? 'apiPositivePoints'
    : 'kmApiPositivePoints';
  const apiNegativeKey = !isKeepMask
    ? 'apiNegativePoints'
    : 'kmApiNegativePoints';
  const isPreviewLoadingKey = !isKeepMask
    ? 'isPreviewLoading'
    : 'isKmPreviewLoading';
  const isPreviewCompletedKey = !isKeepMask
    ? 'isPreviewCompleted'
    : 'isKmPreviewCompleted';
  const maskBase64Key = !isKeepMask ? 'maskBase64' : 'keepMaskBase64';
  const maskIdKey = !isKeepMask ? 'maskId' : 'keepMaskId';
  const combinedBase64Key = !isKeepMask ? 'combinedBase64' : 'keepMaskCombinedBase64';

  const { logger } = useError();
  const {
    activeFeatureName,
    featureData,
    updateFeatureData,
    updateFeatureDataSingle,
    initialFeatureDataGenResultState,
    initialFeatureDataGenStatusState,
  } = useFeaturesContext({});
  const [apiResponse, setApiResponse] = useState<PostAndProcessImageResponse | undefined>(
    undefined,
  );
  const { postImages, postSegmentationAnything } = useBackendApi({
    isInverseResult: !!(
      activeFeatureName === 'background' ||
      activeFeatureName === 'white' ||
      activeFeatureName === 'backgroundLora'
    ),
  });

  const postAndProcessImage = useCallback(async () => {
    // MEMO: mainImageのfileNameは複数背景では保存していないのでfileNameが空でも実行する（保存していないことで問題が出ないかどうかは不明なので、問題があれば修正する）
    if (
      !featureData?.[field].base64
    ) return undefined

    const {base64, fileName} = featureData[field]

    const postMainImagesResult = await postImages({
      image: base64,
      fileName,
    });

    const postSegmentationResult = await postSegmentationAnything(
      base64 || '',
      featureData?.[field][apiPositiveKey] || [],
      featureData?.[field][apiNegativeKey] || [],
    )

    const postMaskImagesResult = await postImages({
      image: postSegmentationResult?.maskImageBase64,
      fileName: `${fileName}-mask`,
    });

    if (
      !postMainImagesResult?.id
      || !postMaskImagesResult?.id
      || !postSegmentationResult?.maskImageBase64
      || !postSegmentationResult?.maskOverlayImageBase64
    ) {
      throw new Error("Response value is invalid");
    }

    const response = {
      ...postSegmentationResult,
      id: postMainImagesResult.id,
      maskId: postMaskImagesResult.id,
    }

    return response
  }, [
    apiNegativeKey,
    apiPositiveKey,
    featureData,
    field,
    postImages,
    postSegmentationAnything
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const handleRequestPreview = useCallback(async (): Promise<void> => {
    try {
      updateFeatureDataSingle('genResult', initialFeatureDataGenResultState);
      updateFeatureDataSingle('genStatus', initialFeatureDataGenStatusState);
      updateFeatureData(field, {
        [isPreviewLoadingKey]: true,
        [maskBase64Key]: '',
        [maskIdKey]: '',
        [combinedBase64Key]: '',
      });

      const updateSet = await postAndProcessImage();
      setApiResponse(updateSet);
    } catch (error) {
      logger({ error });
    }
  }, [
    featureData?.[field].base64,
    featureData?.[field][apiPositiveKey],
    featureData?.[field][apiNegativeKey],
    postSegmentationAnything,
    postImages,
  ]);

  const handleClearPoints = useCallback((): void => {
    updateFeatureData(field, {
      [positiveKey]: [],
      [negativeKey]: [],
      [isPreviewLoadingKey]: false,
      [isPreviewCompletedKey]: false,
      [maskBase64Key]: '',
      [maskIdKey]: '',
      [combinedBase64Key]: '',
    });
    if (!isKeepMask) {
      updateFeatureDataSingle('param', {
        newRef: '',
        currentRef: '',
      });
    }

    setApiResponse(undefined);
    updateFeatureDataSingle('genResult', initialFeatureDataGenResultState);
    updateFeatureDataSingle('genStatus', initialFeatureDataGenStatusState);

    if (isKeepMask) {
      updateFeatureDataSingle('param', {
        keepMaskImage: '',
      });
      // 固定アイテムがstep間を跨ぐためフォースクリアを設定（命名は今度考える）
      updateFeatureDataSingle('genStatus', {
        isForceClear: true,
      });
    }
  }, [
    combinedBase64Key,
    field,
    initialFeatureDataGenResultState,
    initialFeatureDataGenStatusState,
    isKeepMask,
    isPreviewCompletedKey,
    isPreviewLoadingKey,
    maskBase64Key,
    maskIdKey,
    negativeKey,
    positiveKey,
    updateFeatureData,
    updateFeatureDataSingle,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: featureDataを依存配列に入れると二回目以降のセグメンテーションが効かないためdisableにする
  useEffect(() => {
    if (!apiResponse) return;
    if (featureData?.[field][combinedBase64Key]) return;

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      const combinedBase64 = (await getImageMasking({
        mainImageSource: featureData?.[field].base64 || '',
        maskImageSource: apiResponse.maskImageBase64,
        color: !isKeepMask ? '#ff0000' : '#0000ff',
      })) as unknown as string;

      // TODO: 固定アイテムセグメンテーションをアップロードしたとき（maskIdKeyが `keepMaskId` のとき）、本来はrefImageを更新すべきだが、mainImageとして扱ってしまっている。バックエンドのレスポンスと齟齬が出てしまっているので修正する。
      // TODO: アイテム生成について、ギャラリーからの生成も修正が必要？要確認
      updateFeatureData(field, {
        [isPreviewLoadingKey]: false,
        [isPreviewCompletedKey]: true,
        [maskBase64Key]: apiResponse.maskImageBase64,
        [maskIdKey]: apiResponse.maskId,
        [combinedBase64Key]: combinedBase64,
        id: apiResponse.id,
      });
    })();
  }, [
    apiResponse,
    combinedBase64Key,
    field,
    getImageMasking,
    isKeepMask,
    isPreviewCompletedKey,
    isPreviewLoadingKey,
    maskBase64Key,
    maskIdKey,
    updateFeatureData,
  ]);

  return {
    handleRequestPreview,
    handleClearPoints,
  };
};
