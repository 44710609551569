import { PageInfo, Tasks } from '@/api/schemas';
import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';

type TasksCounter = {
  inQueue: number;
  inProgress: number;
  completed: number;
  failed: number;
  total: number;
  pageInfo: PageInfo;
};

const initialTaskCounter: TasksCounter = {
  inQueue: 0,
  inProgress: 0,
  completed: 0,
  failed: 0,
  total: 0,
  pageInfo: {
    total: 0,
    offset: 0,
    limit: 0,
    count: 0,
  },
};

const taskTrayCounterAtom = atom<TasksCounter>(initialTaskCounter);
const taskListCounterAtom = atom<TasksCounter>(initialTaskCounter);

export const useTasksCounter = () => {
  const [taskTrayCounter, setTaskTrayCounter] = useAtom(taskTrayCounterAtom);
  const [taskListCounter, setTaskListCounter] = useAtom(taskListCounterAtom);

  const updateTaskTrayCounter = useCallback(
    (apiResponse: Tasks) => {
      const inQueue = apiResponse.data.filter(
        (task) => task.result.status === 'IN_QUEUE',
      ).length;
      const inProgress = apiResponse.data.filter(
        (task) => task.result.status === 'IN_PROGRESS',
      ).length;
      const completed = apiResponse.data.filter(
        (task) => task.result.status === 'COMPLETED',
      ).length;
      const failed = apiResponse.data.filter(
        (task) => task.result.status === 'FAILED',
      ).length;
      const total = apiResponse.data.length;
      setTaskTrayCounter({
        inQueue,
        inProgress,
        completed,
        failed,
        total,
        pageInfo: apiResponse.pageInfo,
      });
    },
    [setTaskTrayCounter],
  );

  const updateTaskListCounter = useCallback(
    (apiResponse: Tasks) => {
      const inQueue = apiResponse.data.filter(
        (task) => task.result.status === 'IN_QUEUE',
      ).length;
      const inProgress = apiResponse.data.filter(
        (task) => task.result.status === 'IN_PROGRESS',
      ).length;
      const completed = apiResponse.data.filter(
        (task) => task.result.status === 'COMPLETED',
      ).length;
      const failed = apiResponse.data.filter(
        (task) => task.result.status === 'FAILED',
      ).length;
      const total = apiResponse.data.length;
      setTaskListCounter({
        inQueue,
        inProgress,
        completed,
        failed,
        total,
        pageInfo: apiResponse.pageInfo,
      });
    },
    [setTaskListCounter],
  );

  return {
    taskTrayCounter,
    updateTaskTrayCounter,
    taskListCounter,
    updateTaskListCounter,
  };
};
