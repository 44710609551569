import { memo } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { StyledDownloadArrowIconBox } from './styled';

export const DownloadButton = memo((): JSX.Element => {
  return (
    <StyledDownloadArrowIconBox>
      <MdKeyboardArrowRight size={22} />
    </StyledDownloadArrowIconBox>
  );
});
