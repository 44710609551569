import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { memo, useMemo } from 'react';
import { BackgroundLoraHead } from './BackgroundLoraHead';
import { ColorTemperatureHead } from './ColorTemperatureHead';

export const Head = memo((): JSX.Element => {
  const { activeFeatureName } = useFeaturesContext({});

  const headType = useMemo(() => {
    if (activeFeatureName === 'backgroundLora') {
      return 'backgroundLora`';
    }

    return 'colorTemperature';
  }, [activeFeatureName]);

  return (
    <>
      {headType === 'colorTemperature' ? (
        <ColorTemperatureHead />
      ) : (
        <BackgroundLoraHead />
      )}
    </>
  );
});
