import { COLOR_TEMPERATURE_BATCH_LIMIT_IMAGES } from '@/constants';
import { BatchDownload } from '@/features/components/steps';
import { ReEditContainer } from '@/features/components/steps/ReEdit/ReEditContainer';

export const initialStepStates = [
  {
    title: '画像のアップロード',
    name: 'reEditContainer',
    num: 1,
    explain:
      '色温度を調整する領域を選択してください',
    component: ReEditContainer,
    isActive: true,
  },
  {
    title: '画像のダウンロード',
    name: 'batchDownload',
    num: 2,
    explain: '生成された画像を確認し、ダウンロードしてください',
    component: BatchDownload,
    isActive: false,
  },
];

// MEMO: とりあえずでcolorTemperatureの初期値をコピー
export const initialDataParamState = {
  isNoBackground: false,
  keepMaskImage: '',
  targetType: 'image',
  pickColor: '',
  newColor: '',
  currentColor: '',
  newRef: '',
  currentRef: '',
  withShadow: 'on',
  blurLevel: 'none',
  generationCount: 1,
  format: 'png',
  batchLimitImages: COLOR_TEMPERATURE_BATCH_LIMIT_IMAGES,
};
