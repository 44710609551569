import { StyledFlexGap5, StyledFontBold } from '@/components/styled';
import { TableHead, TableCell as Td, TableRow as Tr } from '@mui/material';
import { memo } from 'react';
import { StyledTdWidth300 } from './styled';

export const Head = memo(
  (): JSX.Element => (
    <TableHead>
      <Tr>
        <StyledTdWidth300>
          <StyledFlexGap5>
            <StyledFontBold>ファイル名</StyledFontBold>
          </StyledFlexGap5>
        </StyledTdWidth300>
        <Td>
          <StyledFlexGap5>
            <StyledFontBold>生成日時・ユーザー名</StyledFontBold>
          </StyledFlexGap5>
        </Td>
        <Td>
          <StyledFlexGap5>
            <StyledFontBold>生成数</StyledFontBold>
          </StyledFlexGap5>
        </Td>
        <Td>
          <StyledFlexGap5>
            <StyledFontBold>DL数</StyledFontBold>
          </StyledFlexGap5>
        </Td>
        <Td colSpan={2} />
      </Tr>
    </TableHead>
  ),
);
