import { StaticButton } from '@/components/atoms/StaticButton';
import { Dialog } from '@/components/molecules/Dialog';
import { styledBatchUploadRetryDialogCancelButton, styledBatchUploadRetryDialogOKButton } from '@/components/styled/button.css';
import { Button } from '@mui/material';
import { useBatchUploadAtoms } from '../../steps/BatchUpload/hooks/useBatchUploadAtoms';
import { buttonContainer, container, title } from './styled/vanilla.css';

type Props = {
  isOpen: boolean;
  handleCloseDialog: () => void;
  handleRetryPostImages: () => void;
};

export const BatchUploadRetryDialog = ({
  isOpen,
  handleCloseDialog,
  handleRetryPostImages,
}: Props) => {
  const {
    setIsRetryMode,
  } = useBatchUploadAtoms()

  const handleRetrySubmit = () => {
    handleCloseDialog()
    // TODO: 状態を変更することで別コンポーネントにある画像アップロード用のuseEffectを再実行している。見通しが悪いので、直接ここからアップロード処理を呼び出せるように修正する。
    setIsRetryMode(true)
    handleRetryPostImages();
  }

  const handleRetryCancel = () => {
    // TODO: リセット処理が色々なコンポーネントで呼ばれていて管理が大変なので、set〜〜は使わずhandle〜〜でカプセル化する
    handleCloseDialog()
    setIsRetryMode(true)
  }

  return (
    <Dialog
      isOpenDialog={isOpen}
      maxWidth="sm"
      hasTitle={false}
      hasCloseIcon={false}
    >
      <div className={container}>
        <h2 className={title}>タイムアウトエラー</h2>
        <div>
          リトライを押すとエラーで中断したところから<br />
          アップロードを再開します。<br />
          キャンセルをすると、アップロードを中止します。<br />
          ファイルの内容を確認してから再度お試しください。
        </div>
        <div className={buttonContainer}>
          <Button
            variant="text"
            className={styledBatchUploadRetryDialogCancelButton}
            onClick={handleRetryCancel}
          >キャンセル</Button>
          <StaticButton
            className={styledBatchUploadRetryDialogOKButton}
            onClick={handleRetrySubmit}
          >リトライ</StaticButton>
        </div>
      </div>
    </Dialog>
  );
}
