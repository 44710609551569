import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { FeatureBatch } from '@/contexts/FeaturesContext/types';
import { useBatchOptionalContext } from '@/features/components/steps/hooks/useBatchOptionalContext';
import { PostTasksV2Param } from '@/types/backendApi';
import { useCallback } from 'react';

type Props = {
  set: FeatureBatch;
};

export const useCoordinateParam = ({ set }: Props) => {
  const {
    isCollectiveSetting,
    collectiveSettingParam,
    collectiveRefImage,
    collectivePresetImage,
  } = useBatchOptionalContext();
  const { activeFeatureName } = useFeaturesContext({});

  const makeParam = useCallback(() => {
    if (activeFeatureName === 'backgroundLora') {
      if (
        (!isCollectiveSetting && set.mainParam.targetType === 'image') ||
        (isCollectiveSetting && collectiveSettingParam.targetType === 'image')
      ) {
        const param: PostTasksV2Param = {
          displayName: set.setName,
          nSample: isCollectiveSetting
            ? collectiveSettingParam.generationCount
            : set.mainParam.generationCount,
          isHidden: true,
          generationMode: 'generate_background_by_reference_image',
          originalImages: set.originalImages.map((image) => ({
            inputImageId: image.inputImageId,
            maskImageId: image.maskImageId,
          })),
          refImage: {
            inputImageId: isCollectiveSetting
              ? collectiveRefImage.refImageId
              : set.refImage.refImageId,
          },
          parameters: {
            addBokeh: isCollectiveSetting
              ? collectiveSettingParam.blurLevel
              : set.mainParam.blurLevel,
            returnBgOnly: true,
          },
        };

        return param
      }

      if (
        (!isCollectiveSetting && set.mainParam.targetType === 'preset') ||
        (isCollectiveSetting && collectiveSettingParam.targetType === 'preset')
      ) {
        const param: PostTasksV2Param = {
          displayName: set.setName,
          nSample: isCollectiveSetting
            ? collectiveSettingParam.generationCount
            : set.mainParam.generationCount,
          isHidden: true,
          generationMode: 'generate_background_by_preset',
          originalImages: set.originalImages.map((image) => ({
            inputImageId: image.inputImageId,
            maskImageId: image.maskImageId,
          })),
          parameters: {
            presetId: isCollectiveSetting
              ? collectivePresetImage.id
              : set.presetImage.id,
            addBokeh: isCollectiveSetting
              ? collectiveSettingParam.blurLevel
              : set.mainParam.blurLevel,
            returnBgOnly: true,
          },
        };

        return param;
      }
    }

    if (activeFeatureName === 'colorTemperature') {
      const param: PostTasksV2Param = {
        displayName: set.setName,
        nSample: 0,
        generationMode: 'generate_color_correction',
        originalImages: set.originalImages.map((image) => ({
          inputImageId: image.inputImageId,
        })),

        refImage: {
          inputImageId: set.refImage.refImageId,
          maskImageId: set.refImage.maskImageId,
        },
        parameters: {},
      };

      return param;
    }

    if (activeFeatureName === 'reEditColorTemperature') {
      const param: PostTasksV2Param = {
        displayName: set.setName,
        nSample: 0,
        generationMode: 'generate_color_correction',
        originalImages: set.originalImages.map((image) => ({
          inputImageId: image.inputImageId,
          maskImageId: image.maskImageId,
        })),
        refImage: {
          inputImageId: set.refImage.refImageId,
          maskImageId: set.refImage.maskImageId,
        },
        parameters: {},
      };

      return param;
    }

    // TODO: ここで例外的にundefinedが返ったとき色温度再生成のbatchDownloadで無限ループしてしまう？要調査
    return undefined;
  }, [
    activeFeatureName,
    isCollectiveSetting,
    set.mainParam.targetType,
    set.mainParam.generationCount,
    set.mainParam.blurLevel,
    set.setName,
    set.originalImages,
    set.refImage.refImageId,
    set.refImage.maskImageId,
    set.presetImage.id,
    collectiveSettingParam.targetType,
    collectiveSettingParam.generationCount,
    collectiveSettingParam.blurLevel,
    collectiveRefImage.refImageId,
    collectivePresetImage.id,
  ]);



  return { makeParam };
};
