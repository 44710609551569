import { Box } from '@/components/atoms/Box';
import { StyledBoxHeight15 } from '@/components/styled';
import {
  StyledDownloaderCheckAllButton,
  StyledDownloaderUnCheckAllButton,
  StyledSupportContainedButton,
  StyledSupportOutlinedButton,
} from '@/components/styled/button';
import { DownloadDialogMode } from '@/features/components/modals/DownloadDialog';
import { useCheckArray } from '@/hooks/global/useCheckArray';
import { usePopper } from '@/hooks/local/usePopper';
import { Popper } from '@mui/material';
import { memo, useRef } from 'react';
import { MdInfo } from 'react-icons/md';
import { selectButtonsStyle, selectSupportImageModeTitleStyle, supportButtonsStyle, supportPopperContentTextStyle, supportPopperContentWrapperStyle, wrapperStyle } from './Label.css';

type Props = {
  isDisplaySupportButton: boolean;
  downloadDialogMode: DownloadDialogMode;
  handleClickSupportStartButton: () => void;
  handleClickSupportCancelButton: () => void;
}
export const Label = memo(({
  isDisplaySupportButton,
  downloadDialogMode,
  handleClickSupportStartButton,
  handleClickSupportCancelButton,
}: Props) => {
  const { handleCheckAll, handleUnCheckAll } = useCheckArray();
  const {
    isOpenPopper,
    handleClosePopper,
    handleOpenPopper,
    anchorElement,
  } = usePopper();
  const popperAnchorRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className={wrapperStyle}>
        {downloadDialogMode === 'selectDownloadImage' &&
        <>
          <div className={selectButtonsStyle}>
            <StyledDownloaderCheckAllButton onClick={handleCheckAll}>
              すべて選択
            </StyledDownloaderCheckAllButton>
            <StyledDownloaderUnCheckAllButton onClick={handleUnCheckAll}>
              すべて解除
            </StyledDownloaderUnCheckAllButton>
          </div>
          {isDisplaySupportButton &&
            <div className={supportButtonsStyle}>
              <StyledSupportContainedButton
                onClick={handleClickSupportStartButton}
              >
                サポート
              </StyledSupportContainedButton>
              <Box
                ref={popperAnchorRef}
                onMouseOver={handleOpenPopper}
                onMouseOut={handleClosePopper}
              >
                <MdInfo size={24} color='rgba(0, 0, 0, 0.56)' />
              </Box>
              <Popper
                open={isOpenPopper}
                anchorEl={anchorElement}
                placement={'bottom-end'}
                style={{
                  zIndex: 99999999999,
                }}
                onMouseLeave={() => {
                  handleClosePopper();
                }}
              >
                <div className={supportPopperContentWrapperStyle}>
                  <span className={supportPopperContentTextStyle}>
                    生成結果に問題のある画像があった場合、こちらからSugeKaeサポートチームへご報告ください。
                  </span>
                  <span className={supportPopperContentTextStyle}>
                    いただいた報告の内容を確認次第、登録されているメールアドレスへ回答を送信いたします。
                  </span>
                </div>
              </Popper>
            </div>
          }
        </>
        }

        {(downloadDialogMode === 'selectSupportImage' || downloadDialogMode === 'supportForm') &&
        <>
          <h2 className={selectSupportImageModeTitleStyle}>サポート</h2>
          <StyledSupportOutlinedButton
            onClick={handleClickSupportCancelButton}
          >キャンセル</StyledSupportOutlinedButton>
        </>
        }
      </div>
      <StyledBoxHeight15 />
    </>
  );
});
