import { Task } from '@/api/schemas';
import { StyledTable } from '@/features/components/styled/table';
import { usePopper } from '@/hooks/local/usePopper';
import { Table } from '@mui/material';
import { memo, useState } from 'react';
import { Body } from './Body';
import { Head } from './Head';

type Props = {
  taskApiResponse: Task | undefined;
  setTaskApiResponse: (task: Task | undefined) => void;
  retryId: string;
  setRetryId: (retryId: string) => void;
  batchId: string;
  setBatchId: (batchId: string) => void;
  isAllTasksDone: boolean;
  setIsAllTasksDone: (isAllTasksDone: boolean) => void;
  deletedIds: string[];
  setDeletedIds: (deletedIds: string[]) => void;
};

export const SetTable = memo(
  ({
    taskApiResponse,
    setTaskApiResponse,
    retryId,
    setRetryId,
    batchId,
    setBatchId,
    isAllTasksDone,
    setIsAllTasksDone,
    deletedIds,
    setDeletedIds,
  }: Props): JSX.Element => {
    const { handleOpenPopper } =
      usePopper();

    const [selectedColumnIndex, setSelectedColumnIndex] = useState<number | undefined>(undefined);

    return (
      <>
        <StyledTable>
          <Table>
            <Head />
            <Body
              handleOpenPopper={handleOpenPopper}
              setTaskApiResponse={setTaskApiResponse}
              retryId={retryId}
              setRetryId={setRetryId}
              batchId={batchId}
              setBatchId={setBatchId}
              isAllTasksDone={isAllTasksDone}
              setIsAllTasksDone={setIsAllTasksDone}
              deletedIds={deletedIds}
              setDeletedIds={setDeletedIds}
              selectedColumnIndex={selectedColumnIndex}
              setSelectedColumnIndex={setSelectedColumnIndex}
              taskApiResponse={taskApiResponse}
            />
          </Table>
        </StyledTable>
        {/* {taskApiResponse && (
          <>
            <DownloadDialog
              taskId={taskApiResponse.id}
              isOpenDownloadDialog={isOpenDownloadDialog}
              handleCloseDownloadDialog={() => {
                handleCloseDownloadDialogAndInitialize()
                setSelectedColumnIndex(undefined)
              }} // ギャラリーと違う内容なので注意
              targetModeJp={
                TASK_TYPE_TO_JP_MODE_FROM_BACKEND.generate_color_correction
              } // 色温度固定
              targetModeEn={
                TASK_TYPE_TO_EN_MODE_FROM_BACKEND.generate_color_correction
              } // 色温度固定
            />
          </>
        )} */}

        {/* <PopupDownloadFormat
          popperTitle="出力形式の選択"
          popperPlacement="bottom"
          anchorElement={anchorElement}
          isOpenPopper={isOpenPopper}
          handleClosePopper={handleClosePopper}
          taskApiResponse={taskApiResponse}
          isNoBackground={false}
        /> */}
      </>
    );
  },
);
