import { Task } from '@/api/schemas';
import {
  StyledFlexNowrapGap10,
  StyledFontBold,
  StyledGrid,
} from '@/components/styled';
import { TASK_STATUS } from '@/constants';
import { CircularProgress } from '@mui/material';
import { memo } from 'react';

type Props = {
  data: Task;
};

export const InProgress = memo(({ data }: Props): JSX.Element => {
  return (
    <>
      <StyledGrid>
        <StyledFlexNowrapGap10>
          <StyledFontBold>
            {TASK_STATUS[data.result.status as keyof typeof TASK_STATUS]}...
          </StyledFontBold>
          <CircularProgress style={{ padding: '5px' }} />
        </StyledFlexNowrapGap10>
      </StyledGrid>
    </>
  );
});
