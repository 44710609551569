import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import { useFeaturesContext } from '../FeaturesContext';

type Params = Record<string, number | string | number[] | string[]> | undefined
const paramsAtom = atom<Params>(undefined);

// クエリパラメータの代わりに使うcontext。routerを導入したら不要になる
export const useParamsContext = () => {
  const { activateTargetFeature } = useFeaturesContext({});
  const [paramsContext, setParamsContext] = useAtom(paramsAtom);

  const activateTargetFeatureWithParams = useCallback((featureName: string, params: Params) => {
    activateTargetFeature(featureName);
    setParamsContext(params)
  }, [activateTargetFeature, setParamsContext]);

  return {
    paramsContext,
    activateTargetFeatureWithParams,
    // MEMO: setParamsContextは呼び出し先で利用しないこと！
  };
};
