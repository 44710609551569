import houndstooth from '@/assets/any/houndstooth.png';
import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import { ProgressDialog } from '@/features/components/modals/ProgressDialog';
import { useGenerate } from '@/features/components/steps/Setting/hooks/useGenerate';
import { StyledFunctionBox } from '@/features/components/styled/function';
import { FeaturesStepLayout } from '@/features/components/templates/Layout/FeaturesStepLayout';
import { memo } from 'react';
import { Buttons } from './Buttons';
import { Functions } from './Functions';
import { Preview } from './Preview';
import { useHandlers, useProgress, useRequest, useSkip } from './hooks';

export const Setting = memo((): JSX.Element => {
  const { featureData, activeFeatureName } = useFeaturesContext({});

  const {
    apiPostResponse,
    apiGetResponse,
    handlePostPreviewApi,
    handleGetPreviewApi,
    setPostApiResponse,
    setGetApiResponse,
  } = useGenerate();

  const {
    isOpenProgress,
    loadingProgressTitle,
    loadingProgressPercent,
    handleCloseProgress,
  } = useProgress({
    apiPostResponse,
    apiGetResponse,
  });

  const {
    handleClear,
    handleResetAfterPreview,
    handleGenerate,
    handleStopGenerating,
  } = useHandlers({
    handlePostPreviewApi,
    setPostApiResponse,
    setGetApiResponse,
    handleCloseProgress,
  });

  useSkip({
    handleResetAfterPreview,
    handleGenerate,
    apiPostResponse,
    apiGetResponse,
  });

  const { isError } = useRequest({
    handleStopGenerating,
    handleGetPreviewApi,
    apiPostResponse,
    apiGetResponse,
    handleCloseProgress,
  });

  const repeatBackgroundImage = () => {
    if (activeFeatureName === 'background') return '';
    if (!featureData?.single?.param?.isNoBackground) return '';

    return houndstooth.src;
  };

  console.log('setting');

  return (
    <>
      {activeFeatureName === 'white' && (
        <FeaturesStepLayout>
          <></>
          <></>
        </FeaturesStepLayout>
      )}
      {activeFeatureName !== 'white' && (
        <FeaturesStepLayout>
          <Preview repeatBackgroundImage={repeatBackgroundImage()} />
          <StyledFunctionBox>
            <Functions handleResetAfterPreview={handleResetAfterPreview} />
            <>
              <Buttons
                handleGenerate={handleGenerate}
                handleClear={handleClear}
                handleResetAfterPreview={handleResetAfterPreview}
              />
            </>
          </StyledFunctionBox>
        </FeaturesStepLayout>
      )}
      {!isError && (
        <ProgressDialog
          isOpen={isOpenProgress}
          title={loadingProgressTitle}
          loadingProgressPercent={loadingProgressPercent}
          handleCloseProgress={handleCloseProgress}
          handleStopGenerating={handleStopGenerating}
        />
      )}
      {/*! isError && !process.env.NEXT_PUBLIC_PRODUCTION && (
        <LoadingProgressWithBG
          isOpen={isOpenLoadingProgress}
          loadingProgressPercent={loadingProgressPercent}
          handleStopLoadingProgress={handleStopGenerating}
        />
      ) */}
    </>
  );
});
