/**
 * JSDoc
 * @see 画像のURLからBase64形式のデータへ変換するカスタムフック
 * @returns {Object}
 */
export const useBase64 = () => {
  const getImageBase64 = async (url: string) => {
    const response = await fetch(url);

    const contentType = response.headers.get('content-type');
    const arrayBuffer = await response.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);
    const charArray = Array.from(uint8Array, (byte) =>
      String.fromCharCode(byte),
    );
    const base64String = btoa(charArray.join(''));

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `data:${contentType};base64,${base64String}`;
  };

  const base64ToBlob = (originalBase64: string) => {
    const binaryString = atob(originalBase64.split(',')[1]);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], { type: 'image/jpeg' });
  };

  return { getImageBase64, base64ToBlob };
};
