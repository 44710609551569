import { Task } from '@/api/schemas';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useEffect } from 'react';

type Props = {
  handleResetAfterPreview: () => void;
  handleGenerate: () => void;
  apiPostResponse: Task | undefined;
  apiGetResponse: Task | undefined;
};

export const useSkip = ({
  handleResetAfterPreview,
  handleGenerate,
  apiPostResponse,
  apiGetResponse,
}: Props) => {
  const {
    activeFeatureName,
    featureData,
    updateFeatureData,
    updateFeatureDataSingle,
    activateTargetStep,
  } = useFeaturesContext({});

  /* 白抜きスキップ */
  // biome-ignore lint/correctness/useExhaustiveDependencies: 依存配列に入れると二回生成されるので handleGenerate は依存配列に入れない
  useEffect(() => {
    if (activeFeatureName !== 'white') return;
    if (!featureData?.mainImage.combinedBase64) return;
    if (apiPostResponse) return;
    if (apiGetResponse) return;
    if (featureData?.single?.genStatus.isGenerating) return;
    if (featureData?.single?.genStatus.isStop) {
      handleResetAfterPreview();
      updateFeatureDataSingle('genStatus', {
        isStop: false,
      });

      return;
    }
    console.log('skip white');
    // biome-ignore lint/complexity/noVoid: あとで修正
    void handleGenerate();

  }, [
    activeFeatureName,
    apiGetResponse,
    apiPostResponse,
    featureData?.single?.genStatus.isGenerating,
    featureData?.single?.genStatus.isStop,
    featureData?.mainImage.combinedBase64,
    activateTargetStep,
    handleResetAfterPreview,
    updateFeatureData,
  ]);
};
