import { Task } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import { StyledGrid } from '@/components/styled';
import { TASK_STATUS } from '@/constants';
import { memo } from 'react';

type Props = {
  data: Task;
};

export const Failed = memo(({ data }: Props): JSX.Element => {
  return (
    <>
      <StyledGrid>
        <Box style={{ fontSize: 14 }}>
          {TASK_STATUS[data.result.status as keyof typeof TASK_STATUS]}
        </Box>
      </StyledGrid>
    </>
  );
});
