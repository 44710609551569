import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledCompletedStatusBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledCompletedStatusBox} {...props}>
        {children}
      </Box>
    );
  },
);
