import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import { TargetContentShadow } from './TargetContentShadow';
import { TargetIsBackgroundImage } from './TargetIsBackgroundImage';
import { TargetIsColor } from './TargetIsColor';

export const CircledTargetContent = () => {
  const { featureData, activeFeatureName } = useFeaturesContext({});

  return (
    <>
      {featureData?.single?.param?.targetType === 'color' &&
        featureData?.single?.param?.currentColor && (
          <>
            <TargetContentShadow />
            <TargetIsColor />
          </>
        )}
      {activeFeatureName === 'background' &&
        featureData?.single?.param?.targetType === 'image' &&
        featureData?.single?.param?.currentRef && (
          <>
            <TargetContentShadow />
            <TargetIsBackgroundImage />
          </>
        )}
    </>
  );
};
