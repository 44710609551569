import { Box, BoxProps } from '@/components/atoms/Box';
import { grey } from '@mui/material/colors';
import { MotionProps, motion } from 'framer-motion';
import hexToRgba from 'hex-to-rgba';
import Image, { ImageProps } from 'next/image';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledHistoryMotionTabContainer = memo(
  ({ children, ...props }: MotionProps) => {
    return (
      <motion.div
        className={vanilla.styledHistoryMotionTabContainer}
        {...props}
      >
        {children}
      </motion.div>
    );
  },
);

export const StyledHistoryMotionTab = memo(
  ({ children, ...props }: MotionProps) => {
    return (
      <motion.div className={vanilla.styledHistoryMotionTab} {...props}>
        {children}
      </motion.div>
    );
  },
);

export const StyledHistoryMotionContainer = memo(
  ({ children, ...props }: MotionProps) => {
    return (
      <motion.div className={vanilla.styledHistoryMotionContainer} {...props}>
        {children}
      </motion.div>
    );
  },
);

export const StyledHistoryBar = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledHistoryBar} {...props}>
      {children}
    </Box>
  );
});

export const StyledHistoryBarThumbnailGrid = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledHistoryBarThumbnailGrid} {...props}>
        {children}
      </Box>
    );
  },
);

type ThumbNailBoxProps = {
  stepName: string;
  canClick: boolean;
  isNull: boolean;
} & BoxProps;

export const StyledThumbnailBox = memo(
  ({ stepName, canClick, isNull, children, ...props }: ThumbNailBoxProps) => {
    const thumbnailStyle = {
      backgroundColor: isNull
        ? hexToRgba('#000', 0.04)
        : 'var(--color-gray-white)',
      outline: isNull
        ? ''
        : stepName !== 'upload'
        ? `1px solid ${grey[400]}`
        : `1px solid ${grey[300]}`,
      '&:hover': {
        cursor: canClick ? 'pointer' : '',
      },
    };

    return (
      <Box
        className={vanilla.styledThumbnailBox}
        sx={thumbnailStyle}
        {...props}
      >
        {children}
      </Box>
    );
  },
);

type StyledImageProps = {
  stepName: string;
  isActive: boolean;
  isRepeat: boolean;
} & ImageProps;

export const StyledImage = memo(
  ({ stepName, isActive, isRepeat, children, ...props }: StyledImageProps) => {
    const imageStyle = {
      opacity:
        stepName === 'upload'
          ? 0.5
          : !isRepeat
          ? 1
          : isRepeat && isActive
          ? 1
          : 0.5,
      filter: stepName === 'upload' ? 'grayScale(1)' : '',
    };

    return (
      <Image className={vanilla.styledImage} style={imageStyle} {...props} />
    );
  },
);

export const StyledImageEmpty = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledImageEmpty} {...props}>
      {children}
    </Box>
  );
});

type ThumbNailNumberBoxProps = {
  stepName: string;
  isActive: boolean;
} & BoxProps;

export const StyledThumbnailNumberBox = memo(
  ({ stepName, isActive, children, ...props }: ThumbNailNumberBoxProps) => {
    const thumbnailNumberBoxStyle = {
      backgroundColor: isActive
        ? 'var(--color-primary-main)'
        : stepName !== 'upload'
        ? grey[600]
        : grey[400],
    };

    return (
      <Box
        className={vanilla.styledThumbnailNumberBox}
        sx={thumbnailNumberBoxStyle}
        {...props}
      >
        {children}
      </Box>
    );
  },
);

export const StyledHistoryTab = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledHistoryTab} {...props}>
      {children}
    </Box>
  );
});

export const StyledHistoryTabText = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledHistoryTabText} {...props}>
      {children}
    </Box>
  );
});

export const StyledHistoryRepeatGenHeadBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledHistoryRepeatGenHeadBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledBigCircle = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBigCircle} {...props}>
      {children}
    </Box>
  );
});

export const StyledSmallCircleContainer = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledSmallCircleContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledSmallCircle = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledSmallCircle} {...props}>
      {children}
    </Box>
  );
});

export const StyledRepeatGenImageBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledRepeatGenImageBox} {...props}>
        {children}
      </Box>
    );
  },
);
