import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './paginator.css';

export const StyledPaginatorBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledPaginatorBox} {...props}>
      {children}
    </Box>
  );
});
