import { Box } from '@/components/atoms/Box';
import { StyledFlexCenter, StyledFlexGap10 } from '@/components/styled';
import { memo } from 'react';
import {
  StyledCircle56x56,
  StyledSquare100x16,
  StyledSquare126x16,
  StyledSquare16x16,
} from '../styled';

export const BatchDownloadRow = memo((): JSX.Element => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 90,
          cursor: 'pointer',
          '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.02)' },
          borderTop: '1px solid #e0e0e0',
        }}
      >
        <Box style={{ minWidth: 380, padding: '10px 10px 10px 30px' }}>
          <StyledFlexGap10>
            <StyledCircle56x56 />
            <StyledSquare126x16 />
          </StyledFlexGap10>
        </Box>
        <Box style={{ minWidth: 250 }}>
          <StyledSquare100x16 />
        </Box>
        <Box
          style={{
            width: 150,
          }}
        >
          <StyledFlexCenter>
            <StyledSquare16x16 />
          </StyledFlexCenter>
        </Box>
        <Box style={{ width: '100%', maxWidth: '100%' }} />
      </Box>
    </>
  );
});
