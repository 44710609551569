import { Box } from '@/components/atoms/Box';
import { CheckedCircle, UnCheckedCircle } from '@/components/icons';
import { ImageWithLoader } from '@/components/molecules/ImageWithLoader/ImageWithLoader';
import {
  FeatureBatchOriginalImage,
  FeatureData,
} from '@/contexts/FeaturesContext/types';
import { WarningFunction } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { memo, useMemo } from 'react';
import {
  StyledImageSetThumbnailBox,
  StyledImageSetThumbnailCenter,
} from '../styled';

type Props = {
  image: FeatureBatchOriginalImage;
  index: number;
  handleCheckDeleteImages: (image: FeatureBatchOriginalImage) => void;
  handleSelectImage: (image: string) => void;
  warningFunction: WarningFunction;
  featureData: FeatureData;
  targetNumber: number;
};

export const ThumbnailWithCheck = memo(
  ({
    image,
    index,
    handleCheckDeleteImages,
    handleSelectImage,
    warningFunction,
    featureData,
    targetNumber,
  }: Props): JSX.Element => {
    const combinedBase64Thumbnail120 = useMemo(
      () => image.mainImage.combinedBase64Thumbnail120,
      [image.mainImage.combinedBase64Thumbnail120],
    );

    return (
      <>
        <StyledImageSetThumbnailBox
          key={`upload-set-image-${index}`.toString()}
          style={{
            outline: image.isSelected
              ? `1px solid ${'var(--color-primary-main)'}`
              : '1px solid #ccc',
            cursor: 'pointer',
            backgroundColor: '#fff',
          }}
        >
          <Box
            style={{ position: 'absolute', bottom: 3, right: 3, zIndex: 1}}
            onClick={() => {
              warningFunction.resetWarnings();
              handleCheckDeleteImages(image);
            }}
          >
            {featureData.batch[targetNumber].deleteImages.includes(
              image.fileName,
            ) ? (
              <CheckedCircle />
            ) : (
              <UnCheckedCircle />
            )}
          </Box>
          <StyledImageSetThumbnailCenter
            onClick={() => {
              warningFunction.resetWarnings();
              handleSelectImage(image.fileName);
            }}
          >
            <ImageWithLoader
              src={combinedBase64Thumbnail120 || image.url || ''}
              width={100}
              height={120}
              alt=""
              loaderHeight={8}
              loaderWidth={8}
              loaderMode="beater"
            />
          </StyledImageSetThumbnailCenter>
        </StyledImageSetThumbnailBox>
      </>
    );
  },
);
