import { useCallback, useEffect, useState } from 'react';

type Props = {
  dateString: string;
  setDateString: (date: string) => void;
  isDateError: boolean;
  setIsDateError: (isError: boolean) => void;
  isCalendarEnable?: boolean;
};

export const useCalendarInput = ({
  dateString,
  setDateString,
  isDateError,
  setIsDateError,
  isCalendarEnable,
}: Props) => {
  const [inputDate, setInputDate] = useState<string>(dateString);
  const [isInputting, setIsInputting] = useState<boolean>(false);

  const validateDate = useCallback((dateStr: string) => {
    const [year, month, day] = dateStr.split('-').map(Number);
    const dateObj = new Date(year, month - 1, day);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (
      dateObj.getFullYear() !== year ||
      dateObj.getMonth() !== month - 1 ||
      dateObj.getDate() !== day
    ) {
      return false;
    }

    if (dateObj > today) {
      return false;
    }

    return true;
  }, []);

  useEffect(() => {
    // dateStringが空であれば、エラーを設定せずに現在の日付を設定
    if (isCalendarEnable) {
      if (!dateString) {
        const today = new Date().toISOString().split('T')[0];
        setInputDate(today);
        setIsDateError(false); // 初期オープンではエラーを設定しない
      } else {
        const isValid = validateDate(dateString);
        setIsDateError(!isValid);
        if (isValid) {
          setInputDate(dateString);
        }
      }
    }
  }, [dateString, isCalendarEnable, validateDate, setIsDateError]);

  const handleInputDate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isCalendarEnable) return;
      setInputDate(e.target.value);
      setIsInputting(true);
    },
    [isCalendarEnable],
  );

  useEffect(() => {
    if (!isCalendarEnable || !isInputting) return; // カレンダーが無効、またはユーザーが入力していない場合は何もしない

    const isValid = validateDate(inputDate); // 日付のバリデーションを実行
    setIsDateError(!isValid);

    if (isValid) {
      setDateString(inputDate); // バリデーションが成功した場合、入力された日付を親コンポーネントに設定
    }
  }, [
    inputDate,
    isCalendarEnable,
    validateDate,
    setIsDateError,
    setDateString,
    isInputting,
  ]);

  useEffect(() => {
    if (isDateError || isInputting) return; // ユーザーが入力中の場合は何もしない
    if (isCalendarEnable) {
      setInputDate(dateString);
    } else {
      setInputDate('');
    }
  }, [dateString, isCalendarEnable, isDateError, isInputting]);

  return {
    inputDate,
    handleInputDate,
    isInputting,
  };
};
