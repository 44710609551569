import { FeatureData, ImageFields } from '@/contexts/FeaturesContext/types';
import { Fragment, memo, useCallback } from 'react';
import { useSegmentationKeys } from '../hooks/useSegmentationKeys';
import { StyledNegativePointPin, StyledPositivePointPin } from './styled';

type Props = {
  field: ImageFields;
  pointType: string;
  points: Array<[number, number]>;
  isKeepMask: boolean;
  data: FeatureData;
  updateData: (field: keyof FeatureData, value: unknown) => void;
};

/**
 * JSDoc
 * @see セグメンテーションのポイントPinを表示するコンポーネント
 * @see Pinクリック時に配列から該当の座標を削除するハンドラーを持つ
 * @param {Props} { field, pointType, points }
 * @returns {JSX.Element}
 */
export const DisposePointPin = memo(
  ({
    field,
    pointType,
    points,
    isKeepMask,
    data,
    updateData,
  }: Props): JSX.Element => {
    const { positiveKey, negativeKey, isPreviewCompletedKey } =
      useSegmentationKeys(isKeepMask);

    const handleRemoveTargetPin = useCallback(
      (index: number) => {
        if (pointType === 'positive') {
          updateData(field, {
            [isPreviewCompletedKey]: false,
            [positiveKey]: points.filter((_, i) => i !== index),
          });
        } else {
          updateData(field, {
            [isPreviewCompletedKey]: false,
            [negativeKey]: points.filter((_, i) => i !== index),
          });
        }
      },
      [
        pointType,
        updateData,
        field,
        isPreviewCompletedKey,
        positiveKey,
        points,
        negativeKey,
      ],
    );

    return (
      <>
        {data?.[field].samType === 'manual' &&
          points.map((point, index) => (
            <Fragment key={`${pointType}-${index}`.toString()}>
              {pointType === 'positive' && (
                <StyledPositivePointPin
                  left={point[0]}
                  top={point[1]}
                  onClick={() => {
                    handleRemoveTargetPin(index);
                  }}
                  data-testid="positive-point-pin"
                />
              )}
              {data?.[field].samType === 'manual' &&
                pointType === 'negative' && (
                  <StyledNegativePointPin
                    left={point[0]}
                    top={point[1]}
                    onClick={() => {
                      handleRemoveTargetPin(index);
                    }}
                    data-testid="negative-point-pin"
                  />
                )}
            </Fragment>
          ))}
      </>
    );
  },
);
