import { memo } from 'react';

type Props = {
  color?: string;
};
const CheckedCircle = memo(({ color = '#2196f3' }: Props): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
      <style>{'.checkedCircle_svg__st0{fill:#fff}'}</style>
      <g id="checkedCircle_svg__\u30EC\u30A4\u30E4\u30FC_2">
        <circle cx={12} cy={12} r={10.5} className="checkedCircle_svg__st0" />
        <circle cx={12} cy={12} r={11.8} className="checkedCircle_svg__st0" />
      </g>
      <path
        d="M12 1.5C6.2 1.5 1.5 6.2 1.5 12S6.2 22.5 12 22.5 22.5 17.8 22.5 12 17.8 1.5 12 1.5zm4.5 7.1-4.9 6.8c-.1.1-.2.2-.3.2-.1.1-.2.1-.3.1-.1 0-.2 0-.3-.1-.1-.1-.2-.1-.3-.2l-2.9-4c-.1-.1 0-.3.2-.3h1.1c.2 0 .5.1.6.3l1.7 2.3 3.7-5.1c.1-.2.4-.3.6-.3h1.1s.1.1 0 .3z"
        style={{
          fill: color,
        }}
      />
    </svg>
  );
});
export default CheckedCircle;
