import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { Tooltip } from '@/components/atoms/Tooltip';
import { CheckedRef } from '@/components/icons/others';
import {
  StyledFlexCenter,
  StyledFlexGap10,
  StyledFontSx
} from '@/components/styled';
import {
  StyledDividerBox,
  StyledTableRowContainer,
  StyledTableRowName,
  StyledTableRowNameEllipsis,
  StyledTableRowNameFlex,
} from '@/features/components/styled/batch';
import { useConvertDate } from '@/hooks/utils/useConvertDate';
import { Divider } from '@mui/material';
import hexToRgba from 'hex-to-rgba';
import { memo, useMemo } from 'react';
import { FaFolder } from 'react-icons/fa';
import { useReEditAtom } from '../../../../hooks/useReEditAtom';
import { useTaskData } from '../../../../hooks/useTaskData';

type Props = {
  handleOpenSetNameDialog: () => void;
};

export const Bar = memo(
  ({
    handleOpenSetNameDialog,
  }: Props): JSX.Element => {
    const hasRefImage = true; // MEMO: 再生成では参照画像の削除はできないので常にtrue

    const { convertToDateTime } = useConvertDate();

    const { taskData } = useTaskData();
    const { maskImagesMap, tmpDisplayName, targetOriginalImages } = useReEditAtom();

    const displayName = useMemo(() => {
      return tmpDisplayName || '';
    }, [
      tmpDisplayName,
    ])

    const isCompletedSegmentation = useMemo(() => {
      if (maskImagesMap === undefined) return false;

      return Object.keys(maskImagesMap).length === targetOriginalImages.length;
    }, [maskImagesMap, targetOriginalImages])

    return (
      <>
        <StyledTableRowContainer
          sx={{
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.02)' },
          }}
        >
          <StyledTableRowName>
            <StyledTableRowNameFlex sx={{ color: '#000' }}>
              <StyledFlexGap10>
                <FaFolder size={24} color={hexToRgba('#000', 0.56)} />
                <StyledTableRowNameEllipsis style={{ color: '#000' }}>
                  <Tooltip title={displayName || ''} placement="bottom-start">
                    {displayName}
                  </Tooltip>
                </StyledTableRowNameEllipsis>
              </StyledFlexGap10>
            </StyledTableRowNameFlex>
          </StyledTableRowName>
          <Box style={{ width: 100, maxWidth: 100 }}>
            <Button
              mode="border"
              width={101}
              height={30}
              style={{ fontSize: 13 }}
              onClick={handleOpenSetNameDialog}
            >
              セット名変更
            </Button>
          </Box>
          <StyledDividerBox>
            <Divider orientation="vertical" sx={{ backgroundColor: '#ddd' }} />
          </StyledDividerBox>
          <Box style={{ width: 200 }}>
            <StyledFontSx>
              {taskData?.createdAt
                ? convertToDateTime(taskData.createdAt)
                : 'ー'}
            </StyledFontSx>
          </Box>
          <StyledFlexCenter style={{ width: 150 }}>
            <StyledFontSx>
              {targetOriginalImages.length || 'ー'}
            </StyledFontSx>
          </StyledFlexCenter>
          <StyledFlexCenter style={{ width: 150 }}>
            {hasRefImage ? <CheckedRef /> : <StyledFontSx>ー</StyledFontSx>}
          </StyledFlexCenter>
          <StyledFlexCenter style={{ width: 150 }}>
            {isCompletedSegmentation ? <CheckedRef /> : <StyledFontSx>ー</StyledFontSx>}
          </StyledFlexCenter>
        </StyledTableRowContainer>
      </>
    );
  },
);
