import { Box } from '@/components/atoms/Box';
import { CircularProgress } from '@mui/material';
import { grey } from '@mui/material/colors';
import { memo, useCallback } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import {
  StyledSpinnerBox,
  StyledSpinnerCancelBox,
  StyledSpinnerContainer,
} from './styled';

/**
 * @see Sugekaeのローディングスピナー
 * @see 画像を取得する際やApi待ちの際に表示される
 */

type Props = {
  width?: number;
  height?: number;
  padding?: number;
  hasBackground?: boolean;
  hasCancel?: boolean;
  isLoading?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
  mode?: 'beater' | 'spinner';
};

/**
 * @JSDoc
 * @see Sugekaeのローディングスピナー
 * @see 画像を取得する際やApi待ちの際に表示される
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const Spinner = memo(
  ({
    width = 32,
    height = 32,
    padding = 24,
    hasBackground = true,
    hasCancel = false,
    isLoading = true,
    setIsLoading,
    mode = 'spinner',
  }: Props) => {
    const handleCancel = useCallback(() => {
      if (hasCancel) {
        // eslint-disable-next-line no-unused-expressions
        setIsLoading && setIsLoading(false);
      }
    }, [hasCancel, setIsLoading]);

    return (
      <StyledSpinnerContainer
        data-testid="spinner-background"
        isLoading={isLoading}
        hasBackground={hasBackground}
        padding={padding}
      >
        <StyledSpinnerBox>
          <Box>
            {mode === 'spinner' ? (
              <CircularProgress data-testid="spinner" sx={{ width, height }} />
            ) : (
              <BeatLoader
                data-testid="beater"
                size={8}
                color={grey[400]}
                speedMultiplier={0.5}
              />
            )}
          </Box>
          {hasCancel && (
            <StyledSpinnerCancelBox onClick={handleCancel}>
              キャンセル
            </StyledSpinnerCancelBox>
          )}
        </StyledSpinnerBox>
      </StyledSpinnerContainer>
    );
  },
);
