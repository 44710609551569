import {
  Pagination as MuiPagination,
  PaginationItem,
  PaginationProps,
  Stack,
  styled,
} from '@mui/material';
import { memo } from 'react';
import { MdSkipNext, MdSkipPrevious } from 'react-icons/md';

export const StyledPagination = styled(MuiPagination)({});

type Props = {
  count: number;
  page: number;
  onChange?: (event: React.ChangeEvent<unknown>, value: number) => void;
} & PaginationProps;

/**
 * JSDoc
 * @see ページネーションコンポーネント
 * @see ページネーションを表示する際に使用する
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const Paginator = memo(
  ({ count = 0, page = 1, onChange, ...props }: Props) => {
    return (
      <Stack spacing={2}>
        <StyledPagination
          style={{ backgroundColor: 'primary' }}
          page={page}
          count={count}
          onChange={onChange}
          showFirstButton
          showLastButton
          renderItem={(item) => (
            <PaginationItem
              slots={{ first: MdSkipPrevious, last: MdSkipNext }}
              {...item}
            />
          )}
          {...props}
        />
      </Stack>
    );
  },
);
