import { ContentHeader } from '@/features/components/organisms/ContentHeader';
import {
  StyledScrollBox,
  StyledSingleContentWithScrollBox,
} from '@/features/components/templates/Layout/styled';
import { memo } from 'react';

type Props = {
  children: React.ReactNode[];
};

export const FeaturesSingleLayoutWithScroll = memo(
  ({ children }: Props): JSX.Element => (
    <>
      <ContentHeader />
      <div>{children[0]}</div>
      <StyledSingleContentWithScrollBox>
        <StyledScrollBox>{children[1]}</StyledScrollBox>
      </StyledSingleContentWithScrollBox>
      <div>{children[2]}</div>
    </>
  ),
);
