import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fstyled%2Ffont.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA6WVz06EMBDG7zxFEy9rYgkg%2B0f27sGDMfEJulBKXWix1AXW%2BO5uUNlK0W4h4UL7zW%2Bm3xTGFWkbNEcPvDsApJxJmKKC5m0EHrnk4BmxCjw8bZ0Px%2F1S%2BmdljSnJZARCz1MEgS5Y%2FxLcngUVPeII%2BGHZbE9LMc%2B5iMABiQWE3QskArUwQWJ%2FrQDCvwB1RiWGVYni0yLjtUClEracm3dlAAiyQwvvBnw%2F7koNXltnx7hUAZuJAJMtdwbu1fI%2B8AO1gUiLCOyM3M0FxNaAgZmJBSBBjGABC0SZisAdQuJGQpRTwiIQYyaxUCSpbZldpy7oGRnvWb%2BfafsrO4PpOKDff5mYwHSwvS33x7GekNsShn0tDAD9e2BaxMbObT4XUA4BgWd7nV81hOXfUVw8QiqT8t%2FxIS2ju%2BlkundvU0qaMkxMhRymFTIO%2FQQMG7RA3QcAAA%3D%3D%22%7D"
export var fontFamily = 'rfy2xz0';
export var styledFont = 'rfy2xz3 rfy2xz1 rfy2xz0';
export var styledFont12 = 'rfy2xza rfy2xz1 rfy2xz0';
export var styledFont12Bold = 'rfy2xzb rfy2xz2 rfy2xz0';
export var styledFont12BoldNowrapWhite = 'rfy2xzf rfy2xz2 rfy2xz0';
export var styledFont12Danger = 'rfy2xzd rfy2xz1 rfy2xz0';
export var styledFont12DangerCenter = 'rfy2xze rfy2xzd rfy2xz1 rfy2xz0';
export var styledFont12Deep = 'rfy2xzc rfy2xz1 rfy2xz0';
export var styledFont14 = 'rfy2xzg rfy2xz1 rfy2xz0';
export var styledFont16 = 'rfy2xzh rfy2xz1 rfy2xz0';
export var styledFont16Bold = 'rfy2xzi rfy2xz2 rfy2xz0';
export var styledFont16Error = 'rfy2xzm rfy2xz1 rfy2xz0';
export var styledFont16Main = 'rfy2xzl rfy2xz1 rfy2xz0';
export var styledFont16Nowrap = 'rfy2xzj rfy2xz1 rfy2xz0';
export var styledFont16White = 'rfy2xzk rfy2xz1 rfy2xz0';
export var styledFont18 = 'rfy2xzn rfy2xz1 rfy2xz0';
export var styledFont18Bold = 'rfy2xzo rfy2xz2 rfy2xz0';
export var styledFont20BoldDanger = 'rfy2xzp rfy2xz2 rfy2xz0';
export var styledFont24Dark = 'rfy2xzq rfy2xz1 rfy2xz0';
export var styledFontBold = 'rfy2xz5 rfy2xz2 rfy2xz0';
export var styledFontBoldBlack06 = 'rfy2xz6 rfy2xz2 rfy2xz0';
export var styledFontBoldNowrap = 'rfy2xzu';
export var styledFontBoldNowrapSx = 'rfy2xzv';
export var styledFontBoldSx = 'rfy2xzs';
export var styledFontDeep = 'rfy2xz7 rfy2xz1 rfy2xz0';
export var styledFontDeepNowrap = 'rfy2xz8 rfy2xz1 rfy2xz0';
export var styledFontError = 'rfy2xz9 rfy2xz1 rfy2xz0';
export var styledFontNowrap = 'rfy2xz4 rfy2xz1 rfy2xz0';
export var styledFontNowrapSx = 'rfy2xzt';
export var styledFontSx = 'rfy2xzr';