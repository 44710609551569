import {
  StyledModalStepContentBox,
  StyledModalStepLeftBox,
  StyledModalStepRightBox,
  StyledScrollBox,
} from '@/features/components/templates/Layout/styled';
import { memo } from 'react';

type Props = {
  children: React.ReactNode[];
};

export const FeaturesModalStepLayout = memo(
  ({ children }: Props): JSX.Element => {
    const [leftNode, rightNode] = children;

    return (
      <>
        <StyledModalStepContentBox>
          <StyledModalStepLeftBox>{leftNode}</StyledModalStepLeftBox>
          <StyledModalStepRightBox>
            <StyledScrollBox>{rightNode}</StyledScrollBox>
          </StyledModalStepRightBox>
        </StyledModalStepContentBox>
      </>
    );
  },
);
