import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './function.css';

export const StyledFunctionBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledFunctionBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledFunctionPadding = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledFunctionPadding} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledFunctionWrapper = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledFunctionWrapper} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledFunctionPaddingBottom0 = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledFunctionPaddingBottom0} {...props}>
        {children}
      </Box>
    );
  },
);
type StyledFunctionPartitionProps = BoxProps & {
  height?: number;
};
export const StyledFunctionPartition = memo(
  ({ children, height = 17, ...props }: StyledFunctionPartitionProps) => {
    return (
      <Box
        className={vanilla.styledFunctionPartition}
        style={{ height }}
        {...props}
      >
        {children}
      </Box>
    );
  },
);

export const StyledFunctionMessageBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledFunctionMessageBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledFunctionMessageStar = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledFunctionMessageStar} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledFunctionMessageEnd = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledFunctionMessageEnd} {...props}>
        {children}
      </Box>
    );
  },
);
