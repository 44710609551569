import {
  StyledFlex,
  StyledFlexEnd,
  StyledFlexGap5,
  StyledFlexNowrap,
  StyledFont16,
  StyledFontBold,
} from '@/components/styled';
import { StyledAdminAddUserButton } from '@/components/styled/button';
import { AdminAddUserDialog } from '@/features/components/modals/AdminAddUserDialog';
import { useDialog } from '@/hooks/local/useDialog';
import {
  FormControlLabel,
  Switch,
  TableHead,
  TableCell as Td,
  TableRow as Tr,
} from '@mui/material';
import { ChangeEvent, memo } from 'react';
import { MdOutlineAdd } from 'react-icons/md';
import { StyledTd, StyledTdWidth300 } from './styled';

type Props = {
  handleShowDeleted: () => void;
  showDeleted: boolean;
  handleInviteUser: (arg: {
    email: string;
    name: string | null;
  }) => Promise<void>;
};

export const Head = memo(
  ({
    showDeleted,
    handleShowDeleted,
    handleInviteUser,
  }: Props): JSX.Element => {
    const { isOpenDialog, handleOpenDialog, handleCloseDialog } = useDialog();

    return (
      <>
        <TableHead>
          <Tr>
            <StyledTd colSpan={4}>
              <StyledFlexNowrap>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showDeleted}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleShowDeleted();
                      }}
                    />
                  }
                  label={<StyledFont16>削除済みユーザーの表示</StyledFont16>}
                />
                <StyledFlexEnd>
                  <StyledAdminAddUserButton onClick={handleOpenDialog}>
                    <StyledFlexGap5>
                      <MdOutlineAdd size={18} />
                      ユーザーを新規追加
                    </StyledFlexGap5>
                  </StyledAdminAddUserButton>
                </StyledFlexEnd>
              </StyledFlexNowrap>
            </StyledTd>
          </Tr>
          <Tr>
            <StyledTdWidth300>
              <StyledFontBold>ユーザー（メールアドレス）</StyledFontBold>
            </StyledTdWidth300>
            <Td>
              <StyledFlex>
                <StyledFontBold>ステータス</StyledFontBold>
              </StyledFlex>
            </Td>
            <Td />
          </Tr>
        </TableHead>
        <AdminAddUserDialog
          isOpenDialog={isOpenDialog}
          handleCloseDialog={handleCloseDialog}
          handleInviteUser={handleInviteUser}
        />
      </>
    );
  },
);
