import { TaskOriginalImage } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import { ImageWithLoader } from '@/components/molecules/ImageWithLoader/ImageWithLoader';
import { StyledFlexCenterHeightExpanded } from '@/components/styled';
import { Spinner } from '@/features/components/atoms/Spinner';
import { StyledLoadingSpinnerBox } from '@/features/components/styled/loading';
import { useBase64 } from '@/hooks/utils/useBase64';
import { useImageMasking } from '@/hooks/utils/useImageMasking';
import { Dispatch, SetStateAction, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { SelectedImageSize } from '../../../Functions/hooks/useSamDialog';
import { useReEditAtom } from '../../../hooks/useReEditAtom';
import { DisposePointPin } from './DisposePointPin';


export type ReEditSegmentationData = {
  // positivePoints: [number, number][];
  // negativePoints: [number, number][];
  // apiPositivePoints: [number, number][];
  // apiNegativePoints: [number, number][];
  // isPreviewCompleted: boolean;
  // isPreviewLoading: boolean;
  // base64: string;
  // combinedBase64: string,
  originalSize: {
    height: number;
    width: number;
    bytes: number;
  }
  resizedSize: {
    height: number;
    width: number;
    bytes: number;
  }
}

type Props = {
  selectedImageSize: SelectedImageSize;
  samType: 'auto' | 'manual';
  pointType: 'positive' | 'negative';
  positivePoints: [number, number][];
  negativePoints: [number, number][];
  isPreviewLoading: boolean;
  isPreviewCompleted: boolean;
  setPositivePoints: Dispatch<SetStateAction<[number, number][]>>;
  setNegativePoints: Dispatch<SetStateAction<[number, number][]>>;
  setApiPositivePoints: Dispatch<SetStateAction<[number, number][]>>;
  setApiNegativePoints: Dispatch<SetStateAction<[number, number][]>>;
  setIsPreviewCompleted: Dispatch<SetStateAction<boolean>>;
};

// MEMO: 現状ではReEdit専用のFeatureContext未使用コンポーネント。（最終的にFeatureContext自体を廃止したい）
export const GlobalSegmentation = memo(
  ({
    selectedImageSize,
    samType,
    pointType,
    positivePoints,
    negativePoints,
    isPreviewLoading,
    isPreviewCompleted,
    setPositivePoints,
    setNegativePoints,
    setApiPositivePoints,
    setApiNegativePoints,
    setIsPreviewCompleted,
  }: Props): JSX.Element => {
    const { maskImagesMap, tmpMaskImage, setTmpMaskImage, selectedIndex, targetOriginalImages } = useReEditAtom();
    const selectedImage: TaskOriginalImage | undefined = useMemo(() => {
      if (selectedIndex === undefined) return undefined

      return targetOriginalImages[selectedIndex]
    }, [
      targetOriginalImages,
      selectedIndex,
    ])

    const [previewSize, setPreviewSize] = useState<{
      width: number;
      height: number;
      reductionRatio: number;
    }>({ width: 0, height: 0, reductionRatio: 1 });

    // TODO: あとで整備する
    const INITIAL_DATA = {
        // [field]: {
        // positivePoints: [],
        // negativePoints: [],
        apiPositivePoints: [],
        apiNegativePoints: [],
        // isPreviewCompleted: false,
        // isPreviewLoading: false,
        // base64: '',
        // combinedBase64: '',

        originalSize: {
          height: 0,
          width: 0,
          bytes: 0,
        },
        resizedSize: {
          height: 0,
          width: 0,
          bytes: 0,
        },

        // [pointType]: 'positive',
        // [positiveKey]: 111,
        // [negativeKey]: [],
        // [apiPositiveKey]: [],
        // [apiNegativeKey]: [],
        // [isPreviewCompletedKey]: false,
      // }
    }

    // taskDataから取得できるデータ（画像情報）と座標データ、状態データは分けた方が良いかも
    const [data, setData] = useState<ReEditSegmentationData>(INITIAL_DATA)

    // dataの初期値を設定するuseEffect
    useEffect(() => {
      if(!selectedImage || !selectedImageSize) return

      setData(_data => {
        return {
          ..._data,
          base64: selectedImage.inputImage.originalImageUrl,
          originalSize: {
            height: selectedImageSize.height,
            width: selectedImageSize.width,
            bytes: selectedImageSize.bytes,
          },
        }
      })
    }, [selectedImage, selectedImageSize])

    const handleAddPoint = useCallback(
      (x: number, y: number) => {
        if (x <= 0 || y <= 0) return;
        if (pointType === 'positive') {
          setPositivePoints(prev => ([...prev, [x, y]]));
          setApiPositivePoints(prev => ([
            ...prev,
            [
              Math.round(x / previewSize.reductionRatio),
              Math.round(y / previewSize.reductionRatio),
            ],
          ]));
        } else {
          setNegativePoints(prev => ([...prev, [x, y]]));
          setApiNegativePoints(prev => ([
            ...prev,
            [
              Math.round(x / previewSize.reductionRatio),
              Math.round(y / previewSize.reductionRatio),
            ],
          ]));
        }
      },
      [
        setPositivePoints,
        setNegativePoints,
        setApiPositivePoints,
        setApiNegativePoints,
        pointType,
        previewSize.reductionRatio,
      ],
    );

    // localなpointsの変更時にAPIに渡すpointsを更新するuseEffect（APIに渡す際（プレビュー時に）に計算するか、useMemoにしてしまって良い気がするがどうか？）
    // biome-ignore lint/correctness/useExhaustiveDependencies: localのpointsが変更された時だけ実行する
    useEffect(() => {
      setApiPositivePoints(
        positivePoints.map(
          (point: [number, number]) => [
            Math.round(point[0] / previewSize.reductionRatio),
            Math.round(point[1] / previewSize.reductionRatio),
          ],
        )
      )
      setApiNegativePoints(
        negativePoints.map(
          (point: [number, number]) => [
            Math.round(point[0] / previewSize.reductionRatio),
            Math.round(point[1] / previewSize.reductionRatio),
          ],
        )
      )
    }, [
      positivePoints,
      negativePoints,
    ]);

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (samType === 'auto') return;
        const { offsetX } = event.nativeEvent;
        const { offsetY } = event.nativeEvent;
        const x = Math.round(offsetX);
        const y = Math.round(offsetY);
        handleAddPoint(x, y);
      },
      [
        handleAddPoint,
        samType,
      ],
    );

    useEffect(() => {
      const maxWidth = 660;
      const maxHeight = 600;

      let height;
      let width;
      let reductionRatio;

      const originalHeight = data.originalSize.height;
      const originalWidth = data.originalSize.width;
      const resizedHeight = data.resizedSize.height || originalHeight;
      const resizedWidth = data.resizedSize.width || originalWidth;

      if (resizedWidth / resizedHeight > maxWidth / maxHeight) {
        // 横が縦より大きい場合、横幅を基準にサイズ調整
        reductionRatio = maxWidth / resizedWidth;
        width = maxWidth;
        height = Math.ceil(resizedHeight * reductionRatio);
      } else {
        // 縦が横より大きいまたは等しい場合、縦幅を基準にサイズ調整
        reductionRatio = maxHeight / resizedHeight;
        height = maxHeight;
        width = Math.ceil(resizedWidth * reductionRatio);
      }

      setPreviewSize({ width, height, reductionRatio });
    }, [
      data.originalSize,
      data.resizedSize,
    ]);

    // 画像周り制御
    const { getImageBase64 } = useBase64();
    const { getImageMasking } = useImageMasking();
    const [selectedImageBase64, setSelectedImageBase64] = useState<string | undefined>(undefined);

    // selectedImageBase64を制御するuseEffect
    useEffect(() => {
      const processForSelectedImageBase64 = async () => {
        if (!selectedImage?.inputImage?.originalImageUrl) {
          setSelectedImageBase64(undefined);

          return;
        }

        const base64 = await getImageBase64(selectedImage.inputImage.originalImageUrl);
        setSelectedImageBase64(base64);
      };

      processForSelectedImageBase64().catch((error) => {
        throw new Error(error);
      })
    }, [selectedImage?.inputImage.originalImageUrl, getImageBase64]);

    // selectedImageCombinedBase64を制御するuseEffect
    useEffect(() => {
      const processForSelectedImageCombinedBase64 = async () => {
        if (
          !selectedImage?.inputImage?.originalImageUrl
          || !selectedImage?.maskImage?.originalImageUrl
        ) {
          // setSelectedImageCombinedBase64(undefined);

          return;
        }

        const base64 = await getImageMasking({
          mainImageSource: selectedImage.inputImage.originalImageUrl,
          maskImageSource: selectedImage.maskImage.originalImageUrl,
        })
        // setSelectedImageCombinedBase64(base64);
      };

      processForSelectedImageCombinedBase64().catch((error) => {
        throw new Error(error);
      });
    }, [
      selectedImage?.inputImage.originalImageUrl,
      selectedImage?.maskImage?.originalImageUrl,
      getImageMasking,
    ]);

    const maskImage = useCallback((index: number | undefined) => {
      if (index !== undefined) {
        return maskImagesMap?.[index];
      }
      return undefined;
    }, [maskImagesMap])

    const combinedBase64 = useMemo(() => {
      console.debug(tmpMaskImage?.maskOverlayImageBase64)
      return tmpMaskImage?.maskOverlayImageBase64;
    }, [tmpMaskImage])

    // セグメンテーション確定 -> 再度ダイアログ開く としたときに確定結果を初期値とする
    // biome-ignore lint/correctness/useExhaustiveDependencies: マウント時のみ実行する
    useEffect(() => {
      if (maskImage(selectedIndex)) {
        setTmpMaskImage(maskImage(selectedIndex))
      }
    }, [])

    return (
      <>
        <StyledFlexCenterHeightExpanded>
          <Box
            sx={{
              position: 'relative',
              width: previewSize?.width,
              height: previewSize?.height,
            }}
          >
            <DisposePointPin
              pointType="positive"
              samType={samType}
              points={positivePoints || []}
              setPositivePoints={setPositivePoints}
              setNegativePoints={setNegativePoints}
              setIsPreviewCompleted={setIsPreviewCompleted}
            />
            <DisposePointPin
              pointType="negative"
              samType={samType}
              points={negativePoints || []}
              setPositivePoints={setPositivePoints}
              setNegativePoints={setNegativePoints}
              setIsPreviewCompleted={setIsPreviewCompleted}
            />
            {isPreviewLoading && (
              <StyledLoadingSpinnerBox>
                <Spinner />
              </StyledLoadingSpinnerBox>
            )}
            {selectedImageBase64 && (
              <ImageWithLoader
                src={combinedBase64 || selectedImageBase64}
                fill
                alt=""
                style={{
                  objectFit: 'contain',
                }}
                quality={90}
                onClick={handleClick}
                loaderHeight={24}
                loaderWidth={24}
                loaderMode="spinner"
              />
            )}
          </Box>
        </StyledFlexCenterHeightExpanded>
      </>
    );
  },
);
