import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import { PostTasksV2Param } from '@/types/backendApi';
import { useCallback } from 'react';

export const useBackgroundParam = () => {
  const { featureData } = useFeaturesContext({});

  const getBackgroundRefImageParam = useCallback(() => {
    const param: PostTasksV2Param = {
      generationMode: 'generate_background_by_reference_image',
      originalImages: [
        {
          inputImageId: featureData?.mainImage.id,
          maskImageId: featureData?.mainImage.maskId,
        }
      ],
      displayName: featureData?.mainImage.fileName,
      nSample: featureData?.single?.param?.generationCount,
      refImage: {
        inputImageId: featureData?.refImage?.id
      },
      parameters: {
        withShadow: featureData?.single?.param?.withShadow === 'on',
        addBokeh: featureData?.single?.param?.blurLevel,
      },
    };

    return param;
  }, [
    featureData?.mainImage.fileName,
    featureData?.mainImage.id,
    featureData?.mainImage.maskId,
    featureData?.single?.param?.blurLevel,
    featureData?.single?.param?.generationCount,
    featureData?.single?.param?.withShadow,
    featureData?.refImage?.id,
  ]);

  const getBackgroundSinglePresetParam = useCallback(() => {
    const param = {
      generationMode: 'generate_background_by_preset',
      originalImages: [
        {
          inputImageId: featureData?.mainImage.id,
          maskImageId: featureData?.mainImage.maskId,
        }
      ],
      displayName: featureData?.mainImage.fileName,
      isHidden: false,
      nSample: featureData?.single?.param?.generationCount,
      parameters: {
        color: featureData?.single?.param?.currentColor,
        withShadow: featureData?.single?.param?.withShadow === 'on',
        returnBgOnly: false,
        presetId: featureData?.single?.param?.presetId,
      },
    };

    return param;
  }, [
    featureData?.mainImage.fileName,
    featureData?.mainImage.id,
    featureData?.mainImage.maskId,
    featureData?.single?.param?.currentColor,
    featureData?.single?.param?.generationCount,
    featureData?.single?.param?.presetId,
    featureData?.single?.param?.withShadow,
  ]);

  return {
    getBackgroundRefImageParam,
    getBackgroundSinglePresetParam,
  };
};
