import { Task } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import CheckFilled from '@/components/icons/export/CheckFilled';
import { StyledBoxWidth10, StyledFlexGap10 } from '@/components/styled';
import { Divider } from '@mui/material';
import { memo, useMemo } from 'react';
import { Completed } from './Completed';
import { Failed } from './Failed';
import { InProgress } from './InProgress';
import { InQueue } from './InQueue';

type Props = {
  data: Task;
};

export const Status = memo(({ data }: Props): JSX.Element => {
  const isDownloaded = useMemo(() => {
    return data.result.resultImages.some((image) => !!image.downloadedAt);
  }, [data.result.resultImages]);
  // TODO: あとで修正
  return (
    <Box>
      <StyledFlexGap10>
        <Divider orientation="vertical" flexItem />
        <StyledBoxWidth10 />
        {data.result.status === 'COMPLETED' && <Completed data={data} />}
        {data.result.status === 'IN_PROGRESS' && <InProgress data={data} />}
        {data.result.status === 'IN_QUEUE' && <InQueue data={data} />}
        {(data.result.status === 'FAILED' ||
          data.result.status === 'TIMEOUT') && <Failed data={data} />}
        { (isDownloaded || data.isInquired) && (
        <div>
          {isDownloaded && <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: 0,
            flexWrap: 'nowrap',
          }}>
            <CheckFilled width={20} height={20} fill="#2196F3" />
            <span style={{
              fontSize: '12px',
              fontWeight: '400',
              lineHeight: '166%',
              letterSpacing: '0.4px',
              color: 'var(--color-text-secondary)',
            }}>ダウンロード</span>
          </div>}
          {data.isInquired && <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: 0,
            flexWrap: 'nowrap',
          }}>
            <CheckFilled width={20} height={20} fill="var(--color-teal-400)" />
            <span style={{
              fontSize: '12px',
              fontWeight: '400',
              lineHeight: '166%',
              letterSpacing: '0.4px',
              color: 'var(--color-text-secondary)',
            }}>サポート</span>
          </div>}
        </div>
        )}
      </StyledFlexGap10>
    </Box>
  );
});
