import React, { memo } from 'react';
import * as vanilla from './vanilla.css';

type StyledTitleProps = {
  children: React.ReactNode;
}

export const StyledTitle = memo(({
  children
}: StyledTitleProps) => {
  return <span className={vanilla.styledTitle}>{children}</span>
})
