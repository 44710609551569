import { Box } from '@/components/atoms/Box';
import { StyledFlex } from '@/components/styled';
import { UploadRef } from '@/features/components/steps/BatchUpload/UploadRef';
import { UploadSet } from '@/features/components/steps/BatchUpload/UploadSet';
import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { WarningFunction } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { FunctionViewType } from '@/features/components/steps/BatchUpload/types';
import { memo, useMemo } from 'react';

type Props = {
  targetId: string;
  targetNumber: number;
  setFunctionViewType: (type: FunctionViewType) => void;
  warningFunction: WarningFunction;
};

export const Content = memo(
  ({
    targetId,
    targetNumber,
    setFunctionViewType,
    warningFunction,
  }: Props): JSX.Element => {
    const { batchConstant, currentNumber } = useBatchUpload();

    const backgroundColor = useMemo(() => {
      return currentNumber === targetNumber ? '#F5FAFF' : '#fff';
    }, [currentNumber, targetNumber]);

    return (
      <>
        <Box
          style={{
            borderTop: '1px solid #ddd',
            display: 'grid',
            padding: '16px 24px 16px 24px',
            backgroundColor,
          }}
        >
          <StyledFlex style={{ gap: '24px', alignItems: 'flex-start' }}>
            {batchConstant && batchConstant.hasUploadRef && (
              <UploadRef
                targetId={targetId}
                targetNumber={targetNumber}
                warningFunction={warningFunction}
              />
            )}
            <UploadSet
              targetId={targetId}
              targetNumber={targetNumber}
              setFunctionViewType={setFunctionViewType}
              warningFunction={warningFunction}
            />
          </StyledFlex>
        </Box>
      </>
    );
  },
);
