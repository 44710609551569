import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { StyledCircleAbove, StyledCircleBelow } from '../styled';

export const TargetIsColor = () => {
  const { featureData } = useFeaturesContext({});

  return (
    <>
      <StyledCircleAbove
        backgroundColor={
          featureData?.single?.param?.newColor
            ? featureData?.single?.param?.newColor
            : featureData?.single?.param?.currentColor
            ? featureData?.single?.param?.currentColor
            : ''
        }
      />
      <StyledCircleBelow
        backgroundColor={
          featureData?.single?.param?.currentColor
            ? featureData?.single?.param?.currentColor
            : featureData?.single?.param?.newColor
            ? featureData?.single?.param?.newColor
            : ''
        }
      />
    </>
  );
};
