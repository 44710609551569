export type TasksParam = {
  limit: number;
  offset: number;
  orderBy: string;
  createdAtAfterUnix: number;
  createdAtBeforeUnix: number;
  timer: number;
  userId?: string;
};

export const initialTasksOrderBy = {
  originalImageFileName: '',
  category: '',
  taskType: '',
  status: '',
  createdAt: 'desc',
  userId: '',
};

export type TasksOrderBy = {
  originalImageFileName: string;
  category: string;
  taskType: string;
  status: string;
  createdAt: string;
  userId: string;
};

export type UsersParam = {
  limit: number;
  offset: number;
  showDeleted: boolean;
  timer: number;
};
