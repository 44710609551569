import { ImageResult } from '@/contexts/FeaturesContext/types';
import { Swiper } from '@/features/components/organisms/DownloadDialogViewer/Swiper';
import { SwiperThumbs } from '@/features/components/organisms/DownloadDialogViewer/SwiperThumbs';
import { UseQueryResult } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import React, { memo, useMemo, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import type { DownloadDialogMode } from '../../modals/DownloadDialog';
import {
  StyledGlobalViewerContainer,
  StyledSwiperMotionDiv,
  StyledSwiperThumbsMotionDiv,
} from './styled';

type Props = {
  imageResults: ImageResult[];
  label?: React.ReactNode;
  hasAnimation?: boolean;
  hasCheckIcon?: boolean;
  repeatBackgroundImage?: string;
  taskType?: string;
  isDownloadDialog?: boolean;
  downloadDialogMode?: DownloadDialogMode;
  taskId: string | undefined;
  isOpenSupportAccordion: boolean;
  targetModeEn: string | undefined;
  handleCloseDownloadDialog: () => void;
  refetchTasks: UseQueryResult['refetch'];
};
// MEMO: 元々GlobbalViewerだったものを分離してサポート機能を追加にしたもの。子コンポーネントがかなりGlobalViewerと重複しているので、可能であれば共通化する。
// TODO: labelだけで仕様に追いつけなくなっているのでpropsから消してGlovalViewerと切り離す
// TODO: DownloadDialogのディレクトリに移動する
export const DownloadDialogViewer = memo(
  ({
    imageResults,
    label,
    hasAnimation = false,
    hasCheckIcon = false,
    repeatBackgroundImage,
    taskType,
    isDownloadDialog,
    downloadDialogMode,
    taskId,
    isOpenSupportAccordion,
    targetModeEn,
    handleCloseDownloadDialog,
    refetchTasks,
  }: Props) => {
    // biome-ignore lint/suspicious/noExplicitAny: あとで修正
    const [swiperRef, setSwiperRef] = useState<any>(null);
    const [swiperIndex, setSwiperIndex] = useState(0);
    const variant = useMemo(() => {
      return downloadDialogMode === 'selectSupportImage' || downloadDialogMode === 'supportForm' ? 'teal' : 'primary'
    }, [downloadDialogMode]);

    return (
      <StyledGlobalViewerContainer>
        <AnimatePresence initial={hasAnimation}>
          <StyledSwiperMotionDiv
            key="viewer-left"
            initial={{ x: hasAnimation ? 100 : 0, opacity: 0.5 }}
            animate={{ x: hasAnimation ? 0 : 0, opacity: 1 }}
            transition={{ duration: 0.4, ease: [0.04, 0.1, 0.2, 0.1] }}
          >
            <Swiper
              imageResults={imageResults}
              swiperRef={swiperRef}
              setSwiperRef={setSwiperRef}
              swiperIndex={swiperIndex}
              setSwiperIndex={setSwiperIndex}
              repeatBackgroundImage={repeatBackgroundImage}
              taskType={taskType}
              downloadDialogMode={downloadDialogMode}
              variant={variant}
            />
          </StyledSwiperMotionDiv>
          <StyledSwiperThumbsMotionDiv
            key="viewer-right"
            initial={{ x: hasAnimation ? -100 : 0, opacity: 0.5 }}
            animate={{ x: hasAnimation ? 0 : 0, opacity: 1 }}
            transition={{ duration: 0.4, ease: [0.04, 0.1, 0.2, 0.1] }}
          >
            <SwiperThumbs
              imageResults={imageResults}
              label={label}
              hasCheckIcon={hasCheckIcon}
              thumbnailBoxWidth={420}
              swiperRef={swiperRef}
              setSwiperRef={setSwiperRef}
              swiperIndex={swiperIndex}
              setSwiperIndex={setSwiperIndex}
              repeatBackgroundImage={repeatBackgroundImage}
              variant={variant}
              downloadDialogMode={downloadDialogMode}
              taskId={taskId}
              isOpenSupportAccordion={isOpenSupportAccordion}
              targetModeEn={targetModeEn}
              handleCloseDownloadDialog={handleCloseDownloadDialog}
              refetchTasks={refetchTasks}
            />
          </StyledSwiperThumbsMotionDiv>
        </AnimatePresence>
      </StyledGlobalViewerContainer>
    );
  },
);
