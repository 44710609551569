// import { useSnackbar } from '@/hooks/global/useSnackbar';
import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import {
  StyledFlexCenter,
  StyledFlexGap10,
  StyledGridGap25,
} from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ConfirmUnsettledSetDialog } from '@/features/components/modals/ConfirmUnsettledSetDialog';
import { useConditionDetector } from '@/features/components/steps/BatchUpload/hooks/useConditionDetector';
import { useBatchOptionalContext } from '@/features/components/steps/hooks/useBatchOptionalContext';
import { memo, useCallback, useMemo, useState } from 'react';
import { useBatchUpload } from '../hooks/useBatchUpload';
import { styledVerticalAlignBottom } from './styled/vanilla.css';

export const Buttons = memo((): JSX.Element => {
  const { activateTargetStep, activeFeatureName, updateFeatureDataArray } =
    useFeaturesContext({});
  const { isDisabledButton } = useConditionDetector();
  const { featureData, batchConstant } = useBatchUpload();
  const { setIsBatchGenerating } = useBatchOptionalContext();
  const [isAlertDialog, setIsAlertDialog] = useState(false);

  const handleNext = useCallback(() => {
    if (!featureData) return;
    if (!featureData.batch.length) return;
    const isUnsettled =
      featureData.batch.find((item) => {
        if (
          // 色温度の条件
          activeFeatureName === 'colorTemperature' &&
          batchConstant?.hasUploadRef &&
          featureData.batch.length > 1 &&
          item.refImage.refUrl &&
          item.originalImages.length <= 0
        ) {
          return true;
        }
        if (
          // 色温度の条件
          activeFeatureName === 'colorTemperature' &&
          batchConstant?.hasUploadRef &&
          featureData.batch.length > 1 &&
          !item.refImage.refUrl &&
          item.originalImages.length > 0
        ) {
          return true;
        }
        if (
          // 色温度の条件
          activeFeatureName === 'backgroundLora' &&
          featureData.batch.length > 1 &&
          item.originalImages.length <= 0
        ) {
          return true;
        }

        return false;
      }) !== undefined;
    if (isUnsettled && activeFeatureName === 'colorTemperature') {
      setIsAlertDialog(isUnsettled);
    }
    if (isUnsettled && activeFeatureName === 'backgroundLora') {
      console.log('backgroundLora');
      // 0番目を除外
      const updateSet = featureData.batch.slice(1);
      updateFeatureDataArray('batch', updateSet);
    }
    // if (isUnsettled) return;
    if (activeFeatureName === 'colorTemperature') {
      setIsBatchGenerating(true);
      activateTargetStep('batchDownload');
    } else {
      activateTargetStep('batchSetting');
    }
  }, [
    setIsBatchGenerating,
    featureData,
    activeFeatureName,
    batchConstant?.hasUploadRef,
    updateFeatureDataArray,
    activateTargetStep,
  ]);

  const buttonText = useMemo(() => {
    return activeFeatureName === 'colorTemperature' ? '生成' : '背景を設定';
  }, [activeFeatureName]);

  return (
    <>
      <StyledFlexCenter>
        <Box className={styledVerticalAlignBottom}>
          <StyledGridGap25>
            {activeFeatureName === 'colorTemperature' && (
              <StyledFlexGap10 style={{ alignItems: 'flex-start' }}>
                <Box>※</Box>
                <Box style={{ width: '250px' }}>
                  調整画像の色調整する領域は、生成時に自動で選択されます。
                </Box>
              </StyledFlexGap10>
            )}
            <>
              <Button
                width={272}
                height={42}
                onClick={handleNext}
                disabled={isDisabledButton}
              >
                {buttonText}
              </Button>
            </>
            <Box />
          </StyledGridGap25>
        </Box>
        <ConfirmUnsettledSetDialog
          isOpenDialog={isAlertDialog}
          handleCloseDialog={() => setIsAlertDialog(false)}
          handleSubmit={() => {
            setIsAlertDialog(false);
            activateTargetStep('batchDownload');
          }}
        />
      </StyledFlexCenter>
    </>
  );
});
