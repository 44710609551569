import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

type ColumnProps = {
  isClickable: boolean
  status: string;
} & BoxProps;

export const StyledTaskTrayRow = memo(
  ({ isClickable, status, children, ...props }: ColumnProps) => {
    return (
      <Box
        className={
          status === 'IN_PROGRESS'
            ? vanilla.styledColumnInProgress
            : status === 'FAILED' || status === 'TIMEOUT' || !isClickable // TODO: パターンが分かりづらいので言葉を定義しなおして整理する
            ? vanilla.styledColumnAnother
            : vanilla.styledColumnCompleted
        }
        {...props}
      >
        {children}
      </Box>
    );
  },
);
