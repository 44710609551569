import { Box } from '@/components/atoms/Box';
import { StyledGridEnd } from '@/components/styled';
import { StepExplain } from '@/features/components/atoms/StepExplain';
import { StyledExplainBox } from '@/features/components/atoms/StepExplain/styled';
import { ConfirmEraseDataDialog } from '@/features/components/modals/ConfirmEraseDataDialog';
import { useConfirmEraseData } from '@/features/components/modals/ConfirmEraseDataDialog/hooks/useConfirmEraseData';
import { StyledFunctionBox } from '@/features/components/styled/function';
import { FeaturesBatchDownloadLayout } from '@/features/components/templates/Layout/FeaturesBatchDownloadLayout';
import { memo } from 'react';
import { Buttons } from './Buttons';
import { Functions } from './Functions';
import { SetTable } from './SetTable';
import { useBatchDownload } from './hooks/useBatchDownload';

export const BatchDownload = memo((): JSX.Element => {
  const {
    taskApiResponse,
    setTaskApiResponse,
    retryId,
    setRetryId,
    batchId,
    setBatchId,
    isAllTasksDone,
    setIsAllTasksDone,
    deletedIds,
    setDeletedIds,
  } = useBatchDownload();

  const {
    isOpenConfirmEraseDataDialog,
    handleCloseConfirmEraseDataDialog,
    handleSubmitEraseDialog,
  } = useConfirmEraseData();

  return (
    <>
      <FeaturesBatchDownloadLayout>
        <Box>
          <StyledExplainBox>
            <StepExplain />
          </StyledExplainBox>
          <SetTable
            taskApiResponse={taskApiResponse}
            setTaskApiResponse={setTaskApiResponse}
            retryId={retryId}
            setRetryId={setRetryId}
            batchId={batchId}
            setBatchId={setBatchId}
            isAllTasksDone={isAllTasksDone}
            setIsAllTasksDone={setIsAllTasksDone}
            deletedIds={deletedIds}
            setDeletedIds={setDeletedIds}
          />
        </Box>
        <StyledFunctionBox>
          <Functions
            taskApiResponse={taskApiResponse}
            setRetryId={setRetryId}
            batchId={batchId}
          />
          <StyledGridEnd>
            <Buttons isAllTasksDone={isAllTasksDone} deletedIds={deletedIds} />
          </StyledGridEnd>
        </StyledFunctionBox>
      </FeaturesBatchDownloadLayout>
      <ConfirmEraseDataDialog
        isOpenDialog={isOpenConfirmEraseDataDialog}
        handleCloseDialog={handleCloseConfirmEraseDataDialog}
        handleSubmit={handleSubmitEraseDialog}
        displayType="batchDownload"
      />
    </>
  );
});
