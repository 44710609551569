import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { FeatureBatch } from '@/contexts/FeaturesContext/types';
import { useEffect, useState } from 'react';

export const useBatchCurrent = () => {
  const { featureData } = useFeaturesContext({});
  const [currentNumber, setCurrentNumber] = useState<number>(0);
  const [currentId, setCurrentId] = useState<string>('');
  const [currentState, setCurrentState] = useState<FeatureBatch | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!featureData) return;
    const foundIndex = featureData.batch.findIndex((set) => set.isCurrent);
    if (foundIndex !== -1) {
      setCurrentNumber(foundIndex);
      setCurrentId(featureData.batch[foundIndex].id);
      setCurrentState(featureData.batch[foundIndex]);
    }
  }, [featureData]);

  return {
    currentNumber,
    setCurrentNumber,
    currentId,
    setCurrentId,
    currentState,
    setCurrentState,
  };
};
