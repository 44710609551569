import { fabric } from 'fabric';
import { useCallback, useRef } from 'react';

type Props = {
  mainImageSource: string | null;
  maskImageSource: string | null;
  color?: string;
};

export const useImageMasking = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  // 画像を読み込むための関数
  const loadImage = useCallback((source: string, options = {}) => {
    return new Promise<fabric.Image>((resolve, reject) => {
      if (source) {
        fabric.Image.fromURL(
          source,
          (img) => {
            resolve(img);
          },
          options,
        );
      } else {
        reject(new Error('Invalid image source'));
      }
    });
  }, []);

  // 画像マスキング処理を行う関数
  const getImageMasking = useCallback(
    async ({ mainImageSource, maskImageSource, color = '#ff0000' }: Props) => {
      if (!mainImageSource || !maskImageSource) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Images are not set');
      }

      // 色の変換（Hex to RGB）
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);

      const canvas = new fabric.Canvas(canvasRef.current, { selection: false });

      try {
        const mainImg = await loadImage(mainImageSource, {
          crossOrigin: 'anonymous',
        });
        mainImg.set({
          originX: 'left',
          originY: 'top',
          selectable: false,
        });
        canvas.setWidth(mainImg.width || 0);
        canvas.setHeight(mainImg.height || 0);
        canvas.add(mainImg);

        const maskImg = await loadImage(maskImageSource, {
          crossOrigin: 'anonymous',
        });
        maskImg.set({
          originX: 'left',
          originY: 'top',
          selectable: false,
        });

        const maskCanvas = document.createElement('canvas');
        maskCanvas.width = maskImg.width || 0;
        maskCanvas.height = maskImg.height || 0;
        const maskCtx = maskCanvas.getContext('2d');

        if (!maskCtx) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('Failed to create mask context');
        }

        maskImg.render(maskCtx); // マスク画像を仮想Canvasに描画

        const imageData = maskCtx.getImageData(
          0,
          0,
          maskImg.width || 0,
          maskImg.height || 0,
        );
        const { data } = imageData;

        // 白い部分を半透明の指定色に変更
        for (let i = 0; i < data.length; i += 4) {
          if (data[i] > 200 && data[i + 1] > 200 && data[i + 2] > 200) {
            data[i] = r; // 赤
            data[i + 1] = g; // 緑
            data[i + 2] = b; // 青
            data[i + 3] = 127; // 透明度（半透明）
          } else {
            // 黒いピクセル、またはそれ以外の暗いピクセルの透明度を0にする
            data[i + 3] = 0; // 完全に透明に
          }
        }

        // 変更した imageData を maskCtx に戻す
        maskCtx.putImageData(imageData, 0, 0);
        const newImg = new fabric.Image(maskCanvas, {
          originX: 'left',
          originY: 'top',
          selectable: false,
        });
        canvas.add(newImg);
        canvas.renderAll();

        return Promise.resolve(canvas.toDataURL());
      } catch (error) {
        return Promise.reject(error);
      }
    },
    [loadImage],
  );

  return { getImageMasking };
};
