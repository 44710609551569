import { UPLOAD_MAX_SIZE } from '@/constants';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useBase64 } from '@/hooks/utils/useBase64';
import { useCallback } from 'react';
import Resizer from 'react-image-file-resizer';

/**
 * JSDoc
 * @see 画像アップロードのヘルパー関数
 * @returns {function} handleGetResized - 画像をリサイズしてbase64とサイズ情報を返却する関数
 * @returns {function} getImageInfo - 画像情報を判別してbase64とサイズ情報を返却する関数
 * @returns
 */
export const useUploadImageHelpers = () => {
  const { activeFeatureName } = useFeaturesContext({});
  const MAX_SIZE =
    UPLOAD_MAX_SIZE[activeFeatureName as keyof typeof UPLOAD_MAX_SIZE];
  const { getImageBase64 } = useBase64();

  const getResizedImage = useCallback(
    async (imageUrl: string, width: number, height: number) => {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      const resizedImage = await new Promise((resolve) => {
        Resizer.imageFileResizer(
          blob,
          width,
          height,
          'WEBP',
          90,
          0,
          (uri) => {
            resolve(uri as string);
          },
          'base64',
        );
      });
      if (!resizedImage) return;
      const base64 = resizedImage as string;
      const base64Length = base64.length;
      if (base64Length < 20000) return;
      const resizedBytes = blob.size;

      // eslint-disable-next-line consistent-return
      return {
        resizedBytes,
        base64,
      };
    },
    [],
  );

  const getImageInfo = useCallback(
    async (
      file: File,
      dimension: { width: number; height: number },
      blobUrl: string,
    ) => {
      let resizedWidth = 0;
      let resizedHeight = 0;

      if (dimension.width > MAX_SIZE || dimension.height > MAX_SIZE) {
        if (dimension.width > dimension.height) {
          const n = dimension.width / MAX_SIZE;
          resizedWidth = MAX_SIZE;
          resizedHeight = Math.round(dimension.height / n);
        } else if (dimension.width < dimension.height) {
          const n = dimension.height / MAX_SIZE;
          resizedWidth = Math.round(dimension.width / n);
          resizedHeight = MAX_SIZE;
        } else if (dimension.width === dimension.height) {
          resizedWidth = MAX_SIZE;
          resizedHeight = MAX_SIZE;
        }

        const { resizedBytes, base64 } =
          (await getResizedImage(blobUrl, resizedWidth, resizedHeight)) || {};

        return {
          originalSize: {
            width: dimension.width,
            height: dimension.height,
            bytes: file.size,
          },
          resizedSize: resizedBytes
            ? {
                width: resizedWidth,
                height: resizedHeight,
                bytes: resizedBytes,
              }
            : {
                width: 0,
                height: 0,
                bytes: 0,
              },
          base64: base64 || '',
        };
      }

      // リサイズ不要の場合は、元の画像サイズとBase64文字列を返す
      const base64 = await getImageBase64(blobUrl);

      return {
        originalSize: {
          width: dimension.width,
          height: dimension.height,
          bytes: file.size,
        },
        resizedSize: {
          width: 0,
          height: 0,
          bytes: 0,
        }, // リサイズされていないのでnull
        base64,
      };
    },
    [MAX_SIZE, getResizedImage, getImageBase64],
  );

  return {
    getImageInfo,
  };
};
