import { memo } from 'react';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import { StyledRadioBox, StyledRadioFlexEnd } from './styled';
import { styledDescription, styledLabel, styledLabelContainer } from './styled/vanilla.css';

type Props = {
  width?: string | number;
  label: string | JSX.Element;
  description: string;
  target: string | boolean;
  radioValue: string | boolean;
  isActive: boolean;
  onClick: (target: string | boolean) => void;
};

// MEMO: RadioButtonをベースに作成。RadioButtonの見た目とかぶっている部分があるため、必要あれば共通化する
// TODO: 余計なpropsがあるかもしれないので確認・最適化する
export const DownloadRadioButton = memo(
  ({
    width,
    label,
    description,
    target,
    radioValue,
    isActive,
    onClick: handleClick,
  }: Props): JSX.Element => {
    return (
      <StyledRadioBox
        width={width}
        onClick={() => {
          handleClick(target);
        }}
        isActive={isActive}
      >
        <div className={styledLabelContainer}>
          <span
            className={styledLabel}
          >{label}</span>
          {description && <span className={styledDescription}>{description}</span>}
        </div>
        <StyledRadioFlexEnd isActive={radioValue === target}>
          {radioValue === target ? (
            <MdRadioButtonChecked size={24} data-testid="radio-checked-icon" />
          ) : (
            <MdRadioButtonUnchecked
              size={24}
              data-testid="radio-unchecked-icon"
            />
          )}
        </StyledRadioFlexEnd>
      </StyledRadioBox>
    );
  },
);
