import { FeatureData } from '@/contexts/FeaturesContext/types';
import { useBatchOptionalContext } from '@/features/components/steps/hooks/useBatchOptionalContext';
import { useMemo } from 'react';

type Props = {
  featureData: FeatureData | undefined;
  currentNumber: number;
};
/**
 * JSDoc
 * @see 単体設定・一括設定の参照パラメーターをuseMemoで切り分けるカスタムフック
 */
export const useFunctionsCoordinateParams = ({
  featureData,
  currentNumber,
}: Props) => {
  const {
    isCollectiveSetting,
    collectiveSettingParam,
    collectiveRefImage,
    collectivePresetImage,
  } = useBatchOptionalContext();
  const targetType = useMemo(() => {
    if (!featureData) return 'none';
    if (isCollectiveSetting && collectiveSettingParam) {
      return collectiveSettingParam.targetType;
    }

    return featureData?.batch[currentNumber]?.mainParam.targetType;
  }, [collectiveSettingParam, currentNumber, featureData, isCollectiveSetting]);

  const blurLevel = useMemo(() => {
    if (!featureData) return 'none';
    if (isCollectiveSetting && collectiveSettingParam) {
      return collectiveSettingParam.blurLevel;
    }
    if (
      featureData &&
      featureData.batch[currentNumber] &&
      featureData?.batch[currentNumber].mainParam.blurLevel
    ) {
      return featureData?.batch[currentNumber].mainParam.blurLevel;
    }

    return 'none';
  }, [collectiveSettingParam, currentNumber, featureData, isCollectiveSetting]);

  const generationCount = useMemo(() => {
    if (!featureData) return 0;
    if (isCollectiveSetting && collectiveSettingParam) {
      return collectiveSettingParam.generationCount;
    }
    if (
      featureData &&
      featureData.batch[currentNumber] &&
      featureData?.batch[currentNumber].mainParam.generationCount
    ) {
      return featureData?.batch[currentNumber].mainParam.generationCount;
    }

    return 1;
  }, [collectiveSettingParam, currentNumber, featureData, isCollectiveSetting]);

  const backgroundUrl = useMemo(() => {
    if (!featureData) return '';
    if (!collectiveRefImage) return '';
    if (isCollectiveSetting && collectiveRefImage.refUrl) {
      return collectiveRefImage.refUrl;
    }
    if (
      featureData &&
      featureData.batch[currentNumber] &&
      featureData?.batch[currentNumber].refImage.refUrl
    ) {
      return featureData?.batch[currentNumber].refImage.refUrl;
    }

    return '';
  }, [collectiveRefImage, currentNumber, featureData, isCollectiveSetting]);

  const presetData = useMemo(() => {
    if (!featureData) return undefined;

    if (
      isCollectiveSetting &&
      collectivePresetImage &&
      collectivePresetImage?.examples?.length > 0
    ) {
      return {
        displayName: collectivePresetImage.displayName,
        url: collectivePresetImage.examples[0].url,
      };
    }
    if (
      featureData &&
      featureData.batch[currentNumber] &&
      featureData.batch[currentNumber]?.presetImage?.examples?.length > 0
    ) {
      return {
        displayName: featureData.batch[currentNumber].presetImage.displayName,
        url: featureData.batch[currentNumber].presetImage.examples[0].url,
      };
    }

    return undefined;
  }, [collectivePresetImage, currentNumber, featureData, isCollectiveSetting]);

  return {
    targetType,
    blurLevel,
    generationCount,
    backgroundUrl,
    presetUrl: presetData?.url ?? '',
    presetDisplayName: presetData?.displayName ?? '',
  };
};
