import { memo } from 'react';

type Props = {
  color?: string;
};
const WhiteIcon = memo(({ color = '#fff' }: Props): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
      <path
        fill={color}
        fillRule="evenodd"
        d="M1 3a2 2 0 0 1 2-2h1v3H1V3Zm5.4-2h3v3h-3V1Zm8.4 0h-3v3h3V1Zm2.4 0h3v3h-3V1Zm8.4 0h-3v3h3V1ZM28 1h1a2 2 0 0 1 2 2v1h-3V1ZM4 6H1v3h3V6Zm-3 5.5h3v3H1v-3ZM4 17H1v3h3v-3Zm-3 5.5h3v3H1v-3ZM4 28H1v1a2 2 0 0 0 2 2h1v-3ZM28 6h3v3h-3V6Zm3 5.5h-3v3h3v-3ZM28 17h3v3h-3v-3Zm3 5.5h-3v3h3v-3ZM28 28h3v1a2 2 0 0 1-2 2h-1v-3Zm-12-9a6 6 0 1 0 0-12 6 6 0 0 0 0 12ZM5.045 31H26.95c-.505-5.605-5.215-10-10.955-10S5.55 25.395 5.04 31h.005Z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default WhiteIcon;
