import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledBadgePosition = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBadgePosition} {...props}>
      {children}
    </Box>
  );
});

export const StyledBadgeWidth7Height7 = memo(() => {
  return <Box className={vanilla.styledBadgeWidth7Height7} />;
});
