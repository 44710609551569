import {
  StyledFlexEnd,
  StyledFlexGap10,
  StyledFont16Bold,
} from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageFields } from '@/contexts/FeaturesContext/types';
import { Switch } from '@mui/material';
import { memo } from 'react';

type Props = {
  field?: ImageFields;
  isKeepMask?: boolean;
  handleResetAfterPreview?: () => void;
};

export const NoBackgroundSwitch = memo(
  ({
    field = 'mainImage',
    isKeepMask,
    handleResetAfterPreview,
  }: Props): JSX.Element => {
    const { activeFeatureName, featureData, updateFeatureDataSingle } =
      useFeaturesContext({});

    const handleClickSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
      updateFeatureDataSingle('param', {
        isNoBackground: e.target.checked,
      });
      updateFeatureDataSingle('genStatus', {
        isGenerated: false,
        isEditing: false,
      });
      updateFeatureDataSingle('genResult', {
        generatedImageResults: [],
        image: '',
      });
      if (handleResetAfterPreview) {
        handleResetAfterPreview();
      }
    };

    return (
      <>
        {/* 微妙な条件（mainImageでkeepMaskじゃない）本当はisDialogで判定したい */}
        {field === 'mainImage' &&
        !isKeepMask &&
        activeFeatureName !== 'background' ? (
          <>
            <StyledFlexGap10 style={{ whiteSpace: 'nowrap' }}>
              <StyledFont16Bold>背景を透過する</StyledFont16Bold>
              <StyledFlexEnd>
                <Switch
                  checked={featureData?.single?.param?.isNoBackground ?? false}
                  onChange={handleClickSwitch}
                />
              </StyledFlexEnd>
            </StyledFlexGap10>
          </>
        ) : (
          <></>
        )}
      </>
    );
  },
);
