import { useBackendApi } from '@/api';
import { Segmentation } from '@/api/schemas';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageFields } from '@/contexts/FeaturesContext/types';
import { useError } from '@/hooks/global/useError';
import { useCallback, useEffect, useState } from 'react';

type Props = {
  field: ImageFields;
};

type PostAndProcessImageResponse = Segmentation & {
  id: string,
  maskId: string,
}

/**
 * JSDoc
 * @see セグメンテーションの背景をリクエストするカスタムフック
 * @param {Props} { field }
 * @returns {Object} handleRequestBackground
 */
export const useRequestBackground = ({ field }: Props) => {
  const { logger } = useError();
  const { featureData, updateFeatureData, activeFeatureName } =
    useFeaturesContext({});
  const [apiResponse, setApiResponse] = useState<PostAndProcessImageResponse | undefined>(
    undefined,
  );
  // 臨時対応・色温度の場合はtrue（外から受け取るべき？）
  const { postSegmentationBackground, postImages } = useBackendApi({
    isInverseResult: activeFeatureName === 'colorTemperature',
  });

  const postAndProcessImage = useCallback(async () => {
    if (
      !featureData?.[field].base64
    ) return undefined
    const {base64, fileName} = featureData[field]

    const postMainImagesResult = await postImages({
      image: base64,
      fileName,
    })

    const resultPostSegmentationBackground = await postSegmentationBackground(base64)

    const postMaskImagesResult = await postImages({
      image: resultPostSegmentationBackground?.maskImageBase64,
      fileName: `${fileName}-mask`,
    });

    if (
      !postMainImagesResult?.id
      || !postMaskImagesResult?.id
      || !resultPostSegmentationBackground?.maskImageBase64
      || !resultPostSegmentationBackground?.maskOverlayImageBase64
    ) {
      throw new Error("Reponse is invalid");
    }

    const response: PostAndProcessImageResponse = {
      ...resultPostSegmentationBackground,
      id: postMainImagesResult.id,
      maskId: postMaskImagesResult.id,
    }

    return response
  }, [
    featureData,
    field,
    postImages,
    postSegmentationBackground
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const handleRequestBackground = useCallback(async (): Promise<void> => {
    // MEMO: mainImageのfileNameは複数背景では保存していないのでfileNameが空でも実行する（保存していないことで問題が出ないかどうかは不明なので、問題があれば修正する）
    if (
      !featureData?.[field].base64
    ) return;
    try {
      updateFeatureData(field, {
        isPreviewLoading: true,
        maskBase64: '',
        combinedBase64: '',
      });

      const updateSet = await postAndProcessImage()
      setApiResponse(updateSet)
    } catch (error) {
      console.log(error);
      logger({ error });
    }
  }, [
    featureData?.[field].base64,
    postSegmentationBackground,
  ]);

  useEffect(() => {
    if (!apiResponse) return;

    // TODO: featureDataを自由な値にできるため危険。型制御やロジックの整備を行う。
    updateFeatureData(field, {
      isPreviewLoading: false,
      isPreviewCompleted: true,
      maskBase64: apiResponse.maskImageBase64, // TODO: v1/gen/tasks からv2に置き換えてから使われていない可能性があるため要確認
      combinedBase64: apiResponse.maskOverlayImageBase64, // TODO: v1/gen/tasks からv2に置き換えてから使われていない可能性があるため要確認
      id: apiResponse.id, // MEMO: v1/gen/tasks からv2に置き換えた際に追加した値。他のstepでは含まれていない場合がある
      maskId: apiResponse.maskId, // MEMO: v1/gen/tasks からv2に置き換えた際に追加した値。他のstepでは含まれていない場合がある
    });
  }, [
    apiResponse,
    field,
    updateFeatureData,
  ]);

  return {
    handleRequestBackground,
  };
};
