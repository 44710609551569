import { UPLOAD_IMAGE_LIMIT_BYTES } from '@/constants';

/**
 * @see アップロード画像のバリデーションカスタムフック
 */

export const useValidateUploadImage = () => {
  const validateImageFiles = (
    file: File,
    newArray: File[],
    dataSizeArray: File[],
    formatErrorArray: File[],
  ) => {
    if (file.name.match(/\.(jpg|jpeg|png|webp)$/i)) {
      if (file.size <= UPLOAD_IMAGE_LIMIT_BYTES) {
        newArray.push(file);
      } else {
        dataSizeArray.push(file);
      }
    } else {
      formatErrorArray.push(file);
    }
  };

  return {
    validateImageFiles,
  };
};
