import { atom, useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';

const contentRefAtom = atom<React.RefObject<HTMLDivElement> | null>(null);
const contentElementAtom = atom<HTMLDivElement | null>(null);
const hasFrameAtom = atom(false);
const contentWidthAtom = atom(0);
const contentHeightAtom = atom(0);
const contentScaleAtom = atom(1);

/**
 * JSDoc
 * @see アプリケーション全体のコンテキスト
 * @see 主にウィンドウ、コンテンツ領域のサイズを管理する
 * @returns {Object}
 * todo: オブジェクトにする
 */
export const useAppContext = () => {
  const [contentRefContext, setContentRefContext] = useAtom(contentRefAtom);
  const [contentWidth, setContentWidth] = useAtom(contentWidthAtom);
  const [contentHeight, setContentHeight] = useAtom(contentHeightAtom);
  const [contentScale, setContentScale] = useAtom(contentScaleAtom);

  const [contentElementContext, setContentElementContext] =
    useAtom(contentElementAtom);
  const [hasFrameContext, setHasFrameContext] = useAtom(hasFrameAtom);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!contentElementContext) return;
    const { width, height } = contentElementContext.getBoundingClientRect();
    setContentWidth(width);
    setContentHeight(height);
  }, [
    contentElementContext,
    setContentElementContext,
    setContentHeight,
    setContentWidth,
  ]);

  const MIN_HEIGHT = 770;
  const MAX_HEIGHT = 1100;
  const MIN_SCALE = 0.8;
  const MAX_SCALE = 1.2;

  const onResizeContent = useCallback(() => {
    if (!contentRefContext?.current) return;
    const { width, height } = contentRefContext.current.getBoundingClientRect();

    setContentWidth(width);
    setContentHeight(height);

    const calculateScale = (h: number) => {
      if (h <= MIN_HEIGHT) return MIN_SCALE;
      if (h >= MAX_HEIGHT) return MAX_SCALE;

      return (
        MIN_SCALE +
        ((h - MIN_HEIGHT) / (MAX_HEIGHT - MIN_HEIGHT)) * (MAX_SCALE - MIN_SCALE)
      );
    };
    setContentScale(calculateScale(height));
  }, [contentRefContext, setContentHeight, setContentScale, setContentWidth]);

  useEffect(() => {
    // biome-ignore lint/suspicious/noEmptyBlockStatements: 必要性が不明。あとで確認
    if (!contentRefContext?.current) return () => {};

    const resizeObserver = new ResizeObserver(onResizeContent);
    resizeObserver.observe(contentRefContext.current);

    return () => resizeObserver.disconnect();
  }, [onResizeContent, contentRefContext]);

  return {
    contentRefContext,
    setContentRefContext,
    contentElementContext,
    setContentElementContext,
    contentWidth,
    contentHeight,
    contentScale,
    hasFrameContext,
    setHasFrameContext,
  };
};
