import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import {
  FeatureData,
  FeaturePresetImage,
} from '@/contexts/FeaturesContext/types';
import { useBatchOptionalContext } from '@/features/components/steps/hooks/useBatchOptionalContext';
import { useCallback, useEffect, useState } from 'react';

type Props = {
  handleCloseBackgroundDialog: () => void;
  handleClosePresetDialog: () => void;
  featureData: FeatureData | undefined;
  currentNumber: number;
};

export const useFunctionsHandlers = ({
  handleCloseBackgroundDialog,
  handleClosePresetDialog,
  featureData,
  currentNumber,
}: Props) => {
  const { updateFeatureDataArray } = useFeaturesContext({});
  const [value, setValue] = useState<unknown | undefined>(undefined);
  const {
    isCollectiveSetting,
    collectiveSettingParam,
    setCollectiveSettingParam,
    setIsCollectiveSetting,
    setCollectivePresetImage,
  } = useBatchOptionalContext();
  const [isSubmit, setIsSubmit] = useState(false);

  const handleClickFeatureTargetType = useCallback(
    (target: string) => {
      if (!featureData) return;
      const updateSet = featureData.batch.map((set, index: number) => {
        if (index === currentNumber) {
          return {
            ...set,
            uploadedAt: Date.now(),
            mainParam: {
              ...featureData.batch[index].mainParam,
              targetType: target,
            },
          };
        }

        return set;
      });
      if (isCollectiveSetting) {
        setCollectiveSettingParam({
          ...collectiveSettingParam,
          ...updateSet[currentNumber].mainParam,
        });
      } else {
        updateFeatureDataArray('batch', updateSet);
      }
    },
    [
      collectiveSettingParam,
      currentNumber,
      featureData,
      isCollectiveSetting,
      setCollectiveSettingParam,
      updateFeatureDataArray,
    ],
  );

  const handleClickCollectiveSettingParam = useCallback(() => {
    if (!featureData) return;
    console.log('aaaaa');

    setIsCollectiveSetting(!isCollectiveSetting);
    if (!isCollectiveSetting) {
      // currentのbatch paramをコピー
      const updateSet = featureData?.batch.find(
        (set, index: number) => index === currentNumber,
      );
      if (updateSet) {
        console.log(collectiveSettingParam);

        setCollectiveSettingParam({
          ...collectiveSettingParam,
          ...updateSet.mainParam,
        });
        setCollectivePresetImage(updateSet.presetImage);
      }
    }
  }, [
    featureData,
    setIsCollectiveSetting,
    isCollectiveSetting,
    currentNumber,
    collectiveSettingParam,
    setCollectiveSettingParam,
    setCollectivePresetImage,
  ]);

  useEffect(() => {
    if (!featureData) return;
    if (!featureData.batch) return;
    if (!value) return;
    const key = Object.keys(value)[0];
    const targetValue = value[key as keyof typeof value];
    const updateSet = featureData?.batch.map((set, index: number) => {
      if (index === currentNumber) {
        return {
          ...set,
          uploadedAt: Date.now(),
          mainParam: {
            ...featureData.batch[index].mainParam,
            [key]: targetValue,
          },
        };
      }

      return set;
    });
    if (isCollectiveSetting) {
      console.log(updateSet[currentNumber].mainParam);
      setCollectiveSettingParam({
        ...collectiveSettingParam,
        ...updateSet[currentNumber].mainParam,
      });
    } else {
      updateFeatureDataArray('batch', updateSet);
    }
    setValue(undefined);
  }, [
    collectiveSettingParam,
    currentNumber,
    featureData,
    isCollectiveSetting,
    setCollectiveSettingParam,
    updateFeatureDataArray,
    value,
  ]);

  const handleSubmitBackground = useCallback(() => {
    setIsSubmit(true);

    handleCloseBackgroundDialog();
  }, [handleCloseBackgroundDialog]);

  const handleSubmitPreset = useCallback(
    (presetValue: FeaturePresetImage) => {
      if (!featureData) return;
      if (!featureData.batch) return;
      const updateSet = featureData?.batch.map((set, index: number) => {
        if (index === currentNumber) {
          return {
            ...set,
            uploadedAt: Date.now(),
            presetImage: presetValue,
          };
        }

        return set;
      });
      if (isCollectiveSetting) {
        setCollectivePresetImage({
          id: updateSet[currentNumber].presetImage.id,
          displayName: updateSet[currentNumber].presetImage.displayName,
          examples: updateSet[currentNumber].presetImage.examples,
        });
      } else {
        updateFeatureDataArray('batch', updateSet);
      }

      setIsSubmit(true);
      handleClosePresetDialog();
    },
    [
      currentNumber,
      featureData,
      handleClosePresetDialog,
      isCollectiveSetting,
      setCollectivePresetImage,
      updateFeatureDataArray,
    ],
  );

  return {
    handleClickFeatureTargetType,
    value,
    setValue,
    handleClickCollectiveSettingParam,
    isCollectiveSetting,
    collectiveSettingParam,
    handleSubmitBackground,
    handleSubmitPreset,
    isSubmit,
    setIsSubmit,
  };
};
