import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useEffect } from 'react';

/**
 * JSDoc
 * @see ステップの判定を行うカスタムフック
 * @returns {void}
 */
export const useStepDetector = () => {
  const { featureData, activateTargetStep } = useFeaturesContext({});

  useEffect(() => {
    if (!featureData?.mainImage.base64) {
      activateTargetStep('upload');
    }
  }, [featureData?.mainImage.base64, activateTargetStep]);
};
