import { Box } from '@/components/atoms/Box';
import {
  StyledFlexGap5,
  StyledFont,
  StyledFont16Bold,
} from '@/components/styled';
import { theme } from '@/config/theme';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { memo } from 'react';

export const BackgroundLoraNotice = memo((): JSX.Element => {
  const { updateFeatureDataArray, activateTargetStep } = useFeaturesContext({});

  return (
    <>
      <Box
        style={{
          padding: 16,
          borderRadius: '4px',
          backgroundColor: theme.palette.primary.pale,
          width: 280,
          display: 'grid',
          gap: 20,
          marginBottom: '24px',
        }}
      >
        <StyledFont16Bold>
          すべてのセットが 生成準備完了になると生成ができます。
        </StyledFont16Bold>
        <Box>
          <StyledFont>
            セット内のアップロードしたすべての画像に、バリエーション選択した背景が適用されます。
          </StyledFont>
        </Box>
      </Box>
      <Box style={{
        marginBottom: '16px',
        display: 'flex',
        alignItems: 'flex-start',
      }}>
        <Box
          style={{
            verticalAlign: 'top',
            height: '100%',
            fontSize: '14px',
          }}
        >
          ※
        </Box>
        <Box style={{ fontSize: '14px' }}>
          「バリエーションを再生成」ボタンをクリックして、バリエーションを更新できます。
        </Box>
      </Box>
      <StyledFlexGap5>
        <Box
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            verticalAlign: 'top',
            height: '100%',
            fontSize: '14px',
          }}
        >
          ※
        </Box>
        <Box style={{ fontSize: '14px' }}>
          不要なセットは生成から除外できます。
        </Box>
      </StyledFlexGap5>
    </>
  );
});
