import { atom, useAtom } from 'jotai';

const isOpenLoadingSpinnerAtom = atom<boolean>(false);

/**
 * JSDoc
 * @see ローディングスピナーの状態を管理するグローバルカスタムフック
 * @returns {Object} ローディングスピナーの状態を管理するオブジェクト
 */
export const useLoadingSpinner = () => {
  const [isOpenLoadingSpinner, setIsOpenLoadingSpinner] = useAtom(
    isOpenLoadingSpinnerAtom,
  );

  return {
    isOpenLoadingSpinner,
    setIsOpenLoadingSpinner,
  };
};
