import { memo } from 'react';
import { AccordionRow } from './AccordionRow';

type Props = {
  handleOpenSetNameDialog: () => void;
};

export const Body = memo(
  ({
    handleOpenSetNameDialog,
  }: Props): JSX.Element => {
    return (
      <>
        <AccordionRow
          handleOpenSetNameDialog={handleOpenSetNameDialog}
        />
      </>
    );
  },
);
