import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useEffect, useState } from 'react';

type Props = {
  field: 'refImage' | 'mainImage';
};

/**
 * JSDoc
 * @see モーダルステップ検出フック
 * @returns {{ modalStep: string }}
 */
export const useStepDetector = ({ field }: Props) => {
  const { featureData, activeFeatureName, findActiveStep } = useFeaturesContext(
    {},
  );

  const [modalStep, setModalStep] = useState('upload');

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!featureData?.[field].base64) {
      setModalStep('upload');

      return;
    }
    if (activeFeatureName === 'background' && featureData?.[field].base64) {
      setModalStep('uploadEnd');

      return;
    }
    if (
      activeFeatureName === 'backgroundLora' &&
      findActiveStep()?.name === 'batchSetting'
    ) {
      setModalStep('uploadEnd');

      return;
    }
    setModalStep('segmentation');
  }, [activeFeatureName, featureData?.[field].base64]);

  return { modalStep };
};
