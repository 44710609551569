import { Report } from '@/api/schemas';
import { StyledFlexGap10, StyledFlexWidthExpanded } from '@/components/styled';
import { IconButton } from '@mui/material';
import { memo } from 'react';
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';
import { useMonthlyPicker } from './hooks/useMonthlyPicker';
import {
  StyledDateSpanBox,
  StyledMonth,
  StyledSearchContainer,
  StyledYear,
} from './styled';

type Props = {
  apiResponse: Report;
  startYear: number;
  startMonth: number;
  targetYear: number;
  targetMonth: number;
  setTargetYear: (year: number) => void;
  setTargetMonth: (month: number) => void;
};

export const Search = memo(
  ({
    apiResponse,
    startYear,
    startMonth,
    targetYear,
    targetMonth,
    setTargetYear,
    setTargetMonth,
  }: Props): JSX.Element => {
    const { thisYear, thisMonth, thisStartDate, thisEndDate, availableMonths } =
      useMonthlyPicker({
        apiResponse,
        startYear,
        startMonth,
        targetYear,
        targetMonth,
        setTargetYear,
        setTargetMonth,
      });

    return (
      <StyledSearchContainer>
        <StyledFlexWidthExpanded>
          <StyledFlexGap10>
            {targetYear > startYear && (
              <IconButton
                onClick={() => {
                  setTargetYear(targetYear - 1);
                  setTargetMonth(12);
                }}
              >
                <MdOutlineKeyboardArrowLeft
                  size={20}
                  color={
                    targetYear > startYear
                      ? 'var(--color-gray-deep)'
                      : 'var(--color-gray-soft)'
                  }
                />
              </IconButton>
            )}
            <StyledYear>{targetYear}年</StyledYear>
            {availableMonths.map((month) => (
              <StyledMonth
                key={month}
                targetYear={targetYear}
                month={month}
                thisYear={thisYear}
                thisMonth={thisMonth}
                onClick={() => {
                  setTargetYear(thisYear);
                  setTargetMonth(month);
                }}
              >
                {month}月
              </StyledMonth>
            ))}
            {targetYear <= startYear && (
              <IconButton
                onClick={() => {
                  setTargetYear(targetYear + 1);
                  setTargetMonth(1);
                }}
              >
                <MdOutlineKeyboardArrowRight
                  size={20}
                  color={
                    targetYear <= startYear
                      ? 'var(--color-gray-deep)'
                      : 'var(--color-gray-soft)'
                  }
                />
              </IconButton>
            )}
          </StyledFlexGap10>
          <StyledDateSpanBox>
            集計期間 {thisStartDate} ~ {thisEndDate}
          </StyledDateSpanBox>
        </StyledFlexWidthExpanded>
      </StyledSearchContainer>
    );
  },
);
