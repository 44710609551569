import { Box } from '@/components/atoms/Box';
import TrayIcon from '@/components/icons/others/TrayIcon';
import { useTaskTray } from '@/hooks/global/useTaskTray';
import { AnimatePresence, motion } from 'framer-motion';
import { memo } from 'react';
import { StyledBadgePosition, StyledBadgeWidth7Height7 } from './styled';
import { styledIconClosed, styledIconOpened } from './styled/vanilla.css';

export const TaskTrayIconWithAnimation = memo((): JSX.Element => {
  const { isTaskTrayAnimation, setIsTaskTrayAnimation, isOpenTaskTray } =
    useTaskTray();

  const handleEndTaskTrayAnimation = () => {
    setIsTaskTrayAnimation(false);
  };

  return (
    <Box className={isOpenTaskTray ? styledIconOpened : styledIconClosed}>
      <AnimatePresence initial={false}>
        {isTaskTrayAnimation && (
          <motion.div
            initial={{ opacity: 0, y: 10, scale: 0.5 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{
              type: 'spring',
              stiffness: 300,
              duration: isTaskTrayAnimation ? 1 : 0,
              repeat: 0,
            }}
            onAnimationComplete={() => {
              handleEndTaskTrayAnimation();
            }}
          >
            <StyledBadgePosition>
              <StyledBadgeWidth7Height7 />
            </StyledBadgePosition>
          </motion.div>
        )}
      </AnimatePresence>
      <TrayIcon isOpenTaskTray={isOpenTaskTray} />
    </Box>
  );
});
