import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledDialogContainer = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledDialogContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledUploadEndFunctionBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledUploadEndFunctionBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledUploadEndButtonBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledUploadEndButtonBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledSegmentationFunctionBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledSegmentationFunctionBox} {...props}>
        {children}
      </Box>
    );
  },
);
