import { axiosGet } from "@/api/axios";
import { Task } from '@/api/schemas';
import { useQuery } from "@tanstack/react-query";

type UseTaskDataArgs = {
  id: string | undefined;
}

// TODO: 共通のリクエストロジックではenabledが使える状態になかったのでいったん簡易的に定義した。後々useGet, usePost側でenabledが使えるように書き直す。
export const useTaskData = ({
  id,
}: UseTaskDataArgs) => {
  const {
    data: taskData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["gen/tasks", id],
    queryFn: () => axiosGet(`gen/tasks/${id}`),
    useErrorBoundary: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 0,
    enabled: !!id,
  })

  return {
    taskData: taskData as Task | undefined,
    isLoading,
    refetch,
  }
}
