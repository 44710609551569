import {
  StyledFlexCenter,
  StyledGridEnd,
  StyledGridGap10,
} from '@/components/styled';
import { StyledDialogUploadEndButton } from '@/components/styled/button';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageFields } from '@/contexts/FeaturesContext/types';
import { FileDetail } from '@/features/components/atoms/FileDetail';
import { GlobalSegmentation } from '@/features/components/organisms/GlobalSegmentation';
import {
  StyledPreview,
  StyledPreviewSlide,
} from '@/features/components/styled/preview';
import { FeaturesModalStepLayout } from '@/features/components/templates/Layout/FeaturesModalStepLayout';
import { memo } from 'react';
import { useHandlers } from '../hooks/useHandlers';
import {
  StyledUploadEndButtonBox,
  StyledUploadEndFunctionBox,
} from '../styled';

type Props = {
  field: ImageFields;
  onSubmit: () => void;
};
/**
 * JSDoc
 * @see アップロード終了ステップ
 * @see 背景の場合はこのステップで終了
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const UploadEnd = memo(({ field, onSubmit }: Props): JSX.Element => {
  const { handleResetImageFromModal } = useHandlers();
  const { featureData, updateFeatureData } = useFeaturesContext({});

  return (
    <>
      <FeaturesModalStepLayout>
        <StyledGridGap10>
          <StyledPreview swiperIndex={0} maxIndex={0} width={660}>
            <StyledPreviewSlide>
              {featureData && (
                <GlobalSegmentation
                  field="refImage"
                  data={featureData}
                  updateData={updateFeatureData}
                />
              )}
            </StyledPreviewSlide>
          </StyledPreview>
        </StyledGridGap10>
        <StyledUploadEndFunctionBox sx={{ marginTop: '20px' }}>
          <FileDetail field={field} onResetImage={handleResetImageFromModal} onDialog />

          <StyledGridEnd>
            <StyledFlexCenter>
              <StyledUploadEndButtonBox>
                <StyledDialogUploadEndButton onClick={onSubmit}>
                  決定
                </StyledDialogUploadEndButton>
              </StyledUploadEndButtonBox>
            </StyledFlexCenter>
          </StyledGridEnd>
        </StyledUploadEndFunctionBox>
      </FeaturesModalStepLayout>
    </>
  );
});
