import { StyledGridGap10 } from '@/components/styled';
import { FeatureBatchOriginalImage } from '@/contexts/FeaturesContext/types';
import { BatchUploadRetryDialog } from '@/features/components/modals/BatchUploadRetryDialog/BatchUploadRetryDialog';
import { useBatchUploadRetryDialog } from '@/features/components/modals/BatchUploadRetryDialog/hooks/useBatchUploadRetryDialog';
import { UploadRefWithOptionalSam } from '@/features/components/modals/UploadRefWithOptionalSam';
import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { WarningFunction } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { FunctionViewType } from '@/features/components/steps/BatchUpload/types';
import { useDialog } from '@/hooks/local/useDialog';
import { memo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useBatchGetFiles } from '../hooks/useGetBatchFiles';
import { ThumbnailWithCheck } from './ThumbnailWithCheck';
import { UploadAddInterface } from './UploadAddInterface';
import { UploadSetInterface } from './UploadSetInterface';
import { FileResult } from './hooks/types';
import { useUploadImage } from './hooks/useUploadImage';
import { useUploadSetHandlers } from './hooks/useUploadSetHandlers';
import { StyledImageSetContainer } from './styled';

type Props = {
  targetId: string;
  targetNumber: number;
  setFunctionViewType: (type: FunctionViewType) => void;
  warningFunction: WarningFunction;
};

/**
 * @see 調整画像をアップロード・表示するコンポーネント
 * @see 色温度と背景Loraで挙動が異なる
 * @see 色温度は領域選択なし
 * @see 背景Loraは領域選択あり
 * @see 背景Loraの場合は領域選択語の合成画像を表示する
 */
export const UploadSet = memo(
  ({
    targetId,
    targetNumber,
    setFunctionViewType,
    warningFunction,
  }: Props): JSX.Element => {
    const { featureData, currentNumber, updateFeatureDataArray } = useBatchUpload();
    const { handleSelectImage, handleCheckDeleteImages } = useUploadSetHandlers(
      { targetNumber, setFunctionViewType },
    );
    const { isOpenDialog, handleCloseDialog } = useDialog();

    // TODO: 意味なさそうだが消して問題ないか確認する
    const handleSubmit = () => {
      console.log('submit');
    };

    // TODO: uploadAddInterface/uploadSetInterfaceから実行していた処理。適切なカスタムフックを作って切り出す。
    const [apiResponse, setApiResponse] = useState<FileResult[]>([]);
    const {
      isOpenBatchUploadRetryDialog,
      handleCloseBatchUploadRetryDialog,
    } = useBatchUploadRetryDialog();

    const { getFiles } = useBatchGetFiles({
      updateErrorAtGettingFiles: warningFunction.updateErrorAtGettingFiles,
    });
    const { getRootProps, getInputProps, acceptedFiles, isDragActive } =
      useDropzone({
        getFilesFromEvent: (event) => getFiles(event),
      });

    const { handleRetryPostImages } = useUploadImage({
      featureData,
      updateFeatureDataArray,
      updateWarningKeyValue: warningFunction.updateWarningKeyValue,
      resetWarnings: warningFunction.resetWarnings,
      acceptedFiles,
      targetNumber,
      apiResponse,
      setApiResponse,
    });

    return (
      <>
        {featureData &&
        featureData.batch.length > 0 &&
        featureData?.batch[targetNumber]?.originalImages.length > 0 ? (
          <StyledGridGap10>
            <StyledImageSetContainer>
              <>
                <UploadAddInterface
                  key={`upload-add-interface${featureData.batch[targetNumber].id}`}
                  targetId={targetId}
                  targetNumber={targetNumber}
                  disabled={
                    targetNumber !== currentNumber ||
                    featureData.batch[targetNumber].originalImages.length >= 20
                  }
                  warningFunction={warningFunction}
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  isDragActive={isDragActive}
                />
                {featureData?.batch[targetNumber]?.originalImages.map(
                  (image: FeatureBatchOriginalImage, index: number) => (
                    <ThumbnailWithCheck
                      key={`thumbnail-with-check-${index}`.toString()}
                      image={image}
                      index={index}
                      handleCheckDeleteImages={handleCheckDeleteImages}
                      handleSelectImage={handleSelectImage}
                      warningFunction={warningFunction}
                      featureData={featureData}
                      targetNumber={targetNumber}
                    />
                  ),
                )}
              </>
            </StyledImageSetContainer>
          </StyledGridGap10>
        ) : (
          <UploadSetInterface
            key={`upload-set-interface${featureData?.batch[targetNumber].id}`}
            targetId={targetId}
            targetNumber={targetNumber}
            disabled={targetNumber !== currentNumber}
            warningFunction={warningFunction}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
          />
        )}
        <UploadRefWithOptionalSam
          isOpenDialog={isOpenDialog}
          onClose={handleCloseDialog}
          onSubmit={handleSubmit}
          dialogTitle="選択範囲の調整"
          dialogExplain=""
          isBatch
        />
        <BatchUploadRetryDialog
          isOpen={isOpenBatchUploadRetryDialog}
          handleCloseDialog={handleCloseBatchUploadRetryDialog}
          handleRetryPostImages={handleRetryPostImages}
        />
      </>
    );
  },
);
