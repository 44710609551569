import { atom, useAtom } from "jotai";

const isRetryModeAtom = atom<boolean>(false);

// TODO: 必要なくなったので消す
export const useBatchUploadAtoms = () => {
  const [isRetryMode, setIsRetryMode] = useAtom(isRetryModeAtom); // TODO: 仕様が変わり、現状はusePostImagesのuseEffectを実行するトリガーとしてのみ機能している。useEffectでの処理を直接呼び出して実行できるような処理に書き換える

  return {
    isRetryMode,
    setIsRetryMode,
  };
}
