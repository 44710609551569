import type { SVGProps } from 'react';
import { memo } from 'react';
const BigChecked = memo((props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      fill="none"
      {...props}
    >
      <rect width={48} height={48} fill="#fff" rx={24} />
      <path
        fill="#2196F3"
        d="M24 3C12.403 3 3 12.403 3 24s9.403 21 21 21 21-9.403 21-21S35.597 3 24 3Zm9.07 14.142L23.198 30.83a1.489 1.489 0 0 1-2.423 0l-5.845-8.1a.376.376 0 0 1 .304-.596h2.199c.478 0 .933.23 1.214.624l3.337 4.631 7.37-10.219a1.5 1.5 0 0 1 1.213-.623h2.199c.304 0 .482.347.304.595Z"
      />
    </svg>
  );
});
export default BigChecked;
