import DummyImage from '@/assets/any/dummy.png';
import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import Image from 'next/image';
import {
  StyledCircleAbove,
  StyledCircleBelow,
  StyledCircleBoth,
  StyledImageAbove,
  StyledImageBelow,
} from '../styled';

export const TargetIsBackgroundImage = () => {
  const { featureData } = useFeaturesContext({});

  return (
    <>
      {featureData?.single?.param?.newRef ===
        featureData?.single?.param?.currentRef && (
        <>
          <StyledCircleBoth>
            <Image
              src={
                featureData?.single?.param?.currentRef
                  ? featureData?.single?.param?.currentRef
                  : DummyImage
              }
              style={{
                objectFit: 'cover',
                borderRadius: '40px',
              }}
              fill
              alt="pict"
            />
          </StyledCircleBoth>
        </>
      )}
      {featureData?.single?.param?.newRef !==
        featureData?.single?.param?.currentRef && (
        <>
          <StyledCircleAbove>
            <StyledImageAbove
              src={
                featureData?.single?.param?.newRef
                  ? featureData?.single?.param?.newRef
                  : featureData?.single?.param?.currentRef
                  ? featureData?.single?.param?.currentRef
                  : DummyImage
              }
              fill
              alt="pict"
            />
          </StyledCircleAbove>

          <StyledCircleBelow>
            <StyledImageBelow
              src={
                featureData?.single?.param?.currentRef
                  ? featureData?.single?.param?.currentRef
                  : featureData?.single?.param?.newRef
                  ? featureData?.single?.param?.newRef
                  : DummyImage
              }
              fill
              alt="pict"
            />
          </StyledCircleBelow>
        </>
      )}
    </>
  );
};
