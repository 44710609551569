import { Box, BoxProps } from '@/components/atoms/Box';
import {
  FormControl,
  FormControlProps,
  Input,
  InputProps,
} from '@mui/material';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledFormControl = memo(
  ({ children, ...props }: FormControlProps) => {
    return (
      <FormControl className={vanilla.styledFormControl} {...props}>
        {children}
      </FormControl>
    );
  },
);

type DateInputProps = {
  isDateError: boolean;
} & InputProps;

export const StyledInput = memo(({ isDateError, ...props }: DateInputProps) => {
  const inputStyle = {
    color: isDateError ? 'var(--color-danger-main)' : 'inherit',
  };

  return <Input style={inputStyle} {...props} />;
});

export const StyledPaddingRight20pxBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledPaddingRight20pxBox} {...props}>
        {children}
      </Box>
    );
  },
);
