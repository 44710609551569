import { TableCellProps, TableCell as Td } from '@mui/material';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledTd = memo(({ children, ...props }: TableCellProps) => {
  return (
    <Td className={vanilla.styledTd} {...props}>
      {children}
    </Td>
  );
});

export const StyledTdWidth300 = memo(
  ({ children, ...props }: TableCellProps) => {
    return (
      <Td className={vanilla.styledTdWidth300} {...props}>
        {children}
      </Td>
    );
  },
);
