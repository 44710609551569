import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { StyledFlex, StyledFlexGap5, StyledFontSx } from '@/components/styled';
import {
  StyledErrorIcon,
  StyledErrorIconBox,
} from '@/features/components/atoms/ErrorCard/styled';
import { memo, useCallback } from 'react';

type Props = {
  setRetryId: (retryId: string) => void;
  batchId: string;
};

export const FailedDisplay = memo(
  ({ setRetryId, batchId }: Props): JSX.Element => {
    const handleRetry = useCallback(() => {
      setRetryId(batchId);
    }, [batchId, setRetryId]);

    return (
      <>
        <Box
          style={{
            padding: 16,
            borderRadius: '4px',
            backgroundColor: '#fdeded',
            color: '#5f2120',
            width: 280,
            display: 'grid',
            gap: 20,
          }}
        >
          <StyledFlex
            style={{
              alignItems: 'center',
              gap: '5px',
              width: '100%',
              cursor: 'pointer',
            }}
          >
            <Box style={{ height: 26 }}>
              <StyledErrorIconBox data-testid="test-icon">
                <StyledErrorIcon />
              </StyledErrorIconBox>
            </Box>
            <StyledFontSx
              style={{
                fontSize: 16,
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
              }}
            >
              エラーが発生しました
            </StyledFontSx>
          </StyledFlex>
          <Box>
            <Button
              mode="dangerBorder"
              style={{ width: 245, fontSize: 16, fontWeight: 600 }}
              onClick={handleRetry}
            >
              リトライする
            </Button>
          </Box>
          <Box
            style={{
              whiteSpace: 'pre-line',
              overflowWrap: 'anywhere',
            }}
          >
            <Box>
              <StyledFlexGap5 style={{ alignItems: 'flex-start' }}>
                <StyledFontSx>※</StyledFontSx>
                <StyledFontSx>
                  何度もエラーが起こる場合は画面右上のボタンからお問い合わせください。
                </StyledFontSx>
              </StyledFlexGap5>
            </Box>
          </Box>
        </Box>
      </>
    );
  },
);
