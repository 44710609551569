import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useEffect, useState } from 'react';

/**
 * JSDoc
 * @see モーダルステップ検出フック
 * @returns {{ modalStep: string }}
 */
export const useStepDetector = () => {
  const { featureData, activeFeatureName } = useFeaturesContext({});

  const [modalStep, setModalStep] = useState('segmentation');

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    setModalStep('segmentation');
  }, [activeFeatureName, featureData?.refImage.base64]);

  return { modalStep };
};
