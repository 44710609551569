import { z } from 'zod';

export const SegmentationSchema = z
  .object({
    maskImageBase64: z.string(),
    maskOverlayImageBase64: z.string(),
  })
  .nullable();

export type Segmentation = z.infer<typeof SegmentationSchema>;
