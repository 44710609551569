import { Box, BoxProps } from '@/components/atoms/Box';
import { forwardRef, memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledUploadInterfaceContainer = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledUploadInterfaceContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledDragAndDropArea = forwardRef<HTMLDivElement, BoxProps>(
  ({ children, ...props }, ref) => {
    return (
      <Box ref={ref} className={vanilla.styledDragAndDropArea} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledUploadConditionBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledUploadConditionBox} {...props}>
        {children}
      </Box>
    );
  },
);
