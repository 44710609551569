import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledDialogContainer = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledDialogContainer} {...props}>
        {children}
      </Box>
    );
  },
);
export const StyledDialogWidthBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledDialogWidthBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledHeaderBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledHeaderBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledBoxPaddingTop100 = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledBoxPaddingTop100} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledBoxPaddingTop270 = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledBoxPaddingTop270} {...props}>
        {children}
      </Box>
    );
  },
);
