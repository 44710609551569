import { Report } from '@/api/schemas';
import {
  StyledFlexEnd,
  StyledFlexGap20,
  StyledFont,
} from '@/components/styled';
import { memo } from 'react';
import {
  StyledBigBoldRightText,
  StyledBigBoldText,
  StyledStatusBox,
  StyledStatusContainer,
} from './styled';

type Props = {
  reportApiResponse: Report;
};

export const Status = memo(({ reportApiResponse }: Props): JSX.Element => {
  return (
    <>
      {reportApiResponse.generation && (
        <StyledStatusContainer>
          <StyledFlexGap20>
            <StyledStatusBox>
              <StyledFont>生成数</StyledFont>
              <StyledFlexEnd>
                <StyledBigBoldText>
                  {reportApiResponse.generation.total}
                </StyledBigBoldText>
                <StyledBigBoldRightText>
                  {reportApiResponse.generation.limit === null
                    ? ''
                    : `/${reportApiResponse.generation.limit.toLocaleString()}`}
                </StyledBigBoldRightText>
              </StyledFlexEnd>
            </StyledStatusBox>
            <StyledStatusBox>
              <StyledFont>ダウンロード数</StyledFont>
              <StyledFlexEnd>
                <StyledBigBoldText>
                  {reportApiResponse.download.total}
                </StyledBigBoldText>
              </StyledFlexEnd>
            </StyledStatusBox>
            <StyledStatusBox>
              <StyledFont>課金額</StyledFont>
              <StyledFlexEnd>
                <StyledBigBoldText>
                  {reportApiResponse.totalUnits.toLocaleString()}
                </StyledBigBoldText>
                <StyledBigBoldRightText>円</StyledBigBoldRightText>
              </StyledFlexEnd>
            </StyledStatusBox>
          </StyledFlexGap20>
        </StyledStatusContainer>
      )}
    </>
  );
});
