import { Task } from '@/api/schemas';
import {
  StyledFlexGap15,
  StyledFontBold,
  StyledGridGap10,
} from '@/components/styled';
import { TASK_STATUS } from '@/constants';
import { StyledGenerateStatusBox } from '@/features/components/styled/icon';
import { memo } from 'react';
import { MdOutlineError } from 'react-icons/md';

type Props = {
  data: Task;
};

export const Failed = memo(({ data }: Props): JSX.Element => {
  return (
    <StyledFlexGap15>
      <StyledGenerateStatusBox>
        <MdOutlineError size={22} color={'var(--color-gray-soft)'} />
      </StyledGenerateStatusBox>
      <StyledGridGap10>
        <StyledFontBold>
          {TASK_STATUS[data.result.status as keyof typeof TASK_STATUS]}...
        </StyledFontBold>
      </StyledGridGap10>
    </StyledFlexGap15>
  );
});
