import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledDashboardContainer = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledDashboardContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledDashboardNoticeBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledDashboardNoticeBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledDashboardCardBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledDashboardCardBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledCardBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledCardBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledCardBox90 = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledCardBox90} {...props}>
      {children}
    </Box>
  );
});

export const StyledCardBoxDummy = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledCardBoxDummy} {...props}>
      {children}
    </Box>
  );
});

export const StyledCardUpperFlex = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledCardUpperFlex} {...props}>
      {children}
    </Box>
  );
});

export const StyledCardButtonFlex = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledCardButtonFlex} {...props}>
      {children}
    </Box>
  );
});

export const StyledCardTitleWrapper = memo(({ children }: BoxProps) => {
  return (
    <div className={vanilla.styledCardTitleWrapper}>
      {children}
    </div>
  );
});

export const StyledCardTitle = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledCardTitle} {...props}>
      {children}
    </Box>
  );
});

export const StyledCardExplain = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledCardExplain} {...props}>
      {children}
    </Box>
  );
});

export const StyledRightContentBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledRightContentBox} {...props}>
        {children}
      </Box>
    );
  },
);
