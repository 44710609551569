import { Box } from '@/components/atoms/Box';
import {
  StyledBoxRelativeWidthExpanded,
  StyledFlexCenter,
  StyledFlexGap5,
  StyledFontDeep,
} from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { MdAdd } from 'react-icons/md';
import {
  StyledBackgroundResultBox,
  StyledBoxHeight260,
  StyledSettingImageThumbnailBox,
  StyledSettingImageUpload,
  StyledSettingResultImage,
} from '../../styled';

type Props = {
  handleOpenDialog: () => void;
};
export const ResultBox = ({ handleOpenDialog }: Props): JSX.Element => {
  const { featureData } = useFeaturesContext({});

  return (
    <StyledFlexCenter>
      <StyledBackgroundResultBox
        width={280}
        height={280}
        padding="10px"
      >
        {featureData?.single?.param?.targetType === 'image' && (
          <StyledBoxRelativeWidthExpanded onClick={handleOpenDialog}>
            {featureData?.single?.param?.currentRef && (
              <StyledBoxRelativeWidthExpanded>
                <StyledBoxHeight260>
                  <StyledSettingResultImage
                    src={featureData?.refImage?.combinedBase64}
                    fill
                    alt="pict"
                  />
                </StyledBoxHeight260>
              </StyledBoxRelativeWidthExpanded>
            )}
            {!featureData?.single?.param?.currentRef && (
              <StyledFlexCenter>
                <Box>
                  <StyledSettingImageThumbnailBox>
                    <StyledSettingImageUpload>
                      <StyledFlexGap5>
                        <MdAdd size={32} color={'var(--color-gray-main)'} />
                        <StyledFontDeep>画像をアップロード</StyledFontDeep>
                      </StyledFlexGap5>
                    </StyledSettingImageUpload>
                  </StyledSettingImageThumbnailBox>
                </Box>
              </StyledFlexCenter>
            )}
          </StyledBoxRelativeWidthExpanded>
        )}
      </StyledBackgroundResultBox>
    </StyledFlexCenter>
  );
};
