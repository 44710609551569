import { Box, BoxProps } from '@/components/atoms/Box';

import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledStatusContainer = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledStatusContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledStatusBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledStatusBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledBigBoldText = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBigBoldText} {...props}>
      {children}
    </Box>
  );
});

export const StyledBigBoldRightText = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledBigBoldRightText} {...props}>
        {children}
      </Box>
    );
  },
);
