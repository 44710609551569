import { Box } from '@/components/atoms/Box';
import {
  StyledFlexGap10,
  StyledFlexGap5,
  StyledGridGap5,
} from '@/components/styled';
import { StyledTdWidth300 } from '@/features/Admin/uniques/DownloadManagement/Table/Head/styled';
import {
  StyledBoxNowrapWidth50,
  StyledFileNameBox,
} from '@/features/Admin/uniques/DownloadManagement/Table/styled';
import { StyledTable } from '@/features/components/styled/table';
import { range } from '@/utils/range';
import {
  Table,
  TableBody,
  TableHead,
  TableCell as Td,
  TableRow as Tr,
} from '@mui/material';
import { motion } from 'framer-motion';
import { memo } from 'react';
import {
  StyledSquare120x14,
  StyledSquare160x14,
  StyledSquare160x20,
  StyledSquare180x16,
  StyledSquare24x16,
  StyledSquare34x34,
  StyledSquare60x20,
} from '../styled';

export const DownloadManagementList = memo(() => {
  return (
    <Box>
      <Box sx={{ paddingBottom: '0px' }}>
        <motion.div
          initial={{ opacity: 0.4 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatType: 'reverse',
            delay: 1,
          }}
        >
          <StyledTable>
            <Table>
              <TableHead>
                <Tr>
                  <StyledTdWidth300>
                    <StyledFlexGap5>
                      <StyledSquare160x20 />
                    </StyledFlexGap5>
                  </StyledTdWidth300>
                  <Td>
                    <StyledFlexGap5>
                      <StyledSquare160x20 />
                    </StyledFlexGap5>
                  </Td>
                  <Td>
                    <StyledFlexGap5>
                      <StyledSquare60x20 />
                    </StyledFlexGap5>
                  </Td>
                  <Td>
                    <StyledFlexGap5>
                      <StyledSquare60x20 />
                    </StyledFlexGap5>
                  </Td>
                  <Td colSpan={2} />
                </Tr>
              </TableHead>
              <TableBody>
                {range(1, 10).map((i) => (
                  <Tr key={`key-${i}`.toString()}>
                    <Td>
                      <StyledFlexGap10>
                        <StyledBoxNowrapWidth50>
                          <StyledSquare34x34 />
                        </StyledBoxNowrapWidth50>
                        <StyledFileNameBox>
                          <StyledSquare180x16 />
                        </StyledFileNameBox>
                      </StyledFlexGap10>
                    </Td>
                    <Td>
                      <StyledGridGap5>
                        <StyledSquare120x14 />
                        <StyledSquare160x14 />
                      </StyledGridGap5>
                    </Td>
                    <Td>
                      <StyledSquare24x16 />
                    </Td>
                    <Td>
                      <StyledSquare24x16 />
                    </Td>
                    <Td />
                    <Td />
                  </Tr>
                ))}
              </TableBody>
            </Table>
          </StyledTable>
        </motion.div>
      </Box>
    </Box>
  );
});
