import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
} from '@mui/material';
import { memo } from 'react';
import * as vanilla from './styled/vanilla.css';

export type TypographyProps = MuiTypographyProps & {
  ref?: React.Ref<HTMLDivElement>;
};

export const Typography = memo(({ children, ...props }: TypographyProps) => {
  return (
    <MuiTypography className={vanilla.styledBox} {...props}>
      {children}
    </MuiTypography>
  );
});
