import { Box, BoxProps } from '@/components/atoms/Box';
import { forwardRef, memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledUploadInterface = forwardRef<HTMLDivElement, BoxProps>(
  ({ children, sx, ...props }, ref) => {
    return (
      <Box
        ref={ref}
        sx={sx}
        className={vanilla.styledUploadInterface}
        {...props}
      >
        {children}
      </Box>
    );
  },
);

export const StyledDragDropZone = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledDragDropZone} {...props}>
      {children}
    </Box>
  );
});

export const StyledDragDropZoneFontStyle = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledDragDropZoneFontStyle} {...props}>
        {children}
      </Box>
    );
  },
);
