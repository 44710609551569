import { Box } from '@/components/atoms/Box';
import { StyledBoxHeight15, StyledFlexGap10 } from '@/components/styled';
import {
  StyledDownloaderCheckAllButton,
  StyledDownloaderUnCheckAllButton,
} from '@/components/styled/button';
import { useCheckArray } from '@/hooks/global/useCheckArray';
import { memo } from 'react';

export const SelectButtons = memo(() => {
  const { handleCheckAll, handleUnCheckAll } = useCheckArray();

  return (
    <>
      <StyledFlexGap10>
        <Box>
          <StyledDownloaderCheckAllButton onClick={handleCheckAll}>
            すべて選択
          </StyledDownloaderCheckAllButton>
        </Box>
        <Box>
          <StyledDownloaderUnCheckAllButton onClick={handleUnCheckAll}>
            すべて解除
          </StyledDownloaderUnCheckAllButton>
        </Box>
      </StyledFlexGap10>
      <StyledBoxHeight15 />
    </>
  );
});
