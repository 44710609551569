import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';

type SnackbarParam = {
  iconName: string;
  text: string;
  position: {
    placement:
      | 'rightTop'
      | 'centerBottom'
      | 'leftBottom'
      | 'rightBottom'
      | 'centerTop'
      | 'leftTop'
      | 'centerCenter'
      | 'rightCenter'
      | 'leftCenter'
      | 'centerRight'
      | 'centerLeft'
      | 'rightBottom'
      | 'leftBottom'
      | 'rightTop'
      | 'leftTop'
      | 'centerBottom'
      | 'centerTop'
      | 'centerCenter'
      | 'rightCenter'
      | 'leftCenter'
      | 'centerRight'
      | 'centerLeft';
    x: number;
    y: number;
  };
};

const isOpenSnackbarAtom = atom<boolean>(false);
const snackbarParamAtom = atom<SnackbarParam | null>(null);

/**
 * JSDoc
 * @see Snackbarの状態を管理するカスタムフック
 * @returns {Object}
 */
export const useSnackbar = () => {
  const [isOpenSnackbar, setIsOpenSnackbar] = useAtom(isOpenSnackbarAtom);
  const [snackbarParam, setSnackbarParam] = useAtom(snackbarParamAtom);

  const handleOpenSnackbar = useCallback(() => {
    setIsOpenSnackbar(true);
  }, [setIsOpenSnackbar]);

  const handleCloseSnackbar = useCallback(() => {
    setIsOpenSnackbar(false);
  }, [setIsOpenSnackbar]);

  return {
    isOpenSnackbar,
    setIsOpenSnackbar,
    handleOpenSnackbar,
    handleCloseSnackbar,
    snackbarParam,
    setSnackbarParam,
  };
};
