import { Box, BoxProps } from '@/components/atoms/Box';
import Image, { ImageProps } from 'next/image';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledDetailContainer = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledDetailContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledGridGap8PaddingBottom10 = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledGridGap8PaddingBottom10} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledImageBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledImageBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledImage = memo(({ children, ...props }: ImageProps) => {
  return (
    <Image className={vanilla.styledImage} {...props}>
      {children}
    </Image>
  );
});

export const StyledBoxPaddingLeft5 = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledBoxPaddingLeft5} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledBoxPaddingBottom30 = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledBoxPaddingBottom30} {...props}>
        {children}
      </Box>
    );
  },
);
