import { StyledFont14 } from '@/components/styled';

import {
  StyledResultDisplayNameBox,
  StyledResultDisplayNameEllipsis,
} from './styled';

type Props = {
  displayName: string;
};
export const ResultDisplayName = ({ displayName }: Props): JSX.Element => {
  return (
    <>
      <StyledResultDisplayNameBox>
        <StyledResultDisplayNameEllipsis>
          <StyledFont14>{displayName}</StyledFont14>
        </StyledResultDisplayNameEllipsis>
      </StyledResultDisplayNameBox>
    </>
  );
};
