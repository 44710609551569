import {
  StyledBoxTextAlignCenter,
  StyledFlexCenterExpanded,
  StyledGridCenterGap10,
} from '@/components/styled';
import { CircularProgress } from '@mui/material';
import { memo } from 'react';

export const LoadingSpinner = memo(() => {
  return (
    <StyledFlexCenterExpanded>
      <StyledGridCenterGap10>
        <StyledBoxTextAlignCenter>
          <CircularProgress />
        </StyledBoxTextAlignCenter>
        <StyledBoxTextAlignCenter>loading...</StyledBoxTextAlignCenter>
      </StyledGridCenterGap10>
    </StyledFlexCenterExpanded>
  );
});
