import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fstyled%2Fflex.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xb2piMnJuMCB7CiAgZGlzcGxheTogZmxleDsKICBhbGlnbi1pdGVtczogY2VudGVyOwp9Ci5fMW9qYjJybjEgewogIHdoaXRlLXNwYWNlOiBub3dyYXA7Cn0KLl8xb2piMnJuMiB7CiAgd2lkdGg6IDEwMCU7Cn0KLl8xb2piMnJuMyB7CiAganVzdGlmeS1jb250ZW50OiBmbGV4LXN0YXJ0Owp9Ci5fMW9qYjJybjQgewogIHdpZHRoOiAxMDAlOwogIGp1c3RpZnktY29udGVudDogZmxleC1lbmQ7Cn0KLl8xb2piMnJuNSB7CiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7Cn0KLl8xb2piMnJuNiB7CiAgaGVpZ2h0OiAxMDAlOwp9Ci5fMW9qYjJybjcgewogIGFsaWduLWl0ZW1zOiBmbGV4LXN0YXJ0Owp9Ci5fMW9qYjJybjggewogIGFsaWduLWl0ZW1zOiBmbGV4LWVuZDsKfQouXzFvamIycm45IHsKICBnYXA6IDhweDsKfQouXzFvamIycm5hIHsKICB3aWR0aDogMTAwJTsKfQouXzFvamIycm5iIHsKICB3aWR0aDogMTAwJTsKICBoZWlnaHQ6IDEwMCU7Cn0KLl8xb2piMnJuYyB7CiAgZ2FwOiA1cHg7Cn0KLl8xb2piMnJuZCB7CiAgZ2FwOiA4cHg7Cn0KLl8xb2piMnJuZSB7CiAgZ2FwOiA4cHg7Cn0KLl8xb2piMnJuZiB7CiAgZ2FwOiAxMHB4Owp9Ci5fMW9qYjJybmcgewogIGdhcDogMTBweDsKfQouXzFvamIycm5oIHsKICBnYXA6IDE1cHg7Cn0KLl8xb2piMnJuaSB7CiAgZ2FwOiAyMHB4Owp9Ci5fMW9qYjJybmogewogIGdhcDogNXB4Owp9Ci5fMW9qYjJybmsgewogIGdhcDogMTBweDsKfQouXzFvamIycm5sIHsKICBnYXA6IDEwcHg7Cn0%3D%22%7D"
export var styledFlex = '_1ojb2rn0';
export var styledFlexAlignEnd = '_1ojb2rn8 _1ojb2rn0';
export var styledFlexAlignEndGap8 = '_1ojb2rn9 _1ojb2rn8 _1ojb2rn0';
export var styledFlexAlignStart = '_1ojb2rn7 _1ojb2rn0';
export var styledFlexCenter = '_1ojb2rn5 _1ojb2rn0';
export var styledFlexCenterExpanded = '_1ojb2rnb _1ojb2rn5 _1ojb2rn0';
export var styledFlexCenterGap10 = '_1ojb2rnl _1ojb2rn5 _1ojb2rn0';
export var styledFlexCenterGap8 = '_1ojb2rne _1ojb2rn5 _1ojb2rn0';
export var styledFlexCenterHeightExpanded = '_1ojb2rn6 _1ojb2rn5 _1ojb2rn0';
export var styledFlexCenterWidthExpanded = '_1ojb2rna _1ojb2rn5 _1ojb2rn0';
export var styledFlexEnd = '_1ojb2rn4 _1ojb2rn0';
export var styledFlexEndGap10 = '_1ojb2rnk _1ojb2rn4 _1ojb2rn0';
export var styledFlexEndGap5 = '_1ojb2rnj _1ojb2rn4 _1ojb2rn0';
export var styledFlexGap10 = '_1ojb2rnf _1ojb2rn0';
export var styledFlexGap15 = '_1ojb2rnh _1ojb2rn0';
export var styledFlexGap20 = '_1ojb2rni _1ojb2rn0';
export var styledFlexGap5 = '_1ojb2rnc _1ojb2rn0';
export var styledFlexGap8 = '_1ojb2rnd _1ojb2rn0';
export var styledFlexNowrap = '_1ojb2rn1 _1ojb2rn0';
export var styledFlexNowrapGap10 = '_1ojb2rng _1ojb2rn1 _1ojb2rn0';
export var styledFlexStart = '_1ojb2rn3 _1ojb2rn0';
export var styledFlexWidthExpanded = '_1ojb2rn2 _1ojb2rn0';