import { TasksOrderBy } from '@/api/params';
import { StyledFontBold } from '@/components/styled';
import {
  IconButton,
  TableHead,
  TableCell as Td,
  TableRow as Tr,
} from '@mui/material';
import { memo } from 'react';
import { IoMdArrowDown, IoMdArrowUp } from 'react-icons/io';
import { StyledHeadTitleBox } from './styled';

type Props = {
  tasksOrderBy: TasksOrderBy;
  handleClickOrderBy: (name: keyof TasksOrderBy) => void;
};

export const Head = memo(
  ({ tasksOrderBy, handleClickOrderBy }: Props): JSX.Element => {
    const orderByArrow = (name: keyof TasksOrderBy) => {
      return (
        <IconButton
          onClick={() => {
            handleClickOrderBy(name);
          }}
        >
          {tasksOrderBy[name as unknown as keyof TasksOrderBy] === 'asc' ? (
            <IoMdArrowUp size={18} color={'var(--color-gray-deep)'} />
          ) : (
            <IoMdArrowDown size={18} color={'var(--color-gray-deep)'} />
          )}
        </IconButton>
      );
    };

    return (
      <TableHead>
        <Tr>
          <Td>
            <StyledHeadTitleBox>
              <StyledFontBold>ファイル</StyledFontBold>
              {/* orderByArrow('originalImageFileName') */}・
              <StyledFontBold>生成種別</StyledFontBold>
              {/* orderByArrow('category') */}
            </StyledHeadTitleBox>
          </Td>
          <Td>
            <StyledHeadTitleBox>
              <StyledFontBold>指定内容</StyledFontBold>
              {/* orderByArrow('taskType') */}
            </StyledHeadTitleBox>
          </Td>
          <Td>
            <StyledHeadTitleBox>
              <StyledFontBold>ステータス</StyledFontBold>
              {/* orderByArrow('status') */}
            </StyledHeadTitleBox>
          </Td>
          <Td>
            <StyledHeadTitleBox>
              <StyledFontBold>生成日時</StyledFontBold>
              {orderByArrow('createdAt')}
              {/* orderByArrow('userId') */}
            </StyledHeadTitleBox>
          </Td>
          <Td />
        </Tr>
      </TableHead>
    );
  },
);
