import { Button, ButtonProps } from '@mui/material';
import hexToRgba from 'hex-to-rgba';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

type radioBoxProps = {
  width?: string | number;
  isEnable?: boolean;
  isActive?: boolean;
} & ButtonProps;

export const StyledTabButton = memo(
  ({ isActive, children, ...props }: radioBoxProps) => {
    const style = {
      borderRadius: 0,

      borderBottom: isActive
        ? '2px solid var(--color-primary-deep)'
        : `1px solid ${hexToRgba('#000', 0.12)}`,
      color: isActive ? 'var(--color-primary-deep)' : 'var(--secondary)',
    };

    return (
      <Button
        sx={style}
        type="button"
        className={vanilla.styledTabButton}
        {...props}
      >
        {children}
      </Button>
    );
  },
);
