import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { memo } from 'react';
import { GrPowerCycle } from "react-icons/gr";
import { checkedIconStyle, uncheckedIconStyle } from './ReEditButton.css';

type Props = {
  handleClick: (e: React.MouseEvent<HTMLElement>) => void;
  checkedCount: number;
};

// TODO: absoluteを使わない形に修正する（レイアウトから修正する必要があるのでまぁまぁ時間かかりそう）
// MEMO: ボタンの位置をabsoluteで指定してしまっているので、複数ボタンを配置した際レイアウト崩れが起きてしまう。そのためワークアラウンドとしてbottomをpropsで渡してボタン位置をコントロールできるようにした
export const ReEditButton = memo(
  ({ handleClick, checkedCount }: Props): JSX.Element => {
    return (
    <Box style={{ position: 'absolute', bottom: -10, left: -40 }}>
      <Button
        mode="border"
        style={{ width: 360, fontSize: 15 }}
        onClick={handleClick}
        disabled={checkedCount === 0}
      >
        <GrPowerCycle fontSize={20} className={checkedCount ? checkedIconStyle : uncheckedIconStyle} />
          選択したファイルを再生成
        {checkedCount ? `（${checkedCount}）` : ''}
      </Button>
    </Box>
    );
  },
);
