import { useCallback, useState } from 'react';

/**
 * JSDoc
 * @see ポッパーの状態を管理するカスタムフック
 * @returns {Object}
 */
export const usePopper = () => {
  const [isOpenPopper, setIsOpenPopper] = useState(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const handleOpenPopper = useCallback(
    (e: React.MouseEvent<HTMLElement>): void => {
      setAnchorElement(e.currentTarget);
      // const { top, left } = e.currentTarget.getBoundingClientRect();
      setIsOpenPopper(true);
    },
    [setIsOpenPopper],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const handleClosePopper = useCallback((): void => {
    setIsOpenPopper(false);
  }, [setIsOpenPopper]);

  return {
    isOpenPopper,
    setIsOpenPopper,
    handleOpenPopper,
    handleClosePopper,
    anchorElement,
    setAnchorElement,
  };
};
