import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledPopper = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledPopper} {...props}>
      {children}
    </Box>
  );
});

export const StyledYscrollBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledYscrollBox} {...props}>
      {children}
    </Box>
  );
});

type HeadProps = {
  titleSize: number | string;
  titleWeight: number | string;
  titlePosition: 'start' | 'center' | 'end';
} & BoxProps;

export const StyledHead = memo(
  ({
    titleSize,
    titleWeight,
    titlePosition,
    children,
    ...props
  }: HeadProps) => {
    const headStyle = {
      justifyContent: titlePosition,
      fontSize: titleSize,
      fontWeight: titleWeight,
    };

    return (
      <Box className={vanilla.styledHead} style={headStyle} {...props}>
        {children}
      </Box>
    );
  },
);

type CloseBoxProps = {
  hasTitle?: boolean;
} & BoxProps;

export const StyledCloseButton = memo(
  ({ hasTitle, children, ...props }: CloseBoxProps) => {
    const closeButtonStyle = {
      right: hasTitle ? '10px' : '0px',
    };

    return (
      <Box
        className={vanilla.styledCloseButton}
        style={closeButtonStyle}
        {...props}
      >
        {children}
      </Box>
    );
  },
);
