import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';

const isOpenConfirmEraseDataDialogAtom = atom<boolean>(false);
const targetFeatureNameAtom = atom<string>('');

/**
 * JSDoc
 * @see ダイアログの状態を管理するカスタムフック
 * @returns {Object}
 */
export const useConfirmEraseDataDialog = () => {
  const [isOpenConfirmEraseDataDialog, setIsOpenConfirmEraseDataDialog] =
    useAtom(isOpenConfirmEraseDataDialogAtom);
  const [targetFeatureName, setTargetFeatureName] = useAtom(
    targetFeatureNameAtom,
  );

  const handleOpenConfirmEraseDataDialog = useCallback(() => {
    setIsOpenConfirmEraseDataDialog(true);
  }, [setIsOpenConfirmEraseDataDialog]);

  const handleCloseConfirmEraseDataDialog = useCallback(() => {
    setIsOpenConfirmEraseDataDialog(false);
  }, [setIsOpenConfirmEraseDataDialog]);

  return {
    isOpenConfirmEraseDataDialog,
    setIsOpenConfirmEraseDataDialog,
    handleOpenConfirmEraseDataDialog,
    handleCloseConfirmEraseDataDialog,
    targetFeatureName,
    setTargetFeatureName,
  };
};
