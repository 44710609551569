import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { FunctionViewType } from '@/features/components/steps/BatchUpload/types';
import { useCallback } from 'react';

type Props = {
  targetNumber: number;
  setFunctionViewType: (type: FunctionViewType) => void;
};

export const useUploadSetHandlers = ({
  targetNumber,
  setFunctionViewType,
}: Props) => {
  const { featureData, updateFeatureDataArray } = useBatchUpload();

  const handleSelectImage = useCallback(
    (fileName: string) => {
      setFunctionViewType('image');
      console.log(fileName);
      if (!featureData) return;
      const updateOriginalImages = featureData.batch[
        targetNumber
      ].originalImages.map((image) => {
        if (image.fileName === fileName) {
          return {
            ...image,
            isSelected: true,
          };
        }

        return { ...image, isSelected: false };
      });
      const updateSet = featureData.batch.map((set, index) => {
        if (index === targetNumber) {
          return {
            ...set,
            originalImages: updateOriginalImages,
            isCurrent: true,
          };
        }

        return { ...set, isCurrent: false };
      });
      updateFeatureDataArray('batch', updateSet);
    },
    [featureData, setFunctionViewType, targetNumber, updateFeatureDataArray],
  );

  const handleCheckDeleteImages = useCallback(
    (image: { fileName: string }) => {
      if (!featureData) return;
      const updateDeleteImages = featureData.batch[
        targetNumber
      ].deleteImages.includes(image.fileName)
        ? featureData.batch[targetNumber].deleteImages.filter(
            (item) => item !== image.fileName,
          )
        : [...featureData.batch[targetNumber].deleteImages, image.fileName];
      setFunctionViewType('delete');
      // 配列にあるか探す
      const isIncluded = featureData.batch[targetNumber].deleteImages.includes(
        image.fileName,
      );
      if (isIncluded) {
        const newBatch = featureData.batch.map((set, index) => {
          if (index === targetNumber) {
            return {
              ...set,
              deleteImages: updateDeleteImages,
              isCurrent: true,
            };
          }

          return { ...set, isCurrent: false };
        });
        updateFeatureDataArray('batch', newBatch);
      } else {
        const newBatch = featureData.batch.map((set, index) => {
          if (index === targetNumber) {
            return {
              ...set,
              deleteImages: [...set.deleteImages, image.fileName],
              isCurrent: true,
            };
          }

          return { ...set, isCurrent: false };
        });
        updateFeatureDataArray('batch', newBatch);
      }
    },
    [featureData, setFunctionViewType, targetNumber, updateFeatureDataArray],
  );

  return {
    handleSelectImage,
    handleCheckDeleteImages,
  };
};
