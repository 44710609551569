import { memo } from 'react';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import { StyledRadioBox, StyledRadioFlexEnd } from './styled';

type Props = {
  width?: string | number;
  label: string | JSX.Element;
  target: string | boolean;
  radioValue: string | boolean;
  isActive: boolean;
  onClick: (target: string | boolean) => void;
};
/**
 * JSDoc
 * @see ラジオボタン
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const RadioButton = memo(
  ({
    width = '100%',
    label,
    target,
    radioValue,
    isActive,
    onClick: handleClick,
  }: Props): JSX.Element => {
    return (
      <StyledRadioBox
        width={width}
        onClick={() => {
          handleClick(target);
        }}
        isActive={isActive}
      >
        {label}
        <StyledRadioFlexEnd isActive={radioValue === target}>
          {radioValue === target ? (
            <MdRadioButtonChecked size={24} data-testid="radio-checked-icon" />
          ) : (
            <MdRadioButtonUnchecked
              size={24}
              data-testid="radio-unchecked-icon"
            />
          )}
        </StyledRadioFlexEnd>
      </StyledRadioBox>
    );
  },
);
