import { Box, BoxProps } from '@/components/atoms/Box';
import { MenuItem, MenuItemProps } from '@mui/material';
import { memo } from 'react';
import { BiSolidUserCircle } from 'react-icons/bi';
import { BsFillBellFill } from 'react-icons/bs';
import * as vanilla from './vanilla.css';

export const StyledContentHeaderBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledContentHeaderBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledMenuItem = memo(({ children, ...props }: MenuItemProps) => {
  return (
    <MenuItem className={vanilla.styledMenuItem} {...props}>
      {children}
    </MenuItem>
  );
});

export const StyledRightContainer = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledRightContainer} {...props}>
      {children}
    </Box>
  );
});

export const StyledRightContentsBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledRightContentsBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledBellIcon = memo(() => {
  return <BsFillBellFill className={vanilla.styledBellIcon} />;
});

export const StyledUserInfoBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledUserInfoBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledUserInfoPartition = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledUserInfoPartition} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledUserIcon = memo(() => {
  return <BiSolidUserCircle className={vanilla.styledUserIcon} size={40} />;
});

export const StyledPopperGrid = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledPopperGrid} {...props}>
      {children}
    </Box>
  );
});

export const StyledPopperFlex = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledPopperFlex} {...props}>
      {children}
    </Box>
  );
});

export const StyledUserInitialCircle = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledUserInitialCircle} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledMoreReadTextButton = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledMoreReadTextButton} {...props}>
        {children}
      </Box>
    );
  },
);
