import { axiosGet } from '@/api/axios/get';
import { axiosPost } from '@/api/axios/post';
import { useQuery } from '@tanstack/react-query';

/**
 * JSDoc
 * @see ReactQueryを用いたカスタムフック
 * @see 型ガードを使用しない場合はこちらを使用する
 * @see https://react-query.tanstack.com/guides/query-keys
 * @returns {function} usePost, useGet
 */
export const useReactQuery = () => {
  /**
   * JSDoc
   * @param apiName
   * @param payload
   * @param timer
   * @see payload変更時にAPIを叩く
   * @see timer変更時にAPIを叩く
   * @returns
   */
  const usePost = (apiName: string, payload?: unknown, timer?: number) =>
    useQuery(
      [timer || { payload, apiName }],
      () => axiosPost(apiName, payload),
      {
        useErrorBoundary: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        retry: false,
      },
    );
  /**
   * JSDoc
   * @param apiName
   * @param payload
   * @param timer
   * @see payload変更時にAPIを叩く
   * @see timer変更時にAPIを叩く
   * @returns
   */
  const useGet = (apiName: string, payload?: unknown, timer?: number) =>
    useQuery(
      [timer || { payload, apiName }],
      () => axiosGet(apiName, payload),
      {
        useErrorBoundary: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 0,
      },
    );

  return {
    usePost,
    useGet,
  };
};
