import {
  FeatureBatch,
  FeatureBatchOriginalImage,
  FeatureBatchRef,
  FeatureData,
  FeatureImageItem,
  FeatureParam,
  FeaturePresetImage,
} from '@/contexts/FeaturesContext/types';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

type Props = {
  featureData: FeatureData | undefined;
  updateFeatureDataArray: (field: keyof FeatureData, value: unknown) => void;
  setCurrentNumber: (number: number) => void;
};

/**
 * @see バッチアップロードのハンドラー関数群
 */
export const useBatchUploadHandlers = ({
  featureData,
  updateFeatureDataArray,
}: Props) => {
  const handleAddNewSet = useCallback(() => {
    if (!featureData) return;

    const newSet = {
      id: uuidv4(),
      setName: `名称未設定${featureData.batch.length}`,
      images: [],
      fileNames: [],
      updatedAt: '',
      isCurrent: true,
      refSam: {} as FeatureImageItem,
      refImage: {} as FeatureBatchRef,
      presetImage: {} as FeaturePresetImage,
      mainParam: {} as FeatureParam,
      originalImages: [] as FeatureBatchOriginalImage[],
      deleteImages: [],
      hasGenCondition: false,
    };
    const newImageSet = featureData.batch.map((set) => {
      if (set.isCurrent) return { ...set, isCurrent: false };

      return set;
    });
    updateFeatureDataArray('batch', [newSet, ...newImageSet]);
  }, [featureData, updateFeatureDataArray]);

  const handleDeleteSet = useCallback(
    (id: string) => {
      if (!featureData) return;
      const newImageSet = featureData.batch.filter((set) => set.id !== id);
      updateFeatureDataArray('batch', newImageSet);
    },
    [featureData, updateFeatureDataArray],
  );

  const handleChangeCurrentSet = useCallback(
    (id: string) => {
      if (!featureData) return;
      const newImageSet = featureData.batch.map((set: FeatureBatch) => {
        if (set.id === id) return { ...set, isCurrent: true };

        return { ...set, isCurrent: false };
      });

      updateFeatureDataArray('batch', newImageSet);
    },
    [featureData, updateFeatureDataArray],
  );

  return {
    handleAddNewSet,
    handleChangeCurrentSet,
    handleDeleteSet,
  };
};
