
// MEMO: 現状色温度管理でしか利用していない。もし他の機能での流用が難しければ名前かディレクトリ構造を変える
// MEMO: BatchUploadをベースに作成。最適化の余地あり

import { Button } from '@/components/atoms/Button';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { Checkbox, Dialog, FormControlLabel } from '@mui/material';
import { memo, useCallback, useEffect, useState } from 'react';
import { StepExplain } from '../../atoms/StepExplain';
import { StyledExplainBox } from '../../atoms/StepExplain/styled';
import { StyledFunctionBox } from '../../styled/function';
import { FeaturesReEditLayout } from '../../templates/Layout/FeaturesReEditLayout';
import { Buttons } from './Buttons';
import { Functions } from './Functions';
import { SetTable } from './SetTable';

const MessageDialog = () => {
  const { activateTargetFeature } = useFeaturesContext({})
  const [isDisplay, setIsDisplay] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const handleClose = useCallback(() => {
    setIsDisplay(false)
  }, [])

  const handleCancel = useCallback(() => {
    activateTargetFeature('gallery');
  }, [activateTargetFeature])

  const handleSubmit = useCallback(() => {
    if (isChecked) localStorage.setItem('isNotDisplayReEditFirstMessage', 'true')

    handleClose();
  }, [
    isChecked,
    handleClose,
  ])

  useEffect(() => {
    const isNotDisplayReEditFirstMessage = localStorage.getItem('isNotDisplayReEditFirstMessage')
    if (isNotDisplayReEditFirstMessage !== 'true') {
      setIsDisplay(true)
    }
  }, [])

  return (
    <>
      <Dialog
        open={isDisplay}
        onClose={handleClose}
      >
        <div
          style={{
            width: '448px',
            padding: '16px 24px 24px',
          }}
        >
          <h2 style={{
            marginBottom: '16px',
            color: '#EF6C00',
            fontFamily: "Noto Sans JP",
            fontSize: '20px',
            fontWeight: '500',
            lineHeight: '160%',
            letterSpacing: '0.15px',
          }}>
            再生成する際のご注意
          </h2>
          <div
            style={{
              marginBottom: '16px',
              fontFamily: "Noto Sans JP",
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '150%',
              letterSpacing: '0.15px',
            }}
          >
            再生成をすると、リストがリセットされます。過去の生成履歴はギャラリーで確認できます。
          </div>
          <div
            style={{
              marginBottom: '24px',
            }}
          >
            <FormControlLabel
              control={
              <Checkbox
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              }
              label="以降表示しない"
              sx={{
                '& .MuiFormControlLabel-label': {
                  color: 'rgba(0, 0, 0, 0.87)',
                  fontFamily: "Noto Sans JP",
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '150%',
                  letterSpacing: '0.15px',
                },
              }}
              />
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '0 8px',
            width: '100%',
          }}>
            <Button mode="alertBorder" onClick={handleCancel}>
              キャンセル
            </Button>
            <Button
              mode="alert"
              onClick={handleSubmit}
              style={{ width: '69px' }}
            >
              続行
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  )
}

// プレゼンテーション周りを記述するコンポーネント
export const ReEdit = memo((): JSX.Element => {
  return (
    <>
      <FeaturesReEditLayout>
        <StyledExplainBox>
          <>
            <StepExplain />
          </>
        </StyledExplainBox>
          <div>
            <SetTable />
          </div>
          <StyledFunctionBox>
            <div style={{height: '100%'}}>
              <Functions />
            </div>
            <div
              style={{
                paddingTop: '16px',
                alignItems: 'flex-end',
              }}
            >
              <Buttons />
            </div>
          </StyledFunctionBox>
      </FeaturesReEditLayout>
      <MessageDialog />
    </>
  );
});
