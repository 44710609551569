import type { SVGProps } from "react";
import { memo } from "react";
const CheckFilled = memo((props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={props.fill || "#000"}
        fillOpacity={0.56}
        d="m8.795 15.875-4.17-4.17-1.42 1.41 5.59 5.59 12-12-1.41-1.41-10.59 10.58Z"
      />
    </svg>
  );
});
export default CheckFilled;
