import { Box } from '@/components/atoms/Box';
import {
  StyledBoxHeight10,
  StyledBoxHeight5,
  StyledBoxTextAlignLeft,
  StyledFlexCenter,
  StyledFont12DangerCenter,
  StyledFont16Main,
  StyledFont24Dark,
  StyledGridGap10,
} from '@/components/styled';
import { StyledUploadFileSelectButton } from '@/components/styled/button';
import { UPLOAD_MAX_SIZE } from '@/constants';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { UploadedErrorCard } from '@/features/components/molecules/UploaderInterface/UploadedErrorCard';
import { memo } from 'react';
import { DropzoneRootProps } from 'react-dropzone';
import {
  StyledDragAndDropArea,
  StyledUploadConditionBox,
  StyledUploadInterfaceContainer,
} from './styled';

type Props = {
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  errorFiles: File[];
  exceededFiles: File[];
  isDragActive: boolean;
};
/**
 * JSDoc
 * @see 画像アップロードエリア共通インターフェイス
 * @props getRootProps - ドラッグ&ドロップエリアのプロパティ
 * @props getInputProps - ドラッグ&ドロップエリアのプロパティ
 * @props errorFiles - エラーファイル
 * @props exceededFiles - エラーファイル
 * @props isDragActive - ドラッグ&ドロップエリアのアクティブ状態
 * @returns JSX.Element
 */
export const UploaderInterface = memo(
  ({
    getRootProps,
    getInputProps,
    errorFiles,
    exceededFiles,
    isDragActive,
  }: Props): JSX.Element => {
    const { activeFeatureName } = useFeaturesContext({});

    return (
      <>
        <StyledDragAndDropArea {...getRootProps()}>
          <StyledUploadInterfaceContainer>
            <input {...getInputProps()} />
            {isDragActive ? (
              <Box>onDragging</Box>
            ) : (
              <>
                <StyledGridGap10>
                  <UploadedErrorCard
                    errorFiles={errorFiles}
                    exceededFiles={exceededFiles}
                  />
                  <StyledFont24Dark>
                    ここにファイルをドラッグ&ドロップ
                  </StyledFont24Dark>
                  <StyledFont16Main>または</StyledFont16Main>
                  <StyledFlexCenter>
                    <StyledUploadFileSelectButton>
                      ファイル選択
                    </StyledUploadFileSelectButton>
                  </StyledFlexCenter>
                  <StyledUploadConditionBox>
                    <Box>
                      【下記を満たす画像データをご使用ください】
                      <StyledBoxTextAlignLeft>
                        <li>フォーマット: JPG/PNG/WEBPのいずれか</li>
                        <li>
                          サイズ: 500 x 500 〜{' '}
                          {
                            UPLOAD_MAX_SIZE[
                              activeFeatureName as keyof typeof UPLOAD_MAX_SIZE
                            ]
                          }{' '}
                          x{' '}
                          {
                            UPLOAD_MAX_SIZE[
                              activeFeatureName as keyof typeof UPLOAD_MAX_SIZE
                            ]
                          }
                        </li>
                        <li>全身が写っている場合は 750 x 750px以上を推奨</li>
                        <li>ファイル容量: 5MB以下</li>
                        <StyledBoxHeight5 />
                        <StyledFont12DangerCenter>
                          ※ 指定サイズ以上の画像を使用される場合は縮小されます
                        </StyledFont12DangerCenter>
                      </StyledBoxTextAlignLeft>
                    </Box>
                  </StyledUploadConditionBox>
                </StyledGridGap10>
                <StyledBoxHeight10 />
                <Box
                  style={{
                    fontSize: 12,
                    textAlign: 'left',
                    color: 'var(--color-gray-dark)',
                  }}
                >
                  アップロードする画像は、ご自身で著作権を保有しているか、権利者から使用許諾を得た画像を、アップロード者ご自身の責任においてご利用ください。
                  <br />
                  他者の著作物を無断で使用することは、著作権法に違反する可能性がありますのでご注意ください。
                  <br />
                  アップロードいただいた画像は、AIモデルの学習用データとして使用され、低解像度のサムネイル画像として保存されます。
                </Box>
              </>
            )}
          </StyledUploadInterfaceContainer>
        </StyledDragAndDropArea>
      </>
    );
  },
);
