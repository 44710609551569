import { Box } from '@/components/atoms/Box';
import { memo } from 'react';

type Props = {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  color?: string;
  hoverColor?: string;
  fontWeight?: number;
  label: React.ReactNode;
  onClick?: () => void;
};

/**
 * JSDoc
 * @see Sugekaeのテキストボタン
 * @props {Props} props
 * @returns {JSX.Element}
 */
export const TextButton = memo(
  ({
    size = 'sm',
    color = 'var(--color-primary-deep)',
    hoverColor = 'var(--color-gray-main)',
    fontWeight = 500,
    label = 'text button',
    onClick,
  }: Props): JSX.Element => {
    return (
      <Box
        data-testid="text-button"
        sx={{
          fontSize: 'var(--font-size-sm)',
          fontWeight,
          cursor: 'pointer',
          color,
          '&:hover': {
            color: hoverColor,
            cursor: 'pointer',
          },
        }}
        onClick={onClick}
      >
        {label}
      </Box>
    );
  },
);
