import { Box, BoxProps } from '@/components/atoms/Box';
import { Typography, TypographyProps } from '@/components/atoms/Typography';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledFileDetailContainer = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledFileDetailContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledFileDetailBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledFileDetailBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledFileName = memo(
  ({ children, ...props }: TypographyProps) => {
    return (
      <Typography className={vanilla.styledFileName} {...props}>
        {children}
      </Typography>
    );
  },
);

type SizeProps = {
  bytes: number;
  children: React.ReactNode;
} & BoxProps;

export const StyledFileSizeBox = memo(
  ({ bytes, children, ...props }: SizeProps) => {
    const sizeStyle = {
      color: bytes ? 'var(--color-danger-main)' : 'none',
    };

    return (
      <Box className={vanilla.styledFileSizeBox} sx={sizeStyle} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledMessageBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledMessageBox} {...props}>
      {children}
    </Box>
  );
});
