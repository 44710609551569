import { TASK_TYPE_TO_EN_MODE_FROM_BACKEND, TASK_TYPE_TO_JP_MODE_FROM_BACKEND } from "@/constants";

// TODO: アプリ全体でModeJp, ModeEnの呼称を
export const getModeJp = (taskType: keyof typeof TASK_TYPE_TO_JP_MODE_FROM_BACKEND): string => {
  return TASK_TYPE_TO_JP_MODE_FROM_BACKEND[taskType];
}

export const getModeEn = (taskType: keyof typeof TASK_TYPE_TO_EN_MODE_FROM_BACKEND): string => {
  return TASK_TYPE_TO_EN_MODE_FROM_BACKEND[taskType];
}
