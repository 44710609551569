import { MAX_SUPPORT_MESSAGE_LENGTH } from '@/constants/support';
import { TextField } from '@mui/material';
import { Dispatch, SetStateAction, memo } from 'react';
import { labelStyle, textareaWrapperStyle, wrapperStyle } from './SupportForm.css';

type Props = {
  placeholder: string;
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
};

// TODO: Swiperの中に紛れ込ませているサポートフォーム。Swiperの処理を流用したくて紛れ込ませている。ちゃんと分離した方が良いが綺麗に分離する案が思いつかず。（Swiperではなくてもっと大きなレベルの名前に変えれば良いだけか？）
export const SupportForm = memo(
  ({
    placeholder,
    message,
    setMessage,
  }: Props): JSX.Element => {
    return (
      <div className={wrapperStyle}>
        <div>
          <label
            className={labelStyle}
            htmlFor="support-form-text"
          >コメントを入力（必須）&nbsp;※最大{MAX_SUPPORT_MESSAGE_LENGTH}文字まで</label>
          <TextField
            id="support-form-text"
            variant="outlined"
            className={textareaWrapperStyle}
            placeholder={placeholder}
            multiline
            minRows={7}
            color="primary"
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
      </div>
    );
  }
);
