import { memo } from 'react';

type Props = {
  color?: string;
  size?: number;
};
const TaskIcon = memo(({ color = '#fff', size = 32 }: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M6 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm8-5a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H14ZM6 19a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm8-5a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H14ZM6 28a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm8-5a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H14Z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default TaskIcon;
