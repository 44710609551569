import { Box } from '@/components/atoms/Box';
import {
  StyledBoxWidth10,
  StyledBoxWidth50,
  StyledFlex,
  StyledFlexGap5,
  StyledGridGap10,
  StyledGridGap15,
} from '@/components/styled';
import { range } from '@/utils/range';
import { Divider } from '@mui/material';
import { motion } from 'framer-motion';
import { memo } from 'react';
import {
  StyledCircle56x56,
  StyledDividerBox,
  StyledFlexWidth176Gap8,
  StyledGridGap15Pl20,
  StyledSquare126x16,
  StyledSquare56x16,
  StyledSquare56x56,
  StyledSquare80x16,
} from '../styled';

export const TaskTray = memo(() => {
  return (
    <Box>
      <StyledDividerBox>
        <Divider />
      </StyledDividerBox>
      <StyledGridGap15>
        {range(1, 5).map((i) => (
          <motion.div
            key={`skelton-${i}`.toString()}
            initial={{ opacity: 0.4 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 1,
              repeat: Infinity,
              repeatType: 'reverse',
            }}
          >
            <StyledGridGap15Pl20>
              <StyledFlex>
                <StyledFlexWidth176Gap8>
                  <StyledSquare56x56 />
                  <StyledSquare56x16 />
                </StyledFlexWidth176Gap8>
                <StyledBoxWidth10 />
                <StyledFlexGap5>
                  <StyledCircle56x56 />
                  <StyledGridGap10>
                    <StyledSquare126x16 />
                    <StyledSquare126x16 />
                  </StyledGridGap10>
                </StyledFlexGap5>
                <StyledBoxWidth50 />
                <StyledSquare80x16 />
              </StyledFlex>
              <Divider />
            </StyledGridGap15Pl20>
          </motion.div>
        ))}
      </StyledGridGap15>
    </Box>
  );
});
