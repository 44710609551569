import { memo } from 'react';

type Props = {
  color?: string;
};
const SegmentationNegative = memo(
  ({ color = '#FAFF00' }: Props): JSX.Element => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <circle cx={12} cy={12} r={10} fill={color} />
        <path
          fill={color === '#FAFF00' ? '#000' : '#fff'}
          fillOpacity={0.4}
          d="M6 11h12v2H6z"
        />
      </svg>
    );
  },
);
export default SegmentationNegative;
