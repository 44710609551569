import { toInteger } from "lodash";

// 他のコンポーネントにhex値のインプットを渡す際、formatして渡す必要がある場合に利用
// 123456 -> #123456, #12345 -> #000000, #w -> #000000
export const formatHex = (hex: string) => {
  const hexPattern = /^#?([a-fA-F0-9]{6})$/;

  if (hexPattern.test(hex)) {
    // 先頭に#がない場合は追加
    return !hex.startsWith('#') ? `#${hex}` : hex
  }

  // hex値として無効な場合は#000000を返す
  return '#000000';
}

export const numberToHexString = (v: number) => {
  const hex = v.toString(16).toUpperCase();

  return hex.length === 1 ? `0${hex}` : hex;
}

export const rgbToHex = (r: string | undefined, g: string | undefined, b: string | undefined) => {
  const rgb = {
    r: r ? toInteger(r) : 0,
    g: g ? toInteger(g) : 0,
    b: b ? toInteger(b) : 0,
  }

  return `#${numberToHexString(rgb.r)}${numberToHexString(rgb.g)}${numberToHexString(rgb.b)}`
}

export const hexToRgb = (hex: string | undefined) => {
  if (!hex) return {
      r: 0,
      g: 0,
      b: 0,
  }

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? { r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16) } : {r: 0, g: 0, b: 0};
}

// 返り値はreact-color-paletteのiColorに準拠（h: 0~360, g: 0~100, v: 0~100）
export const rgbToHsv = (paramR: number | undefined, paramG: number | undefined, paramB: number | undefined) => {
  const r = paramR ? paramR / 255 : 0
  const g = paramG ? paramG / 255 : 0
  const b = paramB ? paramB / 255 : 0

  const max = Math.max(r, g, b); const min = Math.min(r, g, b);
  let h; const v = max;

  const delta = max - min;

  if (max === min) {
    h = 0;
  } else {
    switch (max) {
      case r: h = (g - b) / delta + (g < b ? 6 : 0); break;
      case g: h = (b - r) / delta + 2; break;
      case b: h = (r - g) / delta + 4; break;
      default: throw new Error('maxの値がr, g, bのいずれにも合致しません')
    }
    h /= 6;
  }
  const s = max === 0 ? 0 : delta / max;

  return { h: h ? h * 360 : 0, s: s * 100, v: v * 100 };
}

export const hexToHsv = (hex: string | undefined) => {
  if (!hex) return {
    h: 0,
    s: 0,
    v: 0,
  }
  const rgb = hexToRgb(hex)

  return rgbToHsv(rgb.r, rgb.g, rgb.b)
}
