import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { GlobalUploader } from '@/features/components/organisms/GlobalUploader';
import { FeaturesStepLayout } from '@/features/components/templates/Layout/FeaturesStepLayout';
import { memo, useEffect } from 'react';

export const Upload = memo((): JSX.Element => {
  console.log('upload');
  const { featureData, activateTargetStep } = useFeaturesContext({});

  useEffect(() => {
    if (!featureData?.mainImage.base64) return;
    activateTargetStep('segmentation');
  }, [featureData?.mainImage.base64, activateTargetStep]);

  return (
    <>
      <FeaturesStepLayout>
        <GlobalUploader field="mainImage" />
        <></>
      </FeaturesStepLayout>
    </>
  );
});
