import { Task } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import { ImageWithLoader } from '@/components/molecules/ImageWithLoader/ImageWithLoader';
import {
  StyledBoxNowrap,
  StyledFlexGap15,
  StyledFont,
  StyledFontBold,
} from '@/components/styled';
import { TASK_STATUS } from '@/constants';
import {
  StyledImageThumbnail
} from '@/features/Gallery/uniques/styled';
import { StyledDownloadDot } from '@/features/components/styled/icon';
import { useTasksHelpers } from '@/hooks/tasks/useTasksHelpers';
import { memo } from 'react';

type Props = {
  data: Task;
};

export const Completed = memo(({ data }: Props): JSX.Element => {
  const { isDownloaded } = useTasksHelpers();

  return (
    <StyledFlexGap15>
      <Box>
        {data.result.resultImages[0] && data.result.resultImages[0].url && (
          <StyledImageThumbnail>
            <ImageWithLoader
              src={`${data.result.resultImages[0].url}&d=56x56`}
              width={56}
              height={56}
              alt=""
              loaderHeight={8}
              loaderWidth={8}
              loaderMode="beater"
            />
          </StyledImageThumbnail>
        )}
        {!data.result.resultImages[0] && (
          <StyledImageThumbnail>no image</StyledImageThumbnail>
        )}
      </Box>
      <StyledBoxNowrap>
        {isDownloaded(data) ? (
          <StyledFont>
            {TASK_STATUS[data.result.status as keyof typeof TASK_STATUS]}
          </StyledFont>
        ) : (
          <StyledFontBold>
            {TASK_STATUS[data.result.status as keyof typeof TASK_STATUS]}
          </StyledFontBold>
        )}
      </StyledBoxNowrap>
      {/* resultImagesからダウンロードされているかを調べる */}
      <div style={{width: '7px'}}>
        {!isDownloaded(data) && <StyledDownloadDot />}
      </div>
    </StyledFlexGap15>
  );
});
