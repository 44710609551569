import type { Tasks } from '@/api/schemas';
import type { TASK_TYPE_TO_EN_MODE_FROM_BACKEND } from '@/constants';
import { TaskCellContent } from '@/features/components/atoms/TaskCellContent';
import { TaskCellMode } from '@/features/components/atoms/TaskCellMode';
import { usePresetIdSrcMap } from '@/hooks/presets/usePresetIdSrcMap';
import { getModeEn } from '@/utils/task';
import { TableBody, TableCell as Td } from '@mui/material';
import type { Dispatch, SetStateAction } from 'react';
import { memo } from 'react';
import { DownloadButton } from './DownloadButton';
import { Status } from './Status';
import { TaskDate } from './TaskDate';
import { StyledTdFile, StyledTdFixed, StyledTdStatus, StyledTr } from './styled';

type Props = {
  apiResponse: Tasks;
  handleSubmitTargetDownloadStep: (id: string) => void;
  handleOpenDownloadDialog: (id: string, taskType: string) => void;
  selectedColumnIndex: number | undefined;
  setSelectedColumnIndex: Dispatch<SetStateAction<number | undefined>>;
};

export const Body = memo(
  ({
    apiResponse,
    handleSubmitTargetDownloadStep,
    handleOpenDownloadDialog,
    selectedColumnIndex,
    setSelectedColumnIndex,
  }: Props): JSX.Element => {
    const { presetIdSrcMap } = usePresetIdSrcMap();

    return (
      <>
        <TableBody>
          {apiResponse.data.map((data, index) => (
            <StyledTr
              key={data.id}
              status={data.result.status}
              isSelected={selectedColumnIndex === index}
              onClick={(e) => {
                if (
                  !e.isPropagationStopped() &&
                  data.result.status === 'COMPLETED'
                ) {
                  handleOpenDownloadDialog(data.id, data.taskType);
                  setSelectedColumnIndex(index);
                }
              }}
            >
              <StyledTdFile>
                <TaskCellMode data={data} />
              </StyledTdFile>
              <StyledTdFixed>
                <TaskCellContent
                  data={data}
                  presetIdSrcMap={presetIdSrcMap}
                />
              </StyledTdFixed>
              <StyledTdStatus>
                <Status data={data} />
              </StyledTdStatus>
              <Td>
                <TaskDate data={data} />
              </Td>
              <Td>
                <DownloadButton
                  data={data}
                  handleSubmitTargetDownloadStep={handleSubmitTargetDownloadStep}
                  handleOpenDownloadDialog={handleOpenDownloadDialog}
                  columnIndex={index}
                  setSelectedColumnIndex={setSelectedColumnIndex}
                  modeEn={getModeEn(
                    data.taskType as keyof typeof TASK_TYPE_TO_EN_MODE_FROM_BACKEND,
                  )}
                />
              </Td>
            </StyledTr>
          ))}
        </TableBody>
      </>
    );
  },
);
