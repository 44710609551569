import { Task } from '@/api/schemas';
import { FadeIn } from '@/components/molecules/FadeIn';
import { TASK_TYPE_TO_EN_MODE_FROM_BACKEND } from '@/constants';
import { useDownloadButtonHandlers } from '@/features/Gallery/hooks/useDownloadButtonHandlers';
import { Skelton } from '@/features/components/atoms/Skelton';
import { TaskCellContent } from '@/features/components/atoms/TaskCellContent';
import { TaskCellMode } from '@/features/components/atoms/TaskCellMode';
import { ConfirmAbortionDialog } from '@/features/components/modals/ConfirmAbortionDialog';
import { useTaskTray } from '@/hooks/global/useTaskTray';
import { usePresetIdSrcMap } from '@/hooks/presets/usePresetIdSrcMap';
import { useTasks, useTasksParam } from '@/hooks/tasks';
import { Divider } from '@mui/material';
import { Fragment, memo, useState } from 'react';
import { DownloadButton } from './DownloadButton';
import { Status } from './Status';
import { StyledTaskTrayRow } from './styled';

// TODO: util/taskTray的な外部ファイルに切り出す
const isClickableTask = (resultStatus: Task['result']['status'], taskType: Task['taskType'] ) => {
  return resultStatus === 'COMPLETED'
    && TASK_TYPE_TO_EN_MODE_FROM_BACKEND[
       taskType as keyof typeof TASK_TYPE_TO_EN_MODE_FROM_BACKEND
    ] !== 'colorTemperature'
    && TASK_TYPE_TO_EN_MODE_FROM_BACKEND[
      taskType as keyof typeof TASK_TYPE_TO_EN_MODE_FROM_BACKEND
    ] !== 'backgroundLora'
    && TASK_TYPE_TO_EN_MODE_FROM_BACKEND[
      taskType as keyof typeof TASK_TYPE_TO_EN_MODE_FROM_BACKEND
    ] !== 'multiWhite'
}

export const TaskTray = memo((): JSX.Element => {
  const { tasksParam, updateTasksParamObject } = useTasksParam({});
  const [targetId, setTargetId] = useState<string>('');
  const { setIsOpenTaskTray, setIsTaskTrayAnimation } = useTaskTray();

  const { apiResponse, isLoading } = useTasks({
    tasksParam,
    updateTasksParamObject,
    isTaskTray: true,
    hasAutoReload: true,
  });

  const {
    handleSwitchTargetDownloadStep,
    handleSubmitTargetDownloadStep,
    isOpenConfirmDialog,
    handleCloseConfirmDialog,
  } = useDownloadButtonHandlers();

  const handleOpenConfirmWithClosePopper = (id: string) => {
    setTargetId(id);
    handleSwitchTargetDownloadStep(id);
    setIsOpenTaskTray(false);
    setIsTaskTrayAnimation(false);
  };

  const handleSubmit = () => {
    setIsOpenTaskTray(false);
    setIsTaskTrayAnimation(false);
    handleSubmitTargetDownloadStep(targetId);
  };

  const { presetIdSrcMap } = usePresetIdSrcMap();

  return (
    <>
      {!apiResponse && isLoading && (
        <>
          <Skelton mode="taskTray" />
        </>
      )}
      {apiResponse && (
        <FadeIn duration={0.2}>
          <Divider />
          {apiResponse.data.map((data: Task) => (
            <Fragment key={`taskTray-${data.id}`}>
              <StyledTaskTrayRow
                isClickable={isClickableTask(data.result.status, data.taskType)}
                status={data.result.status}
                onClick={() =>
                  isClickableTask(data.result.status, data.taskType)
                  && handleOpenConfirmWithClosePopper(data.id)
                }
              >
                <TaskCellMode data={data} isTray />
                <TaskCellContent
                  data={data}
                  presetIdSrcMap={presetIdSrcMap}
                />
                <Status data={data} />
                {isClickableTask(data.result.status, data.taskType) &&
                  <DownloadButton />
                }
              </StyledTaskTrayRow>
              <Divider />
            </Fragment>
          ))}
        </FadeIn>
      )}
      <ConfirmAbortionDialog
        isOpenDialog={isOpenConfirmDialog}
        onSubmit={handleSubmit}
        onClose={handleCloseConfirmDialog}
      />
    </>
  );
});
