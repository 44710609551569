import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import { PostTasksV2Param } from '@/types/backendApi';
import { useCallback } from 'react';

export const useWhiteParam = () => {
  const { featureData } = useFeaturesContext({});

  const getWhiteColorParam = useCallback(() => {
    const param: PostTasksV2Param = {
      generationMode: 'misc_background_removal',
      originalImages: [
        {
          inputImageId: featureData?.mainImage.id,
          maskImageId: featureData?.mainImage.maskId,
        }
      ],
      displayName: featureData?.mainImage.fileName,
      nSample: 1,
      parameters: {
        noBackground: featureData?.single?.param?.isNoBackground,
      },
    };

    return param;
  }, [
    featureData?.mainImage.id,
    featureData?.mainImage.maskId,
    featureData?.mainImage.fileName,
    featureData?.single?.param?.isNoBackground,
  ]);

  return {
    getWhiteColorParam,
  };
};
