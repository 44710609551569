import { ContentHeader } from '@/features/components/organisms/ContentHeader';
import {
  StyledScrollBox,
  StyledSingleContentBox,
  StyledSplitLeftBox,
  StyledSplitRightBox,
} from '@/features/components/templates/Layout/styled';
import { memo } from 'react';

type Props = {
  children: React.ReactNode[];
};

export const FeaturesSplitLayout = memo(({ children }: Props): JSX.Element => {
  const [leftNode, rightNode] = children;

  return (
    <>
      <ContentHeader />
      <StyledSingleContentBox>
        <StyledSplitLeftBox>
          <StyledScrollBox>{leftNode}</StyledScrollBox>
        </StyledSplitLeftBox>
        <StyledSplitRightBox>
          <StyledScrollBox>{rightNode}</StyledScrollBox>
        </StyledSplitRightBox>
      </StyledSingleContentBox>
    </>
  );
});
