import { Box } from '@/components/atoms/Box';
import { Dialog } from '@/components/molecules/Dialog';
import {
  StyledBoxHeight24,
  StyledBoxHeight5,
  StyledFlexEndGap5,
  StyledFont,
  StyledGridGap20,
} from '@/components/styled';
import {
  StyledDialogAddUserButton,
  StyledDialogAddUserCancelButton,
} from '@/components/styled/button';
import { ErrorCard } from '@/features/components/atoms/ErrorCard';
import { validateEmail } from '@/utils/email';
import { TextField } from '@mui/material';
import { memo, useCallback, useEffect, useState } from 'react';

type Props = {
  isOpenDialog: boolean;
  handleCloseDialog: () => void;
  handleInviteUser: (arg: {
    email: string;
    name: string | null;
  }) => Promise<void>;
};

export const AdminAddUserDialog = memo(
  ({
    isOpenDialog,
    handleCloseDialog,
    handleInviteUser,
  }: Props): JSX.Element => {
    const [userEmail, setUserEmail] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string[]>([]);
    const [isDisable, setIsDisable] = useState<boolean>(true);
    const [isInput, setIsInput] = useState<boolean>(false);

    const handleSubmit = useCallback(() => {
      // biome-ignore lint/complexity/noVoid: あとで修正
      void handleInviteUser({ email: userEmail, name: null });
      handleCloseDialog();
      setUserEmail('');
    }, [handleCloseDialog, handleInviteUser, userEmail]);

    const handleChangeEmail = useCallback((val: string) => {
      setIsInput(true);
      setUserEmail(val);
    }, []);

    const handleCheckEmail = useCallback(
      (val: string) => {
        setIsInput(false);

        // フォーム入力からフォーカスが外れたときにエラーチェックを実行
        const isEmailValid = validateEmail(val);

        if (userEmail && !isEmailValid) {
          setErrorMessage(['無効なメールアドレスです']);
        } else {
          setErrorMessage([]);
        }
      },
      [userEmail],
    );

    useEffect(() => {
      if (userEmail === '' || errorMessage.length > 0) {
        setIsDisable(true);

        return;
      }
      setIsDisable(false);
    }, [errorMessage, userEmail]);

    return (
      <>
        <Dialog
          isOpenDialog={isOpenDialog}
          onClose={handleCloseDialog}
          maxWidth="sm"
          dialogTitle="ユーザーを新規追加"
        >
          <Box>
            <StyledGridGap20>
              <StyledFont>
                追加するユーザーのメールアドレスを入力してください
              </StyledFont>
              <Box>
                <TextField
                  label="メールアドレス"
                  variant="standard"
                  sx={{ width: 320 }}
                  value={userEmail}
                  onChange={(e) => handleChangeEmail(e.target.value)}
                  onBlur={(e) => handleCheckEmail(e.target.value)}
                />
              </Box>
              {errorMessage.length > 0 && (
                <ErrorCard errorMessage={errorMessage} width={260} />
              )}
              <StyledBoxHeight5 />
              <StyledFlexEndGap5>
                <StyledDialogAddUserCancelButton onClick={handleCloseDialog}>
                  キャンセル
                </StyledDialogAddUserCancelButton>
                <StyledDialogAddUserButton
                  disabled={isDisable || isInput}
                  onClick={handleSubmit}
                >
                  この内容で追加
                </StyledDialogAddUserButton>
              </StyledFlexEndGap5>
            </StyledGridGap20>
          </Box>
          <StyledBoxHeight24 />
        </Dialog>
      </>
    );
  },
);
