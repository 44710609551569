import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { ImageWithLoader } from '@/components/molecules/ImageWithLoader/ImageWithLoader';
import {
  StyledFlex,
  StyledFlexEnd,
  StyledFlexGap10,
  StyledFont,
  StyledFont16Bold,
  StyledFontBold,
  StyledGridGap20,
} from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { initialFeatureDataImageState } from '@/contexts/FeaturesContext/initial';
import {
  FeatureBatchOriginalImage,
  FeatureData,
} from '@/contexts/FeaturesContext/types';
import { UploadRefWithOptionalSam } from '@/features/components/modals/UploadRefWithOptionalSam';
import { FunctionViewType } from '@/features/components/steps/BatchUpload/types';
import {
  StyledFunctionMessageBox,
  StyledFunctionMessageEnd,
  StyledFunctionMessageStar,
} from '@/features/components/styled/function';
import { useDialog } from '@/hooks/local/useDialog';
import { useResizeBase64 } from '@/hooks/utils/useResizeBase64';
import { Divider } from '@mui/material';
import { memo, useCallback, useMemo, useState } from 'react';
import { StyledFileName, StyledImageBox } from './styled';

type Props = {
  functionViewType: FunctionViewType;
  handleDeleteSelectedImage: () => void;
  selectedImageUrl: string;
  selectedImageFileName: string;
  imageSize: {
    width: number;
    height: number;
    bytes: number;
  };
  selectedOriginalImage: FeatureBatchOriginalImage | undefined;
  featureData: FeatureData | undefined;
  currentNumber: number;
};

export const ImageStatus = memo(
  ({
    functionViewType,
    handleDeleteSelectedImage,
    selectedImageUrl,
    selectedImageFileName,
    imageSize,
    selectedOriginalImage,
    featureData,
    currentNumber,
  }: Props): JSX.Element => {
    const { activeFeatureName, updateFeatureData, updateFeatureDataArray } =
      useFeaturesContext({});
    const [selectedInputId, setSelectedInputId] = useState<string>('');
    const { getResizedBase64 } = useResizeBase64();

    const combinedBase64Thumbnail268 = useMemo(() => {
      if (!selectedOriginalImage) return null;
      if (!selectedOriginalImage.mainImage) return null;

      return selectedOriginalImage.mainImage.combinedBase64Thumbnail268;
    }, [selectedOriginalImage]);

    const { isOpenDialog, handleOpenDialog, handleCloseDialog } = useDialog();

    const handleOpenSamDialog = () => {
      if (!selectedOriginalImage) return;
      console.log(selectedOriginalImage.mainImage);
      console.log(selectedOriginalImage.inputImageId);
      setSelectedInputId(selectedOriginalImage.inputImageId || '');
      updateFeatureData('mainImage', selectedOriginalImage.mainImage);
      handleOpenDialog();
    };

    const handleSubmit = useCallback(async () => {
      // batchのmainImageを更新する
      if (!featureData) return;
      if (!featureData.mainImage) return;
      if (!selectedOriginalImage) return;
      if (!featureData.mainImage.combinedBase64) return;
      const { combinedBase64 } = featureData.mainImage;
      const { width, height } = featureData.mainImage.originalSize;
      // サムネを作る
      const [resized120, resized268, resized512] = await Promise.all([
        getResizedBase64({
          targetSize: 120,
          width,
          height,
          originalBase64: combinedBase64,
        }),
        getResizedBase64({
          targetSize: 268,
          width,
          height,
          originalBase64: combinedBase64,
        }),
        getResizedBase64({
          targetSize: 512,
          width,
          height,
          originalBase64: combinedBase64,
        }),
      ]);

      /**
       * @see 領域選択のデータ（mainImage）から、バッチのデータ（batch）のoriginalImage.mainImageを更新する
       */

      updateFeatureDataArray(
        'batch',
        featureData.batch.map((set, index) => {
          if (index === currentNumber) {
            return {
              ...set,
              uploadedAt: Date.now(),
              originalImages: [
                ...set.originalImages.map((image) => {
                  if (image.inputImageId === selectedInputId) {
                    return {
                      ...image,
                      mainImage: {
                        ...featureData.mainImage,
                        base64: featureData.mainImage.base64 || '',
                        blobUrl: featureData.mainImage.blobUrl || '',
                        maskBase64: featureData.mainImage.maskBase64 || '',
                        combinedBase64:
                          featureData.mainImage.combinedImageBase64 || '',
                        combinedBase64Thumbnail120: resized120.base64,
                        combinedBase64Thumbnail268: resized268.base64,
                        combinedBase64Thumbnail512: resized512.base64,
                        originalSize: featureData.mainImage.originalSize,
                      },
                    };
                  }

                  return image;
                }),
              ],
            };
          }

          return set;
        }),
      );
      // 初期化
      updateFeatureData('mainImage', initialFeatureDataImageState);
      handleCloseDialog();
    }, [
      featureData,
      selectedOriginalImage,
      getResizedBase64,
      updateFeatureDataArray,
      updateFeatureData,
      handleCloseDialog,
      currentNumber,
      selectedInputId,
    ]);

    return (
      <>
        {functionViewType === 'image' && selectedImageUrl && (
          <>
            <StyledFlexGap10>
              <StyledFont16Bold>画像データ</StyledFont16Bold>
            </StyledFlexGap10>
            <StyledImageBox>
              <ImageWithLoader
                src={combinedBase64Thumbnail268 || `${selectedImageUrl}&d=280x280`}
                alt=""
                fill
                style={{
                  objectFit: 'contain',
                  objectPosition: 'center',
                }}
                loaderHeight={18}
                loaderWidth={18}
                loaderMode="spinner"
              />
            </StyledImageBox>
            <StyledFlex>
              <Box style={{ whiteSpace: 'nowrap' }}>
                <StyledFontBold>画像情報</StyledFontBold>
              </Box>
              <StyledFlexEnd>
                <Button
                  mode="text"
                  sx={{
                    color: 'var(--color-primary-main)',
                    '&:hover': {
                      cursor: 'pointer',
                      color: 'var(--color-gray-main)',
                      backgroundColor: 'transparent',
                    },
                    fontSize: '13px',
                  }}
                  onClick={handleDeleteSelectedImage}
                >
                  削除
                </Button>
              </StyledFlexEnd>
            </StyledFlex>
            <StyledFlexGap10>
              <StyledFileName>{selectedImageFileName}</StyledFileName>
            </StyledFlexGap10>
            <StyledFlexGap10
              style={{
                fontSize: '14px',
              }}
            >
              解像度:
              <StyledFont>
                {imageSize.width}x{imageSize.height}
              </StyledFont>
              <StyledFont>({Math.round(imageSize.bytes / 1000)}KB)</StyledFont>
            </StyledFlexGap10>
            {activeFeatureName === 'backgroundLora' && (
              <StyledGridGap20>
                <Box />
                <Divider />
                <StyledFunctionMessageBox>
                  <StyledFunctionMessageStar>※</StyledFunctionMessageStar>
                  <StyledFunctionMessageEnd>
                    選択範囲のプレビューでプレビューで赤く塗られた領域が挿げ替えの対象となります。
                  </StyledFunctionMessageEnd>
                </StyledFunctionMessageBox>

                <Button
                  mode="border"
                  sx={{
                    width: '280px',
                    height: '30px',
                    color: 'var(--color-primary-main)',
                    '&:hover': {
                      cursor: 'pointer',
                      color: 'var(--color-gray-main)',
                      backgroundColor: 'transparent',
                    },
                    fontSize: '13px',
                  }}
                  onClick={handleOpenSamDialog}
                >
                  選択範囲を修正
                </Button>
              </StyledGridGap20>
            )}
          </>
        )}
        <UploadRefWithOptionalSam
          isOpenDialog={isOpenDialog}
          onClose={handleCloseDialog}
          onSubmit={() => {
            // biome-ignore lint/complexity/noVoid: あとで修正
            void handleSubmit();
          }}
          dialogTitle="選択範囲の調整"
          dialogExplain=""
          isBatch
          field="mainImage"
        />
      </>
    );
  },
);
