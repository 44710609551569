import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { initialFeatureDataImageState } from '@/contexts/FeaturesContext/initial';
import { FeatureData } from '@/contexts/FeaturesContext/types';
import { useBatchOptionalContext } from '@/features/components/steps/hooks/useBatchOptionalContext';
import { useDialog } from '@/hooks/local/useDialog';
import { useCallback } from 'react';
import { useFunctionsCoordinateParams } from './useFunctionsCoordinateParams';
import { useFunctionsHandlers } from './useFunctionsHandlers';
import { useFunctionsInitialization } from './useFunctionsInitialization';

type Props = {
  featureData: FeatureData | undefined;
  currentNumber: number;
};
export const useFunctions = ({ featureData, currentNumber }: Props) => {
  const { updateFeatureData } = useFeaturesContext({});
  const {
    isOpenDialog: isOpenBackgroundDialog,
    handleOpenDialog: handleOpenBackgroundDialog,
    handleCloseDialog: handleCloseBackgroundDialog,
  } = useDialog();
  const {
    isOpenDialog: isOpenPresetDialog,
    handleOpenDialog: handleOpenPresetDialog,
    handleCloseDialog: handleClosePresetDialog,
  } = useDialog();
  const { isCollectiveSetting, collectiveSettingParam, setCollectiveRefImage } =
    useBatchOptionalContext();

  const {
    value,
    setValue,
    handleClickFeatureTargetType,
    handleClickCollectiveSettingParam,
    handleSubmitBackground,
    handleSubmitPreset,
    isSubmit,
    setIsSubmit,
  } = useFunctionsHandlers({
    handleCloseBackgroundDialog,
    handleClosePresetDialog,
    featureData,
    currentNumber,
  });

  useFunctionsInitialization();

  const {
    targetType,
    blurLevel,
    generationCount,
    backgroundUrl,
    presetUrl,
    presetDisplayName,
  } = useFunctionsCoordinateParams({
    featureData,
    currentNumber,
  });

  const handleOpenBackgroundDialogWithLoading = useCallback(
    (targetNumber: number) => {
      if (featureData?.batch[targetNumber].refSam) {
        updateFeatureData('refImage', initialFeatureDataImageState);
        updateFeatureData('refImage', featureData?.batch[targetNumber].refSam);
      }
      handleOpenBackgroundDialog();
    },
    [featureData, handleOpenBackgroundDialog, updateFeatureData],
  );

  return {
    dialogs: {
      isOpenBackgroundDialog,
      handleOpenBackgroundDialog,
      handleOpenBackgroundDialogWithLoading,
      handleCloseBackgroundDialog,
      isOpenPresetDialog,
      handleOpenPresetDialog,
      handleClosePresetDialog,
    },
    handlers: {
      handleClickFeatureTargetType,
      handleClickCollectiveSettingParam,
      handleSubmitBackground,
      handleSubmitPreset,
    },
    value,
    setValue,
    isCollectiveSetting,
    collectiveSettingParam,
    setCollectiveRefImage,
    targetType,
    blurLevel,
    generationCount,
    presetUrl,
    presetDisplayName,
    isSubmit,
    setIsSubmit,
    backgroundUrl,
  };
};
