import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import { memo } from 'react';
import { MdCalendarToday } from 'react-icons/md';
import { useCalendarInput } from './hooks/useCalendarInput';
import {
  StyledFormControl,
  StyledInput,
  StyledPaddingRight20pxBox,
} from './styled';

type Props = {
  dateString: string;
  setDateString: (date: string) => void;
  isDateError: boolean;
  setIsDateError: (isError: boolean) => void;
  handleOpenCalendar: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isCalendarEnable?: boolean;
};

export const CalendarInput = memo(
  ({
    dateString,
    setDateString,
    isDateError,
    setIsDateError,
    handleOpenCalendar,
    isCalendarEnable,
  }: Props): JSX.Element => {
    const { inputDate, handleInputDate } = useCalendarInput({
      dateString,
      setDateString,
      isDateError,
      setIsDateError,
      isCalendarEnable,
    });

    return (
      <>
        <StyledPaddingRight20pxBox>
          <StyledFormControl variant="outlined">
            <InputLabel> </InputLabel>
            <StyledInput
              onClick={(e) => {
                handleOpenCalendar(e);
              }}
              onChange={handleInputDate}
              value={inputDate}
              isDateError={isDateError}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <MdCalendarToday size={20} />
                  </IconButton>
                </InputAdornment>
              }
            />
          </StyledFormControl>
        </StyledPaddingRight20pxBox>
      </>
    );
  },
);
