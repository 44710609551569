import { useZodGet } from '@/api';
import { UsersParam } from '@/api/params';
import { Users, UsersSchema } from '@/api/schemas';
import { usePaginator } from '@/hooks/utils/usePaginator';
import { useEffect, useRef, useState } from 'react';

type Props = {
  usersParam: UsersParam;
  updateUsersParamObject: (object: Partial<UsersParam>) => void;
};
export const useUsers = ({ usersParam, updateUsersParamObject }: Props) => {
  const [apiResponse, setApiResponse] = useState<Users | undefined>(undefined);
  const scrollBoxRef = useRef<HTMLDivElement | null>(null);
  const {
    data: taskData,
    isLoading,
    isFetching,
  } = useZodGet('users', UsersSchema, usersParam);

  const { count, offset, page, handleChangePage } = usePaginator({
    pageInfo: apiResponse?.pageInfo,
  });

  useEffect(() => {
    if (!taskData) return;
    if (isLoading || isFetching) return;
    setApiResponse(taskData);
  }, [taskData, isLoading, isFetching]);

  useEffect(() => {
    updateUsersParamObject({
      offset,
    });
  }, [updateUsersParamObject, offset]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (scrollBoxRef && scrollBoxRef.current) {
      scrollBoxRef.current.scrollTop = 0;
    }
  }, [usersParam.limit, usersParam.offset, usersParam.showDeleted]);

  return {
    apiResponse,
    isLoading,
    isFetching,
    count,
    page,
    offset,
    handleChangePage,
    scrollBoxRef,
  };
};
