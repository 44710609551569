import { NumberButton } from '@/features/components/atoms/NumberButton';
import { memo } from 'react';
import { StyledTitle } from './styled';

type Props = {
  data?: unknown;
  setValue: (value: unknown) => void;
  label?: string;
  max?: number;
};
export const GenerationCount = memo(
  ({ data, setValue, label = '生成する数', max = 4 }: Props): JSX.Element => {
    const handleClickGenerationCount = (order: number) => {
      if (Number(data || 1) + order < 1) return;
      if (Number(data || 1) + order > max) return;
      setValue({ generationCount: Number(data || 1) + order });
    };

    return (
      <>
        <StyledTitle>{label}</StyledTitle>
        <NumberButton
          generationCount={Number(data) || 1}
          onClick={handleClickGenerationCount}
        />
      </>
    );
  },
);
