import { UsersParam } from '@/api/params';
import { useCallback, useState } from 'react';

const initialUserParam: UsersParam = {
  limit: 50,
  offset: 0,
  showDeleted: false,
  timer: Date.now(),
};

/**
 * JSDoc
 * @see ユーザー一覧のパラメータを管理するカスタムフック
 * @see ユーザー追加および削除時にデータを取得するためtimerをパラメーターに含む
 * @see timerが更新されるとapiを叩く
 * @returns {usersParam, updateUsersParamObject}
 */
export const useUsersParam = () => {
  const [usersParam, setUsersParam] = useState<UsersParam>(initialUserParam);

  const updateUsersParamObject = useCallback((object: Partial<UsersParam>) => {
    setUsersParam((prev) => ({
      ...prev,
      ...object,
    }));
  }, []);

  return { usersParam, updateUsersParamObject };
};
