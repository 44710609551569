import { useBackendApi } from '@/api/hooks/useBackendApi';
import { Image } from '@/api/schemas';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { initialFeatureDataImageState } from '@/contexts/FeaturesContext/initial';
import { useBatchOptionalContext } from '@/features/components/steps/hooks/useBatchOptionalContext';
import { useError } from '@/hooks/global/useError';
import { useEffect, useState } from 'react';

type Props = {
  currentNumber: number;
  isSubmit: boolean;
  setIsSubmit: (args: boolean) => void;
};

export const useUpdateRefImage = ({
  currentNumber,
  isSubmit,
  setIsSubmit,
}: Props) => {
  const {
    featureData,
    updateFeatureDataArray,
    updateFeatureData,
    updateFeatureDataSingle,
  } = useFeaturesContext({});
  const { postImages } = useBackendApi({});
  const [refApiResponse, setRefApiResponse] = useState<Image | null>(null);
  const { logger } = useError();
  const { isCollectiveSetting, setCollectiveRefImage, collectiveRefImage } =
    useBatchOptionalContext();

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!isSubmit) return;
    if (!featureData) return;
    if (!featureData?.refImage.base64) return;
    if (refApiResponse) return;
    // biome-ignore lint/complexity/noVoid: あとで修正
    void (async () => {
      try {
        const res = await postImages({
          image: featureData?.refImage.base64,
          fileName: featureData?.refImage.fileName,
        });
        if (res) {
          setRefApiResponse(res);
        }

        return res;
      } catch (error) {
        console.error(error);
        logger({ error });

        return null;
      }
    })();
  }, [
    featureData?.refImage.base64,
    featureData?.refImage.fileName,
    currentNumber,
    postImages,
    logger,
    refApiResponse,
    featureData,
    isSubmit,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!refApiResponse) return;
    if (!featureData) return;
    if (!isSubmit) return;

    const updateSet = featureData.batch.map((set, index: number) => {
      if (index === currentNumber) {
        return {
          ...set,
          isCurrent: true,
          uploadedAt: Date.now(),
          refSam: featureData.refImage,
          refImage: {
            ...set.refImage,
            displayName: refApiResponse.fileName,
            refImageId: refApiResponse.id,
            refUrl: refApiResponse.originalImageUrl,
          },
        };
      }

      return { ...set, isCurrent: false };
    });
    if (isCollectiveSetting) {
      setCollectiveRefImage(updateSet[currentNumber].refImage);
    } else {
      updateFeatureDataArray('batch', updateSet);
    }
    updateFeatureData('refImage', initialFeatureDataImageState);
    updateFeatureDataSingle('param', {
      newRef: '',
      currentRef: '',
    });
    setRefApiResponse(null);
    setIsSubmit(false);
  }, [currentNumber, updateFeatureDataArray, refApiResponse]);

  useEffect(() => {
    console.log(collectiveRefImage);
  }, [collectiveRefImage]);
};
