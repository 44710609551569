import { axiosPostWithZod } from "@/api/axios";
import { UserResetPasswordSchema } from "@/api/schemas";
import { useAuthContext } from "@/contexts/AuthContext";
import { captureException } from "@sentry/nextjs";
import { useRouter } from "next/router";
import { useCallback } from "react";

export const useUserInfoPopper = () => {
  const router = useRouter();
  const { user, setUser } = useAuthContext();

  const postResetPassword = useCallback(
    async (id: string) => {
      const res = await axiosPostWithZod(
        `users/${id}/reset_password`,
        UserResetPasswordSchema,
        {},
      );

      return res;
    },
    [],
  );

  const handleResetPassword = useCallback(() => {
    if (!user?.id) {
      throw new Error("user.idが取得できませんでした");
    }

    (async () => {
      await postResetPassword(user.id)
    })().catch((e) => {
      console.error('パスワード再設定に失敗しました')
      captureException(e);
    })
  }, [user?.id, postResetPassword])

  const handleLogout = useCallback(() => {
    router.push('/api/auth/logout').then(() => {
      // MEMO: router.push前にsetUserを変更すると副作用でユーザー情報取得処理が走り、ログアウト処理とコンフリクトしてログアウトがうまくいっていないように思われる。（ログアウト処理だけ適切に走ればsetUserを変更する必要はないが、コンテキスト説明のため書くだけ書いておく）
      setUser(undefined);
    }).catch((e) => {
      captureException(e);
    });
  }, [router, setUser]);

  return {
    user,
    handleResetPassword,
    handleLogout,
  }
}
