import type { SVGProps } from 'react';
import { memo } from 'react';
const QueueIcon = memo((props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={20}
      fill="none"
      {...props}
    >
      <path
        fill="#000"
        fillOpacity={0.3}
        d="m0 0 .01 6L4 10 .01 14.01 0 20h12v-6l-4-4 4-3.99V0H0Zm10 14.5V18H2v-3.5l4-4 4 4Z"
      />
    </svg>
  );
});
export default QueueIcon;
