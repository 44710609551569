import type { SVGProps } from 'react';
import { memo } from 'react';

const SegmentationNegativePin = memo(
  (props: SVGProps<SVGSVGElement>): JSX.Element => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={36}
        height={42}
        fill="none"
        {...props}
      >
        <g filter="url(#segmentationRemovePin_svg__a)">
          <path
            fill="#FAFF00"
            fillRule="evenodd"
            d="M22.974 25.677A9.996 9.996 0 0 0 28 17c0-5.523-4.477-10-10-10S8 11.477 8 17a9.996 9.996 0 0 0 5.027 8.677c1.87 1.504 4.195 4.89 4.973 7.33.778-2.44 3.103-5.826 4.974-7.33Z"
            clipRule="evenodd"
          />
          <path fill="#000" fillOpacity={0.4} d="M12 16h12v2H12z" />
        </g>
        <defs>
          <filter
            id="segmentationRemovePin_svg__a"
            width={40}
            height={46}
            x={-2}
            y={-2}
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feMorphology
              in="SourceAlpha"
              radius={2}
              result="effect1_dropShadow_1070_53208"
            />
            <feOffset dy={3} />
            <feGaussianBlur stdDeviation={1.5} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1070_53208"
            />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy={3} />
            <feGaussianBlur stdDeviation={2} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
            <feBlend
              in2="effect1_dropShadow_1070_53208"
              result="effect2_dropShadow_1070_53208"
            />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy={1} />
            <feGaussianBlur stdDeviation={4} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
            <feBlend
              in2="effect2_dropShadow_1070_53208"
              result="effect3_dropShadow_1070_53208"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect3_dropShadow_1070_53208"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  },
);
export default SegmentationNegativePin;
