import { Task } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { Tooltip } from '@/components/atoms/Tooltip';
import { CheckedRef, DeletedStatus } from '@/components/icons';
import {
  StyledFlex,
  StyledFlexCenter,
  StyledFlexEnd,
  StyledFlexGap10,
} from '@/components/styled';
import { FeatureBatch } from '@/contexts/FeaturesContext/types';
import {
  StyledTableRowContainer,
  StyledTableRowName,
  StyledTableRowNameEllipsis,
  StyledTableRowNameFlex,
} from '@/features/components/styled/batch';
import { useConvertDate } from '@/hooks/utils/useConvertDate';
import { IconButton, LinearProgress } from '@mui/material';
import { memo, useEffect, useMemo } from 'react';
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdMoreVert,
} from 'react-icons/md';
import { StyledCircleImage, StyledProgressContainer } from '../styled';

type GenStatus = {
  isGenerating: boolean;
  isGenerated: boolean;
  isFailed: boolean;
};

type Props = {
  set: FeatureBatch;
  batchId: string;
  apiPostResponse: Task | undefined;
  apiGetResponse: Task | undefined;
  genStatus: GenStatus;
  handleUpdateTaskAndBatchId: () => void;
  isOpenAccordion: boolean;
  setIsOpenAccordion: (isOpen: boolean) => void;
  handleOpenDeleteMenu: (
    e: React.MouseEvent<HTMLButtonElement>,
    id: string,
    hasRetry?: boolean,
  ) => void;
  deletedIds: string[];
};

/**
 * @see バッチダウンロードの生成状態とダウンロード実行ボタンを表示するコンポーネント
 */
export const BackgroundLoraBar = memo(
  ({
    set,
    batchId,
    apiPostResponse,
    apiGetResponse,
    genStatus,
    handleUpdateTaskAndBatchId,
    isOpenAccordion,
    setIsOpenAccordion,
    handleOpenDeleteMenu,
    deletedIds,
  }: Props): JSX.Element => {
    const { convertToDateTime } = useConvertDate();

    const isDeletedSet = useMemo(() => {
      if (deletedIds.includes(set.id)) {
        return true;
      }

      return false;
    }, [deletedIds, set.id]);

    useEffect(() => {
      if (isDeletedSet) {
        setIsOpenAccordion(false);
      }
    }, [isDeletedSet, setIsOpenAccordion]);

    return (
      <>
        {apiPostResponse && apiGetResponse && apiGetResponse.result && (
          <StyledTableRowContainer
            sx={{
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              backgroundColor:
                apiGetResponse.result.status === 'IN_QUEUE' ||
                apiGetResponse.result.status === 'IN_PROGRESS' ||
                apiGetResponse.result.status === 'COMPLETED'
                  ? 'var(--color-gray-white)'
                  : batchId === set.id
                  ? '#fdeded'
                  : 'rgba(0, 0, 0, 0.05)',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor:
                  apiGetResponse.result.status === 'IN_QUEUE' ||
                  apiGetResponse.result.status === 'IN_PROGRESS' ||
                  apiGetResponse.result.status === 'COMPLETED'
                    ? '#fff'
                    : '#fdeded',
              },
            }}
            onClick={handleUpdateTaskAndBatchId}
          >
            <StyledTableRowName>
              <StyledTableRowNameFlex>
                <StyledFlexGap10>
                  <StyledCircleImage
                    src={`${set.originalImages[0].url}&d=56x56`}
                    width={56}
                    height={56}
                    alt={`${set.originalImages[0].url}`}
                  />
                  <StyledTableRowNameEllipsis>
                    <Tooltip title={set.setName} placement="bottom-start">
                      {set.setName}
                    </Tooltip>
                  </StyledTableRowNameEllipsis>
                </StyledFlexGap10>
              </StyledTableRowNameFlex>
            </StyledTableRowName>
            <Box
              style={{
                width: 200,
                minWidth: 200,
                whiteSpace: 'nowrap',
              }}
            >
              {set.uploadedAt
                ? convertToDateTime(set.uploadedAt as unknown as string)
                : 'ー'}
            </Box>
            <StyledFlexCenter style={{ width: 150, minWidth: 150 }}>
              {set.originalImages.length || 'ー'}
            </StyledFlexCenter>
            <Box style={{ minWidth: 'calc(100% - 700px)' }}>
              <StyledProgressContainer>
                {!apiGetResponse.result.progress &&
                !genStatus.isGenerated &&
                !genStatus.isFailed ? (
                  <>
                    <LinearProgress
                      sx={{ width: '100%', height: 4 }}
                      color="secondary"
                    />
                    <Box sx={{ paddingLeft: '10px', fontSize: 14 }}>待機中</Box>
                  </>
                ) : apiGetResponse.result.progress &&
                  apiGetResponse.result.progress < 1 &&
                  !genStatus.isGenerated &&
                  !genStatus.isFailed ? (
                  <>
                    <LinearProgress
                      sx={{ width: '100%', height: 4 }}
                      variant="determinate"
                      value={Math.round(
                        Number(apiGetResponse.result.progress) * 100,
                      )}
                    />
                    <Box sx={{ paddingLeft: '10px', fontSize: 14 }}>
                      {Math.round(apiGetResponse.result.progress * 100)}%
                    </Box>
                    <Box>
                      <IconButton
                        onClick={(e) => {
                          handleOpenDeleteMenu(e, set.id, true);
                          e.stopPropagation();
                        }}
                      >
                        <MdMoreVert size={20} />
                      </IconButton>
                    </Box>
                  </>
                ) : (
                  <>
                    <StyledFlex style={{ minWidth: 'calc(100% - 700px)' }}>
                      {apiGetResponse.result.status === 'COMPLETED' &&
                        !isDeletedSet &&
                        !set.refImage.refImageSelectedId && (
                          <Box
                            sx={{
                              fontSize: 14,
                            }}
                          >
                            <Button
                              style={{ width: 200, height: 32, fontSize: 13 }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsOpenAccordion(!isOpenAccordion);
                              }}
                            >
                              バリエーション選択
                            </Button>
                          </Box>
                        )}
                      {!isDeletedSet && set.refImage.refImageSelectedId && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                            fontSize: 14,
                          }}
                        >
                          <CheckedRef />
                          生成準備完了
                        </Box>
                      )}
                      {apiGetResponse.result.status === 'COMPLETED' &&
                        isDeletedSet && (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '5px',
                              fontSize: 14,
                            }}
                          >
                            <DeletedStatus />
                            生成から除外
                          </Box>
                        )}
                      {apiGetResponse.result.status === 'FAILED' && (
                        <Box
                          sx={{
                            fontSize: 14,
                            color: 'var(--color-danger-main)',
                          }}
                        >
                          失敗
                        </Box>
                      )}
                      {apiGetResponse.result.status === 'TIMEOUT' && (
                        <Box
                          sx={{
                            fontSize: 14,
                            color: 'var(--color-danger-main)',
                          }}
                        >
                          失敗
                        </Box>
                      )}
                    </StyledFlex>
                    {apiGetResponse.result.status === 'COMPLETED' && (
                      <StyledFlexEnd style={{ gap: '30px' }}>
                        <IconButton
                          onClick={(e) => {
                            handleOpenDeleteMenu(e, set.id, false);
                            e.stopPropagation();
                          }}
                        >
                          <MdMoreVert size={20} />
                        </IconButton>

                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsOpenAccordion(!isOpenAccordion);
                          }}
                          disabled={isDeletedSet}
                        >
                          <>
                            {isOpenAccordion ? (
                              <MdKeyboardArrowUp size={20} />
                            ) : (
                              <MdKeyboardArrowDown size={20} />
                            )}
                          </>
                        </IconButton>
                      </StyledFlexEnd>
                    )}
                  </>
                )}
              </StyledProgressContainer>
            </Box>
          </StyledTableRowContainer>
        )}
      </>
    );
  },
);
