import {
  StyledBoxHeight32,
  StyledBoxHeight64,
  StyledFlexEnd,
} from '@/components/styled';
import { StylePopperTaskTrayGalleryButton } from '@/components/styled/button';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { TaskTray } from '@/features/Gallery/uniques/TaskTray';
import { memo } from 'react';
import { StyledPopperFlex, StyledPopperGrid } from '../../styled';
import { useTaskTrayPopper } from '../hooks/useTaskTrayPopper';
import {
  StyledTaskTrayContainer,
  StyledTaskTrayContentBox,
  StyledTaskTrayFooterBox,
  StyledTaskTrayFooterFixed,
} from './styled';
/**
 * JSDoc
 * @see タスクトレイポッパー
 * @returns {JSX.Element}
 */
export const TaskTrayPopper = memo((): JSX.Element => {
  const { activateTargetFeature } = useFeaturesContext({});
  const { handleCloseTaskTrayPopper } = useTaskTrayPopper();

  const handleGotoGallery = () => {
    handleCloseTaskTrayPopper();
    activateTargetFeature('gallery');
  };

  return (
    <StyledTaskTrayContainer>
      <StyledPopperGrid>
        <StyledPopperFlex>
          <StyledTaskTrayContentBox>
            <TaskTray />
            <StyledBoxHeight64 />
          </StyledTaskTrayContentBox>
        </StyledPopperFlex>
      </StyledPopperGrid>
      <StyledFlexEnd>
        <StyledTaskTrayFooterBox />
        <StyledTaskTrayFooterFixed>
          <StylePopperTaskTrayGalleryButton onClick={() => handleGotoGallery()}>
            生成ギャラリーで確認
          </StylePopperTaskTrayGalleryButton>
        </StyledTaskTrayFooterFixed>
      </StyledFlexEnd>
      <StyledBoxHeight32 />
    </StyledTaskTrayContainer>
  );
});
