import { Box } from '@/components/atoms/Box';
import { BATCH_MAX_SIZE } from '@/constants';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { memo } from 'react';

export const UploadRule = memo((): JSX.Element => {
  const { activeFeatureName } = useFeaturesContext({});

  return (
    <>
      <Box
        style={{
          padding: '16px',
          backgroundColor: '#eee',
          display: 'flex',
          gap: '16px',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            backgroundColor: '#fff',
            padding: '16px',
            border: '1px solid #ccc',
            fontSize: '14px',
            minWidth: '390px',
          }}
        >
          <Box>【 下記を満たす画像データをご使用ください 】</Box>
          <Box>
            <Box>・フォーマット：JPEG／PNG／WEBP いずれか</Box>
            <Box>
              ・サイズ：500 x 500 〜{' '}
              {BATCH_MAX_SIZE[activeFeatureName as keyof typeof BATCH_MAX_SIZE]}{' '}
              x{' '}
              {BATCH_MAX_SIZE[activeFeatureName as keyof typeof BATCH_MAX_SIZE]}
              px
            </Box>
            <Box style={{ paddingLeft: '10px' }}>
              ※全身が写っている場合は 750 x 750 px 以上を推奨
            </Box>
            <Box>・ファイル容量：5MB以下</Box>
            <Box
              style={{
                fontSize: '12px',
                color: 'var(--color-danger-main)',
              }}
            >
              ※指定サイズ以上の画像使用する場合は縮小されます
            </Box>
          </Box>
        </Box>
        <Box style={{ fontSize: '12px' }}>
          アップロードする画像は、ご自身で著作権を保有しているか、権利者から仕様許諾を得た画像を、
          アップロード者ご自身の責任においてご利用ください。
          <br />
          他社の著作物を無断で使用することは、著作権法に違反する可能性がありますのでご注意ください。
          <br />
          アップロードいただいた画像は、AIモデルの学習用データとして使用され、
          低解像度のサムネイル画像として保存されます。
        </Box>
      </Box>
    </>
  );
});
