import { Box } from '@/components/atoms/Box';
import { StyledFlex } from '@/components/styled';
import { UploadedImages } from '@/features/components/steps/BatchSetting/UploadedImages';
import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { useBatchOptionalContext } from '@/features/components/steps/hooks/useBatchOptionalContext';
import { memo, useMemo } from 'react';

type Props = {
  targetId: string;
  targetNumber: number;
};

export const Content = memo(
  ({ targetId, targetNumber }: Props): JSX.Element => {
    const { isCollectiveSetting } = useBatchOptionalContext();
    const { currentNumber } = useBatchUpload();

    const backgroundColor = useMemo(() => {
      if (isCollectiveSetting) {
        return '#F5FAFF';
      }

      return currentNumber === targetNumber ? '#F5FAFF' : '#fff';
    }, [currentNumber, isCollectiveSetting, targetNumber]);

    return (
      <>
        <Box
          style={{
            borderTop: '1px solid #ddd',
            display: 'grid',
            padding: '16px 24px 16px 24px',
            backgroundColor,
          }}
        >
          <StyledFlex style={{ gap: '24px', alignItems: 'flex-start' }}>
            <UploadedImages targetId={targetId} targetNumber={targetNumber} />
          </StyledFlex>
        </Box>
      </>
    );
  },
);
