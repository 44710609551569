import { SegmentationNegative, SegmentationPositive } from '@/components/icons';
import {
  StyledFlexGap10,
  StyledFont16,
  StyledFont16Bold,
  StyledFont16Main,
  StyledGridGap10,
} from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageFields } from '@/contexts/FeaturesContext/types';
import { RadioButton } from '@/features/components/atoms/RadioButton';
import { memo, useCallback } from 'react';

type Props = {
  field: ImageFields;
  isKeepMask?: boolean;
};

/**
 * JSDoc
 * @see セグメンテーションのポイントタイプを切り替えるコンポーネント
 * @param {Props} { field }
 * @returns {JSX.Element}
 */
export const PointTypeRadio = memo(
  ({ field, isKeepMask = false }: Props): JSX.Element => {
    const { featureData, updateFeatureData } = useFeaturesContext({});
    const pointType = !isKeepMask ? 'pointType' : 'kmPointType';

    const handleClickRadio = useCallback(
      (target: string) => {
        updateFeatureData(field, {
          [pointType]: target,
        });
      },
      [field, pointType, updateFeatureData],
    );

    return (
      <>
        {featureData?.[field].samType === 'manual' && (
          <>
            <StyledFont16Bold>ツールの切り替え</StyledFont16Bold>
            <StyledGridGap10>
              <RadioButton
                target="positive"
                radioValue={featureData?.[field][pointType]}
                onClick={() => {
                  handleClickRadio('positive');
                }}
                label={
                  <StyledFlexGap10>
                    <SegmentationPositive
                      color={
                        featureData?.[field][pointType] === 'positive'
                          ? '#00D2A0'
                          : 'var(--color-gray-soft)'
                      }
                    />
                    {featureData?.[field][pointType] === 'positive' ? (
                      <StyledFont16>選択範囲を追加</StyledFont16>
                    ) : (
                      <StyledFont16Main>選択範囲を追加</StyledFont16Main>
                    )}
                  </StyledFlexGap10>
                }
                isActive={featureData?.[field][pointType] === 'positive'}
              />
              <RadioButton
                target="negative"
                radioValue={featureData?.[field][pointType]}
                onClick={() => {
                  handleClickRadio('negative');
                }}
                label={
                  <StyledFlexGap10>
                    <SegmentationNegative
                      color={
                        featureData?.[field][pointType] === 'negative'
                          ? '#FAFF00'
                          : 'var(--color-gray-soft)'
                      }
                    />
                    {featureData?.[field][pointType] === 'negative' ? (
                      <StyledFont16>選択範囲を解除</StyledFont16>
                    ) : (
                      <StyledFont16Main>選択範囲を解除</StyledFont16Main>
                    )}
                  </StyledFlexGap10>
                }
                isActive={featureData?.[field][pointType] === 'negative'}
              />
            </StyledGridGap10>
          </>
        )}
      </>
    );
  },
);
