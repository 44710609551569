import { Box } from '@/components/atoms/Box';
import {
  StyledModalDownloadContentBox,
  StyledModalDownloadRightBox,
} from '@/features/components/templates/Layout/styled';
import { memo } from 'react';

type Props = {
  children: React.ReactNode[];
};

export const FeaturesModalDownloadLayout = memo(
  ({ children }: Props): JSX.Element => {
    return (
      <>
        <Box>{children[0]}</Box>
        <StyledModalDownloadContentBox>
          <Box>{children[1]}</Box>
          <StyledModalDownloadRightBox>
            {children[2]}
          </StyledModalDownloadRightBox>
        </StyledModalDownloadContentBox>
      </>
    );
  },
);
