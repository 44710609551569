import { User, Users } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import {
  StyledFlexEnd,
  StyledFlexGap10,
  StyledFont,
} from '@/components/styled';
import { StyledAdminDetailBorderButton } from '@/components/styled/button';
import { useAuthContext } from '@/contexts/AuthContext';
import { AdminControlUserDialog } from '@/features/components/modals/AdminControlUserDialog';
import { useDialog } from '@/hooks/local/useDialog';
import { useIcons } from '@/hooks/utils/useIcons';
import { TableBody, TableCell as Td, TableRow as Tr } from '@mui/material';
import { memo, useCallback, useState } from 'react';

type Props = {
  apiResponse: Users;
  showDeleted: boolean;
  handleDeleteUser: (email: string, id: string) => Promise<void>;
  handleReInviteUser: (email: string, id: string) => Promise<void>;
};

export const Body = memo(
  ({
    apiResponse,
    showDeleted,
    handleDeleteUser,
    handleReInviteUser,
  }: Props): JSX.Element => {
    const { isOpenDialog, handleOpenDialog, handleCloseDialog } = useDialog();
    const [targetUser, setTargetUser] = useState<User | null>(null);
    const [isDeletedUser, setIsDeletedUser] = useState<boolean>(false);
    const [dialogTitle, setDialogTitle] = useState<string>('');
    const { user } = useAuthContext();

    const { returnIcon } = useIcons();

    const handleClick = useCallback(
      (d: User) => {
        setTargetUser(d);
        setDialogTitle('ユーザー情報');
        if (d.deletedAt) {
          setIsDeletedUser(true);
        } else {
          setIsDeletedUser(false);
        }

        handleOpenDialog();
      },
      [handleOpenDialog],
    );

    return (
      <>
        <TableBody>
          {apiResponse.data.map(
            (d: User) =>
              (showDeleted || !d.deletedAt) && (
                <Tr
                  key={d.id}
                  sx={{
                    backgroundColor: d.deletedAt
                      ? 'var(--color-gray-light)'
                      : !d.lastLoginAt
                      ? 'var(--color-gray-pale)'
                      : '',
                  }}
                >
                  <Td>
                    <StyledFlexGap10>
                      <Box>
                        {returnIcon(
                          d.deletedAt
                            ? 'remove'
                            : !d.lastLoginAt
                            ? 'add'
                            : 'user',
                        )}
                      </Box>
                      <StyledFont>{d.email}</StyledFont>
                    </StyledFlexGap10>
                  </Td>
                  <Td>
                    <StyledFlexGap10>
                      <StyledFont>
                        {d.deletedAt
                          ? '削除済み'
                          : !d.lastLoginAt
                          ? '承認待ち'
                          : '利用中'}
                      </StyledFont>
                    </StyledFlexGap10>
                  </Td>
                  <Td>
                    <StyledFlexEnd>
                      {d.email !== user?.email && (
                        <StyledAdminDetailBorderButton
                          onClick={() => {
                            handleClick(d);
                          }}
                        >
                          詳細
                        </StyledAdminDetailBorderButton>
                      )}
                    </StyledFlexEnd>
                  </Td>
                </Tr>
              ),
          )}
        </TableBody>
        <AdminControlUserDialog
          dialogTitle={dialogTitle}
          targetUser={targetUser}
          isDeletedUser={isDeletedUser}
          isOpenDialog={isOpenDialog}
          handleCloseDialog={handleCloseDialog}
          handleDeleteUser={handleDeleteUser}
          handleReInviteUser={handleReInviteUser}
        />
      </>
    );
  },
);
