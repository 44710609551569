import { memo } from 'react';
import { StyledTabButton } from './styled';

type Props = {
  label: string;
  isActive: boolean;
  onClick: () => void;
};
/**
 * JSDoc
 * @see タブボタン
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const TabButton = memo(
  ({
    label,
    isActive,
    onClick,
  }: Props): JSX.Element => {
    return (
      <StyledTabButton
        onClick={onClick}
        isActive={isActive}
      >
        {label}
      </StyledTabButton>
    );
  },
);
