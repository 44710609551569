import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledSearchContainer = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledSearchContainer} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledDateSpanBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledDateSpanBox} {...props}>
      {children}
    </Box>
  );
});

export const StyledYear = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledYear} {...props}>
      {children}
    </Box>
  );
});

type MonthProps = {
  month: number;
  targetYear: number;
  thisYear: number;
  thisMonth: number;
} & BoxProps;

export const StyledMonth = memo(
  ({
    month,
    targetYear,
    thisYear,
    thisMonth,
    children,
    ...props
  }: MonthProps) => {
    const monthStyle = {
      color:
        thisYear === targetYear && thisMonth === month
          ? 'var(--color-primary-main)'
          : 'var(--color-gray-deep)',
      borderBottom:
        thisYear === targetYear && thisMonth === month
          ? `2px solid ${'var(--color-primary-main)'}`
          : 'none',
    };

    return (
      <Box className={vanilla.styledMonth} sx={monthStyle} {...props}>
        {children}
      </Box>
    );
  },
);
