import { axiosDelete, axiosPostWithZod } from '@/api/axios';
import { UsersParam } from '@/api/params';
import { UserResetPasswordSchema, UserSchema } from '@/api/schemas';
import { useError } from '@/hooks/global/useError';
import { useSnackbar } from '@/hooks/global/useSnackbar';
import { SelectChangeEvent } from '@mui/material';
import { useCallback, useState } from 'react';

type Props = {
  updateUsersParamObject: (object: Partial<UsersParam>) => void;
};

export const useUsersHandlers = ({ updateUsersParamObject }: Props) => {
  const { handleOpenSnackbar, setSnackbarParam } = useSnackbar();
  const { logger } = useError();
  const [isToggledShowDeleted, setIsToggledShowDeleted] = useState(false);

  const inviteUser = useCallback(
    async (param: { email: string; name: string | null }) => {
      const res = await axiosPostWithZod(`users/invite`, UserSchema, param);
      updateUsersParamObject({ timer: Date.now() });

      return res;
    },
    [updateUsersParamObject],
  );

  const reInviteUser = useCallback(
    async (id: string) => {
      const res = await axiosPostWithZod(
        `users/${id}/reset_password`,
        UserResetPasswordSchema,
        {},
      );
      updateUsersParamObject({ timer: Date.now() });

      return res;
    },
    [updateUsersParamObject],
  );

  const deleteUser = useCallback(
    async (id: string) => {
      // biome-ignore lint/complexity/noVoid: あとで修正
      void (await axiosDelete(`users/${id}`));
      updateUsersParamObject({ timer: Date.now() });
    },
    [updateUsersParamObject],
  );

  const handleInviteUser = useCallback(
    async (param: { email: string; name: string | null }): Promise<void> => {
      try {
        await inviteUser(param);
        setSnackbarParam({
          iconName: 'success',
          text: `${param.email}へ招待を送信しました`,
          position: { placement: 'rightTop', x: 0, y: 0 },
        });
        handleOpenSnackbar();
      } catch (error) {
        logger({ error });
      }
    },
    [handleOpenSnackbar, inviteUser, logger, setSnackbarParam],
  );

  const handleReInviteUser = useCallback(
    async (email: string, id: string) => {
      try {
        await reInviteUser(id);
        setSnackbarParam({
          iconName: 'success',
          text: `${email}へ招待を送信しました`,
          position: { placement: 'rightTop', x: 0, y: 0 },
        });
        handleOpenSnackbar();
      } catch (error) {
        logger({ error });
      }
    },
    [handleOpenSnackbar, logger, reInviteUser, setSnackbarParam],
  );

  const handleDeleteUser = useCallback(
    async (email: string, id: string) => {
      try {
        // biome-ignore lint/complexity/noVoid: あとで修正
        void (await deleteUser(id));
        setSnackbarParam({
          iconName: 'success',
          text: `${email}を削除しました`,
          position: { placement: 'rightTop', x: 0, y: 0 },
        });
        handleOpenSnackbar();
      } catch (error) {
        logger({ error });
      }
    },
    [deleteUser, handleOpenSnackbar, logger, setSnackbarParam],
  );

  const handleToggleShowDeleted = useCallback(() => {
    if (!isToggledShowDeleted) {
      setIsToggledShowDeleted(true);
      updateUsersParamObject({
        showDeleted: true,
        timer: Date.now(),
      });
    } else {
      setIsToggledShowDeleted(false);
      updateUsersParamObject({
        showDeleted: false,
        timer: Date.now(),
      });
    }
  }, [isToggledShowDeleted, updateUsersParamObject]);

  const handelChangeLimit = useCallback(
    (event: SelectChangeEvent<number>) => {
      updateUsersParamObject({
        offset: 0,
        limit: Number(event.target.value),
        timer: Date.now(),
      });
    },
    [updateUsersParamObject],
  );

  return {
    handelChangeLimit,
    handleInviteUser,
    handleReInviteUser,
    handleDeleteUser,
    isToggledShowDeleted,
    handleToggleShowDeleted,
  };
};
