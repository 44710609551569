import { useAccordionContext } from '@/features/components/molecules/AccordionWrapper/hooks/useAccordionContext';
import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { memo } from 'react';
import { Body } from './Body';
import { Head } from './Head';

export const SetTable = memo((): JSX.Element => {
  const { currentNumber } = useBatchUpload();
  const {
    isDisabledAccordionAll,
    accordionAllButtonObject,
    handleClickAllAccordion,
  } = useAccordionContext({ isInitial: true, currentNumber });

  return (
    <>
      <Head
        isDisabledAccordionAll={isDisabledAccordionAll}
        accordionAllButtonObject={accordionAllButtonObject}
        handleClickAllAccordion={handleClickAllAccordion}
      />
      <Body />
    </>
  );
});
