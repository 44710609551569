import { Report, ReportSchema, UserSchema } from '@/api/schemas';

import { useZodGet } from '@/api';
import { useConvertDate } from '@/hooks/utils/useConvertDate';
import { useEffect, useState } from 'react';

/**
 * JSDoc
 * @see ダウンロード管理APIカスタムフック
 * @returns {Object}
 */
export const useDownloadManagement = () => {
  const [timer, setTimer] = useState(Date.now());
  const [reportApiResponse, setReportApiResponse] = useState<
    Report | undefined
  >(undefined);
  const [startYear, setStartYear] = useState<number>(0);
  const [startMonth, setStartMonth] = useState<number>(0);
  const [endYear, setEndYear] = useState<number>(0);
  const [endMonth, setEndMonth] = useState<number>(0);
  const [targetYear, setTargetYear] = useState<number>(0);
  const [targetMonth, setTargetMonth] = useState<number>(0);
  const [isCurrent, setIsCurrent] = useState<boolean>(true);

  const { convertToUnixTime } = useConvertDate();

  const {
    data: userData,
    isLoading: isUserLading,
    isFetching: isUserFetching,
  } = useZodGet('users/by/auth0', UserSchema, {});

  const [apiUrl, setApiUrl] = useState('organizations/usage/reports/current');
  const { data, isLoading, isFetching } = useZodGet(
    apiUrl,
    ReportSchema,
    {},
    timer,
  );

  useEffect(() => {
    if (!targetYear || !targetMonth) return;
    console.log(targetMonth);
    if (isCurrent) {
      setIsCurrent(false);

      return;
    }
    setApiUrl(
      `organizations/usage/reports/${targetYear}-${String(targetMonth).padStart(
        2,
        '0',
      )}`,
    );
    setTimer(Date.now());
  }, [targetYear, targetMonth, isCurrent]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (isUserLading || isUserFetching) return;
    if (!userData) return;
    const createdDate = userData.organization?.createdAt as unknown as string;
    const [createdYear, createdMonth] = createdDate.split('-');

    setStartYear(Number(createdYear));
    setStartMonth(Number(createdMonth));
    if (isLoading || isFetching) return;
    if (!data) return;
    setReportApiResponse(data);

    if (!endYear && !endMonth && data.endDate) {
      const date = data.startDate as unknown as string;
      const [year, month] = date.split('-');
      setEndYear(Number(year));
      setEndMonth(Number(month));
      setTargetYear(Number(year));
      setTargetMonth(Number(month));
    }
  }, [
    convertToUnixTime,
    data,
    endMonth,
    endYear,
    isFetching,
    isLoading,
    isUserFetching,
    isUserLading,
    timer,
    userData,
  ]);

  return {
    startYear,
    startMonth,
    endYear,
    endMonth,
    targetYear,
    targetMonth,
    setTargetYear,
    setTargetMonth,
    reportApiResponse,
    isLoading,
  };
};
