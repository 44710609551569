import { Box } from '@/components/atoms/Box';
import { Skelton } from '@/features/components/atoms/Skelton';
import { ContentHeader } from '@/features/components/organisms/ContentHeader';
import { GlobalNavigator } from '@/features/components/organisms/GlobalNavigator';
import { StyledScrollContainer } from '@/features/components/styled/navigator';
import { StyledTable } from '@/features/components/styled/table';
import { useUsers, useUsersHandlers, useUsersParam } from '@/hooks/users';
import { Table } from '@mui/material';
import { memo, useRef } from 'react';
import { Body } from './Body';
import { Head } from './Head';
import { StyledDataContainer } from './styled';

export const UserList = memo((): JSX.Element => {
  const { usersParam, updateUsersParamObject } = useUsersParam();

  const { apiResponse, isLoading, count, page, handleChangePage } = useUsers({
    usersParam,
    updateUsersParamObject,
  });

  const {
    handelChangeLimit,
    handleInviteUser,
    handleReInviteUser,
    handleDeleteUser,
    isToggledShowDeleted,
    handleToggleShowDeleted,
  } = useUsersHandlers({
    updateUsersParamObject,
  });

  const scrollBoxRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <ContentHeader />
      <StyledScrollContainer ref={scrollBoxRef}>
        <StyledDataContainer>
          {isLoading &&
          <Box>
            <Skelton mode="userList" />
          </Box>
          }
          {!isLoading && apiResponse &&
          <StyledTable>
            <Table>
              <Head
                showDeleted={isToggledShowDeleted}
                handleShowDeleted={handleToggleShowDeleted}
                handleInviteUser={handleInviteUser}
              />
              {apiResponse && apiResponse.data.length > 0 && (
                <Body
                  apiResponse={apiResponse}
                  showDeleted={isToggledShowDeleted}
                  handleReInviteUser={handleReInviteUser}
                  handleDeleteUser={handleDeleteUser}
                />
              )}
            </Table>
          </StyledTable>
          }
        </StyledDataContainer>
      </StyledScrollContainer>
      <GlobalNavigator
        isLoading={isLoading}
        limit={usersParam.limit}
        count={count}
        page={page}
        handelChangeLimit={handelChangeLimit}
        handleChangePage={handleChangePage}
      />
    </>
  );
});
