import {
  FeatureBatchOriginalImage,
  FeatureBatchRef,
  FeatureImageItem,
  FeatureParam,
  FeaturePresetImage,
} from '@/contexts/FeaturesContext/types';
import { v4 as uuidv4 } from 'uuid';

export const initialBatchState = {
  id: uuidv4(),
  setName: '名称未設定',
  updatedAt: '',
  isCurrent: true,
  refSam: {} as FeatureImageItem,
  refImage: {} as FeatureBatchRef,
  presetImage: {} as FeaturePresetImage,
  mainParam: {} as FeatureParam,
  originalImages: [] as FeatureBatchOriginalImage[],
  deleteImages: [],
  hasGenCondition: false,
};
