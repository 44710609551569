import { memo } from 'react';
import { TaskList } from './uniques/TaskList';

/**
 * JSDoc
 * @see ギャラリーコンポーネント
 * @returns {JSX.Element}
 */
export const Gallery = memo((): JSX.Element => {
  return (
    <>
      <TaskList />
    </>
  );
});
