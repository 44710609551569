import { SegmentationNegative, SegmentationPositive } from '@/components/icons';
import {
  StyledFlexGap10,
  StyledFont16,
  StyledFont16Bold,
  StyledFont16Main,
  StyledGridGap10,
} from '@/components/styled';
import { RadioButton } from '@/features/components/atoms/RadioButton';
import { Dispatch, SetStateAction, memo } from 'react';

type Props = {
  samType: 'manual' | 'auto';
  pointType: 'positive' | 'negative';
  setPointType: Dispatch<SetStateAction<'positive' | 'negative'>>
};

export const PointTypeRadio = memo(
  ({
    samType,
    pointType,
    setPointType,
  }: Props): JSX.Element => {
    return (
      <>
        {samType === 'manual' && (
          <>
            <StyledFont16Bold>ツールの切り替え</StyledFont16Bold>
            <StyledGridGap10>
              <RadioButton
                target="positive"
                radioValue={pointType}
                onClick={() => {setPointType('positive');}}
                label={
                  <StyledFlexGap10>
                    <SegmentationPositive
                      color={
                        pointType === 'positive'
                          ? '#00D2A0'
                          : 'var(--color-gray-soft)'
                      }
                    />
                    {pointType === 'positive' ? (
                      <StyledFont16>選択範囲を追加</StyledFont16>
                    ) : (
                      <StyledFont16Main>選択範囲を追加</StyledFont16Main>
                    )}
                  </StyledFlexGap10>
                }
                isActive={pointType === 'positive'}
              />
              <RadioButton
                target="negative"
                radioValue={pointType}
                onClick={() => {setPointType('negative');}}
                label={
                  <StyledFlexGap10>
                    <SegmentationNegative
                      color={
                        pointType === 'negative'
                          ? '#FAFF00'
                          : 'var(--color-gray-soft)'
                      }
                    />
                    {pointType === 'negative' ? (
                      <StyledFont16>選択範囲を解除</StyledFont16>
                    ) : (
                      <StyledFont16Main>選択範囲を解除</StyledFont16Main>
                    )}
                  </StyledFlexGap10>
                }
                isActive={pointType === 'negative'}
              />
            </StyledGridGap10>
          </>
        )}
      </>
    );
  },
);
